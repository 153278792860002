import styled, { css } from "styled-components";
import { shade } from "polished";

interface IGenderButtons {
    isGenderEmpty: boolean;
}

interface IButtonGenderProps {
    selected: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    background: #fff;
    border-radius: 10px;
    padding: 25px;

    form {
        max-width: 900px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
`;

export const Warning = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 2px solid #c53030;
    margin: 10px 0;
    border-radius: 10px;

    svg {
        margin-right: 16px;
        min-width: 25px;
        width: 30px;
        height: 30px;
        color: #c53030;
    }

    span {
        text-align: start;
    }
`;

export const IGenderButtons = styled.div<IGenderButtons>`
    display: flex;
    align-items: center;
    justify-content: start;
    align-self: flex-start;

    /* isGenderEmpty */
    ${props =>
        props.isGenderEmpty &&
        css`
            border: 2px #c53030 solid;
            border-radius: 5px;
        `}
`;

export const Gender = styled.div<IButtonGenderProps>`
    /* display: ${props => (props.selected ? "flex" : "none")}; */
    display: flex;
    align-items: center;
    padding: 10px;
    background: #eaf6bc;
    border-radius: 5px;
    /* margin-right: 16px; */
    cursor: pointer;

    & + & {
        margin-left: 16px;
    }

    transition: color 0.2s;

    &:hover {
        /* background: ${shade(0.1, "#b2d235")}; */
        background: #b2d235;
    }

    ${props =>
        props.selected &&
        css`
            cursor: not-allowed;
            background: #b2d235;

            &:hover {
                background: #b2d235;
            }
        `}

    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    button {
        background: transparent;
        border: none;

        ${props =>
            props.selected &&
            css`
                cursor: not-allowed;
            `}
    }
`;
