import styled, { css } from "styled-components";
import { shade } from "polished";

interface IAddPreviousNamesProps {
    enabled: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    background: #fff;
    border-radius: 10px;
    padding: 25px;

    form {
        max-width: 900px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
`;

export const Warning = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 2px solid #c53030;
    margin: 1rem 0;
    border-radius: 10px;

    svg {
        margin-right: 16px;
        min-width: 25px;
        width: 30px;
        height: 30px;
        color: #c53030;
    }

    span {
        text-align: start;
    }
`;

export const AddPreviousNames = styled.div<IAddPreviousNamesProps>`
    /* display: flex; */
    display: ${props => (props.enabled ? "flex" : "none")};
    align-items: center;
    padding: 10px;
    margin: 0 0 1rem;
    background: #b2d235;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;

    max-width: 210px;

    transition: color 0.2s;

    ${props =>
        !props.enabled &&
        css`
            cursor: not-allowed;
        `}

    &:hover {
        background: ${shade(0.1, "#b2d235")};
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    span {
        font-weight: 500;
        text-transform: uppercase;
        color: #333;
    }
`;

export const PreviousNameList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    /* border: 1px solid #b2d235; */
    /* padding: 0 5px; */
    margin-bottom: 20px;
`;

export const PreviousNameItem = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;

    svg {
        min-width: 30px;
        width: 20px;
        height: 20px;
        margin-left: 16px;
        color: #c53030;
        cursor: pointer;
    }
`;

export const PreviousNameInputs = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;

    div {
        /* margin: 5px 0; */
        margin: 0.5rem 0;
    }

    @media only screen and (min-width: 992px) {
        flex-direction: row;

        div {
            margin: 0 5px;
            width: 32%;
        }
    }
`;

export const PreviousNameRemoveButton = styled.div``;
