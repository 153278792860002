import React, { useMemo } from "react";

import DetailBox from "../DetailBox";

import { IMOJAddress } from "../../../types/mojTypes";

// Utils
import { ensureObjectExists } from "../../../utils/arrayUtilities";

import {
    AddressListBoxes,
    AddressBox,
    AddressBoxTitle,
    AddressBoxLabels,
    AddressBoxLine,
    AddressBoxField,
    AddressBoxValue,
} from "./styles";

interface IAddressesDetailsProps {
    addresses: IMOJAddress[];
}

const AddressesDetails: React.FC<IAddressesDetailsProps> = ({ addresses }) => {
    const getPostalAddress = useMemo(() => {
        if (addresses && addresses.length > 0) {
            const postalAddressExisting = ensureObjectExists(
                addresses.find(mojAddress => mojAddress.addressType === 1),
            );

            if (postalAddressExisting) {
                return {
                    streetAddress: postalAddressExisting.streetAddress,
                    suburb: postalAddressExisting.suburb,
                    city: postalAddressExisting.city,
                    state: postalAddressExisting.state,
                    postcode: postalAddressExisting.postcode,
                } as IMOJAddress;
            }
        }

        return {} as IMOJAddress;
    }, [addresses]);

    const getResidentialAddress = useMemo(() => {
        if (addresses && addresses.length > 0) {
            const postalResidentialExisting = ensureObjectExists(
                addresses.find(mojAddress => mojAddress.addressType === 2),
            );

            if (postalResidentialExisting) {
                return {
                    streetAddress: postalResidentialExisting.streetAddress,
                    suburb: postalResidentialExisting.suburb,
                    city: postalResidentialExisting.city,
                    state: postalResidentialExisting.state,
                    postcode: postalResidentialExisting.postcode,
                } as IMOJAddress;
            }
        }

        return {} as IMOJAddress;
    }, [addresses]);

    const getPreviousAddresses = useMemo(() => {
        if (addresses && addresses.length > 0) {
            const mojOtherAddresses = addresses
                .filter(
                    mojAddressItem =>
                        mojAddressItem.addressType !== 1 &&
                        mojAddressItem.addressType !== 2,
                )
                .sort((a, b) => (a.order > b.order ? 1 : -1));

            const listExistingPreviousAddress: IMOJAddress[] = [];

            // mojOtherAddresses.map((previousAddressItem, index) => {
            //     const existingPreviousAddress: IMOJAddress = {
            //         ...previousAddressItem,
            //         order: index + 1,
            //     };

            //     listExistingPreviousAddress.push(existingPreviousAddress);
            // });

            for (let index = 0; index < mojOtherAddresses.length; index++) {
                const element = mojOtherAddresses[index];

                const existingPreviousAddress: IMOJAddress = {
                    ...element,
                    order: index + 1,
                };

                listExistingPreviousAddress.push(existingPreviousAddress);
            }

            return listExistingPreviousAddress;
        }

        return [] as IMOJAddress[];
    }, [addresses]);

    return (
        <DetailBox title="Addresses">
            <AddressListBoxes>
                <AddressBox>
                    <AddressBoxTitle>Postal Address</AddressBoxTitle>
                    <AddressBoxLabels>
                        <AddressBoxLine
                            style={{
                                marginTop: "8px",
                            }}
                        >
                            <AddressBoxField>
                                PO Box/Street Address
                            </AddressBoxField>
                            <AddressBoxValue>
                                {getPostalAddress.streetAddress}
                            </AddressBoxValue>
                        </AddressBoxLine>
                        <AddressBoxLine>
                            <AddressBoxField>Suburb</AddressBoxField>
                            <AddressBoxValue>
                                {getPostalAddress.suburb}
                            </AddressBoxValue>
                        </AddressBoxLine>
                        <AddressBoxLine>
                            <AddressBoxField>City</AddressBoxField>
                            <AddressBoxValue>
                                {getPostalAddress.city}
                            </AddressBoxValue>
                        </AddressBoxLine>
                        <AddressBoxLine>
                            <AddressBoxField>Region</AddressBoxField>
                            <AddressBoxValue>
                                {getPostalAddress.state}
                            </AddressBoxValue>
                        </AddressBoxLine>
                        <AddressBoxLine>
                            <AddressBoxField>Postcode</AddressBoxField>
                            <AddressBoxValue>
                                {getPostalAddress.postcode}
                            </AddressBoxValue>
                        </AddressBoxLine>
                    </AddressBoxLabels>
                </AddressBox>
                <AddressBox>
                    <AddressBoxTitle>Residential Address</AddressBoxTitle>
                    <AddressBoxLabels>
                        <AddressBoxLine
                            style={{
                                marginTop: "8px",
                            }}
                        >
                            <AddressBoxField>
                                PO Box/Street Address
                            </AddressBoxField>
                            <AddressBoxValue>
                                {getResidentialAddress.streetAddress}
                            </AddressBoxValue>
                        </AddressBoxLine>
                        <AddressBoxLine>
                            <AddressBoxField>Suburb</AddressBoxField>
                            <AddressBoxValue>
                                {getResidentialAddress.suburb}
                            </AddressBoxValue>
                        </AddressBoxLine>
                        <AddressBoxLine>
                            <AddressBoxField>City</AddressBoxField>
                            <AddressBoxValue>
                                {getResidentialAddress.city}
                            </AddressBoxValue>
                        </AddressBoxLine>
                        <AddressBoxLine>
                            <AddressBoxField>Region</AddressBoxField>
                            <AddressBoxValue>
                                {getResidentialAddress.state}
                            </AddressBoxValue>
                        </AddressBoxLine>
                        <AddressBoxLine>
                            <AddressBoxField>Postcode</AddressBoxField>
                            <AddressBoxValue>
                                {getResidentialAddress.postcode}
                            </AddressBoxValue>
                        </AddressBoxLine>
                    </AddressBoxLabels>
                </AddressBox>

                {getPreviousAddresses &&
                    getPreviousAddresses.map((previousAddress, index) => (
                        <AddressBox key={previousAddress.order}>
                            <AddressBoxTitle>
                                {`Previous Address ${index + 1}`}
                            </AddressBoxTitle>
                            <AddressBoxLabels>
                                <AddressBoxLine
                                    style={{
                                        marginTop: "8px",
                                    }}
                                >
                                    <AddressBoxField>
                                        PO Box/Street Address
                                    </AddressBoxField>
                                    <AddressBoxValue>
                                        {previousAddress.streetAddress}
                                    </AddressBoxValue>
                                </AddressBoxLine>
                                <AddressBoxLine>
                                    <AddressBoxField>Suburb</AddressBoxField>
                                    <AddressBoxValue>
                                        {previousAddress.suburb}
                                    </AddressBoxValue>
                                </AddressBoxLine>
                                <AddressBoxLine>
                                    <AddressBoxField>City</AddressBoxField>
                                    <AddressBoxValue>
                                        {previousAddress.city}
                                    </AddressBoxValue>
                                </AddressBoxLine>
                                <AddressBoxLine>
                                    <AddressBoxField>Region</AddressBoxField>
                                    <AddressBoxValue>
                                        {previousAddress.state}
                                    </AddressBoxValue>
                                </AddressBoxLine>
                                <AddressBoxLine>
                                    <AddressBoxField>Postcode</AddressBoxField>
                                    <AddressBoxValue>
                                        {previousAddress.postcode}
                                    </AddressBoxValue>
                                </AddressBoxLine>
                            </AddressBoxLabels>
                        </AddressBox>
                    ))}
            </AddressListBoxes>
        </DetailBox>
    );
};

export default AddressesDetails;
