import React, { useMemo } from "react";

// Hooks
import { useAuth } from "../../hooks/auth";

interface ICanProps {
    // roleName: string;
    action: string;
    yes: React.ComponentType;
    // no: React.ComponentType;
}

const Can: React.FC<ICanProps> = ({
    action,
    yes: AllowedComponent,
    // no: NotAllowedComponent,
}) => {
    const { isUserAllowed } = useAuth();

    const userAllowed = useMemo(() => {
        return isUserAllowed(action);
    }, [isUserAllowed, action]);

    return userAllowed ? <AllowedComponent /> : null;
};

export default Can;
