import React, { useEffect, useRef } from "react";
import ReactSelect, {
    OptionTypeBase,
    Props as SelectProps,
} from "react-select";

import { useField } from "@unform/core";

import { IconBaseProps } from "react-icons";
import { FiAlertCircle } from "react-icons/fi";

import { Container, Error } from "./styles";

interface InputProps extends SelectProps<OptionTypeBase> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    data: IDataOptions[];
}

interface IDataOptions {
    value: string;
    label: string;
}

const Select: React.FC<InputProps> = ({ name, icon: Icon, data, ...rest }) => {
    const selectRef = useRef(null);

    const { fieldName, defaultValue, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map(
                        (option: OptionTypeBase) => option.value,
                    );
                }
                if (!ref.state.value) {
                    return "";
                }
                return ref.state.value.value;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);

    return (
        <Container isErrored={!!error} className="MyContainer">
            {Icon && <Icon size={20} />}
            <ReactSelect
                className="selectList"
                defaultValue={defaultValue}
                ref={selectRef}
                classNamePrefix="react-select"
                value={data.find(item => item.value === defaultValue)}
                options={data}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    minHeight: "50px",
                    borderColor: "#7c9220",
                    colors: {
                        ...theme.colors,
                        primary25: "#b2d235",
                        primary: "#b2d235",
                    },
                })}
                {...rest}
            />
            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default Select;
