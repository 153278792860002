import React from "react";

import Modal from "../../components/Modal";

import { Container, Paragraph, Subtitle } from "./styles";

interface IModalProps {
    show: boolean;
    size: "sm" | "md" | "lg" | "xl";
    onClose: any;
}

const PrivacyPolicyModal: React.FC<IModalProps> = ({ show, size, onClose }) => {
    return (
        <Modal onClose={onClose} show={show} size={size} title="Privacy Policy">
            <Container>
                <Paragraph>
                    BOSS Online takes your privacy seriously. We follow all
                    rules set out in the Privacy Act 1993 which controls how
                    ‘agencies’ collect, use, disclose, store and give access to
                    personal information. BOSS Online has the right to change,
                    modify or update this policy.
                </Paragraph>
                <Subtitle>Confidential or personal information</Subtitle>
                <Paragraph>
                    We acknowledge that all the information related to your
                    Criminal Check is highly confidential. You (‘the applicant’)
                    have control of what information you provide us with for the
                    purpose of a Criminal Record Check. This may include, and is
                    not limited to name, gender, contact details, ID
                    information, date and place of birth etc. BOSS Online will
                    only use this information for the purpose of a Criminal
                    Record Check.
                </Paragraph>
                <Paragraph>
                    BOSS Online will not sell, trade, or disclose your personal
                    information to anyone without your permission unless
                    required by law.
                </Paragraph>
                <Paragraph>
                    If required by law, BOSS Online holds the right to check
                    your ID with the Issuer of Official Record Holder to verify
                    the information provided, or if necessary we will disclose
                    your information to government authorities or third parties
                    involved in legal action.
                </Paragraph>
                <Subtitle>Updating your personal information</Subtitle>
                <Paragraph>
                    The applicant has the right to view, update or correct their
                    personal information held by BOSS Online. Should you need to
                    view, update or correct your personal information, please do
                    so in writing to{" "}
                    <a href="mailto:admin@bossonline.co.nz">
                        admin@bossonline.co.nz
                    </a>{" "}
                    where one of our team members will be able to help you with
                    your inquiry.
                </Paragraph>
                <Subtitle>Web Site Privacy</Subtitle>
                <Paragraph>
                    At BOSS Online the privacy of our visitors is extremely
                    important. In order to provide our customers with the best
                    experience possible, like many other websites, we use log
                    files. The information inside the log files includes
                    internet protocol (IP) addresses, type of browser, Internet
                    Service Provider (ISP), date/time stamp, referring/exit
                    pages, and number of clicks to analyze trends, administer
                    the site, track user’s movement around the site, and gather
                    demographic information. IP addresses, and other such
                    information are not linked to any information that is
                    personally identifiable.
                </Paragraph>
                <Paragraph fontStyle="italic">
                    For any information relating to the privacy of your
                    information, please contact our team via email:{" "}
                    <a href="mailto:admin@bossonline.co.nz">
                        admin@bossonline.co.nz
                    </a>
                </Paragraph>
            </Container>
        </Modal>
    );
};

export default PrivacyPolicyModal;
