import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #fff;
    padding: 16px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 1rem;

    font-size: 1.5rem;
    font-weight: 700;

    background: #f2f1f3;
    color: #333;
    border-radius: 0.5rem;
    text-transform: uppercase;
`;

export const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const BadgeGold = styled.span`
    letter-spacing: 0.3px;

    color: "#333";

    /* background-color: "transparent"; */
    background: var(--background-gold);

    display: inline-block;
    width: 50%;
    padding: 4px 8px;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const BadgeSilver = styled.span`
    letter-spacing: 0.3px;

    color: "#333";

    background: var(--background-silver);

    display: inline-block;
    width: 50%;
    padding: 4px 8px;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const BadgeBronze = styled.span`
    letter-spacing: 0.3px;

    color: "#333";

    background: var(--background-bronze);

    display: inline-block;
    width: 50%;
    padding: 4px 8px;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const ActionsCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a,
    button {
        color: #333;
        background: transparent;
        padding: 0.4rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.7;
        }
    }
`;
