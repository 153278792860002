import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

// Icons
import { FiLogIn } from "react-icons/fi";
import {
    FaRegBuilding,
    FaRegUser,
    FaBars,
    FaChevronDown,
    FaChevronUp,
    FaSignInAlt,
} from "react-icons/fa";
import { MdAccountCircle, MdClose } from "react-icons/md";

// Hooks
import UseOutsideClick from "../../../../hooks/UseOutsideClick";

// image
import bossLogo from "../../../../assets/boss-logo.png";
import bossLogoGreen from "../../../../assets/boss-logo-green.png";

// Common Types
import UrlsAddress from "../../../../types/urlsAddress";

// Styles
import { FixedHeader, AccountLinks, MobileMenu } from "./styles";

interface IMenuLinks {
    id: number;
    section?: string;
    text: string;
    parent?: boolean;
}

const menuLinks: IMenuLinks[] = [
    { id: 1, section: "home", text: "Home" },
    { id: 2, section: "about-us", text: "About Us" },
    { id: 3, section: "pricing", text: "Pricing" },
    { id: 4, section: "contact-us", text: "Contact us" },
];

const Header: React.FC = () => {
    // States
    const [showAccountLinks, setShowAccountLinks] = useState(false);
    const [divLinksTopPosition, setDivLinksTopPosition] = useState(0);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [expandSignUpMenu, setExpandSignUpMenu] = useState(false);
    const [activeLink, setActiveLink] = useState(1);
    const [headerBackgroundColor, setHeaderBackgroundColor] = useState(
        "transparent",
    );
    const [transparentHeader, setTransparentHeader] = useState(true);
    const [logo, setLogo] = useState(bossLogoGreen);

    // Refs
    const prevScrollY = useRef(0);
    const refLinksAccount = useRef<HTMLDivElement>(null);

    // const handleMenuTransparency = (currentScrollY: number) => {
    //     if (currentScrollY > 50 && headerBackgroundColor === "transparent") {
    //         setTransparentHeader(false);
    //         setHeaderBackgroundColor("#fff");
    //         setLogo(bossLogo);
    //     }

    //     if (currentScrollY <= 50) {
    //         setTransparentHeader(true);
    //         setHeaderBackgroundColor("transparent");
    //         setLogo(bossLogoGreen);
    //     }
    // };
    const handleMenuTransparency = useCallback(
        (currentScrollY: number) => {
            if (
                currentScrollY > 50 &&
                headerBackgroundColor === "transparent"
            ) {
                setTransparentHeader(false);
                setHeaderBackgroundColor("#fff");
                setLogo(bossLogo);
            }

            if (currentScrollY <= 50) {
                setTransparentHeader(true);
                setHeaderBackgroundColor("transparent");
                setLogo(bossLogoGreen);
            }
        },
        [headerBackgroundColor],
    );

    // const handleSectionActive = () => {
    //     const sectionsLanding = document.querySelectorAll(".landing-section");

    //     let minorDistance = window.innerHeight;
    //     let activeSection;

    //     if (sectionsLanding) {
    //         [].forEach.call(sectionsLanding, function (sectionItem: Element) {
    //             const offset = sectionItem.getBoundingClientRect();

    //             if (Math.abs(offset.top) < minorDistance) {
    //                 minorDistance = Math.abs(offset.top);
    //                 activeSection = sectionItem;
    //             }
    //         });

    //         if (activeSection) {
    //             const sectionId = (activeSection as Element).id;

    //             if (sectionId) {
    //                 const menuLink = menuLinks.find(
    //                     link => link.section === sectionId,
    //                 );

    //                 if (menuLink?.id === activeLink) return;

    //                 if (menuLink) {
    //                     setActiveLink(menuLink.id);
    //                 }
    //             }
    //         }
    //     }
    // };
    const handleSectionActive = useCallback(() => {
        const sectionsLanding = document.querySelectorAll(".landing-section");

        let minorDistance = window.innerHeight;
        let activeSection;

        if (sectionsLanding) {
            [].forEach.call(sectionsLanding, (sectionItem: Element) => {
                const offset = sectionItem.getBoundingClientRect();

                if (Math.abs(offset.top) < minorDistance) {
                    minorDistance = Math.abs(offset.top);
                    activeSection = sectionItem;
                }
            });

            if (activeSection) {
                const sectionId = (activeSection as Element).id;

                if (sectionId) {
                    const menuLink = menuLinks.find(
                        link => link.section === sectionId,
                    );

                    if (menuLink?.id === activeLink) return;

                    if (menuLink) {
                        setActiveLink(menuLink.id);
                    }
                }
            }
        }
    }, [activeLink]);

    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;

        // Handle Menu Transparency
        handleMenuTransparency(currentScrollY);

        // Handle Section is visible
        handleSectionActive();

        prevScrollY.current = currentScrollY;
    }, [handleMenuTransparency, handleSectionActive]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);
    }, [headerBackgroundColor, handleScroll]);

    UseOutsideClick(refLinksAccount, () => {
        if (showAccountLinks) {
            setShowAccountLinks(false);
        }
    });

    // const handleAccountMenuClick = () => {
    //     const btnAccountLink = document.getElementById("btnAccountLink");

    //     if (btnAccountLink) {
    //         const domRect: ClientRect = btnAccountLink.getBoundingClientRect();

    //         const { bottom, right } = domRect;

    //         setDivLinksTopPosition(bottom + 5);
    //     }

    //     if (!showAccountLinks) {
    //         setShowAccountLinks(true);
    //     } else {
    //         setShowAccountLinks(false);
    //     }
    // };
    const handleAccountMenuClick = useCallback(() => {
        const btnAccountLink = document.getElementById("btnAccountLink");

        if (btnAccountLink) {
            const domRect: ClientRect = btnAccountLink.getBoundingClientRect();

            const { bottom } = domRect;

            setDivLinksTopPosition(bottom + 5);
        }

        if (!showAccountLinks) {
            setShowAccountLinks(true);
        } else {
            setShowAccountLinks(false);
        }
    }, [showAccountLinks]);

    // const handleToggleMenuMobile = (id: number | null) => {
    //     if (!showMobileMenu) {
    //         setShowMobileMenu(true);
    //     } else {
    //         setShowMobileMenu(false);
    //     }

    //     if (id) {
    //         handleMenuClick(id);
    //     }
    // };

    const handleMenuClick = useCallback((id: number) => {
        const menuLink = menuLinks.find(link => link.id === id);

        if (menuLink && menuLink.section) {
            const elSectionContainer = document.getElementById(
                menuLink.section,
            );

            if (elSectionContainer) {
                const { top } = elSectionContainer.getBoundingClientRect();
                const headerHeight = 70;

                if (top) {
                    const topPosition = top + window.pageYOffset - headerHeight;

                    window.scrollTo({ top: topPosition, behavior: "smooth" });
                }
            }
        }
    }, []);

    const handleToggleMenuMobile = useCallback(
        (id: number | null) => {
            if (!showMobileMenu) {
                setShowMobileMenu(true);
            } else {
                setShowMobileMenu(false);
            }

            if (id) {
                handleMenuClick(id);
            }
        },
        [handleMenuClick, showMobileMenu],
    );

    // const handleExpandSignUpMenu = () => {
    //     if (!expandSignUpMenu) {
    //         setExpandSignUpMenu(true);
    //     } else {
    //         setExpandSignUpMenu(false);
    //     }
    // };
    const handleExpandSignUpMenu = useCallback(() => {
        if (!expandSignUpMenu) {
            setExpandSignUpMenu(true);
        } else {
            setExpandSignUpMenu(false);
        }
    }, [expandSignUpMenu]);

    // const handleMenuClick = (id: number) => {
    //     const menuLink = menuLinks.find(link => link.id === id);

    //     if (menuLink && menuLink.section) {
    //         const elSectionContainer = document.getElementById(
    //             menuLink.section,
    //         );

    //         if (elSectionContainer) {
    //             const { top } = elSectionContainer.getBoundingClientRect();
    //             const headerHeight = 70;

    //             if (top) {
    //                 const topPosition = top + window.pageYOffset - headerHeight;

    //                 window.scrollTo({ top: topPosition, behavior: "smooth" });
    //             }
    //         }
    //     }
    // };

    return (
        <>
            <FixedHeader
                backgroundColor={headerBackgroundColor}
                transparentHeader={transparentHeader}
            >
                <div className="container-large">
                    <div className="navbar">
                        <div className="navbrand">
                            <img src={logo} alt="BOSS Technologies" />
                        </div>
                        <div className="nav-links">
                            <ul>
                                {menuLinks.map(link => (
                                    <li
                                        key={link.id}
                                        className={
                                            link.id === activeLink
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleMenuClick(link.id)
                                            }
                                        >
                                            {link.text}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="extra-menu">
                            <AccountLinks
                                showLinks={showAccountLinks}
                                top={divLinksTopPosition}
                                transparentHeader={transparentHeader}
                            >
                                <div className="account-links-container">
                                    <button
                                        type="button"
                                        id="btnAccountLink"
                                        onClick={() => handleAccountMenuClick()}
                                    >
                                        Account <MdAccountCircle size={30} />
                                    </button>
                                    <div
                                        ref={refLinksAccount}
                                        className="links-account"
                                    >
                                        <ul className="authorization">
                                            <li className="link">
                                                {/* <a href="#">
                                                    <FiLogIn size={20} />
                                                    Login
                                                </a> */}
                                                {/* <Link to="/sign-in"> */}
                                                <Link to={UrlsAddress.SIGN_IN}>
                                                    <span data-hover="Login">
                                                        <FiLogIn size={20} />
                                                        Login
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <h4>Sign Up</h4>
                                            </li>
                                            <li className="link">
                                                {/* <a href="#">
                                                    <FaRegUser size={20} />
                                                    Customer
                                                </a> */}
                                                {/* <Link to="/sign-up-customer"> */}
                                                <Link
                                                    to={
                                                        UrlsAddress.SIGN_UP_CUSTOMER
                                                    }
                                                >
                                                    <span data-hover="Customer">
                                                        <FaRegUser />
                                                        Customer
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className="link">
                                                {/* <a href="#">
                                                    <FaRegBuilding size={20} />
                                                    Company
                                                </a> */}
                                                {/* <Link to="/sign-up-company"> */}
                                                <Link
                                                    to={
                                                        UrlsAddress.SIGN_UP_COMPANY
                                                    }
                                                >
                                                    <span data-hover="Company">
                                                        <FaRegBuilding />
                                                        Company
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </AccountLinks>
                        </div>
                        <div className="mobile-menu">
                            <button type="button">
                                <FaBars
                                    onClick={() => handleToggleMenuMobile(null)}
                                    size={30}
                                />
                            </button>
                        </div>
                        <MobileMenu
                            // ref={refMobileMenu}
                            showMenu={showMobileMenu}
                            displaySignUpLinks={expandSignUpMenu}
                        >
                            <div className="backgroundMobileMenu">
                                <div className="foregroundMobileMenu">
                                    <ul className="linksMobileMenu">
                                        <button
                                            type="button"
                                            className="linksLineMobileMenu"
                                            onClick={() =>
                                                handleToggleMenuMobile(null)
                                            }
                                        >
                                            <span />
                                            <MdClose size={30} />
                                        </button>
                                    </ul>
                                    <ul className="linksMobileMenu">
                                        {menuLinks.map((link, index) => {
                                            let classname =
                                                "linksLineMobileMenu";

                                            if (!menuLinks[index + 1]) {
                                                classname =
                                                    "linksLineMobileMenu last-child";
                                            }

                                            return (
                                                <button
                                                    type="button"
                                                    key={link.id}
                                                    onClick={() =>
                                                        handleToggleMenuMobile(
                                                            link.id,
                                                        )
                                                    }
                                                    className={classname}
                                                >
                                                    <span>{link.text}</span>
                                                </button>
                                            );
                                        })}
                                    </ul>
                                    <hr
                                        className="linksMobileMenuDivider"
                                        style={{
                                            background:
                                                "rgba(255, 255, 255, 0.8)",
                                        }}
                                    />
                                    <ul className="linksMobileMenu">
                                        <Link
                                            // to="/sign-in"
                                            to={UrlsAddress.SIGN_IN}
                                            className="linksLineMobileMenu authLink"
                                        >
                                            <span>Login</span>
                                            <FaSignInAlt size={20} />
                                        </Link>
                                    </ul>
                                    <hr
                                        className="linksMobileMenuDivider"
                                        style={{
                                            background:
                                                "rgba(255, 255, 255, 0.8)",
                                        }}
                                    />
                                    <ul className="linksMobileMenu">
                                        <button
                                            type="button"
                                            onClick={handleExpandSignUpMenu}
                                            className="linksLineMobileMenu authLink"
                                        >
                                            <span>Sign Up</span>
                                            {expandSignUpMenu ? (
                                                <FaChevronUp size={20} />
                                            ) : (
                                                <FaChevronDown size={20} />
                                            )}
                                        </button>
                                        <Link
                                            // to="/sign-up-customer"
                                            to={UrlsAddress.SIGN_UP_CUSTOMER}
                                            className="childLinkLineMobileMenu"
                                        >
                                            <FaRegUser size={14} />
                                            <span>Customer</span>
                                        </Link>
                                        <Link
                                            // to="/sign-up-company"
                                            to={UrlsAddress.SIGN_UP_COMPANY}
                                            className="childLinkLineMobileMenu"
                                        >
                                            <FaRegBuilding size={14} />
                                            <span>Company</span>
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </MobileMenu>
                    </div>
                </div>
            </FixedHeader>
        </>
    );
};

export default Header;
