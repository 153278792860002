import styled, { css } from "styled-components";
import { shade } from "polished";

interface IAddPreviousAddressProps {
    enabled: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    background: #fff;
    border-radius: 10px;
    padding: 25px;

    form {
        max-width: 900px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
`;

export const AddressBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const AddressBlockTitle = styled.div`
    align-self: flex-start;
    margin-bottom: 8px;

    span {
        font-size: 24px;
        font-weight: 500;
    }
`;

export const MoreAddress = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const PreviousAddressList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Warning = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 2px solid #c53030;
    margin: 10px 0;
    border-radius: 10px;

    svg {
        margin-right: 16px;
        min-width: 25px;
        width: 30px;
        height: 30px;
        color: #c53030;
    }

    span {
        text-align: start;
    }
`;

export const PreviousAddressItem = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;

    svg:first-child {
        margin-right: 16px;
        color: #7c9220;
    }

    svg {
        min-width: 30px;
        width: 20px;
        height: 20px;
        margin-left: 16px;
        color: #c53030;
        cursor: pointer;
    }
`;

export const PreviousAddressItemBlock = styled.div`
    flex: 1;
`;

// PreviousAddressRemoveButton
// AddPreviousAddress

export const PreviousAddressItemRemoveButton = styled.div``;

export const AddPreviousAddress = styled.div<IAddPreviousAddressProps>`
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 0 10px;
    background: #b2d235;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;

    max-width: 240px;

    transition: color 0.2s;

    ${props =>
        !props.enabled &&
        css`
            cursor: not-allowed;
        `}

    &:hover {
        background: ${shade(0.1, "#b2d235")};
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    span {
        font-weight: 500;
        text-transform: uppercase;
        color: #333;
    }
`;
