import React, {
    InputHTMLAttributes,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { IconBaseProps } from "react-icons";
import { FiAlertCircle } from "react-icons/fi";
import { useField } from "@unform/core";

import { Container, Error } from "./styles";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    inline?: boolean;
    label: string;
    min?: number;
    max?: number;
}

const InputCurrencyWithLabel: React.FC<IInputProps> = ({
    name,
    inline,
    icon: Icon,
    label,
    min,
    max,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [currentValue, setCurrentValue] = useState("");

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const { fieldName, defaultValue, error, registerField } = useField(name);

    const { readOnly } = rest;

    useEffect(() => {
        if (defaultValue) {
            setIsFilled(true);
            setCurrentValue(defaultValue);
        }
    }, [defaultValue]);

    const formatValue = useCallback((value: string): void => {
        let formattedValue = value;
        let formatted = false;
        const hasDecimal = value.includes(".");

        const currencyValues = value.split(".");

        if (hasDecimal && currencyValues[1].length < 2) {
            formattedValue = `${currencyValues[0]}.${currencyValues[1].padEnd(
                2,
                "0",
            )}`;
            formatted = true;
        }

        if (!formatted && !hasDecimal) {
            formattedValue = value.replace(".", "");
            formattedValue = Number(formattedValue).toFixed(2);
            formatted = true;
        }

        if (inputRef.current && formatted) {
            inputRef.current.value = formattedValue;
            setCurrentValue(formattedValue);
        }
    }, []);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);

        setIsFilled(!!inputRef.current?.value);

        if (inputRef.current) {
            formatValue(inputRef.current.value);
        }
    }, [formatValue]);

    useEffect(() => {
        registerField({
            name: fieldName,
            // Within "current" is our "input"
            ref: inputRef.current,
            // When needs to get input value, we access by "path"
            path: "value",
        });
    }, [fieldName, registerField]);

    // const getValue = (val: string): number => {

    //     if (val.length > 2) {
    //         return Number(val).toFixed(2);
    //     }

    //     return
    // }

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newValue = e.currentTarget.value;

            const currencyValues = newValue.split(".");

            const hasDecimal = newValue.includes(".");

            if (!hasDecimal && currencyValues[0].length > 4) return;

            if (hasDecimal && currencyValues[1].length > 2) return;

            if (inputRef && inputRef.current) {
                const formattedValue = newValue;

                inputRef.current.value = formattedValue;
            }

            setCurrentValue(newValue);
        },
        [],
    );

    return (
        <Container
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocused}
            isReadOnly={readOnly || false}
            inline={inline}
        >
            {Icon && <Icon size={20} />}
            <input
                id={name}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                onChange={handleChange}
                value={currentValue}
                ref={inputRef}
                type="number"
                min={min}
                max={max}
                {...rest}
            />
            {label && (
                <label htmlFor={name} className="input-label">
                    {label}
                </label>
            )}

            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default InputCurrencyWithLabel;
