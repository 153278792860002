import styled from "styled-components";

export const Container = styled.div`
    /* background: red; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;
