import styled, { css } from "styled-components";

interface IProductProps {
    type: "bronze" | "silver" | "gold";
    promote: boolean;
}

interface IBadgeProps {
    bgColor?: string;
}

const promotedProduct = {
    promote: css`
        height: 400px;
        box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.3);
    `,

    notPromoted: css`
        height: 360px;
        box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.125);
    `,
};

const productOrdering = {
    bronze: css`
        order: 3;
    `,
    silver: css`
        order: 2;
    `,
    gold: css`
        order: 1;
    `,
};

const productBackgroundVariation = {
    bronze: css`
        /* background-image: linear-gradient(
            to right,
            #cd4432 0%,
            #e59e58 51%,
            #cd4432 100%
        ); */
        background-image: var(--background-bronze);
    `,

    silver: css`
        /* background-image: linear-gradient(
            to right,
            #9a9a9a 0%,
            #c0c0c0 51%,
            #9a9a9a 100%
        ); */
        background-image: var(--background-silver);
    `,

    gold: css`
        /* background-image: linear-gradient(
            to right,
            #ffae00 0%,
            #ffd700 51%,
            #ffae00 100%
        ); */
        background-image: var(--background-gold);
    `,
};

export const Container = styled.div`
    /* background: #fff; */

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const ActionsCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a,
    button {
        color: #333;
        background: transparent;
        padding: 5px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.7;
        }
    }
`;

export const ProductsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

export const ProductBox = styled.div<IProductProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    border-radius: 10px;
    margin: 20px 5px;

    ${props =>
        props.promote
            ? promotedProduct["promote"]
            : promotedProduct["notPromoted"]}

    ${props =>
        productOrdering[props.type]}

    @media screen and (min-width: 1050px) {
        width: 260px;
        margin: 20px auto;
    }

    @media screen and (min-width: 1200px) {
        width: 280px;
        margin: 20px auto;
    }
`;

export const ProductBoxHeader = styled.div<IProductProps>`
    ${props => productBackgroundVariation[props.type]}

    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-radius: 10px 10px 0 0;

    color: #333;
`;

export const ProductBoxHeaderTitle = styled.div<IProductProps>`
    span {
        font-weight: bold;
        font-size: ${({ type }) => (type === "gold" && "4.5rem") || "3rem"};
    }
`;

export const ProductBoxBody = styled.div<IProductProps>`
    height: 245px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    background: #fff;
`;

export const ProductBoxBodySpecifications = styled.ul<IProductProps>`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;

export const ProductBoxBodySpecificationItem = styled.div<IProductProps>`
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin: 5px 0;
    /* max-width: 250px; */

    text-align: left;

    svg {
        color: #b2d235;
        width: 12px;
        height: 12px;
    }

    span {
        /* max-width: 210px; */
        color: #333;
        font-weight: 400;
        line-height: 1rem;
        margin-left: 10px;

        font-size: ${({ promote }) => (promote && "1.2rem") || "1rem"};

        @media screen and (min-width: 1100px) {
            font-size: 1rem;
        }
    }
`;

export const ProductBoxBodyPrice = styled.div<IProductProps>`
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    /* margin-top: auto; */

    span.signal {
        font-size: 1.8rem;
        font-weight: 400;
        margin-right: 5px;
    }

    span {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.5;
        margin-right: 5px;
    }

    > small {
    }
`;

export const ProductBoxBodyWarning = styled.span`
    width: 100%;
    font-size: 11px;
    font-style: italic;
    margin: 10px 0;
`;

export const ProductBoxFooter = styled.div<IProductProps>`
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 0 20px;
    background: #fff;

    button {
        display: flex;
        justify-content: center;
        width: 100%;
        font-weight: 500;
        text-transform: capitalize;
        border-radius: 5px;
        padding: 0.75rem 1.75rem;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        border-color: transparent;
        border: none;
        letter-spacing: 1px;
        text-transform: uppercase;

        font-size: ${({ promote }) => (promote && "1.2rem") || "1rem"};

        @media screen and (min-width: 1100px) {
            font-size: 1rem;
        }

        ${props => productBackgroundVariation[props.type]}
        color: #333;

        transition: all 0.5s;
        background-size: 200% auto;

        &:hover {
            color: #333;
            background-position: right center;
        }

        cursor: pointer;
        box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.2);
    }
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #b2d235;
    border-radius: 10px;

    span {
        font-weight: 500;
    }

    svg {
        margin: 0 8px;
    }
`;

export const Badge = styled.span<IBadgeProps>`
    letter-spacing: 0.3px;

    color: ${props => (props.bgColor ? "#fff" : "#333")};

    background-color: ${props =>
        props.bgColor ? props.bgColor : "transparent"};

    display: inline-block;
    padding: 4px 8px;
    font-size: 80%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
