import styled, { css } from "styled-components";

interface IProductProps {
    type: "bronze" | "silver" | "gold";
}

const productBackgroundVariation = {
    bronze: css`
        /* background-image: linear-gradient(
            to right,
            #cd4432 0%,
            #e59e58 51%,
            #cd4432 100%
        ); */
        background-image: var(--background-bronze);
    `,

    silver: css`
        /* background-image: linear-gradient(
            to right,
            #9a9a9a 0%,
            #c0c0c0 51%,
            #9a9a9a 100%
        ); */
        background-image: var(--background-silver);
    `,

    gold: css`
        /* background-image: linear-gradient(
            to right,
            #ffae00 0%,
            #ffd700 51%,
            #ffae00 100%
        ); */
        background-image: var(--background-gold);
    `,
};

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 16px 0;
    padding: 8px 16px;

    background: #fff;
    border-radius: 8px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
`;

export const Column = styled.div`
    display: flex;
    align-items: center;
`;

export const RowField = styled.div`
    font-weight: 500;
    font-size: 18px;
`;

export const RowValue = styled.div`
    font-weight: 400;
    margin-left: 8px;
`;

export const Badge = styled.span<IProductProps>`
    color: #333;

    ${props => productBackgroundVariation[props.type]}

    margin-left: 8px;

    display: inline-block;
    padding: 8px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 8px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
