import styled from "styled-components";

interface IAppContentInnerProps {
    tinyPadding?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
`;

export const AppMain = styled.div`
    position: relative;
    min-height: 100vh;
`;

export const Sidebar = styled.div`
    position: fixed;
    min-height: 100%;
    overflow: hidden;
    display: none;
    flex-direction: column;
    z-index: 60;
    width: 130px;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    background: #fff;

    @media only screen and (min-width: 768px) {
        display: flex;
    }
`;

export const SidebarHeader = styled.div`
    display: flex;
    height: 74px;
    width: 130px;
    align-items: center;
    background: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
`;

export const SidebarMenu = styled.div`
    height: calc(100vh - 74px);
`;

export const ScrollbarContainer = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden !important;
    overflow-anchor: none;
    touch-action: auto;
`;

export const LinksWrapper = styled.div`
    p {
        padding: 1rem 1rem 0.90909rem;
        font-weight: 700;
        color: #263055;
        text-transform: uppercase;
        font-size: 0.83125rem;
        white-space: normal;
        text-align: center;
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }

    ul {
        position: relative;
        list-style: none;

        /* li {
            display: block;
            padding: 2px 1rem 1px 0;

            width: 100%;

            position: relative;
            box-sizing: border-box;
            text-align: left;
            align-items: center;
            justify-content: flex-start;
            text-decoration: none;
        } */
    }
`;

export const LinkItem = styled.li`
    transition: background 0.4s;

    &:hover {
        background: var(--color-green);
    }

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 90px;
        color: #333;
        text-decoration: none;
        padding: 0 4px;

        > svg {
            margin-bottom: 10px;
        }
    }
`;

export const LinkText = styled.span`
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
`;

export const AppContent = styled.div`
    min-height: 100vh;

    display: flex;
    flex: 1 1;
    flex-direction: column;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-top: 74px;

    @media only screen and (min-width: 768px) {
        padding-left: 130px;
    }
`;

export const AppContentInner = styled.div<IAppContentInnerProps>`
    padding: 2rem 2rem 1rem;

    padding: ${props => (props.tinyPadding ? "1rem 0.5rem" : "1rem")};

    display: flex;
    flex: 1 1;
    justify-content: center;

    // If crashes grid layout, need to be removed
    align-items: baseline;

    @media only screen and (min-width: 500px) {
        padding: 1rem 2rem;
    }
`;

export const AppWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 1200px;
`;

export const AppFooter = styled.div`
    display: block;
    height: auto;
    padding: 0.33333rem 0.5rem;

    text-align: center;

    width: 100%;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    background: #fff;
    /* font-size: 0.83125rem; */

    border-top: 3px solid #b2d235;

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;

export const AppFooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    height: auto;
    padding: 0.33333rem 0.5rem;
    align-items: center;
    justify-content: space-around;

    flex-wrap: wrap;

    @media only screen and (min-width: 500px) {
        flex-direction: row;
    }
`;

export const AppFooterCompany = styled.div``;

export const AppFooterQuote = styled.div`
    /* margin-bottom: 0.5rem; */

    display: flex;
    align-items: center;

    margin-top: 0.5rem;

    @media only screen and (min-width: 500px) {
        margin-top: 0;
    }

    svg {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
`;
