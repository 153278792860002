import React from "react";
import { Link } from "react-router-dom";
import { FaTimes, FaChevronLeft } from "react-icons/fa";

// MasterPage
import Main from "../../Main";

// Common Types
import UrlsAddress from "../../../types/urlsAddress";

import {
    Container,
    MessageDisplay,
    MessageTitle,
    MessageContent,
    MessageLine,
    LinkContainer,
} from "./styles";

const PaymentFailed: React.FC = () => {
    return (
        <Main center>
            <Container>
                <MessageDisplay>
                    <FaTimes />
                    <MessageTitle>Payment failed</MessageTitle>
                    <MessageContent>
                        <MessageLine>
                            Something went wrong while processing your payment.
                        </MessageLine>
                    </MessageContent>
                    <LinkContainer>
                        <FaChevronLeft />
                        {/* <Link to="/dashboard">Go to dashboard</Link> */}
                        <Link to={UrlsAddress.DASHBOARD}>Go to dashboard</Link>
                    </LinkContainer>
                </MessageDisplay>
            </Container>
        </Main>
    );
};

export default PaymentFailed;
