import React, { useMemo } from "react";

import { IProduct } from "../../../types/globalTypes";

import { Container, Row, Column, RowField, RowValue, Badge } from "./styles";

interface IMOJServiceProps {
    code: string;
    productDTO: IProduct;
}

const ServiceDetails: React.FC<IMOJServiceProps> = ({ code, productDTO }) => {
    const productTypeMemo = useMemo((): "bronze" | "silver" | "gold" => {
        if (productDTO.mojTypeService === 1) {
            return "gold";
        }

        if (productDTO.mojTypeService === 2) {
            return "silver";
        }

        return "bronze";
    }, [productDTO]);

    return (
        <Container>
            <Row>
                <Column>
                    <RowField>Code:</RowField>
                    <RowValue>{code}</RowValue>
                </Column>
                <Column>
                    <RowField>Service:</RowField>
                    <Badge type={productTypeMemo}>{productDTO.name}</Badge>
                </Column>
            </Row>
        </Container>
    );
};

export default ServiceDetails;
