import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

// Icons
import { FaCheck } from "react-icons/fa";

// API
import api from "../../../services/api";

// Hooks
import { useAuth } from "../../../hooks/auth";
import { useLoader } from "../../../hooks/loader";

// Can Component
import Can from "../../Can";

// Common Types
import ActionTypes from "../../../types/userActions";

// Common Components
import CustomerMOJGrid from "../CustomerMOJGrid";

import {
    Container,
    ProductsContainer,
    ProductBox,
    ProductBoxHeader,
    ProductBoxHeaderTitle,
    ProductBoxBody,
    ProductBoxBodySpecifications,
    ProductBoxBodySpecificationItem,
    ProductBoxBodyPrice,
    ProductBoxBodyWarning,
    ProductBoxFooter,
    Warning,
} from "./styles";

interface IMOJData {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    creationDate: Date;
    expiryDateLink: Date;
    code: string;
    mojStatus: number;
    mojFormLink: string;
    payPalPaymentLink: string;
}

interface IMOJProduct {
    id: string;
    name: string;
    specifications: IMOJProductSpecification[];
    pricePlusGST: number;
    promote: boolean;
    type: "bronze" | "silver" | "gold";
}

interface IMOJProductSpecification {
    order: number;
    specification: string;
    isActive: boolean;
}

const CustomerDashboard: React.FC = () => {
    const [mojsData, setMojsData] = useState<IMOJData[]>([]);
    const [products, setProducts] = useState<IMOJProduct[]>([]);

    const { user } = useAuth();
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();

    const fetchData = useCallback(async () => {
        showLoader("Processing...");

        await api.get("home/getMOJsProductsCustomer").then(response => {
            const { data } = response;
            if (data && data.success) {
                if (data && data.success && data.data) {
                    const productsResponse: IMOJProduct[] = data.data;

                    setProducts(productsResponse);
                }
            }
        });

        await api.get(`customer/GetAllMOJs/${user.id}`).then(response => {
            const { data } = response;
            if (data && data.success && data.data) {
                const mojs: IMOJData[] = data.data;

                setMojsData(mojs);
            }
        });

        hideLoader();
    }, [showLoader, hideLoader, user.id]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasDraftMOJ = useMemo(() => {
        return mojsData.find(moj => moj.mojStatus === 1);
    }, [mojsData]);

    const handleCreateMOJ = useCallback(
        async (idProduct: string) => {
            showLoader("Creating MOJ Form...");

            const { data } = await api.post(`customer/CreateMOJ/${idProduct}`);

            if (data && data.success && data.data) {
                const id: string = data.data;

                hideLoader();

                history.push(`/moj/${id}`);
            }
        },
        [history, showLoader, hideLoader],
    );

    return (
        <Container>
            {!hasDraftMOJ && products.length > 0 && (
                <Can
                    action={ActionTypes.CUSTOMER_CREATE_CUSTOMER_MOJ}
                    yes={() => (
                        <ProductsContainer>
                            {products.map(product => {
                                let productType: "gold" | "silver" | "bronze" =
                                    "gold";

                                if (product.name === "Gold") {
                                    productType = "gold";
                                } else if (product.name === "Silver") {
                                    productType = "silver";
                                } else {
                                    productType = "bronze";
                                }

                                return (
                                    <ProductBox
                                        key={product.id}
                                        type={productType}
                                        promote={product.promote}
                                    >
                                        <ProductBoxHeader
                                            type={productType}
                                            promote={product.promote}
                                        >
                                            <ProductBoxHeaderTitle
                                                type={productType}
                                                promote={product.promote}
                                            >
                                                <span>{product.name}</span>
                                            </ProductBoxHeaderTitle>
                                        </ProductBoxHeader>
                                        <ProductBoxBody
                                            type={productType}
                                            promote={product.promote}
                                        >
                                            <ProductBoxBodySpecifications
                                                type={productType}
                                                promote={product.promote}
                                            >
                                                {product.specifications.map(
                                                    specification => (
                                                        <ProductBoxBodySpecificationItem
                                                            key={
                                                                specification.order
                                                            }
                                                            type={productType}
                                                            promote={
                                                                product.promote
                                                            }
                                                        >
                                                            <FaCheck />
                                                            <span>
                                                                {
                                                                    specification.specification
                                                                }
                                                            </span>
                                                        </ProductBoxBodySpecificationItem>
                                                    ),
                                                )}
                                            </ProductBoxBodySpecifications>
                                            <ProductBoxBodyPrice
                                                type={productType}
                                                promote={product.promote}
                                            >
                                                <span className="signal">
                                                    $
                                                </span>
                                                <span>
                                                    {product.pricePlusGST}
                                                </span>
                                                <small> incl. GST</small>
                                            </ProductBoxBodyPrice>
                                            <ProductBoxBodyWarning>
                                                * processing times may be
                                                delayed if the ministry of
                                                justice requests further
                                                information in order to process
                                                a correct and thorough criminal
                                                background check. Any delay in
                                                processing time is out of our
                                                control.
                                            </ProductBoxBodyWarning>
                                        </ProductBoxBody>
                                        <ProductBoxFooter
                                            type={productType}
                                            promote={product.promote}
                                        >
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleCreateMOJ(product.id)
                                                }
                                            >
                                                Purchase now
                                            </button>
                                        </ProductBoxFooter>
                                    </ProductBox>
                                );
                            })}
                        </ProductsContainer>
                    )}
                />
            )}

            <Warning>
                <span>
                    Please store your MOJ results in a safe place. Results will
                    only be stored for one month on our server. We do not hold
                    them any longer for security and privacy reasons.
                </span>
            </Warning>

            <CustomerMOJGrid />
        </Container>
    );
};

export default CustomerDashboard;
