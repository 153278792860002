import React, {
    useRef,
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useEffect,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

// Icons
import {
    FaInfoCircle,
    FaTimesCircle,
    FaMapMarkerAlt,
    FaPlus,
} from "react-icons/fa";

// Hooks
import { IStepAddressFormData, useMOJ } from "../../../hooks/moj";

// Common Interfaces
import { IMOJAddress } from "../../../types/mojTypes";
import { IRadioOption } from "../../../types/globalTypes";

// Common Components
import InputWithLabel from "../../../components/InputWithLabel";
import InputMaskWithLabel from "../../../components/InputMaskWithLabel";
import Checkbox from "../../../components/Checkbox";
import RadioInput from "../../../components/RadioInput";

// Utils
import getValidationErrors from "../../../utils/getValidationErrors";

// Styles
import {
    Container,
    Content,
    AddressBlock,
    AddressBlockTitle,
    MoreAddress,
    PreviousAddressList,
    Warning,
    PreviousAddressItem,
    PreviousAddressItemBlock,
    PreviousAddressItemRemoveButton,
    AddPreviousAddress,
} from "./styles";

export interface IAddressMethods {
    isValid(): boolean;
    validate(): void;
    returnAddressData(): IStepAddressFormData;
}

const StepAddress: React.RefForwardingComponent<
    IAddressMethods,
    IStepAddressFormData
> = ({ children }, ref) => {
    const formRef = useRef<FormHandles>(null);

    const [addressDetailsData, setAddressDetailsData] = useState<
        IStepAddressFormData | undefined
    >();

    const [previousAddressList, setPreviousAddressList] = useState<
        IMOJAddress[]
    >([]);
    const [canAddPreviousAddress, setCanAddPreviousAddress] = useState(true);
    const [
        isResidentialAddressEqualToPostalAddress,
        setIsResidentialAddressEqualToPostalAddress,
    ] = useState(false);

    const [showOtherAddress, setShowOtherAddress] = useState(false);
    const [showOtherAddressInitState, setShowOtherAddressInitState] = useState(
        "0",
    );

    const [isFormValid, setIsFormValid] = useState(false);

    const radioOptions: IRadioOption[] = [
        { id: "1", value: "1", label: "Yes" },
        { id: "2", value: "2", label: "No" },
    ];

    const { moj, updateAddress } = useMOJ();

    useEffect(() => {
        if (moj) {
            setIsResidentialAddressEqualToPostalAddress(
                moj.isResidentialAddressEqualToPostalAddress,
            );

            if (moj.mojAddresses) {
                const mojPostalAddress = moj.mojAddresses.find(
                    mojAddressItem => mojAddressItem.addressType === 1,
                );

                const mojResidentialAddress = moj.mojAddresses.find(
                    mojAddressItem => mojAddressItem.addressType === 2,
                );

                const addressDetail: IStepAddressFormData = {
                    mojPostalAddress,
                    mojResidentialAddress,
                };

                setAddressDetailsData(addressDetail);

                const mojOtherAddresses = moj.mojAddresses
                    .filter(
                        mojAddressItem =>
                            mojAddressItem.addressType !== 1 &&
                            mojAddressItem.addressType !== 2,
                    )
                    .sort((a, b) => (a.order > b.order ? 1 : -1));

                if (mojOtherAddresses) {
                    setShowOtherAddressInitState("2");
                    setShowOtherAddress(true);
                    const listExistingPreviousAddress: IMOJAddress[] = [];

                    for (
                        let index = 0;
                        index < mojOtherAddresses.length;
                        index++
                    ) {
                        const element = mojOtherAddresses[index];

                        const existingPreviousAddress: IMOJAddress = {
                            ...element,
                            order: index + 1,
                        };

                        listExistingPreviousAddress.push(
                            existingPreviousAddress,
                        );
                    }

                    // mojOtherAddresses.map((previousAddressItem, index) => {
                    //     const existingPreviousAddress: IMOJAddress = {
                    //         ...previousAddressItem,
                    //         order: index + 1,
                    //     };

                    //     listExistingPreviousAddress.push(
                    //         existingPreviousAddress,
                    //     );
                    // });

                    setPreviousAddressList(listExistingPreviousAddress);
                }

                if (mojOtherAddresses.length > 2) {
                    setCanAddPreviousAddress(false);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function isValid(): boolean {
        return isFormValid;
    }

    function validate(): void {
        formRef.current?.submitForm();
    }

    function returnAddressData(): IStepAddressFormData {
        return addressDetailsData!;
    }

    useImperativeHandle(ref, () => ({
        isValid,
        validate,
        returnAddressData,
    }));

    const yupShowOtherAddressValidator = (data: string): boolean => {
        if (data === "1" || data === "2") {
            return true;
        }

        return false;
    };

    const handleCopyPostalAddressToResidentialAddress = useCallback(() => {
        // validate if address[0] is full filled
        // copy address[0] to address[1]
    }, []);

    const handleCleanResidentialAddress = useCallback(() => {
        // clean address[1]
    }, []);

    const handleResidentialAddressEqualToPostalAddress = useCallback(() => {
        setIsResidentialAddressEqualToPostalAddress(
            !isResidentialAddressEqualToPostalAddress,
        );

        if (isResidentialAddressEqualToPostalAddress) {
            handleCopyPostalAddressToResidentialAddress();
        } else {
            handleCleanResidentialAddress();
        }
    }, [
        isResidentialAddressEqualToPostalAddress,
        handleCopyPostalAddressToResidentialAddress,
        handleCleanResidentialAddress,
    ]);

    const handleShowOtherAddress = useCallback(() => {
        if (formRef && formRef.current) {
            const radioShowOtherAddress = formRef.current.getFieldValue(
                "radioShowOtherAddress",
            );

            if (radioShowOtherAddress === "1") {
                setPreviousAddressList([]);
                setShowOtherAddress(false);
            } else if (radioShowOtherAddress === "2") {
                setShowOtherAddress(true);
            }
        }
    }, []);

    const handleAddPreviousAddress = useCallback((): void => {
        if (!canAddPreviousAddress) return;

        let order = 1;

        if (previousAddressList) {
            order = previousAddressList.length + 1;
        }

        const newPreviousAddress: IMOJAddress = {
            streetAddress: "",
            suburb: "",
            city: "",
            state: "",
            postcode: "",
            idCountry: "New Zealand",
            order,
            addressType: 0,
        };

        setPreviousAddressList([...previousAddressList, newPreviousAddress]);

        if (previousAddressList.length >= 2) {
            setCanAddPreviousAddress(false);
        }
    }, [canAddPreviousAddress, previousAddressList]);

    const handleDeletePreviousAddress = useCallback(
        (removeIndex: number): void => {
            if (previousAddressList) {
                const newPreviousAddressList = previousAddressList.filter(
                    (previousAddressItem, index) => {
                        return index !== removeIndex;
                    },
                );

                setPreviousAddressList(newPreviousAddressList);
            }

            if (previousAddressList.length <= 3) {
                setCanAddPreviousAddress(true);
            }
        },
        [previousAddressList],
    );

    const previousAddressListMemo = useMemo(() => {
        return previousAddressList;
    }, [previousAddressList]);

    const isPreviousAddressValid = useCallback(() => {
        if (formRef && formRef.current) {
            const radioShowOtherAddress = formRef.current.getFieldValue(
                "radioShowOtherAddress",
            );

            if (radioShowOtherAddress === "1") return true;

            if (
                radioShowOtherAddress === "2" &&
                previousAddressList.length > 0
            ) {
                return true;
            }
        }

        return false;
    }, [previousAddressList]);

    const handleValidation = useCallback(
        async (data: IStepAddressFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    mojPostalAddress: Yup.object({
                        streetAddress: Yup.string().required(
                            "PO Box/Street Address is required",
                        ),
                        suburb: Yup.string().required("Suburb is required"),
                        city: Yup.string().required("City/Town is required"),
                        state: Yup.string().required("Region is required"),
                        postcode: Yup.string().required("Postcode is required"),
                    }),
                    isResidentialAddressEqualToPostalAddress: Yup.boolean(),
                    mojResidentialAddress: Yup.object().when(
                        "isResidentialAddressEqualToPostalAddress",
                        {
                            is: false,
                            then: Yup.object({
                                streetAddress: Yup.string().required(
                                    "PO Box/Street Address is required",
                                ),
                                suburb: Yup.string().required(
                                    "Suburb is required",
                                ),
                                city: Yup.string().required(
                                    "City/Town is required",
                                ),
                                state: Yup.string().required(
                                    "Region is required",
                                ),
                                postcode: Yup.string().required(
                                    "Postcode is required",
                                ),
                            }),
                        },
                    ),
                    mojAddresses: Yup.array(
                        Yup.object({
                            streetAddress: Yup.string().required(
                                "PO Box/Street Address is required",
                            ),
                            suburb: Yup.string().required("Suburb is required"),
                            city: Yup.string().required(
                                "City/Town is required",
                            ),
                            state: Yup.string().required("Region is required"),
                            postcode: Yup.string().required(
                                "Postcode is required",
                            ),
                        }),
                    ),
                    radioShowOtherAddress: Yup.string().test(
                        "Living history",
                        "Living history is required",
                        value => yupShowOtherAddressValidator(value),
                    ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                if (!isPreviousAddressValid()) return;

                await updateAddress(data);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    setIsFormValid(false);
                }
            }
        },
        [updateAddress, isPreviousAddressValid],
    );

    return (
        <Container>
            <Content>
                {addressDetailsData && (
                    <Form
                        ref={formRef}
                        onSubmit={handleValidation}
                        initialData={{
                            ...addressDetailsData,
                            radioShowOtherAddress: showOtherAddressInitState,
                        }}
                    >
                        <AddressBlock>
                            <AddressBlockTitle>
                                <span>Postal Address</span>
                            </AddressBlockTitle>
                            <InputWithLabel
                                name="mojPostalAddress.streetAddress"
                                icon={FaMapMarkerAlt}
                                label="Street Address"
                                placeholder="PO Box or Street Address *"
                            />
                            <InputWithLabel
                                name="mojPostalAddress.suburb"
                                icon={FaMapMarkerAlt}
                                label="Suburb"
                                placeholder="Suburb *"
                            />
                            <InputWithLabel
                                name="mojPostalAddress.city"
                                icon={FaMapMarkerAlt}
                                label="City"
                                placeholder="City *"
                            />
                            <InputWithLabel
                                name="mojPostalAddress.state"
                                icon={FaMapMarkerAlt}
                                label="Region"
                                placeholder="Region *"
                            />
                            <InputMaskWithLabel
                                name="mojPostalAddress.postcode"
                                icon={FaMapMarkerAlt}
                                label="Postcode"
                                placeholder="Postcode *"
                                mask="9999"
                            />
                            <InputWithLabel
                                name="mojPostalAddress.idCountry"
                                label="Country"
                                icon={FaMapMarkerAlt}
                                // value="New Zealand"
                                defaultValue="New Zealand"
                                readOnly
                            />
                        </AddressBlock>
                        <Checkbox
                            name="isResidentialAddressEqualToPostalAddress"
                            small
                            noBackground
                            noPadding
                            style={{ marginBottom: "10px" }}
                            onClick={
                                handleResidentialAddressEqualToPostalAddress
                            }
                        >
                            Residential address is same as postal address
                        </Checkbox>
                        {!isResidentialAddressEqualToPostalAddress && (
                            <AddressBlock>
                                <AddressBlockTitle>
                                    <span>Residential Address</span>
                                </AddressBlockTitle>
                                <InputWithLabel
                                    name="mojResidentialAddress.streetAddress"
                                    icon={FaMapMarkerAlt}
                                    label="Street Address"
                                    placeholder="PO Box or Street Address *"
                                />
                                <InputWithLabel
                                    name="mojResidentialAddress.suburb"
                                    icon={FaMapMarkerAlt}
                                    label="Suburb"
                                    placeholder="Suburb *"
                                />
                                <InputWithLabel
                                    name="mojResidentialAddress.city"
                                    icon={FaMapMarkerAlt}
                                    label="City"
                                    placeholder="City *"
                                />
                                <InputWithLabel
                                    name="mojResidentialAddress.state"
                                    icon={FaMapMarkerAlt}
                                    label="Region"
                                    placeholder="Region *"
                                />
                                <InputMaskWithLabel
                                    name="mojResidentialAddress.postcode"
                                    icon={FaMapMarkerAlt}
                                    label="Postcode"
                                    placeholder="Postcode *"
                                    mask="9999"
                                />
                                <InputWithLabel
                                    name="mojResidentialAddress.idCountry"
                                    label="Country"
                                    icon={FaMapMarkerAlt}
                                    // value="New Zealand"
                                    defaultValue="New Zealand"
                                    readOnly
                                />
                            </AddressBlock>
                        )}
                        <MoreAddress>
                            <span>
                                Have you lived at the above addresses for more
                                than 10 years ?
                            </span>
                            <RadioInput
                                name="radioShowOtherAddress"
                                options={radioOptions}
                                noBackground
                                onChange={handleShowOtherAddress}
                                inline
                            />
                        </MoreAddress>

                        {showOtherAddress && (
                            <>
                                <PreviousAddressList>
                                    <Warning>
                                        <FaInfoCircle />
                                        <span>
                                            Please list any other New Zealand
                                            addresses(no more than 3 addresses)
                                            you have lived at in the last 10
                                            years. Click on add address button
                                            to add an address.
                                        </span>
                                    </Warning>
                                </PreviousAddressList>
                                {previousAddressList.length === 0 ? (
                                    <AddPreviousAddress
                                        enabled={canAddPreviousAddress}
                                        onClick={handleAddPreviousAddress}
                                    >
                                        <FaPlus />
                                        <span>Add previous address</span>
                                    </AddPreviousAddress>
                                ) : null}
                            </>
                        )}

                        <PreviousAddressList>
                            {previousAddressListMemo &&
                                previousAddressListMemo.map(
                                    (previousAddress, index) => {
                                        return (
                                            <PreviousAddressItem
                                                key={previousAddress.order}
                                            >
                                                <PreviousAddressItemBlock>
                                                    <AddressBlock>
                                                        <AddressBlockTitle>
                                                            <span>
                                                                {`Other Address ${
                                                                    index + 1
                                                                }`}
                                                            </span>
                                                        </AddressBlockTitle>
                                                        <InputWithLabel
                                                            name={`mojAddresses[${index}].streetAddress`}
                                                            icon={
                                                                FaMapMarkerAlt
                                                            }
                                                            label="Street Address"
                                                            placeholder="PO Box or Street Address *"
                                                            defaultValue={
                                                                previousAddress.streetAddress
                                                                    ? previousAddress.streetAddress
                                                                    : ""
                                                            }
                                                        />
                                                        <InputWithLabel
                                                            name={`mojAddresses[${index}].suburb`}
                                                            icon={
                                                                FaMapMarkerAlt
                                                            }
                                                            label="Suburb"
                                                            placeholder="Suburb *"
                                                            defaultValue={
                                                                previousAddress.suburb
                                                                    ? previousAddress.suburb
                                                                    : ""
                                                            }
                                                        />
                                                        <InputWithLabel
                                                            name={`mojAddresses[${index}].city`}
                                                            icon={
                                                                FaMapMarkerAlt
                                                            }
                                                            label="City"
                                                            placeholder="City *"
                                                            defaultValue={
                                                                previousAddress.city
                                                                    ? previousAddress.city
                                                                    : ""
                                                            }
                                                        />
                                                        <InputWithLabel
                                                            name={`mojAddresses[${index}].state`}
                                                            icon={
                                                                FaMapMarkerAlt
                                                            }
                                                            label="Region"
                                                            placeholder="Region *"
                                                            defaultValue={
                                                                previousAddress.state
                                                                    ? previousAddress.state
                                                                    : ""
                                                            }
                                                        />
                                                        <InputMaskWithLabel
                                                            name={`mojAddresses[${index}].postcode`}
                                                            icon={
                                                                FaMapMarkerAlt
                                                            }
                                                            label="Postcode"
                                                            placeholder="Postcode *"
                                                            mask="9999"
                                                            defaultValue={
                                                                previousAddress.postcode
                                                                    ? previousAddress.postcode
                                                                    : ""
                                                            }
                                                        />
                                                        <InputWithLabel
                                                            name={`mojAddresses[${index}].idCountry`}
                                                            label="Country"
                                                            icon={
                                                                FaMapMarkerAlt
                                                            }
                                                            defaultValue="New Zealand"
                                                            readOnly
                                                        />
                                                    </AddressBlock>
                                                </PreviousAddressItemBlock>
                                                <PreviousAddressItemRemoveButton>
                                                    <FaTimesCircle
                                                        style={{
                                                            color: "#c53030",
                                                        }}
                                                        onClick={() =>
                                                            handleDeletePreviousAddress(
                                                                index,
                                                            )
                                                        }
                                                    />
                                                </PreviousAddressItemRemoveButton>
                                            </PreviousAddressItem>
                                        );
                                    },
                                )}
                        </PreviousAddressList>
                        {previousAddressList.length > 0 &&
                            canAddPreviousAddress && (
                                <AddPreviousAddress
                                    enabled={canAddPreviousAddress}
                                    onClick={handleAddPreviousAddress}
                                >
                                    <FaPlus />
                                    <span>Add previous address</span>
                                </AddPreviousAddress>
                            )}
                    </Form>
                )}
            </Content>
        </Container>
    );
};

export default forwardRef(StepAddress);
