import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useAuth } from "../../hooks/auth";
import { useLoader } from "../../hooks/loader";

// Common Components
import MainNew from "../MainNew";
import Button from "../../components/Button";
import InputCurrencyWithLabel from "../../components/InputCurrencyWithLabel";

// Utils
import getValidationErrors from "../../utils/getValidationErrors";

// Types
import UrlsAddress from "../../types/urlsAddress";
import { IMOJCustomError } from "../../types/globalTypes";
import { IInternalMOJCostData } from "../../types/internalConsultantsTypes";

import {
    Container,
    TitleContainer,
    Title,
    MOJsInfo,
    MOJGoldCosts,
    MOJSilverCosts,
    MOJBronzeCosts,
    MoneyIcon,
    FormContent,
} from "./styles";

interface IManageMOJCostFormData {
    goldCost: number;
    silverCost: number;
    bronzeCost: number;
}

const InternalMOJCost: React.FC = () => {
    const [mojsCosts, setMOJsCosts] = useState([] as IInternalMOJCostData[]);

    const formRef = useRef<FormHandles>(null);

    const { user } = useAuth();
    const { showLoader, hideLoader } = useLoader();
    const history = useHistory();

    const fetchData = useCallback(async () => {
        if (user.userType !== 6 && user.userType !== 7) {
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            const branchResponse = await api.get(
                "internalConsultant/get-internal-moj-costs",
            );

            if (branchResponse.status === 200) {
                const { data } = branchResponse.data;

                setMOJsCosts(data);
            }

            hideLoader();
        }
    }, [showLoader, hideLoader, history, user.userType]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getMOJsCostsData = useMemo(() => {
        return mojsCosts;
    }, [mojsCosts]);

    const getGoldMOJCostData = useMemo(() => {
        if (getMOJsCostsData) {
            const gold = getMOJsCostsData.find(moj => moj.mojType === 1);

            if (gold) return gold;
        }

        return { price: 0, pricePlusGST: 0 } as IInternalMOJCostData;
    }, [getMOJsCostsData]);

    const getSilverMOJCostData = useMemo(() => {
        if (getMOJsCostsData) {
            const silver = getMOJsCostsData.find(moj => moj.mojType === 2);

            if (silver) return silver;
        }

        return { price: 0, pricePlusGST: 0 } as IInternalMOJCostData;
    }, [getMOJsCostsData]);

    const getBronzeMOJCostData = useMemo(() => {
        if (getMOJsCostsData) {
            const bronze = getMOJsCostsData.find(moj => moj.mojType === 3);

            if (bronze) return bronze;
        }

        return { price: 0, pricePlusGST: 0 } as IInternalMOJCostData;
    }, [getMOJsCostsData]);

    const handleSubmit = useCallback(
        async (data: IManageMOJCostFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    goldCost: Yup.number()
                        .typeError("must be a number")
                        .required("Gold cost is required")
                        .min(0, "Min acceptable: 0"),
                    silverCost: Yup.number()
                        .typeError("must be a number")
                        .required("Silver cost is required")
                        .min(0, "Min acceptable: 0"),
                    bronzeCost: Yup.number()
                        .typeError("must be a number")
                        .required("Bronze cost is required")
                        .min(0, "Min acceptable: 0"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Updating costs...");

                const formData = {
                    idGold: getGoldMOJCostData.id,
                    goldCost: Number(data.goldCost),
                    idSilver: getSilverMOJCostData.id,
                    silverCost: Number(data.silverCost),
                    idBronze: getBronzeMOJCostData.id,
                    bronzeCost: Number(data.bronzeCost),
                };

                await api.put(
                    "internalConsultant/manage-internal-moj-costs",
                    formData,
                );

                toast.success("MOJ internal costs successfully updated!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error(
                        "There was an error updating costs. Please, try again!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                hideLoader();
            }
        },
        [
            showLoader,
            hideLoader,
            getGoldMOJCostData.id,
            getSilverMOJCostData.id,
            getBronzeMOJCostData.id,
        ],
    );

    return (
        <MainNew>
            <Container>
                <TitleContainer>
                    <Title>Manage Internal MOJ Costs</Title>
                </TitleContainer>
                <MOJsInfo>
                    <MOJGoldCosts>
                        <span>
                            Gold: $ {getGoldMOJCostData.price.toFixed(2)}
                        </span>
                        <small>NZD + GST</small>
                        <small>
                            ($ {getGoldMOJCostData.pricePlusGST.toFixed(2)})
                        </small>
                    </MOJGoldCosts>
                    <MOJSilverCosts>
                        <span>
                            Silver: $ {getSilverMOJCostData.price.toFixed(2)}
                        </span>
                        <small>NZD + GST</small>
                        <small>
                            ($ {getSilverMOJCostData.pricePlusGST.toFixed(2)})
                        </small>
                    </MOJSilverCosts>
                    <MOJBronzeCosts>
                        <span>
                            Bronze: $ {getBronzeMOJCostData.price.toFixed(2)}
                        </span>
                        <small>NZD + GST</small>
                        <small>
                            ($ {getBronzeMOJCostData.pricePlusGST.toFixed(2)})
                        </small>
                    </MOJBronzeCosts>
                </MOJsInfo>
                <FormContent>
                    {getGoldMOJCostData.price > 0 &&
                        getSilverMOJCostData.price > 0 &&
                        getBronzeMOJCostData.price > 0 && (
                            <Form
                                ref={formRef}
                                onSubmit={handleSubmit}
                                initialData={{
                                    goldCost: getGoldMOJCostData.price.toFixed(
                                        2,
                                    ),
                                    silverCost: getSilverMOJCostData.price.toFixed(
                                        2,
                                    ),
                                    bronzeCost: getBronzeMOJCostData.price.toFixed(
                                        2,
                                    ),
                                }}
                            >
                                <InputCurrencyWithLabel
                                    name="goldCost"
                                    icon={MoneyIcon}
                                    label="Gold"
                                    placeholder="Min: 0.01"
                                />
                                <InputCurrencyWithLabel
                                    name="silverCost"
                                    icon={MoneyIcon}
                                    label="Silver"
                                    placeholder="Min: 0.01"
                                />
                                <InputCurrencyWithLabel
                                    name="bronzeCost"
                                    icon={MoneyIcon}
                                    label="Bronze"
                                    placeholder="Min: 0.01"
                                />
                                <Button type="submit">SAVE</Button>
                            </Form>
                        )}
                </FormContent>
            </Container>
        </MainNew>
    );
};

export default InternalMOJCost;
