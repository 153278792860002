import styled from "styled-components";

interface IContainerProps {
    expand: boolean;
}

export const Container = styled.div<IContainerProps>`
    position: fixed;
    right: 0;
    top: 0;
    /* Everything that is shown besides our Toast will be invisible. */
    overflow: hidden;
    z-index: 9001;

    /* Used to overlay mobile menu button */
    padding: ${({ expand }) => (expand ? "30px" : "1px")};
`;
