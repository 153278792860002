import React, {
    createContext,
    useCallback,
    useState,
    useContext,
    useEffect,
    useMemo,
} from "react";
import { useHistory } from "react-router-dom";

// Toast
import { toast } from "react-toastify";

// interfaces
import {
    IMOJPreviousName,
    IMOJAddress,
    IMOJIdentifierReferenceFormDTO,
} from "../types/mojTypes";

import {
    ICountriesOptions,
    ICountryData,
    IMOJCustomError,
} from "../types/globalTypes";

// Utils
import { ensureObjectExists } from "../utils/arrayUtilities";
import {
    convertStringToDate,
    getFormattedDate,
    getDateTimestamp,
} from "../utils/dateUtilities";

// API
import api from "../services/api";

// Hooks
import { useAuth } from "./auth";
// import { useToast } from "./toast";
import { useLoader } from "./loader";
import UrlsAddress from "../types/urlsAddress";

interface IMOJResponseAPI {
    success: boolean;
    data: IMOJ;
}

export interface IMOJ {
    id: string;
    mojFormLink: string;
    code: string;
    expiryDateLink: string;
    idUserApplicant: string;
    idProduct: string;
    mojType: number;
    // Main Details
    email: string;
    firstName: string;
    middleName?: string;
    lastName?: string;
    contactNumber: string;
    nzDriverLicenceNumber?: string;
    mojPreviousNames?: IMOJPreviousName[];
    // Birth Details
    birthDate?: Date;
    birthCity?: string;
    idBirthCountry?: string;
    gender?: number;
    // Address
    mojAddresses?: IMOJAddress[];
    isResidentialAddressEqualToPostalAddress: boolean;
    // Identity of Proof
    mojApplicantIdentificationType?: number;
    identityFormLink?: string;
    hasIncludedProofOfIdentity: boolean;
    hasAgreedWithIDTerms: boolean;
    mojIdentifierReferenceForm: IMOJIdentifierReferenceFormDTO;
    // Signature
    mojReportType?: number;
    copyRequiredViaEmail: boolean;
    copyRequiredViaPost: boolean;
    copyNotRequired: boolean;
    signatureLink?: string;
    hasAcceptedTermsConditions: boolean;
    // manageSteps
    formattedExpiryDate: string;
    noLastName: boolean;
    currentStep: IStep;
    canGoToNextStep: boolean;
    mojStatus: number;
}

export interface IStepMainDetailFormData {
    email: string;
    firstName: string;
    middleName?: string;
    lastName?: string;
    mojPreviousNames?: IMOJPreviousName[];
    contactNumber: string;
    nzDriverLicenceNumber?: string;
    noLastName: boolean;
}

export interface IStepBirthDetailFormData {
    birthDate?: string;
    birthCity?: string;
    idBirthCountry?: string;
    gender?: number;
}

export interface IStepAddressFormData {
    mojPostalAddress?: IMOJAddress;
    mojResidentialAddress?: IMOJAddress;
    isResidentialAddressEqualToPostalAddress?: boolean;
    mojAddresses?: IMOJAddress[];
}

export interface IStepProofOfIdentityFormData {
    mojApplicantIdentificationType?: number;
    identityFormLink?: string;
    hasIncludedProofOfIdentity: boolean;
    hasAgreedWithIDTerms: boolean;
}

export interface IStepSignatureFormData {
    mojReportType?: number;
    copyRequiredViaEmail: boolean;
    copyRequiredViaPost: boolean;
    copyNotRequired: boolean;
    signatureLink?: string;
    hasAcceptedTermsConditions: boolean;
}

interface IMOJContextData {
    moj: IMOJ;
    getMOJData(idMOJ: string): Promise<void>;
    goBack(): void;
    nextStep(): void;
    goToStep(stepId: number): void;
    isStepValid(): boolean;
    showNextButton(): boolean;
    showPreviousButton(): boolean;
    showSubmitButton(): boolean;
    processingRequest: boolean;
    showStep(): number;
    getCountries(): ICountriesOptions[];
    updateMainDetail(data: IStepMainDetailFormData): Promise<void>;
    updateBirthDetails(data: IStepBirthDetailFormData): Promise<void>;
    updateAddress(data: IStepAddressFormData): Promise<void>;
    updateProofOfIdentify(data: IStepProofOfIdentityFormData): Promise<void>;
    updateSignature(data: IStepSignatureFormData): Promise<void>;
    updateMOJIdentityLink(data: string): Promise<void>;
    updateMOJSignatureLink(data: string): Promise<void>;
    submitMOJ(): Promise<void>;
    allCountries: ICountriesOptions[];
}

interface IStep {
    id: number;
    current: boolean;
    title: string;
    valid: boolean;
}

const MOJSteps: IStep[] = [
    { id: 1, current: true, title: "Main Details", valid: false },
    { id: 2, current: false, title: "Birth Details", valid: false },
    { id: 3, current: false, title: "Address", valid: false },
    { id: 4, current: false, title: "Proof of Identity", valid: false },
    { id: 5, current: false, title: "Signature", valid: false },
    { id: 6, current: false, title: "Summary", valid: false },
];

interface IMOJFormState {
    moj: IMOJ;
}

const MOJContext = createContext<IMOJContextData>({} as IMOJContextData);

const MOJProvider: React.FC = ({ children }) => {
    const [stepToShow, setStepToShow] = useState(1);
    const [isProcessingRequest, setIsProcessingRequest] = useState(false);

    const [countries, setCountries] = useState<ICountriesOptions[]>([]);

    const { user } = useAuth();
    // const { addToast } = useToast();
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();

    useEffect(() => {
        async function loadCountries(): Promise<void> {
            await api.get("Country/GetAll").then(response => {
                const { data } = response;
                if (data && data.success) {
                    const countriesResponse: ICountryData[] = data.data;

                    const allCountries: ICountriesOptions[] = countriesResponse.map(
                        countryItem => {
                            return {
                                label: countryItem.shortName,
                                value: countryItem.id,
                            };
                        },
                    );

                    setCountries(allCountries);
                }
            });
        }

        loadCountries();
    }, []);

    const [data, setData] = useState<IMOJFormState>(() => {
        const objMOJ = localStorage.getItem("@BossMOJ:draftMOJ");

        if (objMOJ && user) {
            const moj: IMOJ = JSON.parse(objMOJ);

            if (user.email !== moj.email) {
                localStorage.removeItem("@BossMOJ:draftMOJ");
            }

            const parsedDate = Date.parse(moj.expiryDateLink);

            if (parsedDate <= +new Date()) {
                localStorage.removeItem("@BossMOJ:draftMOJ");

                return {} as IMOJFormState;
            }

            return { moj };
        }

        return {} as IMOJFormState;
    });
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);

    const getMOJData = useCallback(
        async (idMOJ: string) => {
            try {
                showLoader("Loading MOJ...");

                await api
                    .get(`moj/updateMOJ/${idMOJ}`, {
                        // Params => query params
                        params: {
                            idMOJ,
                        },
                    })
                    .then(response => {
                        const responseData = response.data;

                        if (responseData && responseData.success) {
                            localStorage.removeItem("@BossMOJ:draftMOJ");

                            const parsedDate = getDateTimestamp(
                                responseData.data.expiryDateLink,
                            );

                            // const mojFromAPI: IMOJ = {
                            //     ...responseData.data,
                            //     currentStep: MOJSteps[0],
                            //     formattedExpiryDate: format(
                            //         parsedDate,
                            //         `dd'/'MM'/'yyyy HH:mm'h'`,
                            //     ),
                            // };

                            const mojFromAPI: IMOJ = {
                                ...responseData.data,
                                currentStep: MOJSteps[0],
                                formattedExpiryDate: getFormattedDate(
                                    parsedDate,
                                    `dd'/'MM'/'yyyy HH:mm'h'`,
                                ),
                            };

                            localStorage.setItem(
                                "@BossMOJ:draftMOJ",
                                JSON.stringify(mojFromAPI),
                            );

                            setData({
                                moj: mojFromAPI,
                            });
                        } else {
                            // Need to redirect the user because MOJ has expired
                            // history.push("/dashboard");
                            history.push(UrlsAddress.DASHBOARD);

                            // addToast({
                            //     type: "error",
                            //     title: "Error when recovering MOJ",
                            //     description:
                            //         "There was an error recovering your MOJ. Please, try again!",
                            // });
                            toast.error(
                                "There was an error recovering your MOJ. Please, try again!",
                                {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                },
                            );
                        }
                    });
            } catch (err) {
                const errorMessage = err.response.data.errors[0];

                if (errorMessage === "MOJ is expired!") {
                    // addToast({
                    //     type: "error",
                    //     title: errorMessage,
                    //     description: "Your MOJ has expired!",
                    // });
                    toast.error("Your MOJ has expired!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    // history.push("/dashboard");
                    history.push(UrlsAddress.DASHBOARD);
                } else {
                    // addToast({
                    //     type: "error",
                    //     title: "Error when recovering MOJ",
                    //     description:
                    //         "There was an error recovering your MOJ. Please, try again!",
                    // });
                    toast.error(
                        "There was an error recovering your MOJ. Please, try again!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                hideLoader();
            }

            hideLoader();
        },
        [showLoader, hideLoader, history],
    );

    const goBack = useCallback(async () => {
        const nextStepId = data.moj.currentStep.id - 1;

        const nextStepToShow = ensureObjectExists(
            MOJSteps.find(step => step.id === nextStepId),
        );

        const updatedMOJ: IMOJ = {
            ...data.moj,
            canGoToNextStep: false,
            currentStep: nextStepToShow,
        };

        localStorage.setItem("@BossMOJ:draftMOJ", JSON.stringify(updatedMOJ));

        setData({
            moj: updatedMOJ,
        });
    }, [data.moj]);

    const nextStep = useCallback(() => {
        if (isCurrentStepValid) {
            setStepToShow(stepToShow + 1);

            setIsCurrentStepValid(false);
        }
    }, [stepToShow, isCurrentStepValid]);

    const goToStep = useCallback(
        (stepId: number) => {
            const nextStepToShow = ensureObjectExists(
                MOJSteps.find(step => step.id === stepId),
            );

            if (data.moj.currentStep.id > nextStepToShow.id) {
                const updatedMOJ: IMOJ = {
                    ...data.moj,
                    canGoToNextStep: true,
                    currentStep: nextStepToShow,
                };

                localStorage.setItem(
                    "@BossMOJ:draftMOJ",
                    JSON.stringify(updatedMOJ),
                );

                setData({ moj: updatedMOJ });

                setStepToShow(updatedMOJ.currentStep.id);
            }
        },
        [data.moj],
    );

    const isStepValid = useCallback(() => {
        return isCurrentStepValid;
    }, [isCurrentStepValid]);

    const showNextButton = useCallback((): boolean => {
        if (!data || !data.moj || !data.moj.currentStep) return false;

        if (data.moj.currentStep.id >= 1 && data.moj.currentStep.id < 6) {
            return true;
        }

        return false;
    }, [data]);

    const showPreviousButton = useCallback((): boolean => {
        if (!data || !data.moj || !data.moj.currentStep) return false;

        if (data.moj.currentStep.id >= 2 && data.moj.currentStep.id <= 6) {
            return true;
        }

        return false;
    }, [data]);

    const showSubmitButton = useCallback((): boolean => {
        if (!data || !data.moj || !data.moj.currentStep) return false;

        if (data.moj.currentStep.id === 6) {
            return true;
        }

        return false;
    }, [data]);

    const getProcessingRequest = useMemo(() => {
        return isProcessingRequest;
    }, [isProcessingRequest]);

    const showStep = useCallback(() => {
        return stepToShow;
    }, [stepToShow]);

    const getCountries = useCallback(() => {
        return countries;
    }, [countries]);

    const updateMOJ = useCallback(async (moj: IMOJ) => {
        setIsProcessingRequest(true);

        await api
            .put(`MOJ/UpdateMOJ/${moj.id}`, moj)
            .then(response => {
                if (response.data && response.data.success) {
                    localStorage.setItem(
                        "@BossMOJ:draftMOJ",
                        JSON.stringify(moj),
                    );

                    setData({
                        moj,
                    });

                    setStepToShow(moj.currentStep.id);

                    const mojSubtitleEl = document.getElementById(
                        "labelMOJSubtitle",
                    );

                    mojSubtitleEl?.scrollIntoView({ behavior: "smooth" });
                }
            })
            .catch(error => {
                const {
                    errors,
                    success,
                }: IMOJCustomError = error.response.data;

                if (!success) {
                    if (errors) {
                        // errors.map(errorItem => {
                        //     return addToast({
                        //         type: "error",
                        //         title: "Error when updating MOJ Form",
                        //         description: errorItem,
                        //     });
                        // });
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                }
            })
            .then(() => {
                // always executed
                setIsProcessingRequest(false);
            });
    }, []);

    const updateMainDetail = useCallback(
        async (mainDetailData: IStepMainDetailFormData) => {
            const nextStepId = data.moj.currentStep.id + 1;

            const nextStepToShow = ensureObjectExists(
                MOJSteps.find(step => step.id === nextStepId),
            );

            nextStepToShow.current = true;

            const updatedMOJ: IMOJ = {
                ...data.moj,
                ...mainDetailData,
                mojPreviousNames: mainDetailData.mojPreviousNames
                    ? mainDetailData.mojPreviousNames
                    : [],
                canGoToNextStep: true,
                currentStep: nextStepToShow,
            };

            await updateMOJ(updatedMOJ);
        },
        [data.moj, updateMOJ],
    );

    const updateBirthDetails = useCallback(
        async (birthDetailData: IStepBirthDetailFormData) => {
            const nextStepId = data.moj.currentStep.id + 1;

            const nextStepToShow = ensureObjectExists(
                MOJSteps.find(step => step.id === nextStepId),
            );

            nextStepToShow.current = true;

            const { birthDate = "" } = birthDetailData;

            const parsedBirthDate = convertStringToDate(birthDate);

            const updatedMOJ: IMOJ = {
                ...data.moj,
                ...birthDetailData,
                birthDate: parsedBirthDate,
                gender: Number(birthDetailData.gender),
                canGoToNextStep: true,
                currentStep: nextStepToShow,
            };

            await updateMOJ(updatedMOJ);
        },
        [data.moj, updateMOJ],
    );

    const updateAddress = useCallback(
        async (addressData: IStepAddressFormData) => {
            let idNewZealandCountry = "00000000-0000-0000-0000-000000000000";

            await api.get("country/GetNewZealand").then(responseData => {
                const { data } = responseData;

                if (data && data.success) {
                    idNewZealandCountry = data.data.id as string;
                }
            });

            const postalAddress = addressData.mojPostalAddress;
            const residentialAddress = addressData.mojResidentialAddress;
            const otherAddress1 =
                addressData.mojAddresses && addressData.mojAddresses[0];
            const otherAddress2 =
                addressData.mojAddresses && addressData.mojAddresses[1];
            const otherAddress3 =
                addressData.mojAddresses && addressData.mojAddresses[2];
            const nextStepId = data.moj.currentStep.id + 1;

            const nextStepToShow = ensureObjectExists(
                MOJSteps.find(step => step.id === nextStepId),
            );

            nextStepToShow.current = true;

            const mojAddresses: IMOJAddress[] = [];

            // need to get New Zealand ID

            if (postalAddress) {
                mojAddresses.push({
                    ...postalAddress,
                    addressType: 1,
                    order: 1,
                    idCountry: idNewZealandCountry,
                });
            }

            // if residential address igual postal address populate residential address as postal address
            if (addressData.isResidentialAddressEqualToPostalAddress) {
                if (postalAddress) {
                    mojAddresses.push({
                        ...postalAddress,
                        addressType: 2,
                        order: 2,
                        idCountry: idNewZealandCountry,
                    });
                }
            } else {
                if (residentialAddress) {
                    mojAddresses.push({
                        ...residentialAddress,
                        addressType: 2,
                        order: 2,
                        idCountry: idNewZealandCountry,
                    });
                }
            }

            if (otherAddress1) {
                mojAddresses.push({
                    ...otherAddress1,
                    addressType: 3,
                    order: 3,
                    idCountry: idNewZealandCountry,
                });
            }

            if (otherAddress2) {
                mojAddresses.push({
                    ...otherAddress2,
                    addressType: 4,
                    order: 4,
                    idCountry: idNewZealandCountry,
                });
            }

            if (otherAddress3) {
                mojAddresses.push({
                    ...otherAddress3,
                    addressType: 5,
                    order: 5,
                    idCountry: idNewZealandCountry,
                });
            }

            const updatedMOJ: IMOJ = {
                ...data.moj,
                mojAddresses,
                canGoToNextStep: true,
                currentStep: nextStepToShow,
            };

            await updateMOJ(updatedMOJ);
        },
        [data.moj, updateMOJ],
    );

    const updateProofOfIdentify = useCallback(
        async (proofOfIdentityData: IStepProofOfIdentityFormData) => {
            const nextStepId = data.moj.currentStep.id + 1;

            const nextStepToShow = ensureObjectExists(
                MOJSteps.find(step => step.id === nextStepId),
            );

            nextStepToShow.current = true;

            const updatedMOJ: IMOJ = {
                ...data.moj,
                ...proofOfIdentityData,
                mojApplicantIdentificationType: proofOfIdentityData.mojApplicantIdentificationType
                    ? Number(proofOfIdentityData.mojApplicantIdentificationType)
                    : undefined,
                hasAgreedWithIDTerms: true,
                canGoToNextStep: true,
                currentStep: nextStepToShow,
            };

            await updateMOJ(updatedMOJ);
        },
        [data.moj, updateMOJ],
    );

    const updateSignature = useCallback(
        async (signatureData: IStepSignatureFormData) => {
            const nextStepId = data.moj.currentStep.id + 1;

            const nextStepToShow = ensureObjectExists(
                MOJSteps.find(step => step.id === nextStepId),
            );

            nextStepToShow.current = true;

            // "copyRequiredViaEmail": false,
            // "copyRequiredViaPost": false,
            // "copyNotRequired": true,

            let {
                copyRequiredViaEmail,
                copyRequiredViaPost,
                copyNotRequired,
            } = signatureData;

            let mojReportType = signatureData.mojReportType
                ? Number(signatureData.mojReportType)
                : undefined;

            if (data.moj.mojType === 2) {
                mojReportType = 1;
                copyRequiredViaEmail = false;
                copyRequiredViaPost = false;
                copyNotRequired = true;
            }

            const updatedMOJ: IMOJ = {
                ...data.moj,
                ...signatureData,
                // mojReportType: signatureData.mojReportType
                //     ? Number(signatureData.mojReportType)
                //     : undefined,
                mojReportType,
                copyRequiredViaEmail,
                copyRequiredViaPost,
                copyNotRequired,
                canGoToNextStep: true,
                currentStep: nextStepToShow,
            };

            await updateMOJ(updatedMOJ);
        },
        [data.moj, updateMOJ],
    );

    const updateMOJIdentityLink = useCallback(
        async (awsLink: string) => {
            const updatedMOJ: IMOJ = {
                ...data.moj,
                identityFormLink: awsLink,
            };

            await updateMOJ(updatedMOJ);
        },
        [data.moj, updateMOJ],
    );

    const updateMOJSignatureLink = useCallback(
        async (awsLink: string) => {
            const updatedMOJ: IMOJ = {
                ...data.moj,
                signatureLink: awsLink,
            };

            await updateMOJ(updatedMOJ);
        },
        [data.moj, updateMOJ],
    );

    const submitMOJ = useCallback(async () => {
        showLoader("Submitting form...");

        await api
            .post(`MOJ/SubmitMOJ/${data.moj.id}`, data.moj)
            .then(response => {
                if (response.data && response.data.success) {
                    localStorage.removeItem("@BossMOJ:draftMOJ");

                    setData({} as IMOJFormState);

                    toast.success("MOJ successfully submitted!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    history.push(UrlsAddress.DASHBOARD);
                }
            })
            .catch(error => {
                const {
                    errors,
                    success,
                }: IMOJCustomError = error.response.data;

                hideLoader();

                if (!success) {
                    if (errors) {
                        // errors.map(errorItem => {
                        //     return addToast({
                        //         type: "error",
                        //         title: "Error when submitting MOJ Form",
                        //         description: errorItem,
                        //     });
                        // });
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                }
            })
            .then(() => {
                // hideLoader();
            });
    }, [data.moj, showLoader, hideLoader, history]);

    return (
        <MOJContext.Provider
            value={{
                moj: data.moj,
                getMOJData,
                goBack,
                nextStep,
                goToStep,
                isStepValid,
                showNextButton,
                showPreviousButton,
                showSubmitButton,
                processingRequest: getProcessingRequest,
                showStep,
                getCountries,
                updateMainDetail,
                updateBirthDetails,
                updateAddress,
                updateProofOfIdentify,
                updateSignature,
                updateMOJIdentityLink,
                updateMOJSignatureLink,
                submitMOJ,
                allCountries: countries,
            }}
        >
            {children}
        </MOJContext.Provider>
    );
};

function useMOJ(): IMOJContextData {
    const context = useContext(MOJContext);

    return context;
}

export { MOJProvider, useMOJ };
