import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-shrink: 0;
    height: 74px;
    top: 0;
    left: auto;
    right: 0;
    position: fixed;
    /* color: #fff; */
    /* background: #b2d235; */
    color: #333;
    background: #fff;
    border-bottom: 3px solid #b2d235;

    width: 100%;
    z-index: 1100;

    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
        0px 11px 15px 1px rgba(0, 0, 0, 0.14),
        0px 4px 20px 3px rgba(0, 0, 0, 0.12);
`;

export const HeaderLogo = styled.div`
    height: 74px;
    display: flex;
    width: 100px;
    align-items: center;
    background: hsla(0, 0%, 100%, 0.08);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: space-between;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

    /* @media only screen and (min-width: 500px) {
        width: 100px;
    } */

    @media only screen and (min-width: 600px) {
        width: 130px;
    }
`;

export const HeaderLogoWrapper = styled.div`
    display: flex;
    align-items: center;

    a {
        text-decoration: none;
        color: #333;
        background-color: transparent;

        button {
            /* padding: 0.33333rem; */
            color: #333;
            background: hsla(0, 0%, 100%, 0.09);

            flex: 0 0 auto;
            overflow: hidden;
            font-size: 1.5rem;
            text-align: center;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            /* border-radius: 50%; */
            border: none;

            > span.label {
                display: flex;
                width: 100%;
                align-items: inherit;
                justify-content: inherit;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
`;

export const HeaderToolbar = styled.div`
    display: flex;
    flex: 1 1;
    height: 100%;
    justify-content: flex-end;
    padding-left: 1rem;
    padding-right: 1rem;

    align-items: center;

    > * {
        margin-left: 1rem;
    }
`;
