import React from "react";
import {
    Route as ReactDOMRoute,
    RouteProps as ReactDOMRouteProps,
    Redirect,
} from "react-router-dom";

import { useAuth } from "../hooks/auth";

import UrlsAddress from "../types/urlsAddress";

interface IRouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    component: React.ComponentType;
    permission?: string;
}

// Route/Is User Authenticated?
// true/true = OK
// true/false = Redirect to signin
// false/true = Redirect to Dashboard
// false/false = OK

const Route: React.FC<IRouteProps> = ({
    isPrivate = false,
    component: Component,
    permission,
    ...rest
}) => {
    const { user, isUserAllowed } = useAuth();

    const urlsARR: string[] = [];

    for (let index = 0; index < Object.values(UrlsAddress).length; index++) {
        const element = Object.values(UrlsAddress)[index];
        urlsARR.push(element);
    }

    if (permission && rest && rest.location && rest.location.pathname) {
        if (urlsARR.includes(rest.location.pathname)) {
            if (!isUserAllowed(permission)) {
                return (
                    <Redirect
                        to={{
                            pathname: isPrivate
                                ? UrlsAddress.ACCESS_FORBIDDEN
                                : UrlsAddress.BASE,
                        }}
                    />
                );
            }
        }
    }

    return (
        <ReactDOMRoute
            {...rest}
            // To keep history
            render={({ location }) => {
                return isPrivate === !!user ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: isPrivate
                                ? UrlsAddress.BASE
                                : UrlsAddress.DASHBOARD,
                            // To navigate to history.
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};

export default Route;
