import React, { useState, useCallback, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { format } from "date-fns";

// Icons
import { FaEye } from "react-icons/fa";

// API
import api from "../../services/api";

// Hooks
import { useAuth } from "../../hooks/auth";
import { useLoader } from "../../hooks/loader";

// Common Components
import MainNew from "../MainNew";

// Common Types
import { IGridMOJData, TypeMOJStatus } from "../../types/mojTypes";
import ActionTypes from "../../types/userActions";
import UrlsAddress from "../../types/urlsAddress";

import {
    Container,
    // Content,
    Title,
    MOJStatusContent,
    ActionsCell,
    Badge,
    TableContent,
} from "./styles";

const ConsultantMOJs: React.FC = () => {
    const [mojsData, setMojsData] = useState<IGridMOJData[]>([]);
    // const [loadingGrid, setLoadingGrid] = useState(false);

    const { isUserAllowed } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const { showLoader, hideLoader } = useLoader();

    // const mojStatus = location.pathname.replace("/consultant-mojs/", "");
    const mojStatus = location.pathname.replace(
        `${UrlsAddress.CONSULTANT_MOJS}/`,
        "",
    );

    const fetchData = useCallback(async () => {
        if (!isUserAllowed(ActionTypes.CONSULTANT_LIST_ALL_MOJS_CONSULTANT)) {
            // history.push("/access-forbidden");
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            await api
                .get(`company/getMOJsConsultantByMOJStatus/${mojStatus}`)
                .then(response => {
                    const { data } = response;

                    if (data && data.success) {
                        setMojsData(data.data);
                    }
                });

            hideLoader();
        }
    }, [showLoader, hideLoader, mojStatus, history, isUserAllowed]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatDate = (date: Date): string => {
        const parsedDate = Date.parse(date.toString());

        return format(parsedDate, `dd'/'MM'/'yyyy hh:mm a`);
    };

    const getBadgeColorByStatus = (status: TypeMOJStatus): string => {
        switch (status) {
            case TypeMOJStatus.Draft:
                return "orange";
            case TypeMOJStatus.WaitingForPayment:
                return "#1eb7ff";
            case TypeMOJStatus.Paid:
                return "green";
            case TypeMOJStatus.Processing:
                return "#1eb7ff";
            case TypeMOJStatus.Processed:
                return "green";
            case TypeMOJStatus.HasError:
                return "#c53030";
            case TypeMOJStatus.Rejected:
                return "#c53030";
            case TypeMOJStatus.Cancelled:
                return "#c53030";
            case TypeMOJStatus.Expired:
                return "#c53030";

            default:
                return "";
        }
    };

    const getStatusDescription = (status: TypeMOJStatus): string => {
        switch (status) {
            case TypeMOJStatus.Draft:
                return "Draft";
            case TypeMOJStatus.WaitingForPayment:
                return "Waiting for payment";
            case TypeMOJStatus.Paid:
                return "Paid";
            case TypeMOJStatus.Processing:
                return "Processing";
            case TypeMOJStatus.Processed:
                return "Processed";
            case TypeMOJStatus.HasError:
                return "Has error";
            case TypeMOJStatus.Rejected:
                return "Rejected";
            case TypeMOJStatus.Cancelled:
                return "Cancelled";
            case TypeMOJStatus.Expired:
                return "Expired";

            default:
                return "-";
        }
    };

    const getActions = (moj: IGridMOJData): JSX.Element => {
        const detailLink = (
            <Link to={`/consultant-moj-detail/${moj.id}`}>
                <FaEye size={20} />
            </Link>
        );

        let formattedActions: JSX.Element[] = [];

        switch (moj.mojStatus) {
            case TypeMOJStatus.Processing ||
                TypeMOJStatus.Processed ||
                TypeMOJStatus.HasError ||
                TypeMOJStatus.Rejected:
                formattedActions = [detailLink];
                break;
            default:
                break;
        }

        return <ActionsCell>{formattedActions}</ActionsCell>;
    };

    const tableColumns = [
        {
            name: "First name",
            selector: "firstName",
        },
        {
            name: "Email",
            selector: "email",
            // hide: 400,
        },
        {
            name: "Created at",
            selector: "creationDate",
            cell: (row: IGridMOJData) => (
                <span>{formatDate(row.creationDate)}</span>
            ),
            // hide: 900,
            sortable: true,
        },
        {
            name: "Expiry date",
            selector: "expiryDateLink",
            cell: (row: IGridMOJData) => (
                <span>{formatDate(row.expiryDateLink)}</span>
            ),
            // hide: 900,
            sortable: true,
        },
        {
            name: "Status",
            selector: "mojStatus",
            cell: (row: IGridMOJData) => (
                <Badge bgColor={getBadgeColorByStatus(row.mojStatus)}>
                    {getStatusDescription(row.mojStatus)}
                </Badge>
            ),
        },
        {
            name: "",
            cell: (row: IGridMOJData) => getActions(row),
        },
    ];

    const getMOJStatusBackgroundColor = useMemo(() => {
        switch (mojStatus) {
            case "0":
                return "#b2d235";
            case "1":
                return "#FFC600";
            case "4":
                return "#3C8CE7";
            case "5":
                return "#49C628";
            case "6":
                return "#c53030";
            case "7":
                return "#c53030";
            case "9":
                return "#c53030";

            default:
                return "";
        }
    }, [mojStatus]);

    const getMOJStatusDescription = useMemo(() => {
        switch (mojStatus) {
            case "0":
                return "Total";
            case "1":
                return "Draft";
            case "4":
                return "Processing";
            case "5":
                return "Processed";
            case "6":
                return "Has Error";
            case "7":
                return "Rejected";
            case "9":
                return "Expired";

            default:
                return "";
        }
    }, [mojStatus]);

    const getMOJsData = useMemo(() => {
        return mojsData;
    }, [mojsData]);

    return (
        <MainNew>
            {/* <Container>
                <Can
                    action={ActionTypes.CONSULTANT_UPDATE_CONSULTANT_DETAILS}
                    yes={() => (
                        <Content>
                            <Title>
                                <h3>Consultant's MOJs</h3>
                            </Title>
                            <MOJStatusContent
                                background={getMOJStatusBackgroundColor}
                            >
                                {getMOJStatusDescription}
                            </MOJStatusContent>

                            <ConsultantMOJsGrid
                                columns={tableColumns}
                                data={getMOJsData}
                            />
                        </Content>
                    )}
                />
            </Container> */}
            <Container>
                <Title>Consultant's MOJs</Title>
                <MOJStatusContent background={getMOJStatusBackgroundColor}>
                    {getMOJStatusDescription}
                </MOJStatusContent>
                <TableContent>
                    <DataTable
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        noHeader
                        highlightOnHover
                        columns={tableColumns}
                        data={getMOJsData}
                        pagination
                        // progressPending={processingApplicationsGrid}
                        responsive
                    />
                </TableContent>
            </Container>
        </MainNew>
    );
};

export default ConsultantMOJs;
