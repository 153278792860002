enum ActionTypes {
    // admin: {
    ADMIN_GET_ALL_PRODUCTS = "getAllProducts",
    ADMIN_CREATE_PAYPAL_WEBPROFILE_FROM_PRODUCT = "createPayPalWebProfileFromProduct",
    ADMIN_CREATE_PAYPAL_WEBPROFILES_FOR_ALL_PRODUCTS = "createPayPalWebProfilesForAllProducts",
    ADMIN_DELETE_PAYPAL_WEBPROFILES_FOR_ALL_PRODUCTS = "deletePayPalWebProfilesForAllProducts",
    ADMIN_DELETE_PAYPAL_WEBPROFILE_FROM_PRODUCT = "deletePayPalWebProfileFromProduct",
    ADMIN_CREATE_PRODUCT = "createProduct",
    ADMIN_MANAGE_ROLES = "manageRoles",
    ADMIN_MANAGE_USERS = "manageUsers",
    ADMIN_READ_SYSTEM_DASHBOARD = "readSystemDashboard",
    ADMIN_LIST_ORDERS = "listOrders",
    ADMIN_MANAGE_COMPANIES = "manageCompanies",
    // candidate: {
    CANDIDATE_READ_CANDIDATE_DETAILS = "readCandidateDetails",
    CANDIDATE_LIST_ALL_CANDIDATE_MOJS = "listAllCandidateMOJs",
    CANDIDATE_CANCEL_CANDIDATE_MOJ = "cancelCandidateMOJ",
    CANDIDATE_UPDATE_CANDIDATE_DETAILS = "updateCandidateDetails",
    // companyAdmin: {
    COMPANY_ADMIN_ADD_CONSULTANT = "addConsultant",
    COMPANY_ADMIN_BUY_CREDITS = "buyCredits",
    COMPANY_ADMIN_INVITE_CONSULTANT = "inviteConsultant",
    COMPANY_ADMIN_MANAGE_CONSULTANTS = "manageConsultants",
    COMPANY_ADMIN_READ_COMPANY_DASHBOARD = "readCompanyDashboard",
    // consultant: {
    CONSULTANT_READ_CONSULTANT_DETAILS = "readConsultantDetails",
    CONSULTANT_CREATE_CANDIDATE_MOJ = "createCandidateMOJ",
    CONSULTANT_LIST_ALL_MOJS_CONSULTANT = "listAllMOJsConsultant",
    CONSULTANT_READ_COMPANY_CREDITS = "readCompanyCredits",
    CONSULTANT_UPDATE_CONSULTANT_DETAILS = "updateConsultantDetails",
    // customer: {
    CUSTOMER_READ_CUSTOMER_DETAILS = "readCustomerDetails",
    CUSTOMER_UPDATE_CUSTOMER_DETAILS = "updateCustomerDetails",
    CUSTOMER_CREATE_CUSTOMER_MOJ = "createCustomerMOJ",
    CUSTOMER_LIST_ALL_CUSTOMER_MOJS = "listAllCustomerMOJs",
    CUSTOMER_CANCEL_CUSTOMER_MOJ = "cancelCustomerMOJ",
    // Internal Consultant
    INTERNAL_CONSULTANT_READ_DASHBOARD = "readInternalDashboard",
    INTERNAL_CONSULTANT_READ_MOJ_OVERVIEW = "readMOJOverview",
    INTERNAL_CONSULTANT_READ_MOJ_CHARTS = "readMOJCharts",
    INTERNAL_CONSULTANT_READ_MOJ_APPLICATIONS = "readMOJApplications",
    INTERNAL_CONSULTANT_MANAGE_CREDITS_BRANCH = "manageCreditsToBranch",
    INTERNAL_CONSULTANT_MANAGE_BRANCHES = "manageBranches",
    INTERNAL_CONSULTANT_MANAGE_INTERNAL_CONSULTANTS = "manageInternalConsultants",
    INTERNAL_CONSULTANT_READ_FINANCIAL_DASHBOARD = "readFinancialDashboard",
    INTERNAL_CONSULTANT_MANAGE_MOJ_COSTS = "manageMOJCost",
}

export default ActionTypes;
