import React, { useEffect } from "react";

import {
    FiAlertCircle,
    FiCheckCircle,
    FiInfo,
    FiXCircle,
} from "react-icons/fi";

// Hooks
import { IToastMessage, useToast } from "../../../hooks/toast";

import { Container } from "./styles";

interface IToastProps {
    message: IToastMessage;
    style: object;
}

const icons = {
    info: <FiInfo size={24} />,
    error: <FiAlertCircle size={24} />,
    success: <FiCheckCircle size={24} />,
};

const Toast: React.FC<IToastProps> = ({ message, style }) => {
    const { removeToast } = useToast();

    useEffect(() => {
        if (message.type !== "error") {
            const timer = setTimeout(() => {
                removeToast(message.id);
            }, 4000);

            return () => {
                clearTimeout(timer);
            };
        }

        const timer = setTimeout(() => {
            removeToast(message.id);
        }, 7000);

        return () => {
            clearTimeout(timer);
        };
    }, [removeToast, message.id, message.type]);

    return (
        <Container
            type={message.type}
            $hasdescription={!!message.description}
            style={style}
        >
            {icons[message.type || "info"]}

            <div>
                <strong>{message.title}</strong>
                {message.description && <p>{message.description}</p>}
            </div>

            <button onClick={() => removeToast(message.id)} type="button">
                <FiXCircle size={18} />
            </button>
        </Container>
    );
};

export default Toast;
