import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem 2rem;

    background: #fff;
    border-radius: 0.5rem;
`;

export const LinkContainer = styled.div`
    display: flex;
    align-self: flex-start;
    margin-bottom: 1.5rem;
    width: 100%;
`;

export const LinkRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    svg {
        margin-right: 8px;
    }

    a {
        color: #333;
        font-weight: 400;
    }

    button {
        color: #333;
        font-weight: 400;
        background: transparent;
        border: none;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 1rem;

    background: #f2f1f3;
    border-radius: 0.5rem;
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    text-align: center;
`;

export const FormContent = styled.div`
    max-width: 600px;
    width: 100%;
    margin-top: 1rem;
`;
