import React from "react";

import {
    Container,
    ContentStatusCode,
    StatusCode,
    ContentMessage,
    MessageTitle,
    MessageDescription,
} from "./styles";

const Forbidden: React.FC = () => {
    return (
        <Container>
            <ContentStatusCode>
                <StatusCode>403</StatusCode>
            </ContentStatusCode>
            <ContentMessage>
                <MessageTitle>ACCESS FORBIDDEN</MessageTitle>
                <MessageDescription>
                    You don't have permission to access this page
                </MessageDescription>
            </ContentMessage>
        </Container>
    );
};

export default Forbidden;
