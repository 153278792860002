import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactInputMask, { Props as InputProps } from "react-input-mask";
import { IconBaseProps } from "react-icons";
import { FiAlertCircle } from "react-icons/fi";
import { useField } from "@unform/core";

import { Container, Error } from "./styles";

interface IInputMaskProps extends InputProps {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    label: string;
}

interface IInputCurrentProps extends ReactInputMask {
    value?: string;
}

const InputMaskWithLabel: React.FC<IInputMaskProps> = ({
    name,
    icon: Icon,
    label,
    ...rest
}) => {
    const inputRef = useRef<IInputCurrentProps>(null);

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const { fieldName, defaultValue, error, registerField } = useField(name);

    useEffect(() => {
        if (defaultValue) {
            setIsFilled(true);
        }
    }, [defaultValue]);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);

        if (inputRef.current) {
            const inputProps: IInputCurrentProps = inputRef.current;

            setIsFilled(!!inputProps.value);
        }

        // setIsFilled(!!inputRef.current);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value",
            setValue(ref: any, value: string) {
                ref.setInputValue(value);
            },
            clearValue(ref: any) {
                ref.setInputValue("");
            },
        });
    }, [fieldName, registerField]);

    return (
        <Container
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocused}
        >
            {Icon && <Icon size={20} />}
            <ReactInputMask
                id={name}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={inputRef}
                defaultValue={defaultValue}
                {...rest}
            />
            {label && (
                <label htmlFor={name} className="input-label">
                    {label}
                </label>
            )}
            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default InputMaskWithLabel;
