import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useHistory, Link, useRouteMatch } from "react-router-dom";

// Icons
import { MdEdit } from "react-icons/md";
import { FaChevronLeft, FaRegTrashAlt, FaUserCog } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useAuth } from "../../hooks/auth";
import { useLoader } from "../../hooks/loader";

// Common Components
import MainNew from "../MainNew";
import StaffGrid from "../../components/InternalConsultant/StaffGrid";

// Common Types
import UrlsAddress from "../../types/urlsAddress";
import { IStaffData } from "../../types/internalConsultantsTypes";

import { Container, LinkContainer, LinkRow, ActionsCell } from "./styles";
import ModalDeleteStaff, {
    IDeleteStaffInterface,
    IModalHandles,
} from "../../components/InternalConsultant/ModalDeleteStaff";

interface IInternalStaffParams {
    branch: string;
}

const InternalStaff: React.FC = () => {
    const [staffToDelete, setStaffToDelete] = useState<IDeleteStaffInterface>(
        {} as IDeleteStaffInterface,
    );

    const [staffData, setStaffData] = useState<IStaffData[]>([]);
    const [gridTitle, setGridTitle] = useState("");

    const { user } = useAuth();
    const { showLoader, hideLoader } = useLoader();
    const history = useHistory();

    const { params } = useRouteMatch<IInternalStaffParams>();

    const modalDeleteStaffRef = useRef<IModalHandles>(null);

    const fetchData = useCallback(async () => {
        if (user.userType !== 6 && user.userType !== 7) {
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            setGridTitle("");

            if (params.branch === "all") {
                const response = await api.get(
                    "internalConsultant/get-all-internal-staff",
                );

                if (response.status === 200) {
                    const { data } = response.data;

                    const staff: IStaffData[] = data;

                    setStaffData(staff);
                }
            } else {
                const response = await api.get(
                    `internalConsultant/get-internal-staff-by-branch/${params.branch}`,
                    {
                        params: {
                            idBranch: params.branch,
                        },
                    },
                );

                if (response.status === 200) {
                    const { data } = response.data;

                    const staff: IStaffData[] = data;

                    if (staff.length > 0) {
                        setGridTitle(staff[0].company.name);
                    }

                    setStaffData(staff);
                }
            }

            hideLoader();
        }
    }, [showLoader, hideLoader, history, user.userType, params.branch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleOpenModalDeleteStaff = useCallback(() => {
        modalDeleteStaffRef.current?.openModal();
    }, []);

    const handleDeleteStaff = useCallback(
        async (id: string) => {
            try {
                showLoader("Getting staff data...");

                const apiResponse = await api.get(
                    `internalConsultant/delete-staff/${id}`,
                );

                if (apiResponse.status === 200) {
                    const { success, data } = apiResponse.data;

                    if (success && data) {
                        const staffResp: IStaffData = data;

                        const totalPendingMOJs = 0;

                        const staff: IDeleteStaffInterface = {
                            id,
                            name: `${staffResp.firstName} ${staffResp.lastName}`,
                            contact: staffResp.contactNumber,
                            email: staffResp.email,
                            streetAddress: staffResp.streetAddress,
                            suburb: staffResp.suburb,
                            city: staffResp.city,
                            state: staffResp.state,
                            postcode: staffResp.postcode,
                            totalInvitations: staffResp.candidatesInvitations
                                ? staffResp.candidatesInvitations.length
                                : 0,
                            totalMOJsCreated: staffResp.mojs
                                ? staffResp.mojs.length
                                : 0,
                            hasAnyPendingMOJ: staffResp.hasPendingMOJs,
                            totalPendingMOJs,
                            branchName: staffResp.company.name,
                        };

                        setStaffToDelete(staff);

                        handleOpenModalDeleteStaff();
                    }
                }
            } catch (err) {
                toast.error(
                    "There was an error recovering consultant data. Please, try again!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            } finally {
                hideLoader();
            }
        },
        [handleOpenModalDeleteStaff, showLoader, hideLoader],
    );

    const getActions = useCallback(
        (staff: IStaffData): JSX.Element => {
            const permissionLink = (
                <Link
                    to={`${UrlsAddress.INTERNAL_MANAGE_STAFF_PERMISSIONS.replace(
                        "/:idStaff",
                        "",
                    )}/${staff.id}`}
                    title="Manage Permissions"
                >
                    <FaUserCog size={20} />
                </Link>
            );

            const editLink = (
                <Link
                    to={`${UrlsAddress.INTERNAL_MANAGE_STAFF.replace(
                        "/:idStaff",
                        "",
                    )}/${staff.id}`}
                    title="Edit"
                >
                    <MdEdit size={20} />
                </Link>
            );

            const deleteButton = (
                <button
                    type="button"
                    onClick={() => handleDeleteStaff(staff.id)}
                >
                    <FaRegTrashAlt size={20} style={{ color: "#c53030" }} />
                </button>
            );

            let formattedActions: JSX.Element[] = [];

            formattedActions = [permissionLink, editLink, deleteButton];

            return <ActionsCell>{formattedActions}</ActionsCell>;
        },
        [handleDeleteStaff],
    );

    const getTableColumns = useMemo(() => {
        const tableColumns = [
            {
                name: "id",
                selector: "id",
                omit: true,
            },
            {
                name: "Branch",
                selector: "company.name",
                center: true,
                grow: 2,
                sortable: true,
            },
            {
                name: "First name",
                selector: "firstName",
                sortable: true,
            },
            {
                name: "Last name",
                selector: "lastName",
            },
            {
                name: "Email",
                selector: "email",
                sortable: true,
            },
            {
                name: "Contact Number",
                selector: "contactNumber",
                center: true,
            },
            {
                name: "",
                cell: (row: IStaffData) => getActions(row),
                // compact: true,
                width: "100px",
                center: true,
                // maxWidth: "60px",
                // minWidth: "60px",
            },
        ];

        return tableColumns;
    }, [getActions]);

    const getStaffData = useMemo(() => {
        return staffData;
    }, [staffData]);

    const getGridTitle = useMemo(() => {
        return gridTitle;
    }, [gridTitle]);

    return (
        <MainNew>
            <Container>
                {params.branch !== "all" && (
                    <LinkContainer>
                        <LinkRow>
                            <FaChevronLeft />
                            <button
                                type="button"
                                onClick={() => history.goBack()}
                            >
                                Go back
                            </button>
                        </LinkRow>
                    </LinkContainer>
                )}
                <StaffGrid
                    staff={getStaffData}
                    columns={getTableColumns}
                    title={getGridTitle}
                />
                <ModalDeleteStaff
                    {...staffToDelete}
                    ref={modalDeleteStaffRef}
                />
            </Container>
        </MainNew>
    );
};

export default InternalStaff;
