import styled from "styled-components";

interface IIndicatorProps {
    backgroundColorMain: string;
    backgroundColorLight?: string;
    fontColor?: string;
    clickable: boolean;
}

export const Container = styled.div`
    height: auto;
    width: 100%;
    margin-bottom: 20px;
`;

export const Content = styled.div`
    background: #fff;
    border-radius: 8px;

    display: grid;
    width: 100%;
    overflow: hidden;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 15px;
    padding: 15px;

    grid-template-areas:
        "title"
        "total"
        "draft"
        "processing"
        "processed"
        "expired"
        "hasError"
        "rejected";

    @media only screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto auto;
        grid-template-areas:
            "title      title"
            "total      draft"
            "processing processed"
            "expired    hasError"
            "rejected   .";
    }

    @media only screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        grid-template-areas:
            "title     title   title"
            "total     draft   processing"
            "processed expired hasError"
            "rejected  .       .";
    }

    @media only screen and (min-width: 1100px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            "title   title    title      title"
            "total   draft    processing processed"
            "expired hasError rejected   .";
    }
`;

export const Title = styled.div`
    display: grid;
    grid-area: title;

    padding: 15px;
    background: #f2f1f3;
    border-radius: 10px;

    h3 {
        font-size: 2rem;
        text-align: center;
    }
`;

export const TotalBox = styled.div`
    display: grid;
    grid-area: total;
`;

export const DraftBox = styled.div`
    display: grid;
    grid-area: draft;
`;

export const ProcessingBox = styled.div`
    display: grid;
    grid-area: processing;
`;

export const ProcessedBox = styled.div`
    display: grid;
    grid-area: processed;
`;

export const ExpiredBox = styled.div`
    display: grid;
    grid-area: expired;
`;

export const HasErrorBox = styled.div`
    display: grid;
    grid-area: hasError;
`;

export const RejectedBox = styled.div`
    display: grid;
    grid-area: rejected;
`;

export const Card = styled.div<IIndicatorProps>`
    color: ${({ fontColor }) => fontColor || "#333"};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* cursor: pointer; */

    cursor: ${({ clickable }) => (clickable ? "pointer" : "not-allowed")};

    ${({ backgroundColorMain, backgroundColorLight }) => {
        if (backgroundColorLight) {
            return `
          background: ${backgroundColorLight};
          background: -webkit-radial-gradient(top left, ${backgroundColorLight}, ${backgroundColorMain});
          background: -moz-radial-gradient(top left, ${backgroundColorLight}, ${backgroundColorMain});
          background: radial-gradient(to bottom right, ${backgroundColorLight}, ${backgroundColorMain});
        `;
        }

        return `
          background: ${backgroundColorMain};
        `;
    }}
`;

export const CardBody = styled.div`
    padding: 0.9rem;
    text-align: center;
    flex: 1 1 auto;
`;

export const CardTitle = styled.div`
    font-size: 4rem;
    font-weight: bold;
`;

export const CardSubtitle = styled.p`
    margin-bottom: 0.65rem;
    font-size: 1.7rem;
`;

export const CardSeeMore = styled.span`
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-size: 0.9rem;
`;
