import React, {
    useRef,
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useEffect,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import SignatureCanvas from "react-signature-canvas";

// Icons
import { FaInfoCircle, FaTrash, FaUpload } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../services/api";

// Hooks
import { useMOJ, IStepSignatureFormData } from "../../../hooks/moj";
import { useLoader } from "../../../hooks/loader";
import useWindowSize from "../../../hooks/useWindowSize";

// Common Components
import { IRadioOption } from "../../../types/globalTypes";
import RadioInput from "../../../components/RadioInput";
import Checkbox from "../../../components/Checkbox";
import TermsConditionsModal from "../../TermsConditionsModal";
import PrivacyPolicyModal from "../../PrivacyPolicyModal";

// Utils
import getValidationErrors from "../../../utils/getValidationErrors";

// Styles
import {
    Container,
    Content,
    TypeOfReportOptions,
    AuthorisationToReleaseMOJBox,
    SignatureBox,
    SignatureTitle,
    Info,
    Warning,
    SignatureInputs,
    SignatureBoxDrawingInput,
    SingatureActions,
    SignatureActionClear,
    SignatureActionSave,
    SignatureBoxDisplay,
} from "./styles";

// Interfaces
type ICoordinates = {
    x: number;
    y: number;
};

export interface ISignatureMethods {
    validate(): void;
}

const StepSignature: React.RefForwardingComponent<
    ISignatureMethods,
    IStepSignatureFormData
> = (
    {
        mojReportType,
        copyRequiredViaEmail,
        copyRequiredViaPost,
        copyNotRequired,
        signatureLink,
        hasAcceptedTermsConditions,
    },
    ref,
) => {
    const formRef = useRef<FormHandles>(null);
    const singatureCanvasRef = useRef<SignatureCanvas>(null);

    const [existingSignature, setExistingSignature] = useState(false);

    const [urlSignatureFile, setUrlSignatureFile] = useState("");
    // const [signatureData, setSignatureData] = useState<
    //     IStepSignatureFormData | undefined
    // >();
    const [showSignatureWarning, setShowSignatureWarning] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showCopyRequiredWarning, setShowCopyRequiredWarning] = useState(
        false,
    );
    const [
        messageCopyRequiredWarning,
        setMessageCopyRequiredWarning,
    ] = useState("");

    const { width } = useWindowSize();

    const reportTypeOptions: IRadioOption[] = [
        { id: "1", value: "1", label: "All convictions" },
        { id: "2", value: "2", label: "Traffic convictions only" },
    ];

    const { moj, updateMOJSignatureLink, updateSignature } = useMOJ();
    const { showLoader, hideLoader } = useLoader();

    useEffect(() => {
        // const signatureDetail: IStepSignatureFormData = {
        //     mojReportType,
        //     copyRequiredViaEmail,
        //     copyRequiredViaPost,
        //     copyNotRequired,
        //     signatureLink,
        //     hasAcceptedTermsConditions,
        // };

        if (moj && moj.signatureLink) {
            setExistingSignature(true);
            setUrlSignatureFile(moj.signatureLink);
        }

        if (formRef.current) {
            formRef.current.setFieldValue("copyRequiredViaPost", true);
        }

        // setSignatureData(signatureDetail);

        formRef?.current?.setFieldValue("copyRequiredViaPost", true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function validate(): void {
        formRef.current?.submitForm();
    }

    useImperativeHandle(ref, () => ({
        validate,
    }));

    const handleShowTermsClick = (event: any): void => {
        event.stopPropagation();
        event.preventDefault();

        setShowTerms(true);
    };

    const handleShowPrivacyClick = (event: any): void => {
        event.stopPropagation();
        event.preventDefault();

        setShowPrivacy(true);
    };

    const yupReportTypeValidator = useCallback(
        (data: string) => {
            if (moj.mojType === 2) return true;

            if (data === "1" || data === "2") {
                return true;
            }

            return false;
        },
        [moj.mojType],
    );

    const yupRequiredCopyValidator = useCallback(() => {
        setShowCopyRequiredWarning(false);

        if (moj.mojType === 2) return true;

        if (formRef && formRef.current) {
            const copyNotRequiredValue = formRef.current.getFieldValue(
                "copyNotRequired",
            );
            const copyRequiredViaPostValue = formRef.current.getFieldValue(
                "copyRequiredViaPost",
            );
            const copyRequiredViaEmailValue = formRef.current.getFieldValue(
                "copyRequiredViaEmail",
            );

            if (
                !copyNotRequiredValue &&
                !copyRequiredViaPostValue &&
                !copyRequiredViaEmailValue
            ) {
                setMessageCopyRequiredWarning("At least 1 option is required!");
                setShowCopyRequiredWarning(true);
                return false;
            }

            if (
                copyNotRequiredValue &&
                (copyRequiredViaPostValue || copyRequiredViaEmailValue)
            ) {
                setMessageCopyRequiredWarning(
                    "You can't select receive AND not receive a copy",
                );
                setShowCopyRequiredWarning(true);
                return false;
            }

            return true;
        }

        return false;
    }, [moj.mojType]);

    const mojHasSignatureLink = useCallback(() => {
        setShowSignatureWarning(false);

        if (existingSignature) return true;

        setShowSignatureWarning(true);

        return false;
    }, [existingSignature]);

    const handleValidation = useCallback(
        async (data: IStepSignatureFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    mojReportType: Yup.string().test(
                        "ReportType",
                        "Type of report is required",
                        value => yupReportTypeValidator(value),
                    ),
                    hasAcceptedTermsConditions: Yup.boolean()
                        .required("The terms and conditions must be accepted")
                        .oneOf(
                            [true],
                            "The terms and conditions must be accepted",
                        ),
                    copyRequiredViaEmail: Yup.boolean().test(
                        "Via e-mail",
                        "",
                        () => yupRequiredCopyValidator(),
                    ),
                    copyRequiredViaPost: Yup.boolean().test(
                        "Via e-mail",
                        "",
                        () => yupRequiredCopyValidator(),
                    ),
                    copyNotRequired: Yup.boolean().test("Via e-mail", "", () =>
                        yupRequiredCopyValidator(),
                    ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                // if (!yupSignatureValidator()) return;
                if (!mojHasSignatureLink()) return;

                await updateSignature(data);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                }
            }
        },
        [
            mojHasSignatureLink,
            updateSignature,
            yupReportTypeValidator,
            yupRequiredCopyValidator,
        ],
    );

    const saveSignature = useCallback(async () => {
        if (singatureCanvasRef && singatureCanvasRef.current) {
            if (singatureCanvasRef.current.isEmpty()) {
                toast.error("Please, provide a signature before save!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }

            const base64SignatureData = singatureCanvasRef.current
                .getTrimmedCanvas()
                .toDataURL();

            if (base64SignatureData) {
                setUrlSignatureFile(base64SignatureData);
            }

            const signatureFileDTO = {
                idMOJ: moj.id,
                signatureFile: base64SignatureData,
            };

            let isFileUploaded = false;
            let linkToUpload = "";

            showLoader("Uploading signature...");

            await api
                .post("/MOJ/UploadSignatureFileToS3", signatureFileDTO)
                .then(response => {
                    const { data } = response;
                    if (data && data.success) {
                        setUrlSignatureFile(data.data);
                        setExistingSignature(true);

                        linkToUpload = data.data;

                        isFileUploaded = true;
                    }
                })
                .catch(() => {
                    // handle error
                    toast.error("Error when uploading Signature!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

            if (isFileUploaded) {
                await updateMOJSignatureLink(linkToUpload);

                toast.success("Signature successfully uploaded!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            hideLoader();
        }
    }, [moj, updateMOJSignatureLink, showLoader, hideLoader]);

    const clearSignature = useCallback(() => {
        if (singatureCanvasRef && singatureCanvasRef.current) {
            singatureCanvasRef.current.clear();
        }
    }, []);

    const getCanvasWidth = useCallback(() => {
        if (width < 370) {
            return 280;
        }

        if (width < 400) {
            return 320;
        }

        return 350;
    }, [width]);

    const urlSignatureFileMemo = useMemo(() => {
        return urlSignatureFile;
    }, [urlSignatureFile]);

    return (
        <>
            <Container>
                <Content>
                    {moj && (
                        <Form
                            ref={formRef}
                            onSubmit={handleValidation}
                            initialData={{
                                mojReportType,
                                copyRequiredViaEmail,
                                copyRequiredViaPost,
                                copyNotRequired,
                                signatureLink,
                                hasAcceptedTermsConditions,
                            }}
                        >
                            {moj && moj.mojType === 1 ? (
                                <TypeOfReportOptions>
                                    <span>Type of Report</span>
                                    <RadioInput
                                        // name="radioTypeOfIdentification"
                                        name="mojReportType"
                                        options={reportTypeOptions}
                                        noBackground
                                        inline
                                    />
                                </TypeOfReportOptions>
                            ) : null}

                            <Checkbox
                                name="hasAcceptedTermsConditions"
                                noBackground
                            >
                                I authorise the Criminal Records Unit, Ministry
                                of Justice, to release a copy of my criminal
                                convictions, subject to section 7 of the
                                Criminal Records (Clean Slate) Act 2004, to the
                                third party and I read and accept the{" "}
                                <label
                                    onClick={handleShowTermsClick}
                                    aria-hidden="true"
                                >
                                    Terms and Conditions
                                </label>{" "}
                                &{" "}
                                <label
                                    onClick={handleShowPrivacyClick}
                                    aria-hidden="true"
                                >
                                    Privacy Policy
                                </label>
                            </Checkbox>

                            {moj && moj.mojType === 1 ? (
                                <AuthorisationToReleaseMOJBox>
                                    <span>
                                        I want a copy of the information
                                        provided to the third party
                                    </span>
                                    {showCopyRequiredWarning && (
                                        <Warning>
                                            <FaInfoCircle />
                                            <span>
                                                {messageCopyRequiredWarning}
                                            </span>
                                        </Warning>
                                    )}
                                    <Checkbox
                                        name="copyRequiredViaEmail"
                                        noBackground
                                        small
                                        noPadding
                                    >
                                        via e-mail
                                    </Checkbox>
                                    <Checkbox
                                        name="copyRequiredViaPost"
                                        noBackground
                                        small
                                        noPadding
                                    >
                                        via post
                                    </Checkbox>
                                    <Checkbox
                                        name="copyNotRequired"
                                        noBackground
                                        small
                                        noPadding
                                    >
                                        I do NOT require a copy of the report
                                    </Checkbox>
                                </AuthorisationToReleaseMOJBox>
                            ) : null}
                            <SignatureBox>
                                <SignatureTitle>
                                    <Info>
                                        <FaInfoCircle />
                                        <span>
                                            Please use the mouse/track pad or
                                            use your finger on mobile device to
                                            sign below
                                        </span>
                                    </Info>
                                </SignatureTitle>
                                <SignatureInputs>
                                    {showSignatureWarning && (
                                        <Warning>
                                            <FaInfoCircle />
                                            <span>Signature is required</span>
                                        </Warning>
                                    )}
                                    <SignatureBoxDrawingInput>
                                        <SignatureCanvas
                                            ref={singatureCanvasRef}
                                            penColor="#000"
                                            minDistance={0}
                                            clearOnResize={false}
                                            canvasProps={{
                                                width: getCanvasWidth(),
                                                height: 150,
                                            }}
                                        />
                                        <SingatureActions>
                                            <SignatureActionClear
                                                onClick={clearSignature}
                                                type="button"
                                            >
                                                <FaTrash />
                                                Clear
                                            </SignatureActionClear>
                                            <SignatureActionSave
                                                onClick={saveSignature}
                                                type="button"
                                            >
                                                <FaUpload />
                                                Save
                                            </SignatureActionSave>
                                        </SingatureActions>
                                    </SignatureBoxDrawingInput>
                                    <SignatureBoxDisplay>
                                        <span>Your signature</span>
                                        {urlSignatureFileMemo && (
                                            <div>
                                                <img
                                                    src={urlSignatureFileMemo}
                                                    alt="Signature"
                                                />
                                            </div>
                                        )}
                                    </SignatureBoxDisplay>
                                </SignatureInputs>
                            </SignatureBox>
                        </Form>
                    )}
                </Content>
            </Container>
            <TermsConditionsModal
                onClose={setShowTerms}
                show={showTerms}
                size="xl"
            />

            <PrivacyPolicyModal
                onClose={setShowPrivacy}
                show={showPrivacy}
                size="xl"
            />
        </>
    );
};

export default forwardRef(StepSignature);
