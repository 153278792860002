import styled from "styled-components";

export const Container = styled.footer`
    display: flex;
    flex-wrap: wrap;
    background: #1f2326;
    min-height: 70px;
    align-items: center;
    justify-content: center;

    .jss324 {
        max-width: 1140px;
        width: 100%;
        padding: 0 15px;
    }

    .jss343 {
        float: left !important;
        display: block;

        ul {
            margin: 0;
            position: relative;
            margin-top: 0;
            padding: 0;
            margin-bottom: 0;

            li.MuiListItem-root {
                position: relative;
                text-align: left;
                align-items: center;
                justify-content: flex-start;
            }

            li.jss341 {
                width: auto;
                display: inline-block;
                padding: 0px;
            }

            li.MuiListItem-gutters {
                padding-left: 16px;
                padding-right: 16px;
            }

            a.jss340 {
                color: #b2d235;
                background-color: transparent;
                display: block;
                padding: 1rem;
                position: relative;
                font-size: 14px;
                font-weight: bold;
                border-radius: 3px;
                text-transform: uppercase;
                text-decoration: none;
                cursor: pointer;
            }

            button.jss340 {
                color: #b2d235;
                background: transparent;
                display: block;
                padding: 1rem;
                position: relative;
                font-size: 14px;
                font-weight: bold;
                border-radius: 3px;
                text-transform: uppercase;
                border: none;
            }
        }
    }

    .jss344 {
        float: right;
        margin: 0;
        padding: 1rem 0;
        color: #fff;
    }

    a.link-footer {
        color: #b2d235;
    }

    .jss334 {
        clear: both;
    }
`;
