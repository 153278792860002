import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";

// Icons
import { FiLogIn, FiMail, FiLock, FiUser } from "react-icons/fi";
import { FaRegBuilding, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API Service
import api from "../../../services/api";

// Hooks
// import { useToast } from "../../../hooks/toast";
import { useAuth } from "../../../hooks/auth";

// Common Components
import InputWithLabel from "../../../components/InputWithLabel";
import InputMaskWithLabel from "../../../components/InputMaskWithLabel";
import InputPasswordWithLabel from "../../../components/InputPasswordWithLabel";
import InputNumberWithLabel from "../../../components/InputNumberWithLabel";
import Button from "../../../components/Button/index";
import Checkbox from "../../../components/Checkbox";
import getValidationErrors from "../../../utils/getValidationErrors";
import Loader from "../../../components/Loader";

// Logo
import logoImg from "../../../assets/boss-logo.png";

// Modals
import TermsConditionsModal from "../../TermsConditionsModal";
import PrivacyPolicyModal from "../../PrivacyPolicyModal";

// Common Types
import { IMOJCustomError } from "../../../types/globalTypes";
import UrlsAddress from "../../../types/urlsAddress";

import {
    Container,
    Background,
    NewContent,
    NewAnimationContainer,
    Logo,
    Title,
    FormContainer,
    Links,
    GroupDataCard,
    GroupDataCardTitle,
    GroupDataCardContent,
} from "./styles";

interface ICompanyFormData {
    name: string;
    contactEmail: string;
    contactNumber: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    idCountry: string;
}

interface IAdminConsultantFormData {
    email: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    // middleName: string;
    lastName: string;
    contactNumber: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    idCountry: string;
    // companyName: string;
}

interface IRegisterDTO {
    company: ICompanyFormData;
    adminConsultant: IAdminConsultantFormData;
}

interface ISignUpCompanyFormData {
    companyName: string;
    companyContactNumber: string;
    addressStreet: string;
    addressSuburb: string;
    addressCity: string;
    addressRegion: string;
    addressPostcode: string;
    addressCountry: string;
    userFirstName: string;
    userLastName: string;
    email: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
    termsOfService: boolean;
}

const SignUpCompany: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [loadingDescription, setLoadingDescription] = useState("");
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [newZealandId, setNewZealandId] = useState("");

    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();
    const { signIn } = useAuth();
    const history = useHistory();

    const fetchData = useCallback(async () => {
        const { data } = await api.get("country/GetNewZealand");
        let idNZ = "00000000-0000-0000-0000-000000000000";

        if (data && data.success) {
            idNZ = data.data.id as string;
        }

        setNewZealandId(idNZ);
    }, []);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const showLoader = (message: string) => {
    //     setLoading(true);
    //     setLoadingDescription(message);
    // };
    const showLoader = useCallback((message: string) => {
        setLoading(true);
        setLoadingDescription(message);
    }, []);

    // const hideLoader = () => {
    //     setLoading(false);
    //     setLoadingDescription("");
    // };
    const hideLoader = useCallback(() => {
        setLoading(false);
        setLoadingDescription("");
    }, []);

    // const handleShowTermsClick = (event: any) => {
    //     event.stopPropagation();
    //     event.preventDefault();

    //     setShowTerms(true);
    // };
    const handleShowTermsClick = useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();

        setShowTerms(true);
    }, []);

    // const handleShowPrivacyClick = (event: any) => {
    //     event.stopPropagation();
    //     event.preventDefault();

    //     setShowPrivacy(true);
    // };
    const handleShowPrivacyClick = useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();

        setShowPrivacy(true);
    }, []);

    // const getNewZealandId = async (): Promise<string> => {
    //     const { data } = await api.get("country/GetNewZealand");

    //     if (data && data.success) {
    //         return data.data.id as string;
    //     }

    //     return "00000000-0000-0000-0000-000000000000";
    // };

    // const parseFormData = async (
    //     data: ISignUpCompanyFormData,
    // ): Promise<IRegisterDTO> => {
    //     const idCountry = await getNewZealandId();

    //     const contactNumber = data.companyContactNumber;

    //     const streetAddress = data.addressStreet;
    //     const suburb = data.addressSuburb;
    //     const city = data.addressCity;
    //     const state = data.addressRegion;
    //     const postcode = data.addressPostcode;

    //     const registerDTO: IRegisterDTO = {
    //         company: {
    //             name: data.companyName,
    //             contactEmail: data.email,
    //             contactNumber,
    //             streetAddress,
    //             suburb,
    //             city,
    //             state,
    //             postcode,
    //             idCountry,
    //         },
    //         adminConsultant: {
    //             email: data.email,
    //             confirmEmail: data.confirmEmail,
    //             password: data.password,
    //             confirmPassword: data.confirmPassword,
    //             firstName: data.userFirstName,
    //             // // middleName:
    //             lastName: data.userLastName,
    //             contactNumber,
    //             streetAddress,
    //             suburb,
    //             city,
    //             state,
    //             postcode,
    //             idCountry,
    //         },
    //     };

    //     return registerDTO;
    // };

    const getNewZealandId = useMemo(() => {
        return newZealandId;
    }, [newZealandId]);

    const parseFormData = useCallback(
        (data: ISignUpCompanyFormData): IRegisterDTO => {
            const idCountry = getNewZealandId;

            const contactNumber = data.companyContactNumber;

            const streetAddress = data.addressStreet;
            const suburb = data.addressSuburb;
            const city = data.addressCity;
            const state = data.addressRegion;
            const postcode = data.addressPostcode;

            const registerDTO: IRegisterDTO = {
                company: {
                    name: data.companyName,
                    contactEmail: data.email,
                    contactNumber,
                    streetAddress,
                    suburb,
                    city,
                    state,
                    postcode,
                    idCountry,
                },
                adminConsultant: {
                    email: data.email,
                    confirmEmail: data.confirmEmail,
                    password: data.password,
                    confirmPassword: data.confirmPassword,
                    firstName: data.userFirstName,
                    // // middleName:
                    lastName: data.userLastName,
                    contactNumber,
                    streetAddress,
                    suburb,
                    city,
                    state,
                    postcode,
                    idCountry,
                },
            };

            return registerDTO;
        },
        [getNewZealandId],
    );

    const yupEmailMatchValidation = (confirmEmail: string): boolean => {
        if (formRef && formRef.current) {
            const emailValue: string = formRef.current.getFieldValue("email");
            return emailValue.toLowerCase() === confirmEmail.toLowerCase();
        }

        return false;
    };

    const handleSubmit = useCallback(
        async (data: ISignUpCompanyFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    companyName: Yup.string().required(
                        "Company Name is required",
                    ),
                    // company: Yup.object().shape({
                    //     name: Yup.string().required(
                    //         "Company Name is required lalala",
                    //     ),
                    // }),
                    companyContactNumber: Yup.string().required(
                        "Phone number is required",
                    ),
                    addressStreet: Yup.string().required(
                        "PO Box/Street is required",
                    ),
                    addressSuburb: Yup.string().required("Suburb is required"),
                    addressCity: Yup.string().required("City/Town is required"),
                    addressRegion: Yup.string().required("Region is required"),
                    addressPostcode: Yup.string().required(
                        "Postcode is required",
                    ),
                    addressCountry: Yup.string().required(
                        "Country is required",
                    ),
                    userFirstName: Yup.string().required(
                        "First Name is required",
                    ),
                    userLastName: Yup.string().required(
                        "Last Name is required",
                    ),
                    email: Yup.string()
                        .required("E-mail address is required")
                        .email("E-mail address invalid"),
                    // confirmEmail: Yup.string()
                    //     .required("E-mail address is required")
                    //     .email("E-mail address invalid"),
                    // confirmEmail: Yup.string().when("email", {
                    //     is: val => val && val.length > 0,
                    //     then: Yup.string()
                    //         .oneOf(
                    //             [Yup.ref("email")],
                    //             "E-mail and e-mail confirmation do not match",
                    //         )
                    //         .required(),
                    // }),
                    confirmEmail: Yup.string()
                        .email()
                        .test(
                            "emailMatch",
                            "E-mail and e-mail confirmation do not match",
                            value => yupEmailMatchValidation(value),
                        ),
                    password: Yup.string()
                        // .required("Password is required")
                        .min(8, "Password has to be longer than 8 characters"),
                    // confirmPassword: Yup.string().oneOf(
                    //     [Yup.ref("password"), ""],
                    //     "Passwords must match",
                    // ),
                    confirmPassword: Yup.string().when("password", {
                        is: val => val && val.length > 0,
                        then: Yup.string()
                            .oneOf(
                                [Yup.ref("password")],
                                "Password and password confirmation do not match",
                            )
                            .required(),
                    }),
                    termsOfService: Yup.boolean()
                        .required("The terms and conditions must be accepted")
                        .oneOf(
                            [true],
                            "The terms and conditions must be accepted",
                        ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Creating company...");
                const registerDTO = parseFormData(data);

                await api.post("/company/registerCompany", registerDTO);

                toast.success("Company registered!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                await signIn({
                    email: data.email,
                    password: data.password,
                });

                // history.push("/dashboard");
                history.push(UrlsAddress.DASHBOARD);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    hideLoader();

                    if (!success && errors) {
                        // errors.map(error => {
                        //     addToast({
                        //         type: "error",
                        //         title: "Error when creating user",
                        //         description: error,
                        //     });
                        // });

                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            // addToast({
                            //     type: "error",
                            //     title: "Error when creating user",
                            //     description: element,
                            // });
                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    // addToast({
                    //     type: "error",
                    //     title: "Error when creating user",
                    //     description:
                    //         "There was an error signing up, check the required data!",
                    // });
                    toast.error(
                        "There was an error signing up, check the required data!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            }
        },
        [history, parseFormData, showLoader, hideLoader, signIn],
    );

    return (
        <>
            <>
                <Container>
                    <Background />
                    <NewAnimationContainer>
                        <NewContent>
                            <Logo>
                                <img src={logoImg} alt="MOJ" />
                            </Logo>
                            <Title>
                                <h1>Sign Up</h1>
                                <h3>Company</h3>
                            </Title>
                            <FormContainer>
                                <Form ref={formRef} onSubmit={handleSubmit}>
                                    <GroupDataCard>
                                        <GroupDataCardTitle>
                                            Main details
                                        </GroupDataCardTitle>
                                        <GroupDataCardContent>
                                            <InputWithLabel
                                                name="companyName"
                                                icon={FaRegBuilding}
                                                label="Company Name"
                                                placeholder="Company Name *"
                                            />
                                            <InputNumberWithLabel
                                                name="companyContactNumber"
                                                icon={FaPhoneAlt}
                                                label="Contact Number"
                                                placeholder="Contact Number *"
                                            />
                                            <InputWithLabel
                                                name="addressStreet"
                                                icon={FaMapMarkerAlt}
                                                label="Street Address"
                                                placeholder="PO Box/Street Address *"
                                            />
                                            <InputWithLabel
                                                name="addressSuburb"
                                                icon={FaMapMarkerAlt}
                                                label="Suburb"
                                                placeholder="Suburb *"
                                            />
                                            <InputWithLabel
                                                name="addressCity"
                                                icon={FaMapMarkerAlt}
                                                label="City/Town"
                                                placeholder="City/Town *"
                                            />
                                            <InputWithLabel
                                                name="addressRegion"
                                                icon={FaMapMarkerAlt}
                                                label="Region"
                                                placeholder="Region *"
                                            />
                                            <InputMaskWithLabel
                                                name="addressPostcode"
                                                icon={FaMapMarkerAlt}
                                                label="Postcode"
                                                placeholder="Postcode *"
                                                mask="9999"
                                            />
                                            <InputWithLabel
                                                name="addressCountry"
                                                label="Country"
                                                icon={FaMapMarkerAlt}
                                                value="New Zealand"
                                                readOnly
                                            />
                                        </GroupDataCardContent>
                                    </GroupDataCard>

                                    <GroupDataCard>
                                        <GroupDataCardTitle>
                                            Account details{" "}
                                            <small>(Admin user)</small>
                                        </GroupDataCardTitle>
                                        <GroupDataCardContent>
                                            <InputWithLabel
                                                name="userFirstName"
                                                icon={FiUser}
                                                label="First Name"
                                                placeholder="First Name *"
                                            />
                                            <InputWithLabel
                                                name="userLastName"
                                                icon={FiUser}
                                                label="Last Name"
                                                placeholder="Last Name *"
                                            />
                                            <InputWithLabel
                                                name="email"
                                                icon={FiMail}
                                                label="E-mail"
                                                placeholder="E-mail *"
                                            />
                                            <InputWithLabel
                                                name="confirmEmail"
                                                icon={FiMail}
                                                label="Confirm E-mail"
                                                placeholder="Confirm your E-mail *"
                                            />
                                            <InputPasswordWithLabel
                                                name="password"
                                                icon={FiLock}
                                                label="Password"
                                                placeholder="Password *"
                                            />
                                            <InputPasswordWithLabel
                                                name="confirmPassword"
                                                icon={FiLock}
                                                label="Confirm Password"
                                                placeholder="Confirm your Password *"
                                            />
                                        </GroupDataCardContent>
                                    </GroupDataCard>

                                    <GroupDataCard>
                                        <GroupDataCardTitle>
                                            Terms and Conditions
                                        </GroupDataCardTitle>
                                        <GroupDataCardContent>
                                            <Checkbox
                                                name="termsOfService"
                                                noBackground
                                            >
                                                I've read, understand and agree
                                                to the{" "}
                                                <label
                                                    onClick={
                                                        handleShowTermsClick
                                                    }
                                                    aria-hidden="true"
                                                >
                                                    Terms and Conditions
                                                </label>{" "}
                                                &{" "}
                                                <label
                                                    onClick={
                                                        handleShowPrivacyClick
                                                    }
                                                    aria-hidden="true"
                                                >
                                                    Privacy Policy
                                                </label>
                                            </Checkbox>
                                        </GroupDataCardContent>
                                    </GroupDataCard>

                                    <Button type="submit">Sign Up</Button>
                                </Form>
                            </FormContainer>
                            <Links>
                                {/* <Link to="/sign-in"> */}
                                <Link to={UrlsAddress.SIGN_IN}>
                                    <FiLogIn />
                                    Login
                                </Link>

                                {/* <Link to="/sign-up-customer"> */}
                                <Link to={UrlsAddress.SIGN_UP_CUSTOMER}>
                                    <FiLogIn />
                                    Sign Up (Customer)
                                </Link>
                            </Links>
                        </NewContent>
                    </NewAnimationContainer>
                </Container>
                <TermsConditionsModal
                    onClose={setShowTerms}
                    show={showTerms}
                    size="xl"
                />

                <PrivacyPolicyModal
                    onClose={setShowPrivacy}
                    show={showPrivacy}
                    size="xl"
                />
                <Loader show={loading} description={loadingDescription} />
            </>
        </>
    );
};

export default SignUpCompany;
