import styled from "styled-components";

interface IParagraphProps {
    fontStyle?: string;
}

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;

export const Paragraph = styled.p<IParagraphProps>`
    color: #333;
    margin: 15px 0;

    font-style: ${({ fontStyle }) => fontStyle || "normal"};

    a {
        font-weight: 700;
        color: #b2d235;
    }
`;

export const Subtitle = styled.h2`
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: normal;
    margin: 15px 0;
`;
