import React, { useMemo } from "react";

import DetailBox from "../DetailBox";

import {
    TypeOfService,
    Row,
    RowField,
    RowValue,
    CopyRequired,
    SignatureDisplay,
} from "./styles";

interface ISignatureDetailsProps {
    mojReportType: number;
    copyRequiredViaEmail: boolean;
    copyRequiredViaPost: boolean;
    copyNotRequired: boolean;
    signatureLink: string;
}

const SignatureDetails: React.FC<ISignatureDetailsProps> = ({
    mojReportType,
    copyRequiredViaEmail,
    copyRequiredViaPost,
    copyNotRequired,
    signatureLink,
}) => {
    // const [mojCopyNotRequired, setMOJCopyNotRequired] = useState(false);

    const getTypeOfReport = useMemo(() => {
        if (mojReportType) {
            return mojReportType === 1
                ? "All convictions"
                : "Traffic convictions only";
        }

        return "";
    }, [mojReportType]);

    const getRequiredCopy = useMemo(() => {
        if (!copyNotRequired) {
            const listCopyOptions: string[] = [];

            if (copyRequiredViaEmail) {
                listCopyOptions.push("Copy required via e-mail");
            }

            if (copyRequiredViaPost) {
                listCopyOptions.push("Copy required via post");
            }

            return listCopyOptions;
        }

        // setMOJCopyNotRequired(true);

        return [] as string[];
    }, [copyNotRequired, copyRequiredViaEmail, copyRequiredViaPost]);

    return (
        <DetailBox title="Signature & Service Required">
            <TypeOfService>
                <Row>
                    <RowField>Type of Report</RowField>
                    <RowValue>{getTypeOfReport}</RowValue>
                </Row>
            </TypeOfService>
            <CopyRequired>
                {getRequiredCopy &&
                    getRequiredCopy.map(option => (
                        <Row>
                            <RowField>{option}</RowField>
                        </Row>
                    ))}

                {copyNotRequired && (
                    <Row>
                        <RowField>Copy not required</RowField>
                    </Row>
                )}
            </CopyRequired>
            <SignatureDisplay>
                <span>Your signature</span>
                {signatureLink && (
                    <div>
                        <img src={signatureLink} alt="Signature" />
                    </div>
                )}
            </SignatureDisplay>
        </DetailBox>
    );
};

export default SignatureDetails;
