import React, { useMemo } from "react";

import DetailBox from "../DetailBox";

import { InputFileDisplay } from "./styles";

interface IIdentificationProps {
    identityFormLink: string;
}

const IdentificationDetails: React.FC<IIdentificationProps> = ({
    identityFormLink,
}) => {
    const urlProofOfIdentityFileMemo = useMemo(() => {
        return identityFormLink;
    }, [identityFormLink]);

    const isFilePDFMemo = useMemo(() => {
        if (urlProofOfIdentityFileMemo) {
            const splittedURL = urlProofOfIdentityFileMemo.split(".").pop();

            if (splittedURL) {
                const extensionFile = splittedURL.split("?AWSAccessKeyId")[0];

                return extensionFile.toLowerCase() === "pdf";
            }
        }

        return false;
    }, [urlProofOfIdentityFileMemo]);

    return (
        <DetailBox title="Identification">
            {urlProofOfIdentityFileMemo && (
                <InputFileDisplay>
                    {isFilePDFMemo ? (
                        // <embed
                        //     src={urlProofOfIdentityFileMemo}
                        //     type="application/pdf"
                        // />
                        <iframe
                            // id="urlProofOfIdentityFile"
                            src={urlProofOfIdentityFileMemo}
                            title="Proof of Identity"
                        />
                    ) : (
                        <img
                            src={urlProofOfIdentityFileMemo}
                            alt="Proof of Identity"
                        />
                    )}
                </InputFileDisplay>
            )}
        </DetailBox>
    );
};

export default IdentificationDetails;
