import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    /* align-items: center; */
    width: 100%;
`;

export const LinkContainer = styled.div`
    display: flex;
    align-self: flex-start;
    margin-bottom: 1.5rem;
    width: 100%;
`;

export const LinkRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    svg {
        margin-right: 8px;
    }

    a {
        color: #333;
        font-weight: 400;
    }

    button {
        color: #333;
        font-weight: 400;
        background: transparent;
        border: none;
    }
`;

export const ActionsCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a,
    button {
        color: #333;
        background: transparent;
        padding: 0.4rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.7;
        }
    }
`;
