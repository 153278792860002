import React, {
    InputHTMLAttributes,
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from "react";
import { IconBaseProps } from "react-icons";
import { FiAlertCircle } from "react-icons/fi";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useField } from "@unform/core";

import { Container, Error } from "./styles";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    inline?: boolean;
    label: string;
}

const InputPasswordWithLabel: React.FC<IInputProps> = ({
    name,
    inline,
    icon: Icon,
    label,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const { fieldName, defaultValue, error, registerField } = useField(name);

    const { readOnly } = rest;

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);

        setIsFilled(!!inputRef.current?.value);
    }, []);

    const togglePasswordVisiblity = useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    useEffect(() => {
        registerField({
            name: fieldName,
            // Within "current" is our "input"
            ref: inputRef.current,
            // When needs to get input value, we access by "path"
            path: "value",
        });
    }, [fieldName, registerField]);

    const getShowPassword = useMemo(() => {
        return showPassword;
    }, [showPassword]);

    return (
        <Container
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocused}
            isReadOnly={readOnly || false}
            inline={inline}
        >
            {Icon && <Icon size={20} />}
            <input
                id={name}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                ref={inputRef}
                type={getShowPassword ? "text" : "password"}
                {...rest}
            />
            {label && (
                <label htmlFor={name} className="input-label">
                    {label}
                </label>
            )}
            <div className="passwordEye">
                {getShowPassword ? (
                    <FaEyeSlash size={20} onClick={togglePasswordVisiblity} />
                ) : (
                    <FaEye size={20} onClick={togglePasswordVisiblity} />
                )}
            </div>

            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default InputPasswordWithLabel;
