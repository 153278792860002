import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

// Icons
import { FaChevronLeft, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../services/api";

// Hooks
import { useAuth } from "../../../hooks/auth";
import { useLoader } from "../../../hooks/loader";

// Common Components
import MainNew from "../../MainNew";
import Button from "../../../components/Button";
import InputWithLabel from "../../../components/InputWithLabel";
import InputNumberWithLabel from "../../../components/InputNumberWithLabel";
import InputMaskWithLabel from "../../../components/InputMaskWithLabel";

// Common Types
import UrlsAddress from "../../../types/urlsAddress";
import { IBranchData } from "../../../types/internalConsultantsTypes";
import { IMOJCustomError } from "../../../types/globalTypes";

// Utils
import getValidationErrors from "../../../utils/getValidationErrors";

import {
    LinkContainer,
    LinkRow,
    Container,
    TitleContainer,
    Title,
    BranchInfo,
    BranchName,
    MOJCredits,
    MOJGoldCredits,
    MOJSilverCredits,
    MOJBronzeCredits,
    FormContent,
} from "./styles";

interface IBranchDetailsFormData {
    contactNumber: number;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
}

const BranchDetail: React.FC = () => {
    const [branch, setBranch] = useState<IBranchData | undefined>();

    const formRef = useRef<FormHandles>(null);

    const { user } = useAuth();
    const { showLoader, hideLoader } = useLoader();
    const location = useLocation();
    const history = useHistory();

    const idBranch = location.pathname.replace(
        `${UrlsAddress.INTERNAL_BRANCH_DETAIL}/`,
        "",
    );

    const fetchData = useCallback(async () => {
        if (user.userType !== 6 && user.userType !== 7) {
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            const branchResponse = await api.get(
                `internalConsultant/get-internal-branch/${idBranch}`,
                {
                    params: {
                        idBranch,
                    },
                },
            );

            if (branchResponse.status === 200) {
                const { data } = branchResponse.data;

                setBranch(data);
            }

            hideLoader();
        }
    }, [showLoader, hideLoader, history, idBranch, user.userType]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getBranchData = useMemo(() => {
        return branch;
    }, [branch]);

    const getBranchIdCountry = useMemo(() => {
        if (branch && branch.idCountry) {
            return branch.idCountry;
        }

        return "00000000-0000-0000-0000-000000000000";
    }, [branch]);

    const handleSubmit = useCallback(
        async (data: IBranchDetailsFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    contactNumber: Yup.string().required(
                        "Contact Number is required",
                    ),
                    streetAddress: Yup.string().required(
                        "Street Address is required",
                    ),
                    suburb: Yup.string().required("Suburb is required"),
                    city: Yup.string().required("City/Town is required"),
                    state: Yup.string().required("Region is required"),
                    postcode: Yup.string().required("Postcode is required"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Updating details...");

                const formData = {
                    id: idBranch,
                    contactNumber: data.contactNumber,
                    streetAddress: data.streetAddress,
                    suburb: data.suburb,
                    city: data.city,
                    state: data.state,
                    postcode: data.postcode,
                    idCountry: getBranchIdCountry,
                };

                await api.put(
                    `internalConsultant/update-branch-details/${idBranch}`,
                    formData,
                );

                toast.success("Branch was successfully updated!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // clean field e-mail
                history.push(UrlsAddress.INTERNAL_MANAGE_BRANCHES);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error(
                        "There was an error updating details. Please, try again!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                hideLoader();
            }
        },
        [showLoader, hideLoader, history, idBranch, getBranchIdCountry],
    );

    return (
        <MainNew>
            <Container>
                <LinkContainer>
                    <LinkRow>
                        <FaChevronLeft />
                        <Link to={UrlsAddress.INTERNAL_MANAGE_BRANCHES}>
                            Go back to branches
                        </Link>
                    </LinkRow>
                </LinkContainer>
                <TitleContainer>
                    <Title>Branch Details</Title>
                </TitleContainer>
                {getBranchData ? (
                    <BranchInfo>
                        <BranchName>{getBranchData.name}</BranchName>
                        <MOJCredits>
                            <MOJGoldCredits>
                                Gold credits:{" "}
                                {getBranchData.mojGoldCreditsQuantity}
                            </MOJGoldCredits>
                            <MOJSilverCredits>
                                Silver credits:{" "}
                                {getBranchData.mojSilverCreditsQuantity}
                            </MOJSilverCredits>
                            <MOJBronzeCredits>
                                Bronze credits:{" "}
                                {getBranchData.mojBronzeCreditsQuantity}
                            </MOJBronzeCredits>
                        </MOJCredits>
                    </BranchInfo>
                ) : null}
                <FormContent>
                    {getBranchData ? (
                        <Form
                            ref={formRef}
                            onSubmit={handleSubmit}
                            initialData={getBranchData}
                        >
                            <InputWithLabel
                                name="contactEmail"
                                icon={FiMail}
                                label="E-mail"
                                placeholder="E-mail"
                                readOnly
                            />
                            <InputNumberWithLabel
                                name="contactNumber"
                                icon={FaPhoneAlt}
                                label="Contact Number"
                                placeholder="Contact Number *"
                            />
                            <InputWithLabel
                                name="streetAddress"
                                icon={FaMapMarkerAlt}
                                label="Street Address"
                                placeholder="Street Address *"
                            />
                            <InputWithLabel
                                name="suburb"
                                icon={FaMapMarkerAlt}
                                label="Suburb"
                                placeholder="Suburb *"
                            />
                            <InputWithLabel
                                name="city"
                                icon={FaMapMarkerAlt}
                                label="City/Town"
                                placeholder="City/Town *"
                            />
                            <InputWithLabel
                                name="state"
                                icon={FaMapMarkerAlt}
                                label="Region"
                                placeholder="Region *"
                            />
                            <InputMaskWithLabel
                                name="postcode"
                                icon={FaMapMarkerAlt}
                                label="Postcode"
                                placeholder="Postcode *"
                                mask="9999"
                            />
                            <Button type="submit">SAVE</Button>
                        </Form>
                    ) : (
                        <h1>Loading...</h1>
                    )}
                </FormContent>
            </Container>
        </MainNew>
    );
};

export default BranchDetail;
