import styled, { css } from "styled-components";
import { shade } from "polished";

interface ICursorProps {
    // cursor: string;
    processingRequest?: boolean;
}

export const Container = styled.button<ICursorProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    background: #b2d235;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #312e38;
    /* Trick to occupy max size */
    width: 100%;
    font-weight: 700;
    margin-top: 16px;
    transition: background-color 0.2s;
    transition: color 0.2s;
    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

    ${props =>
        props.processingRequest &&
        css`
            cursor: not-allowed;
            background: ${shade(0.2, "#b2d235")};
        `}

    &:hover {
        background: ${shade(0.2, "#b2d235")};
    }

    svg {
        margin-right: 15px;

        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;

        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
`;
