import React from "react";

// Common Components
import MainNew from "../MainNew";

import BranchesGrid from "../../components/InternalConsultant/BranchesGrid";

import { Container } from "./styles";

const Branches: React.FC = () => {
    return (
        <MainNew>
            <Container>
                <BranchesGrid />
            </Container>
        </MainNew>
    );
};

export default Branches;
