import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const LinkContainer = styled.div`
    display: grid;
    grid-area: linkDashboard;
    padding: 8px 16px;
`;

export const LinkRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    @media only screen and (min-width: 400px) {
    }

    svg {
        margin-right: 8px;
    }

    a {
        color: #333;
        font-weight: 400;
    }
`;

export const Title = styled.div`
    display: flex;
    padding: 8px 16px;
    margin: 16px 0 0;

    span {
        font-size: 24px;
    }
`;
