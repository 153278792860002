import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";

// Icons
import { FaChevronLeft } from "react-icons/fa";

// API
import api from "../../services/api";

// Hooks
import { useAuth } from "../../hooks/auth";
import { useLoader } from "../../hooks/loader";
import { IMOJ } from "../../hooks/moj";

// MasterPage
import MainNew from "../MainNew";

// Common Types
import { IProduct } from "../../types/globalTypes";
import UrlsAddress from "../../types/urlsAddress";

// Steps
import ServiceDetails from "./ServiceDetails";
import PersonalDetails from "./PersonalDetails";
import AddressesDetails from "./AddressesDetails";
import IdentificationDetails from "./IdentificationDetails";
import SignatureDetails from "./SignatureDetails";

import { Container, LinkContainer, LinkRow, Title } from "./styles";

const MOJDetail: React.FC = () => {
    const [moj, setMOJ] = useState({} as IMOJ);
    const [product, setProduct] = useState({} as IProduct);
    const [countryName, setCountryName] = useState("");

    const { user } = useAuth();
    const history = useHistory();
    const { showLoader, hideLoader } = useLoader();

    const location = useLocation();

    // const idMOJ = location.pathname.replace("/moj-detail/", "");
    const idMOJ = location.pathname.replace(`${UrlsAddress.MOJ_DETAIL}/`, "");

    const fetchData = useCallback(async () => {
        if (user.userType !== 2 && user.userType !== 5) {
            // history.push("/access-forbidden");
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            const mojResponse = await api.get(`moj/updateMOJ/${idMOJ}`, {
                params: {
                    idMOJ,
                },
            });

            if (mojResponse.status === 200) {
                const objMOJ: IMOJ = mojResponse.data.data;

                setMOJ(objMOJ);

                await api
                    .get(`Home/get-productName/${objMOJ.idProduct}`)
                    .then(response => {
                        const responseData = response.data;

                        const productDTO: IProduct = responseData.data;

                        if (responseData && responseData.success) {
                            setProduct(productDTO);
                        }
                    });

                await api
                    .get(`Country/get-countryName/${objMOJ.idBirthCountry}`)
                    .then(response => {
                        const responseData = response.data;

                        if (responseData && responseData.success) {
                            setCountryName(responseData.data);
                        }
                    });
            }

            hideLoader();
        }
    }, [showLoader, hideLoader, idMOJ, history, user.userType]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mojMemo = useMemo(() => {
        return moj;
    }, [moj]);

    const countryNameMemo = useMemo(() => {
        return countryName;
    }, [countryName]);

    return (
        <MainNew>
            {mojMemo ? (
                <Container>
                    <LinkContainer>
                        <LinkRow>
                            <FaChevronLeft />
                            {/* <Link to="/dashboard">Go back to dashboard</Link> */}
                            <Link to={UrlsAddress.DASHBOARD}>
                                Go back to dashboard
                            </Link>
                        </LinkRow>
                    </LinkContainer>
                    <Title>
                        <span>Criminal check details</span>
                    </Title>
                    <ServiceDetails code={mojMemo.code} productDTO={product} />
                    <PersonalDetails
                        email={mojMemo.email}
                        firstName={mojMemo.firstName}
                        middleName={
                            mojMemo.middleName ? mojMemo.middleName : ""
                        }
                        lastName={mojMemo.lastName ? mojMemo.lastName : ""}
                        noLastName={mojMemo.noLastName}
                        contactNumber={mojMemo.contactNumber}
                        nzDriverLicenceNumber={
                            mojMemo.nzDriverLicenceNumber
                                ? mojMemo.nzDriverLicenceNumber
                                : ""
                        }
                        previousNames={
                            mojMemo.mojPreviousNames
                                ? mojMemo.mojPreviousNames
                                : []
                        }
                        birthDate={
                            mojMemo.birthDate ? mojMemo.birthDate : new Date()
                        }
                        city={mojMemo.birthCity ? mojMemo.birthCity : ""}
                        countryName={countryNameMemo}
                        gender={mojMemo.gender ? mojMemo.gender : 0}
                    />
                    <AddressesDetails
                        addresses={
                            mojMemo.mojAddresses ? mojMemo.mojAddresses : []
                        }
                    />
                    <IdentificationDetails
                        identityFormLink={
                            mojMemo.identityFormLink
                                ? mojMemo.identityFormLink
                                : ""
                        }
                    />
                    <SignatureDetails
                        copyRequiredViaEmail={mojMemo.copyRequiredViaEmail}
                        copyRequiredViaPost={mojMemo.copyRequiredViaPost}
                        copyNotRequired={
                            mojMemo.copyNotRequired
                                ? mojMemo.copyNotRequired
                                : false
                        }
                        signatureLink={
                            mojMemo.signatureLink ? mojMemo.signatureLink : ""
                        }
                        mojReportType={
                            mojMemo.mojReportType ? mojMemo.mojReportType : 0
                        }
                    />
                </Container>
            ) : null}
        </MainNew>
    );
};

export default MOJDetail;
