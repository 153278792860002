import React from "react";

import Section from "../../Section";

// Icons
import { FaIndustry, FaHandsHelping } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import { GrServices } from "react-icons/gr";

import { Container, Box, BoxIcon, BoxTitle, BoxDescription } from "./styles";

const AboutUs: React.FC = () => {
    return (
        <Section
            isHero={false}
            id="about-us"
            title="About Us"
            titleBackgroundColor={"#b2d235"}
            titleColor={"#333"}
        >
            <Container className="containerrr">
                <Box>
                    <BoxIcon>
                        <FaIndustry size={45} />
                    </BoxIcon>
                    <BoxTitle>
                        <h4>Local Business</h4>
                    </BoxTitle>
                    <BoxDescription>
                        <p>
                            We are 100% New Zealand owned and operated providing
                            our customers with technology services that focus on
                            compliance-driven capabilities
                        </p>
                    </BoxDescription>
                </Box>
                <Box>
                    <BoxIcon>
                        <GrServices size={45} />
                    </BoxIcon>
                    <BoxTitle>
                        <h4>Reliable</h4>
                    </BoxTitle>
                    <BoxDescription>
                        <p>
                            Our urgent MOJ checking service was built out of
                            industry demand for direct access to a streamlined,
                            New Zealand based urgent Ministry of Justice
                            checking service
                        </p>
                    </BoxDescription>
                </Box>
                <Box>
                    <BoxIcon>
                        <FaHandsHelping size={45} />
                    </BoxIcon>
                    <BoxTitle>
                        <h4>Helping you</h4>
                    </BoxTitle>
                    <BoxDescription>
                        <p>
                            You may personally need to order a check for
                            immigration reason, or you may be a business hiring
                            new staff and wish to conduct background checks as
                            part of your employment process; No matter your
                            requirements, we are here to help
                        </p>
                    </BoxDescription>
                </Box>
                <Box>
                    <BoxIcon>
                        <FiTarget size={45} />
                    </BoxIcon>
                    <BoxTitle>
                        <h4>Efficiency</h4>
                    </BoxTitle>
                    <BoxDescription>
                        <p>
                            Our fast, efficient and streamlined online service
                            is open 24/7 for your convenience. Order can be made
                            straight from your phone or computer and are sent
                            directly back to you as soon as they are complete
                        </p>
                    </BoxDescription>
                </Box>
            </Container>
        </Section>
    );
};

export default AboutUs;
