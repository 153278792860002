import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

// Icons
import {
    FaChevronLeft,
    FaMapMarkerAlt,
    FaPhoneAlt,
    FaRegBuilding,
} from "react-icons/fa";
import { FiMail, FiUser } from "react-icons/fi";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useAuth } from "../../hooks/auth";
import { useLoader } from "../../hooks/loader";

// Common Components
import MainNew from "../MainNew";
import InputWithLabel from "../../components/InputWithLabel";
import InputNumberWithLabel from "../../components/InputNumberWithLabel";
import SelectWithLabel from "../../components/SelectWithLabel";
import Button from "../../components/Button";

// Utils
import getValidationErrors from "../../utils/getValidationErrors";

// Common Types
import {
    IBranchData,
    IStaffData,
    IBranchesSelectOptions,
} from "../../types/internalConsultantsTypes";
import UrlsAddress from "../../types/urlsAddress";
import { IMOJCustomError } from "../../types/globalTypes";

import {
    Container,
    LinkContainer,
    LinkRow,
    TitleContainer,
    Title,
    FormContent,
} from "./styles";

interface IManageInternalStaffParams {
    idStaff: string;
}

const ManageInternalStaff: React.FC = () => {
    const [staffData, setStaffData] = useState({} as IStaffData);
    const [branchesData, setBranchesData] = useState<IBranchData[]>([]);

    const formRef = useRef<FormHandles>(null);

    const { user } = useAuth();
    const { showLoader, hideLoader } = useLoader();
    const history = useHistory();

    const { params } = useRouteMatch<IManageInternalStaffParams>();

    const fetchData = useCallback(async () => {
        if (user.userType !== 6 && user.userType !== 7) {
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            await api
                .get("internalConsultant/get-internal-branches")
                .then(response => {
                    const { data } = response;

                    if (data && data.success && data.data) {
                        const branches: IBranchData[] = data.data;

                        setBranchesData(branches);
                    }
                });

            const response = await api.get(
                `internalConsultant/get-internal-staff-by-id/${params.idStaff}`,
                {
                    params: {
                        idStaff: params.idStaff,
                    },
                },
            );

            if (response.status === 200) {
                const { data } = response.data;

                setStaffData(data);
            }

            hideLoader();
        }
    }, [showLoader, hideLoader, history, user.userType, params.idStaff]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getStaffData = useMemo(() => {
        return staffData;
    }, [staffData]);

    const yupCustomBranchValidator = (data: string): boolean => {
        if (data) return true;

        return false;
    };

    const handleSubmit = useCallback(
        async (data: IStaffData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    idCompany: Yup.string().test(
                        "Branch",
                        "Branch is required",
                        value => yupCustomBranchValidator(value),
                    ),
                    firstName: Yup.string().required("First Name is required"),
                    contactNumber: Yup.string().required(
                        "Contact Number is required",
                    ),
                    streetAddress: Yup.string().required(
                        "Street Address is required",
                    ),
                    suburb: Yup.string().required("Suburb is required"),
                    city: Yup.string().required("City/Town is required"),
                    state: Yup.string().required("Region is required"),
                    postcode: Yup.string().required("Postcode is required"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Updating...");

                const formData = {
                    id: getStaffData.id,
                    idCompany: data.idCompany,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    lastName: data.lastName,
                    contactNumber: data.contactNumber,
                    streetAddress: data.streetAddress,
                    suburb: data.suburb,
                    city: data.city,
                    state: data.state,
                    postcode: data.postcode,
                    idCountry: getStaffData.idCountry,
                };

                await api.put(
                    `internalConsultant/update-staff-details/${params.idStaff}`,
                    formData,
                );

                toast.success("Staff data successfully updated!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                history.goBack();
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error(
                        "There was an error saving. Please, try again!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                hideLoader();
            }
        },
        [showLoader, hideLoader, history, getStaffData, params.idStaff],
    );

    const getBranches = useMemo(() => {
        let allBranches: IBranchesSelectOptions[] = [];

        if (branchesData.length > 0) {
            allBranches = branchesData.map(branchItem => {
                return {
                    label: branchItem.name,
                    value: branchItem.id,
                };
            });
        }

        return allBranches;
    }, [branchesData]);

    return (
        <MainNew>
            <Container>
                <LinkContainer>
                    <LinkRow>
                        <FaChevronLeft />
                        <button type="button" onClick={() => history.goBack()}>
                            Go back
                        </button>
                    </LinkRow>
                </LinkContainer>
                <TitleContainer>
                    <Title>Manage Staff</Title>
                </TitleContainer>
                <FormContent>
                    {branchesData && staffData && (
                        <Form
                            ref={formRef}
                            onSubmit={handleSubmit}
                            initialData={staffData}
                        >
                            <InputWithLabel
                                name="email"
                                icon={FiMail}
                                label="E-mail"
                                placeholder="E-mail *"
                                readOnly
                            />
                            <SelectWithLabel
                                name="idCompany"
                                icon={FaRegBuilding}
                                label="Branch"
                                data={getBranches}
                                noOptionLabel="Select Branch *"
                            />
                            <InputWithLabel
                                name="firstName"
                                icon={FiUser}
                                label="First Name"
                                placeholder="First Name *"
                            />
                            <InputWithLabel
                                name="middleName"
                                icon={FiUser}
                                label="Middle Name"
                                placeholder="Middle Name"
                            />
                            <InputWithLabel
                                name="lastName"
                                icon={FiUser}
                                label="Last Name"
                                placeholder="Last Name"
                            />
                            <InputNumberWithLabel
                                name="contactNumber"
                                icon={FaPhoneAlt}
                                label="Contact Number"
                                placeholder="only numbers"
                            />
                            <InputWithLabel
                                name="streetAddress"
                                icon={FaMapMarkerAlt}
                                label="Street Address"
                                placeholder="Street Address *"
                            />
                            <InputWithLabel
                                name="suburb"
                                icon={FaMapMarkerAlt}
                                label="Suburb"
                                placeholder="Suburb *"
                            />
                            <InputWithLabel
                                name="city"
                                icon={FaMapMarkerAlt}
                                label="City"
                                placeholder="City *"
                            />
                            <InputWithLabel
                                name="state"
                                icon={FaMapMarkerAlt}
                                label="Region"
                                placeholder="Region *"
                            />
                            <InputNumberWithLabel
                                name="postcode"
                                icon={FaMapMarkerAlt}
                                label="Postcode"
                                placeholder="Postcode *"
                            />
                            <Button type="submit">SAVE</Button>
                        </Form>
                    )}
                </FormContent>
            </Container>
        </MainNew>
    );
};

export default ManageInternalStaff;
