import React from "react";

import { Container } from "./styles";

const PageNotFound: React.FC = () => {
    return (
        <Container>
            <h1>PAGE NOT FOUND!</h1>
        </Container>
    );
};

export default PageNotFound;
