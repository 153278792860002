import styled, { css } from "styled-components";

interface IProductProps {
    type: "bronze" | "silver" | "gold" | "company";
    promote: boolean;
}

const promotedProduct = {
    promote: css`
        height: 550px;
        box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.3);
    `,

    notPromoted: css`
        height: 490px;
        box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.125);
    `,
};

const productOrdering = {
    bronze: css`
        order: 4;

        @media screen and (min-width: 1200px) {
            order: 1;
        }
    `,
    silver: css`
        order: 3;

        @media screen and (min-width: 1200px) {
            order: 2;
        }
    `,
    gold: css`
        order: 1;

        @media screen and (min-width: 1200px) {
            order: 2;
        }
    `,
    company: css`
        order: 2;

        @media screen and (min-width: 1200px) {
            order: 4;
        }
    `,
};

const productBackgroundVariation = {
    bronze: css`
        /* background-image: linear-gradient(
            to right,
            #cd4432 0%,
            #e59e58 51%,
            #cd4432 100%
        ); */
        background-image: var(--background-bronze);
    `,

    silver: css`
        /* background-image: linear-gradient(
            to right,
            #9a9a9a 0%,
            #c0c0c0 51%,
            #9a9a9a 100%
        ); */
        background-image: var(--background-silver);
    `,

    gold: css`
        /* background-image: linear-gradient(
            to right,
            #ffae00 0%,
            #ffd700 51%,
            #ffae00 100%
        ); */
        background-image: var(--background-gold);
    `,
    company: css`
        /* background-image: linear-gradient(
            to right,
            #708c04 0%,
            #b2d235 51%,
            #708c04 100%
        ); */
        background-image: var(--background-company);
    `,
};

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

export const PriceBox = styled.div<IProductProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    border-radius: 10px;
    margin: 20px 5px;

    ${props =>
        props.promote
            ? promotedProduct["promote"]
            : promotedProduct["notPromoted"]}

    ${props =>
        productOrdering[props.type]}

    @media screen and (min-width: 900px) {
        width: 410px;
        margin: 20px auto;
    }

    @media screen and (min-width: 1200px) {
        width: 280px;
        margin: 20px auto;
    }
`;

export const PriceBoxtHeader = styled.div<IProductProps>`
    ${props => productBackgroundVariation[props.type]}

    height: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-radius: 10px 10px 0 0;

    color: #333;
`;

export const ProductTitle = styled.div<IProductProps>`
    span {
        font-weight: bold;
        font-size: ${({ type }) =>
            (type === "gold" && "4.5rem") ||
            (type === "company" && "2.5rem") ||
            "3rem"};
    }
`;

export const ProductTitleBreak = styled.div`
    background-color: #333;
    opacity: 0.7;
    width: 70%;
    height: 1px;
`;

export const ProductPriceContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
`;
export const ProductPrice = styled.div<IProductProps>`
    font-size: 4rem;
    line-height: 1;
    font-weight: 300;
    margin: 0 5px;

    small {
        font-size: 3rem;
    }
`;

export const ProductGst = styled.div``;

export const PriceBoxDescription = styled.div`
    height: 245px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
export const ProductBusinessDays = styled.div<IProductProps>`
    span {
        color: #414141;
        font-weight: 600;
        letter-spacing: normal;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;

        font-size: ${({ promote }) => (promote && "5rem") || "4rem"};
    }
`;

export const ProductDescription = styled.ul<IProductProps>`
    display: flex;
    flex-direction: column;

    li {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        margin: 5px 0;
        max-width: 250px;

        text-align: left;

        svg {
            color: #b2d235;
        }

        span {
            max-width: 210px;
            color: #333;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.8rem;
            margin-left: 20px;

            font-size: ${({ promote }) => (promote && "1.5rem") || "1.2rem"};
        }
    }
`;

export const PriceBoxFooter = styled.div<IProductProps>`
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 0 20px;

    a {
        width: 100%;
        font-weight: 500;
        text-transform: capitalize;
        border-radius: 5px;
        padding: 0.75rem 1.75rem;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        border-color: transparent;
        letter-spacing: 1px;
        text-transform: uppercase;

        font-size: ${({ promote }) => (promote && "1.3rem") || "1rem"};

        ${props => productBackgroundVariation[props.type]}
        color: #333;

        transition: all 0.5s;
        background-size: 200% auto;

        &:hover {
            color: #333;
            background-position: right center;
        }

        cursor: pointer;
        box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.2);
    }
`;
export const ProductLink = styled.a<IProductProps>`
    width: 100%;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 5px;
    padding: 0.75rem 1.75rem;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    border-color: transparent;
    letter-spacing: 1px;
    text-transform: uppercase;

    font-size: ${({ promote }) => (promote && "1.3rem") || "1rem"};

    ${props => productBackgroundVariation[props.type]}
    color: #333;

    transition: all 0.5s;
    background-size: 200% auto;

    &:hover {
        color: #333;
        background-position: right center;
    }

    cursor: pointer;
    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.2);
`;
