import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    overflow: auto hidden;
    min-height: 0px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    align-items: center;
`;

export const Title = styled.div`
    background: #f2f1f3;
    border-radius: 8px;
    padding: 16px;
    width: 100%;

    h3 {
        font-size: 2rem;
        text-align: center;
    }
`;

export const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .rdt_Table {
        margin: 24px 0;
    }

    .rdt_TableHeadRow {
        background: #b2d235;
        text-align: center;

        .rdt_TableCol {
            color: #333;
            font-size: 16px;
        }
    }

    .rdt_Pagination {
        background: #b2d235;
        color: #333;
        font-size: 16px;
    }
`;

export const ActionsCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a,
    button {
        color: #333;
        background: transparent;
        padding: 5px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.7;
        }
    }
`;
