import React, { useMemo, useState, useEffect } from "react";
import { format } from "date-fns";

// Icons
import { FaInfoCircle } from "react-icons/fa";

// API
import api from "../../../services/api";

// Hooks
import { useMOJ } from "../../../hooks/moj";

// Common Types
import { ICountryData } from "../../../types/globalTypes";
import { IMOJAddress, IMOJPreviousName } from "../../../types/mojTypes";

// Utils
import { ensureObjectExists } from "../../../utils/arrayUtilities";

// Styles
import {
    Container,
    Content,
    Warning,
    MainDetails,
    BirthDetails,
    AddressDetails,
    ProofOfIdentityDetails,
    SignatureDetails,
    StepTitle,
    StepTitleText,
    StepLine,
    StepLineField,
    StepLineValue,
    PreviousNamesList,
    PreviousNameListBoxes,
    PreviousNameBox,
    PreviousNameBoxTitle,
    PreviousNameBoxLabels,
    PreviousNameBoxLine,
    PreviousNameValue,
    AddressListBoxes,
    AddressBox,
    AddressBoxTitle,
    AddressBoxLabels,
    AddressBoxLine,
    AddressBoxField,
    AddressBoxValue,
    ProofOfIdentityDisplay,
    TypeOfService,
    CopyRequired,
    SignatureDisplay,
} from "./styles";

// Interfaces
interface IStepSummaryFormData {
    blankProp?: string;
}

const StepSummary: React.FC<IStepSummaryFormData> = ({ blankProp }) => {
    const { moj } = useMOJ();

    const [countryOfBirth, setCountryOfBirth] = useState("");
    const [copyNotRequired, setCopyNotRequired] = useState(false);

    useEffect(() => {
        async function getAllCountries(): Promise<void> {
            if (moj && !moj.idBirthCountry) return;

            await api.get("Country/GetAll").then(response => {
                const { data } = response;
                if (data && data.success) {
                    const countriesResponse: ICountryData[] = data.data;

                    const countryOfBirthExisting = ensureObjectExists(
                        countriesResponse.find(
                            countryItem =>
                                countryItem.id === moj.idBirthCountry,
                        ),
                    );

                    if (countryOfBirthExisting) {
                        setCountryOfBirth(countryOfBirthExisting.shortName);
                    }
                }
            });
        }

        getAllCountries();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPreviousNames = useMemo(() => {
        if (moj && moj.mojPreviousNames) {
            const previousNames = moj.mojPreviousNames.sort((a, b) =>
                a.order > b.order ? 1 : -1,
            );

            const listPreviousNames: IMOJPreviousName[] = [];

            // previousNames.map((previousNameItem, index) => {
            //     const existingPreviousName: IMOJPreviousName = {
            //         ...previousNameItem,
            //         order: index + 1,
            //     };

            //     listPreviousNames.push(existingPreviousName);
            // });

            for (let index = 0; index < previousNames.length; index++) {
                const element = previousNames[index];

                const existingPreviousName: IMOJPreviousName = {
                    ...element,
                    order: index + 1,
                };

                listPreviousNames.push(existingPreviousName);
            }

            return listPreviousNames;
        }

        return [] as IMOJPreviousName[];
    }, [moj]);

    const formattedDateOfBirth = useMemo(() => {
        if (moj && moj.birthDate) {
            const parsedDate = Date.parse(moj.birthDate.toString());

            return format(parsedDate, "dd/MM/yyyy");
        }

        return "";
    }, [moj]);

    const getGender = useMemo(() => {
        if (moj && moj.gender === 1) return "Male";

        if (moj && moj.gender === 2) return "Female";

        return "";
    }, [moj]);

    const getCountryOfBirth = useMemo(() => {
        return countryOfBirth;
    }, [countryOfBirth]);

    const getPostalAddress = useMemo(() => {
        if (moj && moj.mojAddresses) {
            const postalAddressExisting = ensureObjectExists(
                moj.mojAddresses.find(
                    mojAddress => mojAddress.addressType === 1,
                ),
            );

            if (postalAddressExisting) {
                return {
                    streetAddress: postalAddressExisting.streetAddress,
                    suburb: postalAddressExisting.suburb,
                    city: postalAddressExisting.city,
                    state: postalAddressExisting.state,
                    postcode: postalAddressExisting.postcode,
                } as IMOJAddress;
            }
        }

        return {} as IMOJAddress;
    }, [moj]);

    const getResidentialAddress = useMemo(() => {
        if (moj && moj.mojAddresses) {
            const postalResidentialExisting = ensureObjectExists(
                moj.mojAddresses.find(
                    mojAddress => mojAddress.addressType === 2,
                ),
            );

            if (postalResidentialExisting) {
                return {
                    streetAddress: postalResidentialExisting.streetAddress,
                    suburb: postalResidentialExisting.suburb,
                    city: postalResidentialExisting.city,
                    state: postalResidentialExisting.state,
                    postcode: postalResidentialExisting.postcode,
                } as IMOJAddress;
            }
        }

        return {} as IMOJAddress;
    }, [moj]);

    const getPreviousAddresses = useMemo(() => {
        if (moj && moj.mojAddresses) {
            const mojOtherAddresses = moj.mojAddresses
                .filter(
                    mojAddressItem =>
                        mojAddressItem.addressType !== 1 &&
                        mojAddressItem.addressType !== 2,
                )
                .sort((a, b) => (a.order > b.order ? 1 : -1));

            const listExistingPreviousAddress: IMOJAddress[] = [];

            // mojOtherAddresses.map((previousAddressItem, index) => {
            //     const existingPreviousAddress: IMOJAddress = {
            //         ...previousAddressItem,
            //         order: index + 1,
            //     };

            //     listExistingPreviousAddress.push(existingPreviousAddress);
            // });

            for (let index = 0; index < mojOtherAddresses.length; index++) {
                const element = mojOtherAddresses[index];

                const existingPreviousAddress: IMOJAddress = {
                    ...element,
                    order: index + 1,
                };

                listExistingPreviousAddress.push(existingPreviousAddress);
            }

            return listExistingPreviousAddress;
        }

        return [] as IMOJAddress[];
    }, [moj]);

    const urlProofOfIdentityFileMemo = useMemo(() => {
        return moj.identityFormLink;
    }, [moj.identityFormLink]);

    const isFilePDFMemo = useMemo(() => {
        if (urlProofOfIdentityFileMemo) {
            const splittedURL = urlProofOfIdentityFileMemo.split(".").pop();

            if (splittedURL) {
                const extensionFile = splittedURL.split("?AWSAccessKeyId")[0];

                const isPDF = extensionFile.toLowerCase() === "pdf";

                if (isPDF) {
                    // urlProofOfIdentityFile
                    document
                        .getElementById("urlProofOfIdentityFile")
                        ?.setAttribute("src", urlProofOfIdentityFileMemo);
                }

                return isPDF;
            }
        }

        return false;
    }, [urlProofOfIdentityFileMemo]);

    const getTypeOfReport = useMemo(() => {
        if (moj && moj.mojReportType) {
            return moj.mojReportType === 1
                ? "All convictions"
                : "Traffic convictions only";
        }

        return "";
    }, [moj]);

    const getRequiredCopy = useMemo(() => {
        if (moj && !moj.copyNotRequired) {
            const listCopyOptions: string[] = [];

            if (moj.copyRequiredViaEmail) {
                listCopyOptions.push("Copy required via e-mail");
            }

            if (moj.copyRequiredViaPost) {
                listCopyOptions.push("Copy required via post");
            }

            return listCopyOptions;
        }

        setCopyNotRequired(true);

        return [] as string[];
    }, [moj]);

    return (
        <Container>
            <Content>
                {moj && (
                    <>
                        <Warning>
                            <FaInfoCircle />
                            <span>
                                Please, check your details below are correct
                                before submitting your application form!
                            </span>
                        </Warning>
                        {/* MAIN DETAILS */}
                        <MainDetails>
                            <StepTitle>
                                <StepTitleText>Main Details</StepTitleText>
                            </StepTitle>
                            <StepLine>
                                <StepLineField>Email</StepLineField>
                                <StepLineValue>{moj.email}</StepLineValue>
                            </StepLine>
                            <StepLine>
                                <StepLineField>First Name</StepLineField>
                                <StepLineValue>{moj.firstName}</StepLineValue>
                            </StepLine>
                            {moj.middleName && (
                                <StepLine>
                                    <StepLineField>Middle Name</StepLineField>
                                    <StepLineValue>
                                        {moj.middleName}
                                    </StepLineValue>
                                </StepLine>
                            )}
                            {moj.noLastName ? (
                                <StepLine>
                                    <StepLineField>No Last Name</StepLineField>
                                </StepLine>
                            ) : (
                                <StepLine>
                                    <StepLineField>Last Name</StepLineField>
                                    <StepLineValue>
                                        {moj.lastName}
                                    </StepLineValue>
                                </StepLine>
                            )}
                            <StepLine>
                                <StepLineField>Contact Number</StepLineField>
                                <StepLineValue>
                                    {moj.contactNumber}
                                </StepLineValue>
                            </StepLine>
                            {moj.nzDriverLicenceNumber && (
                                <StepLine>
                                    <StepLineField>
                                        NZ Driver Licence
                                    </StepLineField>
                                    <StepLineValue>
                                        {moj.nzDriverLicenceNumber}
                                    </StepLineValue>
                                </StepLine>
                            )}
                            {getPreviousNames && getPreviousNames.length > 0 && (
                                <PreviousNamesList>
                                    <PreviousNameListBoxes>
                                        {getPreviousNames.map(
                                            (prevName, index) => (
                                                <PreviousNameBox
                                                    key={prevName.order}
                                                >
                                                    <PreviousNameBoxTitle>
                                                        {`Previous Name ${
                                                            index + 1
                                                        }`}
                                                    </PreviousNameBoxTitle>
                                                    <PreviousNameBoxLabels>
                                                        <PreviousNameBoxLine>
                                                            <PreviousNameValue>
                                                                {
                                                                    prevName.firstName
                                                                }
                                                            </PreviousNameValue>
                                                            <PreviousNameValue>
                                                                {
                                                                    prevName.middleName
                                                                }
                                                            </PreviousNameValue>
                                                            <PreviousNameValue>
                                                                {
                                                                    prevName.lastName
                                                                }
                                                            </PreviousNameValue>
                                                        </PreviousNameBoxLine>
                                                    </PreviousNameBoxLabels>
                                                </PreviousNameBox>
                                            ),
                                        )}
                                    </PreviousNameListBoxes>
                                </PreviousNamesList>
                            )}
                        </MainDetails>
                        {/* BIRTH DETAILS */}
                        <BirthDetails>
                            <StepTitle>
                                <StepTitleText>Birth Details</StepTitleText>
                            </StepTitle>
                            <StepLine>
                                <StepLineField>Date of birth</StepLineField>
                                <StepLineValue>
                                    {formattedDateOfBirth}
                                </StepLineValue>
                            </StepLine>
                            <StepLine>
                                <StepLineField>Country of birth</StepLineField>
                                <StepLineValue>
                                    {getCountryOfBirth}
                                </StepLineValue>
                            </StepLine>
                            <StepLine>
                                <StepLineField>City of birth</StepLineField>
                                <StepLineValue>{moj.birthCity}</StepLineValue>
                            </StepLine>
                            <StepLine>
                                <StepLineField>Gender</StepLineField>
                                <StepLineValue>{getGender}</StepLineValue>
                            </StepLine>
                        </BirthDetails>
                        {/* ADDRESSES */}
                        <AddressDetails>
                            <StepTitle>
                                <StepTitleText>Address</StepTitleText>
                            </StepTitle>
                            <AddressListBoxes>
                                <AddressBox>
                                    <AddressBoxTitle>
                                        Postal Address
                                    </AddressBoxTitle>
                                    <AddressBoxLabels>
                                        <AddressBoxLine
                                            style={{
                                                marginTop: "8px",
                                            }}
                                        >
                                            <AddressBoxField>
                                                PO Box/Street Address
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getPostalAddress.streetAddress}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                        <AddressBoxLine>
                                            <AddressBoxField>
                                                Suburb
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getPostalAddress.suburb}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                        <AddressBoxLine>
                                            <AddressBoxField>
                                                City
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getPostalAddress.city}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                        <AddressBoxLine>
                                            <AddressBoxField>
                                                Region
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getPostalAddress.state}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                        <AddressBoxLine>
                                            <AddressBoxField>
                                                Postcode
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getPostalAddress.postcode}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                    </AddressBoxLabels>
                                </AddressBox>
                                <AddressBox>
                                    <AddressBoxTitle>
                                        Residential Address
                                    </AddressBoxTitle>
                                    <AddressBoxLabels>
                                        <AddressBoxLine
                                            style={{
                                                marginTop: "8px",
                                            }}
                                        >
                                            <AddressBoxField>
                                                PO Box/Street Address
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {
                                                    getResidentialAddress.streetAddress
                                                }
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                        <AddressBoxLine>
                                            <AddressBoxField>
                                                Suburb
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getResidentialAddress.suburb}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                        <AddressBoxLine>
                                            <AddressBoxField>
                                                City
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getResidentialAddress.city}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                        <AddressBoxLine>
                                            <AddressBoxField>
                                                Region
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getResidentialAddress.state}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                        <AddressBoxLine>
                                            <AddressBoxField>
                                                Postcode
                                            </AddressBoxField>
                                            <AddressBoxValue>
                                                {getResidentialAddress.postcode}
                                            </AddressBoxValue>
                                        </AddressBoxLine>
                                    </AddressBoxLabels>
                                </AddressBox>

                                {getPreviousAddresses &&
                                    getPreviousAddresses.map(
                                        (previousAddress, index) => (
                                            <AddressBox
                                                key={previousAddress.order}
                                            >
                                                <AddressBoxTitle>
                                                    {`Previous Address ${
                                                        index + 1
                                                    }`}
                                                </AddressBoxTitle>
                                                <AddressBoxLabels>
                                                    <AddressBoxLine
                                                        style={{
                                                            marginTop: "8px",
                                                        }}
                                                    >
                                                        <AddressBoxField>
                                                            PO Box/Street
                                                            Address
                                                        </AddressBoxField>
                                                        <AddressBoxValue>
                                                            {
                                                                previousAddress.streetAddress
                                                            }
                                                        </AddressBoxValue>
                                                    </AddressBoxLine>
                                                    <AddressBoxLine>
                                                        <AddressBoxField>
                                                            Suburb
                                                        </AddressBoxField>
                                                        <AddressBoxValue>
                                                            {
                                                                previousAddress.suburb
                                                            }
                                                        </AddressBoxValue>
                                                    </AddressBoxLine>
                                                    <AddressBoxLine>
                                                        <AddressBoxField>
                                                            City
                                                        </AddressBoxField>
                                                        <AddressBoxValue>
                                                            {
                                                                previousAddress.city
                                                            }
                                                        </AddressBoxValue>
                                                    </AddressBoxLine>
                                                    <AddressBoxLine>
                                                        <AddressBoxField>
                                                            Region
                                                        </AddressBoxField>
                                                        <AddressBoxValue>
                                                            {
                                                                previousAddress.state
                                                            }
                                                        </AddressBoxValue>
                                                    </AddressBoxLine>
                                                    <AddressBoxLine>
                                                        <AddressBoxField>
                                                            Postcode
                                                        </AddressBoxField>
                                                        <AddressBoxValue>
                                                            {
                                                                previousAddress.postcode
                                                            }
                                                        </AddressBoxValue>
                                                    </AddressBoxLine>
                                                </AddressBoxLabels>
                                            </AddressBox>
                                        ),
                                    )}
                            </AddressListBoxes>
                        </AddressDetails>
                        {/* PROOF OF IDENTITY */}
                        <ProofOfIdentityDetails>
                            <StepTitle>
                                <StepTitleText>Proof of Identity</StepTitleText>
                            </StepTitle>
                            {urlProofOfIdentityFileMemo && (
                                <ProofOfIdentityDisplay>
                                    {isFilePDFMemo ? (
                                        <iframe
                                            // id="urlProofOfIdentityFile"
                                            src={urlProofOfIdentityFileMemo}
                                            title="Proof of Identity"
                                        />
                                    ) : (
                                        <img
                                            src={urlProofOfIdentityFileMemo}
                                            alt="Proof of Identity"
                                        />
                                    )}
                                </ProofOfIdentityDisplay>
                            )}
                        </ProofOfIdentityDetails>
                        {/* SIGNATURE */}
                        <SignatureDetails>
                            <StepTitle>
                                <StepTitleText>
                                    Signature & <br /> Service required
                                </StepTitleText>
                            </StepTitle>
                            {moj && moj.mojType === 1 ? (
                                <>
                                    <TypeOfService>
                                        <StepLine>
                                            <StepLineField>
                                                Type of Report
                                            </StepLineField>
                                            <StepLineValue>
                                                {getTypeOfReport}
                                            </StepLineValue>
                                        </StepLine>
                                    </TypeOfService>
                                    <CopyRequired>
                                        {getRequiredCopy &&
                                            getRequiredCopy.map(option => (
                                                <StepLine>
                                                    <StepLineField>
                                                        {option}
                                                    </StepLineField>
                                                </StepLine>
                                            ))}

                                        {copyNotRequired && (
                                            <StepLine>
                                                <StepLineField>
                                                    Copy not required
                                                </StepLineField>
                                            </StepLine>
                                        )}
                                    </CopyRequired>
                                </>
                            ) : null}
                            <SignatureDisplay>
                                <span>Your signature</span>
                                {moj && moj.signatureLink && (
                                    <div>
                                        <img
                                            src={moj.signatureLink}
                                            alt="Signature"
                                        />
                                    </div>
                                )}
                            </SignatureDisplay>
                        </SignatureDetails>
                    </>
                )}
            </Content>
        </Container>
    );
};

export default StepSummary;
