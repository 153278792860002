import styled from "styled-components";
import { FaUserCog, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FiPower } from "react-icons/fi";

export const Container = styled.div`
    padding: 0 16px;

    /* height: 50px; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* position: fixed;
    top: 0;
    left: 0;
    right: 0; */
    z-index: 2;

    @media (min-width: 768px) {
        display: none;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex: 1;

    max-width: 1200px;
    margin: 0 auto;
    height: 60px;
`;

export const DropDownContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;

export const MenuDropDownContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    flex: 1;
`;

export const DropDownButton = styled.button`
    background: var(--color-green);
    border: 0;
    outline: 0;
    margin: 0 8px;
    padding: 8px;
    border-radius: 8px;

    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #333;
    cursor: pointer;

    &:hover {
        background: var(--color-green-hover);
    }
`;

export const LongDescription = styled.div`
    display: none;

    @media (min-width: 500px) {
        display: block;
    }
`;

export const ShortDescription = styled.div`
    display: block;

    @media (min-width: 500px) {
        display: none;
    }
`;

export const MOJCreditsDropDownContent = styled.div`
    display: flex;
    background: var(--color-whitesmoke);
    border-radius: 8px;

    position: absolute;
    opacity: 1;
    transform: none;
    transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 56px;

    /* left: 0; */
    left: -110px;

    @media only screen and (min-width: 375px) {
        left: -80px;
    }

    @media only screen and (min-width: 425px) {
        left: -50px;
    }

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const MenuDropDownContent = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--color-whitesmoke);
    border-radius: 8px;

    position: absolute;
    opacity: 1;
    transform: none;
    transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 16px;

    right: 0px;

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const MenuUserInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
`;

export const MenuUserInfoName = styled.div`
    font-weight: 500;
    margin-bottom: 8px;
`;

export const MenuUserInfoEmail = styled.span`
    font-size: 14px;
`;

export const MenuDropDownLinks = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 8px;

    max-height: 150px;
    overflow: auto;
`;

export const MenuDropDownLinksRow = styled.li`
    margin: 8px;
    color: #333;
    font-weight: 400;

    a {
        color: #333;
    }
`;

export const IconDropDown = styled(FaCaretDown)`
    margin-left: 8px;
`;

export const IconDropDownExpanded = styled(FaCaretUp)`
    margin-left: 8px;
`;

export const IconCog = styled(FaUserCog)`
    margin-left: 8px;
`;

export const IconSignOut = styled(FiPower)`
    margin-left: 8px;
`;

export const MOJCreditsContent = styled.div`
    background: red;
`;

export const Divider = styled.div`
    background: var(--color-green);
    height: 2px;
    overflow: hidden;
    width: 100%;
`;

export const BronzeBadgeColor = styled.div`
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: var(--background-bronze);

    color: #333;
    font-weight: 700;
`;

export const SilverBadgeColor = styled.div`
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: var(--background-silver);

    color: #333;
    font-weight: 700;
`;

export const GoldBadgeColor = styled.div`
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: var(--background-gold);

    color: #333;
    font-weight: 700;
`;

export const MOJCreditsBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const MOJCreditsBadges = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BadgeBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;
    margin: 0 8px;
`;

export const MOJCreditsBoxBuyCredits = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;

    a {
        background: var(--color-green);
        border: 0;
        outline: 0;
        margin: 0 8px;
        padding: 8px;
        border-radius: 8px;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #333;
        cursor: pointer;

        &:hover {
            background: var(--color-green-hover);
        }
    }
`;

export const BadgeTitle = styled.span`
    font-weight: 600;
`;

export const SignOutBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    button {
        background: none;
        border: 0;
        outline: 0;
        margin: 0 8px;
        padding: 8px;
        border-radius: 8px;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #333;
        cursor: pointer;

        white-space: nowrap;

        svg {
            margin-right: 8px;
        }
    }
`;
