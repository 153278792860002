import React, { useEffect, useRef, InputHTMLAttributes } from "react";
import { useField } from "@unform/core";

import { FiAlertCircle } from "react-icons/fi";

import { Container, Options, Label, Error } from "./styles";

interface IRadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    options: {
        id: string;
        value: string;
        label: string;
    }[];
    inline?: boolean;
    noBackground?: boolean;
    small?: boolean;
}

const RadioInput: React.FC<IRadioInputProps> = ({
    name,
    options,
    inline,
    noBackground,
    small,
    ...rest
}) => {
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const { fieldName, registerField, error, defaultValue = "" } = useField(
        name,
    );

    const { readOnly } = rest;

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRefs.current,
            // getValue: (refs: HTMLInputElement[]) => {
            //     return refs.find(ref => ref && ref.checked)?.value || "";
            // },
            getValue: ref => {
                if (!ref || ref.length < 1) {
                    return "";
                }
                const item = ref?.find(
                    (itemRef: HTMLInputElement) => itemRef && itemRef.checked,
                );
                if (!item) {
                    return "";
                }
                return item?.value;
            },
            setValue: (refs: HTMLInputElement[], id: string) => {
                const inputRef = refs.find(ref => ref.id === id);
                if (inputRef) inputRef.checked = true;
            },
            clearValue: (refs: HTMLInputElement[]) => {
                const inputRef = refs.find(ref => ref.checked === true);
                if (inputRef) inputRef.checked = false;
            },
        });
    }, [defaultValue, fieldName, registerField]);

    return (
        <Container
            isErrored={!!error}
            isReadOnly={readOnly || false}
            noBackground={noBackground}
            className="containerDiv"
        >
            <Options inline={inline} className="optionsDiv">
                {options.map(option => {
                    return (
                        <Label
                            htmlFor={option.id}
                            key={option.id}
                            noBackground={noBackground}
                            small={small}
                            inline={inline}
                        >
                            <input
                                ref={ref =>
                                    inputRefs.current.push(
                                        ref as HTMLInputElement,
                                    )
                                }
                                id={option.id}
                                type="radio"
                                name={name}
                                defaultChecked={
                                    defaultValue &&
                                    defaultValue.toString() === option.id
                                }
                                value={option.value}
                                {...rest}
                            />
                            {option.label}
                        </Label>
                    );
                })}
            </Options>

            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default RadioInput;
