import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface IContainerProps {
    isErrored: boolean;
    small?: boolean;
    noBackground?: boolean;
    noPadding?: boolean;
}

interface ILabelContainerProps {
    small?: boolean;
}

export const Container = styled.div<IContainerProps>`
    background: ${props => (props.noBackground ? "transparent" : "#eaf6bc")};

    border-radius: 10px;
    padding: ${props => (props.noPadding ? "0" : "16px")};

    ${props =>
        props.small &&
        css`
            padding: ${props.noPadding ? "0" : "0 16px"};

            margin: 10px 0;
        `}

    border: ${props =>
        props.noBackground ? "2px solid transparent" : "2px solid #eaf6bc"};

    display: flex;
    align-items: start;

    ${props =>
        props.isErrored &&
        css`
            border-color: #c53030;
        `}
`;

export const Error = styled(Tooltip)`
    height: 20px;
    margin-left: 16px;

    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`;

export const LabelContainer = styled.label<ILabelContainerProps>`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-top: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: initial;
    line-height: 1.5;

    ${props =>
        props.small &&
        css`
            font-size: 13px;
        `}

    > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eaf6bc;
        border: 2px solid #b2d235;
        border-radius: 5px;

        ${props =>
            props.small &&
            css`
                height: 20px;
                width: 20px;
            `}
    }

    &:hover input ~ .checkmark {
        background-color: #475412;
    }

    > input:checked ~ .checkmark {
        background-color: #b2d235;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 7px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);

        ${props =>
            props.small &&
            css`
                left: 5px;
                top: 1px;
                width: 3px;
                height: 8px;
            `}
    }

    a {
        color: #b2d235;
        background-color: transparent;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
    }

    label {
        color: #b2d235;
        background-color: transparent;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
    }

    button {
        color: #b2d235;
        font-weight: bold;
        font-size: 14px;
        background: transparent;
        border: none;
        text-transform: uppercase;
    }
`;
