import styled, { css } from "styled-components";
// To work with animations and make it work, we need to import
// and use it instead of HTMLstandard elements
import { animated } from "react-spring";

interface IContainerProps {
    type?: "success" | "error" | "info";
    $hasdescription: boolean;
}

const toastTypeVariations = {
    info: css`
        background: #ebf8ff;
        color: #3172b7;
    `,

    success: css`
        background: rgba(178, 210, 53, 0.8);
        color: #212121;
    `,

    error: css`
        background: #fddede;
        color: #c53030;
    `,
};

// Whe using react-spring, Instead of "styled.div", we need to change to "styled(animated.div)"
export const Container = styled(animated.div)<IContainerProps>`
    width: 300px;

    @media screen and (min-width: 900px) {
        width: 360px;
    }

    position: relative;
    padding: 16px 30px 16px 16px;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

    display: flex;

    & + div {
        margin-top: 8px;
    }

    ${props => toastTypeVariations[props.type || "info"]}

    > svg {
        margin: 4px 12px 0 0;
    }

    div {
        flex: 1;

        p {
            margin-top: 4px;
            font-size: 16px;
            opacity: 0.8;
            line-height: 20px;
            font-weight: 500;
        }
    }
    button {
        position: absolute;
        right: 16px;
        top: 19px;
        opacity: 0.6;
        border: 0;
        background: transparent;
        color: inherit;
    }

    ${props =>
        !props.$hasdescription &&
        css`
            align-items: center;

            svg {
                margin-top: 0;
            }
        `}
`;
