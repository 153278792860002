import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

// Icons
import { FaMapMarkerAlt, FaPhoneAlt, FaRegBuilding } from "react-icons/fa";
import { FiLogIn, FiMail, FiLock, FiUser } from "react-icons/fi";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useAuth } from "../../hooks/auth";
import { useLoader } from "../../hooks/loader";

// Common Components
import MainNew from "../MainNew";
import Button from "../../components/Button";
import InputWithLabel from "../../components/InputWithLabel";
import SelectWithLabel from "../../components/SelectWithLabel";
import InputNumberWithLabel from "../../components/InputNumberWithLabel";
import InputMaskWithLabel from "../../components/InputMaskWithLabel";

// Utils
import getValidationErrors from "../../utils/getValidationErrors";

// Types
import UrlsAddress from "../../types/urlsAddress";
import { IMOJCustomError } from "../../types/globalTypes";
import {
    IBranchData,
    IBranchesSelectOptions,
} from "../../types/internalConsultantsTypes";

import { Container, Title, TitleContainer, FormContent } from "./styles";

interface IStaffRegisterDTO {
    email: string;
    confirmEmail: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    contactNumber: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    idBranch: string;
}

const AddStaff: React.FC = () => {
    const [branchesData, setBranchesData] = useState<IBranchData[]>([]);

    const formRef = useRef<FormHandles>(null);

    const { user } = useAuth();
    const { showLoader, hideLoader } = useLoader();

    const history = useHistory();

    const fetchData = useCallback(async () => {
        if (user.userType !== 6 && user.userType !== 7) {
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        }

        showLoader("Processing...");

        try {
            await api
                .get("internalConsultant/get-internal-branches")
                .then(response => {
                    const { data } = response;

                    if (data && data.success && data.data) {
                        const branches: IBranchData[] = data.data;

                        setBranchesData(branches);
                    }
                });
        } catch (err) {
            if (err.response.data) {
                const { errors, success }: IMOJCustomError = err.response.data;

                if (!success && errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const element = errors[index];

                        toast.error(element, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            } else {
                toast.error(
                    "There was an error signing in, check your credentials!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            }
        } finally {
            hideLoader();
        }
    }, [showLoader, hideLoader, history, user.userType]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const yupEmailMatchValidation = (confirmEmail: string): boolean => {
        if (formRef && formRef.current) {
            const emailValue: string = formRef.current.getFieldValue("email");
            return emailValue.toLowerCase() === confirmEmail.toLowerCase();
        }

        return false;
    };

    const yupCustomBranchValidator = (data: string): boolean => {
        if (data) return true;

        return false;
    };

    const handleSubmit = useCallback(
        async (data: IStaffRegisterDTO) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail address is required")
                        .email("E-mail address invalid"),
                    confirmEmail: Yup.string()
                        .email()
                        .test(
                            "emailMatch",
                            "E-mail and e-mail confirmation do not match",
                            value => yupEmailMatchValidation(value),
                        ),
                    firstName: Yup.string().required("First Name is required"),
                    lastName: Yup.string().required("Last Name is required"),
                    contactNumber: Yup.string().required(
                        "Phone number is required",
                    ),
                    streetAddress: Yup.string().required(
                        "PO Box/Street is required",
                    ),
                    suburb: Yup.string().required("Suburb is required"),
                    city: Yup.string().required("City/Town is required"),
                    state: Yup.string().required("Region is required"),
                    postcode: Yup.string().required("Postcode is required"),
                    idBranch: Yup.string().test(
                        "Branch",
                        "Branch is required",
                        value => yupCustomBranchValidator(value),
                    ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Adding staff...");

                const formData = {
                    email: data.email,
                    confirmEmail: data.confirmEmail,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    lastName: data.lastName,
                    contactNumber: data.contactNumber,
                    streetAddress: data.streetAddress,
                    suburb: data.suburb,
                    city: data.city,
                    state: data.state,
                    postcode: data.postcode,
                    idBranch: data.idBranch,
                };

                await api.post("internalConsultant/add-staff", formData);

                toast.success("Staff successfully registered!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // Redirect to Branch
                history.push(
                    `${UrlsAddress.INTERNAL_STAFF.replace("/:branch?", "")}/${
                        data.idBranch
                    }`,
                );
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error(
                        "There was an error adding staff. Please, try again!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                hideLoader();
            }
        },
        [showLoader, hideLoader],
    );

    const getBranches = useMemo(() => {
        const allBranches: IBranchesSelectOptions[] = [];

        if (branchesData.length > 0) {
            for (let index = 0; index < branchesData.length; index++) {
                const branch = branchesData[index];

                const option: IBranchesSelectOptions = {
                    label: branch.name,
                    value: branch.id,
                };

                allBranches.push(option);
            }
        }

        return allBranches;
    }, [branchesData]);

    return (
        <MainNew>
            <Container>
                <TitleContainer>
                    <Title>Add New Staff</Title>
                </TitleContainer>
                <FormContent>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <SelectWithLabel
                            name="idBranch"
                            icon={FaRegBuilding}
                            label="Branch"
                            data={getBranches}
                            noOptionLabel="Branch"
                        />
                        <InputWithLabel
                            name="email"
                            icon={FiMail}
                            label="E-mail"
                            placeholder="E-mail *"
                        />
                        <InputWithLabel
                            name="confirmEmail"
                            icon={FiMail}
                            label="Confirm E-mail"
                            placeholder="Confirm your E-mail *"
                        />
                        <InputWithLabel
                            name="firstName"
                            icon={FiUser}
                            label="First Name"
                            placeholder="First Name *"
                        />
                        <InputWithLabel
                            name="middleName"
                            icon={FiUser}
                            label="Middle Name"
                            placeholder="Middle Name"
                        />
                        <InputWithLabel
                            name="lastName"
                            icon={FiUser}
                            label="Last Name"
                            placeholder="Last Name"
                        />
                        <InputNumberWithLabel
                            name="contactNumber"
                            icon={FaPhoneAlt}
                            label="Contact Number"
                            placeholder="Contact Number *"
                        />
                        <InputWithLabel
                            name="streetAddress"
                            icon={FaMapMarkerAlt}
                            label="Street Address"
                            placeholder="PO Box/Street Address *"
                        />
                        <InputWithLabel
                            name="suburb"
                            icon={FaMapMarkerAlt}
                            label="Suburb"
                            placeholder="Suburb *"
                        />
                        <InputWithLabel
                            name="city"
                            icon={FaMapMarkerAlt}
                            label="City/Town"
                            placeholder="City/Town *"
                        />
                        <InputWithLabel
                            name="state"
                            icon={FaMapMarkerAlt}
                            label="Region"
                            placeholder="Region *"
                        />
                        <InputMaskWithLabel
                            name="postcode"
                            icon={FaMapMarkerAlt}
                            label="Postcode"
                            placeholder="Postcode *"
                            mask="9999"
                        />
                        <Button type="submit">SAVE</Button>
                    </Form>
                </FormContent>
            </Container>
        </MainNew>
    );
};

export default AddStaff;
