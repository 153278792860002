import styled from "styled-components";

import { FaUserCog } from "react-icons/fa";
import { FiPower } from "react-icons/fi";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    /* flex: 1; */
`;

export const DropDownButton = styled.button`
    background: var(--color-green);
    border: 0;
    outline: 0;
    margin: 0 8px;
    padding: 8px;
    border-radius: 8px;

    font-size: 0.875rem;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #333;
    cursor: pointer;

    box-shadow: -1px 3px 4px 2px rgba(0, 0, 0, 0.3);

    &:hover {
        background: var(--color-green-hover);
    }

    @media only screen and (min-width: 600px) {
        font-size: 1rem;
    }
`;

export const LongDescription = styled.div`
    display: none;

    @media (min-width: 500px) {
        display: block;
    }
`;

export const ShortDescription = styled.div`
    display: block;

    @media (min-width: 500px) {
        display: none;
    }
`;

export const IconCog = styled(FaUserCog)`
    margin-left: 8px;
`;

export const MenuDropDownContent = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--color-whitesmoke);
    border-radius: 8px;

    position: absolute;
    opacity: 1;
    transform: none;
    transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 16px;

    right: 0px;

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const MenuUserInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
`;

export const MenuUserInfoName = styled.div`
    font-weight: 500;
    margin-bottom: 8px;
`;

export const MenuUserInfoEmail = styled.span`
    font-size: 14px;
`;

export const Divider = styled.div`
    background: var(--color-green);
    height: 2px;
    overflow: hidden;
    width: 100%;
`;

export const MenuDropDownLinks = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 8px;

    max-height: 150px;
    overflow: auto;
`;

export const MenuDropDownLinksRow = styled.li`
    margin: 8px;
    color: #333;
    font-weight: 400;
    text-align: center;

    a {
        color: #333;
    }
`;

export const SignOutBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    button {
        background: none;
        border: 0;
        outline: 0;
        margin: 0 8px;
        padding: 8px;
        border-radius: 8px;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #333;
        cursor: pointer;

        white-space: nowrap;

        svg {
            margin-right: 8px;
        }
    }
`;

export const IconSignOut = styled(FiPower)`
    margin-left: 8px;
`;
