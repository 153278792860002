import React, {
    useRef,
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useEffect,
    ChangeEvent,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

// Icons
import { FaInfoCircle } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../services/api";

// Hooks
import { IStepProofOfIdentityFormData, useMOJ } from "../../../hooks/moj";
// import { useToast } from "../../../hooks/toast";
import { useLoader } from "../../../hooks/loader";

// Common Interfaces
import { IMOJCustomError, IRadioOption } from "../../../types/globalTypes";

// Common Components
import RadioInput from "../../../components/RadioInput";
import Checkbox from "../../../components/Checkbox";

// Utils
import getValidationErrors from "../../../utils/getValidationErrors";

// Styles
import {
    Container,
    Content,
    Warning,
    OptionsLabel,
    Info,
    LinkDownloadIdentificationForm,
    InputFileBox,
    InputFileDisplay,
} from "./styles";

// Interfaces
export interface IProofOfIdentityMethods {
    isValid(): boolean;
    validate(): void;
    returnProofOfIdentityData(): IStepProofOfIdentityFormData;
}

const StepProofOfIdentity: React.RefForwardingComponent<
    IProofOfIdentityMethods,
    IStepProofOfIdentityFormData
> = ({ hasAgreedWithIDTerms }, ref) => {
    const formRef = useRef<FormHandles>(null);

    const [proofOfIdentityData, setProofOfIdentityData] = useState<
        IStepProofOfIdentityFormData | undefined
    >();
    const [urlProofOfIdentityFile, setUrlProofOfIdentityFile] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [infoTypeOfIdentification, setInfoTypeOfIdentification] = useState(
        "",
    );
    const [
        showFormIdentificationLink,
        setShowFormIdentificationLink,
    ] = useState(false);

    // const [fileUploaded, setFileUploaded] = useState(false);
    const [isMissingFile, setIsMissingFile] = useState(false);

    const radioOptions: IRadioOption[] = [
        { id: "1", value: "1", label: "NZ Driver Licence" },
        { id: "2", value: "2", label: "NZ Passport" },
        { id: "3", value: "3", label: "Overseas Passport" },
        { id: "4", value: "4", label: "NZ Firearms Licence" },
        { id: "5", value: "5", label: "Your RealMe Verified Identity" },
        {
            id: "6",
            value: "6",
            label: "I do not have any of these forms of identification",
        },
    ];

    const { moj, updateProofOfIdentify, updateMOJIdentityLink } = useMOJ();
    // const { addToast } = useToast();
    const { showLoader, hideLoader } = useLoader();

    const manageDisplayIdentificationInfo = useCallback(
        (identificationType: number) => {
            let infoText = "";

            setShowFormIdentificationLink(false);

            switch (identificationType) {
                case 1: {
                    infoText =
                        "New Zealand Driver Licence – can be current or expired within the last 2 years, but cannot be cancelled, defaced or a temporary licence";
                    break;
                }
                case 2: {
                    infoText =
                        "New Zealand Passport – can be current or expired within the last 2 years, but cannot be cancelled or defaced. Must show your signature";
                    break;
                }
                case 3: {
                    infoText =
                        "Overseas Passports – must be current and cannot be expired, cancelled or defaced. Must show your signature";
                    break;
                }
                case 4: {
                    infoText =
                        "New Zealand Firearms Licence – must be current and cannot be expired or defaced";
                    break;
                }
                case 5: {
                    infoText = "Your RealMe verified identity";
                    break;
                }
                case 6: {
                    setShowFormIdentificationLink(true);
                    infoText =
                        "If you do not have any of these forms of identification, you will need to ask someone to confirm your identify. Please download the Proof of identify form below and upload it after filling it. If you are unable to get someone to fill the form, then you must complete a statutory declaration form and upload it. The relevant form can be obtained from your local District Court or go to www.justice.govt.nz/services/criminal-records";
                    break;
                }
                default: {
                    infoText = "";
                }
            }

            setInfoTypeOfIdentification(infoText);
        },
        [],
    );

    useEffect(() => {
        const proofOfIdentityDetail: IStepProofOfIdentityFormData = {
            mojApplicantIdentificationType: moj.mojApplicantIdentificationType,
            identityFormLink: moj.identityFormLink,
            hasIncludedProofOfIdentity: moj.hasIncludedProofOfIdentity,
            hasAgreedWithIDTerms,
        };

        if (moj && moj.identityFormLink) {
            setUrlProofOfIdentityFile(moj.identityFormLink);
            // setFileUploaded(true);
        }

        setProofOfIdentityData(proofOfIdentityDetail);

        manageDisplayIdentificationInfo(
            moj.mojApplicantIdentificationType
                ? moj.mojApplicantIdentificationType
                : 0,
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moj]);

    function isValid(): boolean {
        return isFormValid;
    }

    function validate(): void {
        formRef.current?.submitForm();
    }

    function returnProofOfIdentityData(): IStepProofOfIdentityFormData {
        return proofOfIdentityData!;
    }

    useImperativeHandle(ref, () => ({
        isValid,
        validate,
        returnProofOfIdentityData,
    }));

    const handleInfoTypeOfIdentification = useCallback(() => {
        if (formRef && formRef.current) {
            const mojApplicantIdentificationTypeValue: string = formRef.current.getFieldValue(
                "mojApplicantIdentificationType",
            );

            if (mojApplicantIdentificationTypeValue) {
                manageDisplayIdentificationInfo(
                    Number(mojApplicantIdentificationTypeValue),
                );
            }
        }
    }, [manageDisplayIdentificationInfo]);

    const handleUploadProofOfIdentity = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                let isFileUploaded = false;
                let linkToUpload = "";

                const identityFileDTO = new FormData();

                const baseMOJFileDTO = {
                    idMOJ: moj.id,
                };

                if (e.target.files[0].size > 3000000) {
                    toast.error(
                        "File larger than 3mb. Please, change the resolution of your camera or select a lighter file!",
                        {
                            position: "top-right",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );

                    return false;
                }

                identityFileDTO.append("identityFile", e.target.files[0]);
                identityFileDTO.append("IdentityProofFile", e.target.files[0]);
                identityFileDTO.append(
                    "baseMOJFileDTO",
                    JSON.stringify(baseMOJFileDTO),
                );

                showLoader("Uploading file...");

                await api
                    .post("/MOJ/UploadIdentityProofFileToS3", identityFileDTO, {
                        timeout: 300000,
                    })
                    .then(response => {
                        const { data } = response;

                        if (data && data.success) {
                            setUrlProofOfIdentityFile(data.data);
                            setIsMissingFile(false);

                            linkToUpload = data.data;

                            isFileUploaded = true;
                        }
                    })
                    .catch(error => {
                        const {
                            success,
                            errors,
                        }: IMOJCustomError = error.response.data;

                        if (!success && errors) {
                            for (
                                let index = 0;
                                index < errors.length;
                                index++
                            ) {
                                const element = errors[index];

                                toast.error(element, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        }

                        hideLoader();

                        // handle error
                        // addToast({
                        //     type: "error",
                        //     title: "Error when uploading Proof of Identity",
                        //     description:
                        //         "An error occurred when updating your file. Please, try again!",
                        // });
                        // toast.error(
                        //     "An error occurred when updating your file. Please, try again!",
                        //     {
                        //         position: "top-right",
                        //         autoClose: 5000,
                        //         hideProgressBar: false,
                        //         closeOnClick: true,
                        //         pauseOnHover: true,
                        //         draggable: true,
                        //         progress: undefined,
                        //     },
                        // );
                    });

                if (isFileUploaded) {
                    await updateMOJIdentityLink(linkToUpload);

                    const fileDisplayBoxEl = document.getElementById(
                        "fileDisplayBox",
                    );

                    fileDisplayBoxEl?.scrollIntoView({ behavior: "smooth" });

                    toast.success("Proof of identity successfully uploaded!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                hideLoader();
            }
        },
        [moj.id, updateMOJIdentityLink, showLoader, hideLoader],
    );

    const yupIdentificationTypeValidator = (data: string): boolean => {
        if (
            data === "1" ||
            data === "2" ||
            data === "3" ||
            data === "4" ||
            data === "5" ||
            data === "6"
        ) {
            return true;
        }

        return false;
    };

    const mojHasIdentityLink = useCallback(() => {
        setIsMissingFile(false);

        if (urlProofOfIdentityFile) return true;

        setIsMissingFile(true);

        return false;
    }, [urlProofOfIdentityFile]);

    const handleValidation = useCallback(
        async (data: IStepProofOfIdentityFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    // options
                    mojApplicantIdentificationType: Yup.string().test(
                        "mojApplicantIdentificationType",
                        "Identification type is required",
                        value => yupIdentificationTypeValidator(value),
                    ),
                    //
                    hasAgreedWithIDTerms: Yup.boolean()
                        .required("The terms and conditions must be accepted")
                        .oneOf(
                            [true],
                            "The terms and conditions must be accepted",
                        ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                if (!mojHasIdentityLink()) return;

                await updateProofOfIdentify(data);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    setIsFormValid(false);
                }
            }
        },
        [updateProofOfIdentify, mojHasIdentityLink],
    );

    const urlProofOfIdentityFileMemo = useMemo(() => {
        return urlProofOfIdentityFile;
    }, [urlProofOfIdentityFile]);

    const isFilePDFMemo = useMemo(() => {
        if (urlProofOfIdentityFileMemo) {
            const splittedURL = urlProofOfIdentityFileMemo.split(".").pop();

            if (splittedURL) {
                const extensionFile = splittedURL.split("?AWSAccessKeyId")[0];

                return extensionFile.toLowerCase() === "pdf";
            }
        }

        return false;
    }, [urlProofOfIdentityFileMemo]);

    return (
        <Container>
            <Content>
                {proofOfIdentityData && (
                    <Form
                        ref={formRef}
                        onSubmit={handleValidation}
                        initialData={proofOfIdentityData}
                    >
                        <Warning>
                            <FaInfoCircle />
                            <span>
                                Please upload a clear copy of your
                                identification which must contain your
                                signature. You may use any one of the following:
                            </span>
                        </Warning>
                        <OptionsLabel>
                            <span>Please, choose one</span>
                        </OptionsLabel>
                        <RadioInput
                            // name="radioTypeOfIdentification"
                            name="mojApplicantIdentificationType"
                            options={radioOptions}
                            noBackground
                            onChange={handleInfoTypeOfIdentification}
                        />
                        {infoTypeOfIdentification && (
                            <Info>
                                <FaInfoCircle />
                                <span>{infoTypeOfIdentification}</span>
                            </Info>
                        )}
                        {showFormIdentificationLink && (
                            <LinkDownloadIdentificationForm
                                href="https://mojapp.s3-ap-southeast-2.amazonaws.com/Proof+of+identity.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                DOWNLOAD PROOF OF IDENTITY FORM
                            </LinkDownloadIdentificationForm>
                        )}
                        <Info>
                            <FaInfoCircle />
                            <span>
                                You can upload a pdf document or image from your
                                computer/mobile or take a photo using your
                                mobile phone camera.
                            </span>
                        </Info>
                        <InputFileBox invalid={isMissingFile} className="box">
                            <input
                                type="file"
                                name="identityFile"
                                id="identityFile"
                                className="inputfile inputfile-4"
                                accept="image/jpeg,image/jpg,image/png,application/pdf"
                                // data-multiple-caption="{count} files selected"
                                onChange={handleUploadProofOfIdentity}
                            />
                            <label htmlFor="identityFile">
                                <figure>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="17"
                                        viewBox="0 0 20 17"
                                    >
                                        <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                                    </svg>
                                </figure>{" "}
                                <span>Choose a file&hellip;</span>
                                <div>
                                    <span className="warningLimitSize">
                                        The file size limit is 3mb
                                    </span>
                                </div>
                                <div>
                                    <span className="warningLimitSize">
                                        Allowed file: JPEG | JPG | PNG | PDF
                                    </span>
                                </div>
                            </label>
                        </InputFileBox>

                        {urlProofOfIdentityFileMemo && (
                            <InputFileDisplay id="fileDisplayBox">
                                {isFilePDFMemo ? (
                                    // <embed
                                    //     src={urlProofOfIdentityFileMemo}
                                    //     type="application/pdf"
                                    // />
                                    <iframe
                                        // id="urlProofOfIdentityFile"
                                        src={urlProofOfIdentityFileMemo}
                                        title="Proof of Identity"
                                    />
                                ) : (
                                    <img
                                        src={urlProofOfIdentityFileMemo}
                                        alt="Proof of Identity"
                                    />
                                )}
                            </InputFileDisplay>
                        )}
                        <Checkbox
                            name="hasAgreedWithIDTerms"
                            noBackground
                            // noPadding
                        >
                            I agree this is a clear legible copy of my ID and it
                            does contain my signature
                        </Checkbox>
                    </Form>
                )}
            </Content>
        </Container>
    );
};

export default forwardRef(StepProofOfIdentity);
