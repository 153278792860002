import styled from "styled-components";

interface ILoaderProps {
    showSpinner: boolean;
}

export const Container = styled.div<ILoaderProps>`
    /* position: absolute; */
    /* left: 50%; */
    /* top: 50%; */
    /* padding: 30px; */
    /* Everything that is shown besides our Toast will be invisible. */
    /* overflow: hidden; */
    display: ${({ showSpinner }) => (showSpinner ? "flex" : "none")};
    /* display: "block"; */

    z-index: 9999;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    flex-direction: column;

    h2 {
        color: #333;
        margin-top: 30px;
        font-size: 2.5rem;
    }
`;

export const Spinner = styled.div`
    border: 25px solid #f3f3f3;
    border-radius: 50%;
    border-top: 25px solid #b2d235;
    border-right: 25px solid black;
    border-bottom: 25px solid black;
    border-left: 25px solid black;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
