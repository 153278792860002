import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    min-height: 100%;
    overflow: hidden;
    display: none;
    flex-direction: column;
    z-index: 60;
    width: 130px;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    background: #fff;

    @media only screen and (min-width: 768px) {
        display: flex;
    }
`;

export const SidebarHeader = styled.div`
    display: flex;
    height: 74px;
    width: 130px;
    align-items: center;
    background: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
`;

export const SidebarMenu = styled.div`
    height: calc(100vh - 74px);
`;

export const ScrollbarContainer = styled.div`
    position: relative;
    height: 100%;
    /* overflow: hidden !important; */
    /* overflow-anchor: none; */
    overflow-y: auto;
    touch-action: auto;
`;

export const LinksWrapper = styled.div`
    p {
        background: #333;
        padding: 1rem 1rem 0.90909rem;
        font-weight: 700;
        color: var(--color-green);
        text-transform: uppercase;
        font-size: 0.85rem;
        white-space: normal;
        text-align: center;
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
`;

export const LinksContainer = styled.ul`
    position: relative;
    list-style: none;
`;

export const LinkItem = styled.li`
    transition: background 0.4s;

    &:hover {
        background: var(--color-green);
    }

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 90px;
        color: #333;
        text-decoration: none;
        padding: 0 4px;

        > svg {
            margin-bottom: 10px;
        }
    }
`;

export const LinkText = styled.span`
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
`;
