import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    text-align: center;
    background: #d9e0e7;
`;

export const ContentStatusCode = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1;
    padding: 32px;
`;

export const StatusCode = styled.span`
    color: #333;
    font-size: 200px;
    line-height: 1;
    text-shadow: 10px 10px 0 rgba(45, 53, 60, 0.2);
`;

export const ContentMessage = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex: 1;
    padding: 30px;
    background: #b2d235;
`;

export const MessageTitle = styled.span`
    color: #333;
    font-size: 40px;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
`;

export const MessageDescription = styled.span`
    font-size: 16px;
    color: #333;
    letter-spacing: 0.5px;
`;
