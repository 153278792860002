import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    z-index: 7001;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContentBox = styled.div`
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    /* height: 100%; */
    outline: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;

    background: var(--color-white);
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    margin: 0 24px;
`;

export const Title = styled.div`
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
        font-weight: 500;
        font-size: 24px;
    }
`;

export const BranchName = styled.div`
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    text-align: center;

    span {
        font-weight: 500;
        font-size: 1.35rem;
    }
`;

export const Row = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
`;

export const CellContent = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex: 1;
    margin: 4px 0;
`;

export const EmailContent = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
`;

export const TextLabel = styled.span`
    font-weight: 500;
    white-space: nowrap;
`;

export const TextValue = styled.span`
    display: flex;
    /* text-transform: capitalize; */
    white-space: nowrap;
    margin-left: 8px;
`;

export const AddressBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: 8px;
`;

export const AddressBoxTitle = styled.div`
    margin: 8px 0;

    span {
        font-weight: 500;
        text-decoration: underline;
    }
`;

export const AddressRow = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin: 4px 0;
`;

export const AddressCell = styled.div`
    display: flex;
    align-items: center;
`;

export const ActionsRows = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 16px 8px;
    width: 100%;
`;

export const CloseButton = styled.button`
    background: var(--color-close);
    height: 48px;
    border-radius: 8px;
    border: 0;
    padding: 0 16px;
    color: var(--color-white-9);
    margin: 8px;
    width: 100%;
    font-weight: 700;
    margin-top: 16px;
    transition: background-color 0.2s;
    transition: color 0.2s;

    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
        background: var(--color-close-hover);
    }
`;

export const DeleteButton = styled.button`
    background: var(--color-red-danger);
    height: 48px;
    border-radius: 8px;
    border: 0;
    padding: 0 16px;
    color: var(--color-white-9);
    margin: 8px;
    width: 100%;
    font-weight: 700;
    margin-top: 16px;
    transition: background-color 0.2s;
    transition: color 0.2s;

    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
        background: var(--color-red-danger-hover);
    }
`;
