import React from "react";

import locally_owned_logo from "../../../assets/locally_owned_logo.png";

import { Container, Banner } from "./styles";

const SupportLocal: React.FC = () => {
    return (
        <Container>
            <Banner>
                <div className="support-local">
                    <h3>Support local business</h3>
                </div>
                <img
                    src={locally_owned_logo}
                    alt="Locally Owned, Proudly Kiwi"
                />
            </Banner>
        </Container>
    );
};

export default SupportLocal;
