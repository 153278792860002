import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface IContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<IContainerProps>`
    background: var(--color-green-1);

    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;

    border: 2px solid var(--color-green-1);
    color: #5d700f;

    display: flex;
    /* align-items: center; */
    position: relative;

    & + div {
        margin-top: 1rem
    }

    ${props =>
        props.isFocused &&
        css`
            color: #5d700f;
            border-color: #5d700f;
        `}

    ${props =>
        props.isFilled &&
        css`
            color: #5d700f;
            border-color: #5d700f;
        `}

    ${props =>
        props.isErrored &&
        css`
            border-color: #c53030;
        `}

    textarea {
        flex: 1;
        background: transparent;
        border: 0;
        color: #475412;

        ${props =>
            props.isFilled &&
            css`
                /* padding: 0 0.5rem; */
                border-radius: 0.2rem;
                font-weight: 500;
            `}

        &:focus ~ .input-label {
            display: block;
            color: #333;
            left: 5px;
            top: -10px;
            background: ${props =>
                props.isErrored
                    ? "var(--color-red-danger)"
                    : "var(--color-green)"};
            padding: 0 0.5rem;
            border-radius: 0.2rem;
        }

        &::placeholder {
            color: var(--color-placeholder);
            font-weight: 500;
            font-family: 'Roboto';
        }
    }

    svg {
        margin-right: 1rem;
        color: var(--color-green-4);
    }

    .input-label {
        position: absolute;
        left: 10px;
        top: 10px;
        transition: all 0.3s;
        color: #999;
        font-weight: normal;
        display: none;
        font-weight: 500;

        ${props =>
            props.isFilled &&
            css`
                display: block;
                color: #333;
                left: 5px;
                top: -10px;
                background: ${props.isErrored
                    ? "var(--color-red-danger)"
                    : "var(--color-green)"};
                padding: 0 0.5rem;
                border-radius: 0.2rem;
            `}
    }
`;

// Stylising another Custom Component => Tooltip/index.tsx
// Check Tooltip Component.
// It is receiving a "className" props, and setting its className={className}
export const Error = styled(Tooltip)`
    height: 20px;
    margin-left: 16px;

    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
