import React, { createContext, useCallback, useState, useContext } from "react";
import Loader from "../components/Loader";

interface ILoaderContextData {
    showLoader(description?: string): void;
    hideLoader(): void;
}

const LoaderContext = createContext<ILoaderContextData>(
    {} as ILoaderContextData,
);

const LoaderProvider: React.FC = ({ children }) => {
    const [displayLoader, setDisplayLoader] = useState(false);
    const [descriptionLoader, setDescriptionLoader] = useState("");

    const showLoader = useCallback((description: string) => {
        setDisplayLoader(true);

        if (description) setDescriptionLoader(description);
    }, []);
    const hideLoader = useCallback(() => {
        setDisplayLoader(false);
    }, []);

    return (
        <LoaderContext.Provider value={{ showLoader, hideLoader }}>
            {children}
            <Loader show={displayLoader} description={descriptionLoader} />
        </LoaderContext.Provider>
    );
};

function useLoader(): ILoaderContextData {
    const context = useContext(LoaderContext);

    if (!context) {
        throw new Error("useLoader must be used within a LoaderProvider");
    }

    return context;
}

export { LoaderProvider, useLoader };
