import styled from "styled-components";

interface IAccountLinksProps {
    showLinks: boolean;
    top: number;
    transparentHeader: boolean;
}

interface IMobileProps {
    showMenu: boolean;
    displaySignUpLinks: boolean;
}

interface IHeaderProps {
    backgroundColor: string;
    transparentHeader: boolean;
}

export const FixedHeader = styled.div<IHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    position: fixed;
    width: 100%;

    background: ${({ transparentHeader }) =>
        transparentHeader === true ? "transparent" : "#fff"};

    z-index: 6000;

    div.container-large {
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        justify-content: center;

        div.navbar {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            padding: 5px 20px;

            div.navbrand {
                img {
                    height: 65px;
                    width: auto;
                }
            }

            div.nav-links {
                @media (max-width: 768px) {
                    display: none;
                }

                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                ul {
                    display: flex;
                    height: 70px;

                    li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        padding: 0 10px;
                        position: relative;
                        justify-content: center;

                        a {
                            @media (max-width: 800px) {
                                font-size: 14px;
                            }

                            color: ${({ transparentHeader }) =>
                                transparentHeader === true
                                    ? "#b2d235"
                                    : "#333"};

                            font-size: 16px;
                            font-weight: bold;
                            padding: 7px 10px;
                            border-radius: 5px;
                            text-transform: uppercase;
                            cursor: pointer;
                        }

                        button {
                            background: transparent;
                            border: none;

                            @media (max-width: 800px) {
                                font-size: 14px;
                            }

                            color: ${({ transparentHeader }) =>
                                transparentHeader === true
                                    ? "#b2d235"
                                    : "#333"};

                            font-size: 16px;
                            font-weight: bold;
                            padding: 7px 10px;
                            border-radius: 5px;
                            text-transform: uppercase;
                            cursor: pointer;
                        }

                        &.active::after {
                            content: "";
                            border-style: solid;
                            border-color: #b2d235 transparent;
                            border-width: 10px 10px 0 10px;
                            top: 0;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }

            div.extra-menu {
                @media (max-width: 768px) {
                    display: none;
                }

                display: flex;
                align-items: center;
                flex-direction: column;
            }

            div.mobile-menu {
                @media (min-width: 769px) {
                    display: none;
                }

                display: flex;
                align-items: center;
                flex-direction: column;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: #fff;
                    background: #b2d235;
                    font-weight: bold;
                    padding: 5px 10px;
                    text-transform: uppercase;
                    border: 0;

                    box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.2),
                        0px 1px 2px 0px rgba(0, 0, 0, 0.14),
                        0px 0px 5px 0px rgba(0, 0, 0, 0.12);
                }
            }
        }
    }
`;

export const AccountLinks = styled.div<IAccountLinksProps>`
    div.account-links-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1250px) {
            align-items: flex-end;
        }

        button {
            /* color: #333; */
            color: ${({ transparentHeader }) =>
                transparentHeader === true ? "#b2d235" : "#333"};
            font-weight: bold;
            padding: 7px 10px;
            text-transform: uppercase;
            border: 0;
            display: flex;
            align-items: center;
            /* background: #fff; */
            background: ${({ transparentHeader }) =>
                transparentHeader === true ? "transparent" : "#fff"};

            svg {
                margin-left: 5px;
            }
        }
    }

    div.links-account {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        position: absolute;
        top: ${({ top }) => `${top}px`};
        display: ${({ showLinks }) => (showLinks ? "flex" : "none")};
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
            0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
                /* line-height: 1.5; */
                padding: 8px 0;
                color: #333;

                line-height: 40px;
                -webkit-perspective: 1000px;
                -moz-perspective: 1000px;
                perspective: 1000px;

                /* &.link:hover {
                    border-bottom: 3px solid #b2d235;
                } */

                a span {
                    position: relative;
                    /* display: inline-block; */
                    display: flex;
                    align-items: center;
                    padding: 0 14px;
                    background: #fff;
                    -webkit-transition: -webkit-transform 0.3s;
                    -moz-transition: -moz-transform 0.3s;
                    transition: transform 0.3s;
                    -webkit-transform-origin: 50% 0;
                    -moz-transform-origin: 50% 0;
                    transform-origin: 50% 0;
                    -webkit-transform-style: preserve-3d;
                    -moz-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                }

                a span::before {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    content: attr(data-hover);
                    -webkit-transition: background 0.3s;
                    -moz-transition: background 0.3s;
                    transition: background 0.3s;
                    -webkit-transform: rotateX(-90deg);
                    -moz-transform: rotateX(-90deg);
                    transform: rotateX(-90deg);
                    -webkit-transform-origin: 50% 0;
                    -moz-transform-origin: 50% 0;
                    transform-origin: 50% 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                a:hover span,
                a:focus span {
                    -webkit-transform: rotateX(90deg) translateY(-22px);
                    -moz-transform: rotateX(90deg) translateY(-22px);
                    transform: rotateX(90deg) translateY(-22px);
                }

                a:hover span::before,
                a:focus span::before {
                    background: #b2d235;
                }

                svg {
                    margin-right: 5px;
                }

                h4 {
                    &::before {
                        content: "";
                        display: block;
                        height: 1px;
                        width: 100%;
                        margin: 10px 0;
                        background: #b2d235;
                        top: -5px;
                        position: relative;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    color: #333;
                }
            }
        }
    }
`;

export const MobileMenu = styled.div<IMobileProps>`
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    flex: 1 0 autoI;
    display: ${({ showMenu }) => (showMenu ? "flex" : "none")};
    outline: 0;
    z-index: 6000;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;

    box-shadow: -5px 0px 5px -3px rgba(0, 0, 0, 0.2),
        -8px 0px 10px 1px rgba(0, 0, 0, 0.14),
        -3px 0px 14px 2px rgba(0, 0, 0, 0.12);

    div.backgroundMobileMenu {
        background: rgba(0, 0, 0, 0.8);
        height: 100%;

        div.foregroundMobileMenu {
            ul.linksMobileMenu {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                padding: 8px 0;
                background: rgba(178, 210, 53, 0.9);

                a.linksLineMobileMenu {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    padding: 8px 16px;
                    width: 100%;
                    display: flex;
                    position: relative;
                    text-align: center;
                    align-items: center;
                    justify-content: space-between;
                    color: #fff;
                    border-bottom: 1px solid #8aaa34;

                    cursor: pointer;

                    &:not(:first-child):hover {
                        background: #8aaa34;
                        font-weight: 600;
                    }

                    &:last-child.last-child {
                        border: 0;
                        padding: 8px 16px 0px 16px;
                    }

                    &.authLink {
                        border: 0;
                        cursor: pointer;
                        color: #333;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        color: #fff;
                        background: transparent;
                        font-weight: bold;
                        border: 0;

                        svg {
                            margin: 0;
                        }
                    }

                    span {
                        text-transform: uppercase;
                    }

                    svg {
                        margin-right: 15px;
                        cursor: pointer;
                    }
                }

                button.linksLineMobileMenu {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    background: transparent;
                    border: none;

                    padding: 8px 16px;
                    width: 100%;
                    display: flex;
                    position: relative;
                    text-align: center;
                    align-items: center;
                    justify-content: space-between;
                    color: #fff;
                    border-bottom: 1px solid #8aaa34;

                    /* button { */
                    /* color: #b2d235; */
                    /* font-weight: bold; */
                    /* font-size: 14px; */
                    /* background: transparent;
                    border: none;
                    text-transform: uppercase; */
                    /* } */

                    cursor: pointer;

                    &:not(:first-child):hover {
                        background: #8aaa34;
                        font-weight: 600;
                    }

                    &:last-child.last-child {
                        border: 0;
                        padding: 8px 16px 0px 16px;
                    }

                    &.authLink {
                        border: 0;
                        cursor: pointer;
                        color: #333;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        color: #fff;
                        background: transparent;
                        font-weight: bold;
                        border: 0;

                        svg {
                            margin: 0;
                        }
                    }

                    span {
                        text-transform: uppercase;
                    }

                    svg {
                        margin-right: 15px;
                        cursor: pointer;
                    }
                }

                a.childLinkLineMobileMenu {
                    display: ${({ displaySignUpLinks }) =>
                        displaySignUpLinks ? "flex" : "none"};
                    align-items: center;
                    justify-content: flex-start;

                    background: #eee;
                    padding: 10px 30px;
                    width: 100%;
                    position: relative;
                    text-align: center;
                    align-items: center;
                    justify-content: flex-start;
                    color: #333;

                    &:not(:last-child):after {
                        content: "";
                        background: #8aaa34;
                        position: absolute;
                        bottom: 0;
                        width: 50%;
                        left: 25%;
                        height: 1px;
                    }

                    svg {
                        margin-right: 15px;
                    }

                    span {
                        text-transform: uppercase;
                        font-size: 14px;
                    }
                }
            }

            hr.linksMobileMenuDivider {
                border: none;
                height: 1px;
                margin: 0;
                flex-shrink: 0;
                background-color: rgba(178, 210, 53, 0.8);
            }

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;

                li {
                    line-height: 1.5;
                    padding: 8px 0;
                    color: #333;

                    svg {
                        margin-right: 5px;
                    }

                    h4 {
                        &::before {
                            content: "";
                            display: block;
                            height: 1px;
                            width: 100%;
                            margin: 10px 0;
                            background: #b2d235;
                            top: -5px;
                            position: relative;
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        color: #333;
                    }
                }
            }
        }
    }
`;
