import styled from "styled-components";

export const AddressListBoxes = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-self: center;
`;

export const AddressBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;
    /* border: 2px #b2d235 solid; */
    border-radius: 8px;

    /* min-width: 290px; */
    flex-shrink: 1;

    > span {
        background: #e0f787;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`;

export const AddressBoxTitle = styled.span`
    display: flex;
    width: 100%;
    font-weight: 500;
    text-decoration: underline;
    justify-content: center;
    padding: 8px;
    background: #b2d235;
`;

export const AddressBoxLabels = styled.div`
    padding: 8px;

    background: #cce95a;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const AddressBoxLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 8px;
    }
`;

export const AddressBoxField = styled.span`
    font-weight: 500;
    /* font-size: 18px; */
`;

export const AddressBoxValue = styled.span`
    font-weight: 400;
    margin-left: 8px;
`;
