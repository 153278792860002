import styled from "styled-components";

export const ProofOfIdentityDisplay = styled.div`
    img {
        width: 100%;
        height: auto;
    }
`;

export const InputFileDisplay = styled.div`
    img {
        width: 100%;
        height: auto;
    }

    iframe {
        width: 100%;
        height: 600px;
    }
`;
