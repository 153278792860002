import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";

// API
import api from "../../../services/api";

// Common Types
import { IInternalMOJApplications } from "../../../types/mojTypes";

// Common Types
import UrlsAddress from "../../../types/urlsAddress";

import {
    Container,
    Title,
    TableContent,
    Badge,
    ActionsCell,
    InfoBox,
    InfoBoxLabel,
    InfoBoxButton,
} from "./styles";

// interface IInternalMOJApplications {
//     mojId: string;
//     branch: string;
//     date: string;
//     applicant: string;
//     internalReference: number;
//     status: string;
//     candidateID: number;
//     firstName: string;
//     middleName: string;
//     lastName: string;
//     fullName: string;
// }

const MOJApplicationsGrid: React.FC = () => {
    const [applicationsGridData, setApplicationsGridData] = useState<
        IInternalMOJApplications[]
    >([]);

    const [
        processingApplicationsGrid,
        setProcessingApplicationsGrid,
    ] = useState(false);

    const history = useHistory();

    const fetchData = useCallback(async () => {
        setProcessingApplicationsGrid(true);

        await api.get("internalConsultant/lastApplications").then(response => {
            const { data } = response;

            if (data && data.success) {
                if (data && data.success && data.data) {
                    // setConsultantsGridData(data.data);
                    setApplicationsGridData(data.data);
                }
            }
        });

        setProcessingApplicationsGrid(false);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getActions = (): JSX.Element => {
        // const getActions = (consultant: IConsultantGridData): JSX.Element => {
        // const editLink = (
        //     <Link to={`/consultant-permissions/${consultant.id}`}>
        //         <FaUserCog size={20} />
        //     </Link>
        // );

        // const deleteButton = (
        //     <button
        //         type="button"
        //         onClick={() => handleDeleteConsultant(consultant.id)}
        //     >
        //         <FaRegTrashAlt size={20} style={{ color: "#c53030" }} />
        //     </button>
        // );

        // let formattedActions: JSX.Element[] = [];

        // formattedActions = [editLink, deleteButton];

        // return <ActionsCell>{formattedActions}</ActionsCell>;
        return <ActionsCell>click here</ActionsCell>;
    };

    const getBadgeBgColorByStatus = (status: string): string => {
        switch (status) {
            case "Cons. Created Request":
                return "#FFFF37";
            case "Cand. Processed":
                return "#1eb7ff";
            case "MOJ Returned":
                return "#49C628";
            case "Incomplete (returned)":
                return "#c53030";
            case "Request Expired":
                return "#c53030";
            default:
                return "";
        }
    };

    const getBadgeColorByStatus = (status: string): string => {
        switch (status) {
            case "Cons. Created Request":
                return "#333";
            case "Cand. Processed":
                return "#333";
            case "MOJ Returned":
                return "#333";
            case "Incomplete (returned)":
                return "#fff";
            case "Request Expired":
                return "#fff";
            default:
                return "#333";
        }
    };

    const getTableColumns = useMemo(() => {
        const tableColumns = [
            {
                name: "id",
                selector: "mojId",
                omit: true,
            },
            {
                name: "Branch",
                selector: "branch",
                center: true,
            },
            {
                name: "Date",
                selector: "date",
                // maxWidth: "70px",
                center: true,
            },
            {
                name: "Consultant",
                selector: "applicant",
            },
            {
                name: "Ref.",
                selector: "internalReference",
                // maxWidth: "50px",
                center: true,
                // hide: 1100,
            },
            {
                name: "Status",
                selector: "status",
                // maxWidth: "120px",
                center: true,
                cell: (row: IInternalMOJApplications) => (
                    <Badge
                        bgColor={getBadgeBgColorByStatus(row.status)}
                        color={getBadgeColorByStatus(row.status)}
                    >
                        {row.status}
                    </Badge>
                ),
            },
            {
                name: "Candidate ID",
                selector: "candidateId",
                // maxWidth: "64px",
                center: true,
                // hide: 992,
            },
            {
                name: "Name",
                selector: "fullName",
                // grow: 1,
            },
            // {
            //     name: "",
            //     // cell: (row: IConsultantGridData) => getActions(row),
            //     cell: () => getActions(),
            //     maxWidth: "70px",
            // },
        ];

        return tableColumns;
    }, []);

    const getApplicationsData = useMemo(() => {
        return applicationsGridData;
    }, [applicationsGridData]);

    const handleShowMoreMOJRequestsClick = useCallback(() => {
        history.push(UrlsAddress.INTERNAL_MOJ_REQUESTS);
    }, [history]);

    return (
        <Container>
            <Title>MOJ REQUESTS</Title>
            <InfoBox>
                <InfoBoxLabel>Showing the last 15 MOJ requests</InfoBoxLabel>
                <div>
                    <InfoBoxButton
                        type="button"
                        onClick={handleShowMoreMOJRequestsClick}
                    >
                        show more
                    </InfoBoxButton>
                </div>
            </InfoBox>
            <TableContent>
                <DataTable
                    noHeader
                    highlightOnHover
                    pagination
                    responsive
                    columns={getTableColumns}
                    data={getApplicationsData}
                    progressPending={processingApplicationsGrid}
                />
            </TableContent>
        </Container>
    );
};

export default MOJApplicationsGrid;
