import React from "react";

import {
    Container,
    DetailBoxTitle,
    DetailBoxTitleText,
    Content,
} from "./styles";

interface IDetailBoxProps {
    title: string;
}

const DetailBox: React.FC<IDetailBoxProps> = ({ title, children }) => {
    return (
        <Container>
            <DetailBoxTitle>
                <DetailBoxTitleText>{title}</DetailBoxTitleText>
            </DetailBoxTitle>
            <Content>{children}</Content>
        </Container>
    );
};

export default DetailBox;
