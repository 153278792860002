import React, { useCallback, useState } from "react";

import { FcLike } from "react-icons/fc";

import TermsConditionsModal from "../../../TermsConditionsModal";
import PrivacyPolicyModal from "../../../PrivacyPolicyModal";

import { Container } from "./styles";

const Footer: React.FC = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);

    // const handleShowTermsClick = () => {
    //     setShowTerms(true);
    // };
    const handleShowTermsClick = useCallback(() => {
        setShowTerms(true);
    }, []);

    // const handleShowPrivacyClick = () => {
    //     setShowPrivacy(true);
    // };
    const handleShowPrivacyClick = useCallback(() => {
        setShowPrivacy(true);
    }, []);

    return (
        <Container>
            <div className="jss324">
                {" "}
                <div>
                    <div className="jss343">
                        <ul className="MuiList-root jss342 MuiList-padding">
                            <li className="MuiListItem-root jss341 MuiListItem-gutters">
                                <button
                                    type="button"
                                    className="jss340"
                                    onClick={handleShowTermsClick}
                                >
                                    Terms and Conditions
                                </button>
                            </li>
                            <li className="MuiListItem-root jss341 MuiListItem-gutters">
                                <button
                                    type="button"
                                    className="jss340"
                                    onClick={handleShowPrivacyClick}
                                >
                                    Privacy Policy
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="jss344">
                        © {new Date().getFullYear()} , made with{" "}
                        <FcLike size={18} />
                        {" by "}
                        <a
                            href="https://www.bossonline.co.nz/"
                            target="_blank"
                            className="link-footer"
                            rel="noopener noreferrer"
                        >
                            BOSS Technologies
                        </a>{" "}
                        for a better web.
                    </div>
                </div>
                <div className="jss334" />
            </div>
            <TermsConditionsModal
                onClose={setShowTerms}
                show={showTerms}
                size="xl"
            />

            <PrivacyPolicyModal
                onClose={setShowPrivacy}
                show={showPrivacy}
                size="xl"
            />
        </Container>
    );
};

export default Footer;
