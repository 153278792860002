import React, {
    useCallback,
    useRef,
    useState,
    useEffect,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import * as Yup from "yup";
import { parse, isValid } from "date-fns";

import { Parser } from "json2csv";

// Icons
import { FaCalendar, FaChevronLeft } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useLoader } from "../../hooks/loader";

// Common Components
import MainNew from "../MainNew";
import InputMaskWithLabel from "../../components/InputMaskWithLabel";

// Common Types
import UrlsAddress from "../../types/urlsAddress";
import { IInternalMOJApplications } from "../../types/mojTypes";
import { IMOJCustomError } from "../../types/globalTypes";

// Utils
import {
    convertDateToString,
    getFormattedDate,
} from "../../utils/dateUtilities";
import getValidationErrors from "../../utils/getValidationErrors";

import {
    Container,
    Wrapper,
    LinkRow,
    SearchBox,
    InputsContainer,
    SearchButton,
    TableContent,
    InfoBox,
    InfoBoxLabel,
    Badge,
    ActionsCell,
    GridActions,
} from "./styles";

interface ISearchFormData {
    dateFrom: Date;
    dateTo: Date;
}

interface ICSVFile {
    branch: string;
    date: string;
    consultant: string;
    ref: number;
    status: string;
    candidateId: number;
    name: string;
}

const InternalMOJRequests: React.FC = () => {
    const [applicationsGridData, setApplicationsGridData] = useState<
        IInternalMOJApplications[]
    >([]);

    const [
        processingApplicationsGrid,
        setProcessingApplicationsGrid,
    ] = useState(false);

    const [dateFrom, setDateFrom] = useState(() => {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();

        return getFormattedDate(new Date(year, month, 1), "dd/MM/yyyy");
    });

    const [dateTo, setDateTo] = useState(() => {
        const lastDayOfMonth = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0,
        );

        return getFormattedDate(lastDayOfMonth, "dd/MM/yyyy");
    });

    const formRef = useRef<FormHandles>(null);

    const { showLoader, hideLoader } = useLoader();

    const fetchData = useCallback(async () => {
        setProcessingApplicationsGrid(true);

        await api.get("internalConsultant/lastApplications").then(response => {
            const { data } = response;

            if (data && data.success) {
                if (data && data.success && data.data) {
                    setApplicationsGridData(data.data);
                }
            }
        });

        setProcessingApplicationsGrid(false);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getApplicationsData = useMemo(() => {
        return applicationsGridData;
    }, [applicationsGridData]);

    const getActions = (): JSX.Element => {
        // const getActions = (consultant: IConsultantGridData): JSX.Element => {
        // const editLink = (
        //     <Link to={`/consultant-permissions/${consultant.id}`}>
        //         <FaUserCog size={20} />
        //     </Link>
        // );

        // const deleteButton = (
        //     <button
        //         type="button"
        //         onClick={() => handleDeleteConsultant(consultant.id)}
        //     >
        //         <FaRegTrashAlt size={20} style={{ color: "#c53030" }} />
        //     </button>
        // );

        // let formattedActions: JSX.Element[] = [];

        // formattedActions = [editLink, deleteButton];

        // return <ActionsCell>{formattedActions}</ActionsCell>;
        return <ActionsCell>click here</ActionsCell>;
    };

    const getBadgeBgColorByStatus = (status: string): string => {
        switch (status) {
            case "Cons. Created Request":
                return "#FFFF37";
            case "Cand. Processed":
                return "#1eb7ff";
            case "MOJ Returned":
                return "#49C628";
            case "Incomplete (returned)":
                return "#c53030";
            case "Request Expired":
                return "#c53030";
            default:
                return "";
        }
    };

    const getBadgeColorByStatus = (status: string): string => {
        switch (status) {
            case "Cons. Created Request":
                return "#333";
            case "Cand. Processed":
                return "#333";
            case "MOJ Returned":
                return "#333";
            case "Incomplete (returned)":
                return "#fff";
            case "Request Expired":
                return "#fff";
            default:
                return "#333";
        }
    };

    const getTableColumns = useMemo(() => {
        const tableColumns = [
            {
                name: "id",
                selector: "mojId",
                omit: true,
            },
            {
                name: "Branch",
                selector: "branch",
                center: true,
            },
            {
                name: "Date",
                selector: "date",
                // maxWidth: "70px",
                center: true,
            },
            {
                name: "Consultant",
                selector: "applicant",
            },
            {
                name: "Ref.",
                selector: "internalReference",
                // maxWidth: "50px",
                center: true,
                // hide: 1100,
            },
            {
                name: "Status",
                selector: "status",
                // maxWidth: "120px",
                center: true,
                cell: (row: IInternalMOJApplications) => (
                    <Badge
                        bgColor={getBadgeBgColorByStatus(row.status)}
                        color={getBadgeColorByStatus(row.status)}
                    >
                        {row.status}
                    </Badge>
                ),
            },
            {
                name: "Candidate ID",
                selector: "candidateId",
                center: true,
            },
            {
                name: "Name",
                selector: "fullName",
            },
        ];

        return tableColumns;
    }, []);

    const getDateFrom = useMemo(() => {
        return dateFrom;
    }, [dateFrom]);

    const getDateTo = useMemo(() => {
        return dateTo;
    }, [dateTo]);

    const yupCustomDateValidator = (data: string): boolean => {
        if (data) {
            const parsedDate = parse(data, "dd/MM/yyyy", new Date());

            if (isValid(parsedDate)) {
                return true;
            }
        }

        return false;
    };

    const yupCustomCompareDateValidator = (dateToValue: string): boolean => {
        if (formRef && formRef.current) {
            const dateFromValue: string = formRef.current.getFieldValue(
                "dateFrom",
            );

            const parsedDateFrom = parse(
                dateFromValue,
                "dd/MM/yyyy",
                new Date(),
            );

            const parsedDateTo = parse(dateToValue, "dd/MM/yyyy", new Date());

            return parsedDateTo > parsedDateFrom;
        }

        return false;
    };

    const handleSearch = useCallback(
        async (formData: ISearchFormData) => {
            showLoader("Processing...");
            setProcessingApplicationsGrid(true);

            const splittedDateFrom = formData.dateFrom.toString().split("/");
            const splittedDateTo = formData.dateTo.toString().split("/");

            const response = await api.get(
                "internalConsultant/search-moj-requests-by-date",
                {
                    params: {
                        dateFrom: `${splittedDateFrom[1]}/${splittedDateFrom[0]}/${splittedDateFrom[2]}`,
                        dateTo: `${splittedDateTo[1]}/${splittedDateTo[0]}/${splittedDateTo[2]}`,
                    },
                },
            );

            const { data } = response.data;

            if (response.data.success && data) {
                setApplicationsGridData(data);

                setDateFrom(formData.dateFrom.toString());
                setDateTo(formData.dateTo.toString());
            }

            setProcessingApplicationsGrid(false);

            hideLoader();
        },
        [showLoader, hideLoader],
    );

    const handleSubmitSearch = useCallback(
        async (data: ISearchFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    dateFrom: Yup.string()
                        .test("Date from", "Date is invalid", value =>
                            yupCustomDateValidator(value),
                        )
                        .required("Date From is required"),
                    dateTo: Yup.string().when("dateFrom", {
                        is: val => val && val.length > 0,
                        then: Yup.string().test(
                            "Date from",
                            "Date To must be greater than Date From",
                            value => yupCustomCompareDateValidator(value),
                        ),
                    }),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                await handleSearch(data);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error("There was an error searching moj requests!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        },
        [handleSearch],
    );

    const exportCSV = useCallback(() => {
        const itemsFormatted: ICSVFile[] = [];

        getApplicationsData.forEach(mojRequest => {
            itemsFormatted.push({
                branch: mojRequest.branch,
                date: mojRequest.date,
                consultant: mojRequest.applicant,
                ref: mojRequest.internalReference,
                status: mojRequest.status,
                candidateId: mojRequest.candidateId,
                name: mojRequest.fullName,
            });
        });

        const json2csvParser = new Parser();
        const csvFile = json2csvParser.parse(itemsFormatted);

        const fileName = `${convertDateToString(new Date())
            .replace("/", "-")
            .replace("/", "-")}_moj_requests.csv`;

        const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement("a");

            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }, [getApplicationsData]);

    return (
        <MainNew>
            <Container>
                <LinkRow>
                    <FaChevronLeft />
                    <Link to={UrlsAddress.DASHBOARD}>Go back to dashboard</Link>
                </LinkRow>
                <Wrapper>
                    <SearchBox>
                        <Form
                            ref={formRef}
                            onSubmit={handleSubmitSearch}
                            initialData={{
                                dateFrom: getDateFrom,
                                dateTo: getDateTo,
                            }}
                        >
                            <InputsContainer>
                                <InputMaskWithLabel
                                    name="dateFrom"
                                    icon={FaCalendar}
                                    label="Date From"
                                    placeholder={`e.g. 31/12/${new Date().getFullYear()}`}
                                    mask="99/99/9999"
                                />
                                <InputMaskWithLabel
                                    name="dateTo"
                                    icon={FaCalendar}
                                    label="Date To"
                                    placeholder={`e.g. 31/12/${new Date().getFullYear()}`}
                                    mask="99/99/9999"
                                />
                            </InputsContainer>
                            <SearchButton type="submit">Search</SearchButton>
                        </Form>
                    </SearchBox>
                    {/* GRID */}
                    <TableContent>
                        {getApplicationsData.length > 0 && (
                            <InfoBox>
                                Showing{" "}
                                <InfoBoxLabel>
                                    {getApplicationsData.length}
                                </InfoBoxLabel>{" "}
                                {getApplicationsData.length === 1
                                    ? "MOJ request "
                                    : "MOJs requests "}
                                from <InfoBoxLabel>{getDateFrom}</InfoBoxLabel>{" "}
                                to
                                <InfoBoxLabel>{getDateTo}</InfoBoxLabel>
                            </InfoBox>
                        )}
                        <GridActions>
                            <button type="button" onClick={exportCSV}>
                                Export CSV
                            </button>
                        </GridActions>
                        <DataTable
                            noHeader
                            highlightOnHover
                            pagination
                            responsive
                            columns={getTableColumns}
                            data={getApplicationsData}
                            progressPending={processingApplicationsGrid}
                        />
                    </TableContent>
                </Wrapper>
            </Container>
        </MainNew>
    );
};

export default InternalMOJRequests;
