import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const ActionsCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a,
    button {
        color: #333;
        background: transparent;
        padding: 5px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.7;
        }
    }
`;

export const CardsInvitations = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    gap: 1rem;
`;

export const CardInvite = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 26.5rem;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border-radius: 8px;

    background-color: #fff;
`;

export const CardInviteCandidate = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;
    align-items: center;
    justify-content: center;
    margin: 16px 8px;
    padding: 32px;
    border-radius: 8px;

    @media only screen and (min-width: 600px) {
        min-height: 256px;
    }

    background-color: #fff;
    /* border: 2px #5d700f solid; */
    box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.125);
`;

export const CardInviteConsultant = styled.div`
    /* background: red; */
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;
    align-items: center;
    justify-content: center;
    margin: 16px 8px;
    padding: 32px;
    border-radius: 8px;

    @media only screen and (min-width: 600px) {
        min-height: 256px;
    }

    /* background-image: linear-gradient(135deg, #667eea, #764ba2) !important; */
    /* background-image: linear-gradient(135deg, #e0f787, #718c04) !important; */

    background-color: #fff;
    /* border-color: #5d700f; */
    /* border-width: 2px; */
    /* border: 2px #5d700f solid; */

    box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.125);
`;

export const CardInviteTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    font-size: 24px;

    span {
        font-weight: 500;
        text-align: center;
    }
`;

export const CardInviteDescription = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 16px; */

    span {
        font-weight: 400;
        text-align: center;
    }
`;

export const CardInviteButton = styled.button`
    /* margin-top: 16px;
    padding: 16px 8px;
    border: none;
    border-radius: 8px;
    color: #333;
    font-weight: 500;
    background: #fff; */

    background: #b2d235;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #312e38;
    /* Trick to occupy max size */
    width: 100%;
    font-weight: 700;
    margin-top: 16px;
    transition: background-color 0.2s;
    transition: color 0.2s;
    /* shadow */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
        background: ${shade(0.2, "#b2d235")};
    }
`;
