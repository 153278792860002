import styled from "styled-components";

interface IBadgeProps {
    bgColor?: string;
    color?: string;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #fff;
    padding: 16px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 1rem;

    font-size: 1.5rem;
    font-weight: 700;

    background: #f2f1f3;
    color: #333;
    border-radius: 0.5rem;
`;

export const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    /* TABELA-TESTE */
    /* overflow-x: auto; */

    .rdt_Table {
        margin: 24px 0;
    }

    .rdt_TableHeadRow {
        background: #b2d235;
        text-align: center;

        .rdt_TableCol {
            color: #333;
            font-size: 16px;
        }
    }

    .rdt_Pagination {
        background: #b2d235;
        color: #333;
        font-size: 16px;
    }
`;

export const Badge = styled.span<IBadgeProps>`
    letter-spacing: 0.3px;

    color: ${props => props.color};

    background-color: ${props =>
        props.bgColor ? props.bgColor : "transparent"};

    display: inline-block;
    width: 100%;
    padding: 4px 8px;
    font-size: 85%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const ActionsCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a,
    button {
        color: #333;
        background: transparent;
        padding: 5px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.7;
        }
    }
`;

export const InfoBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0;

    > div {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
`;

export const InfoBoxLabel = styled.label``;

export const InfoBoxButton = styled.button`
    border: none;
    background: var(--color-green);
    color: #333;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;

    &:hover {
        font-weight: 500;
        background: var(--color-green-hover);
    }
`;
