import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

// Icons
import { FaMoneyCheckAlt, FaEye } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

// API
import api from "../../../services/api";

// Hooks
import { useLoader } from "../../../hooks/loader";

// Common Components
import CandidateMOJGrid from "../CandidateMOJGrid";

import { Container, ActionsCell, Info, Badge } from "./styles";
import { TypeMOJStatus } from "../../../types/mojTypes";

interface IMOJData {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    creationDate: Date;
    expiryDateLink: Date;
    code: string;
    mojStatus: number;
    mojFormLink: string;
    payPalPaymentLink: string;
}

const CandidateDashboard: React.FC = () => {
    const [mojsData, setMojsData] = useState<IMOJData[]>([]);

    const { showLoader, hideLoader } = useLoader();

    const fetchData = useCallback(async () => {
        showLoader("Processing...");

        await api.get(`candidate/GetAllMOJs`).then(response => {
            const { data } = response;
            if (data && data.success && data.data) {
                const mojs: IMOJData[] = data.data;

                setMojsData(mojs);
            }
        });

        hideLoader();
    }, [showLoader, hideLoader]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatDate = (date: Date): string => {
        const parsedDate = Date.parse(date.toString());

        return format(parsedDate, `dd'/'MM'/'yyyy hh:mm a`);
    };

    const getStatusDescription = (status: TypeMOJStatus): string => {
        switch (status) {
            case TypeMOJStatus.Draft:
                return "Draft";
            case TypeMOJStatus.WaitingForPayment:
                return "Waiting for payment";
            case TypeMOJStatus.Paid:
                return "Paid";
            case TypeMOJStatus.Processing:
                return "Processing";
            case TypeMOJStatus.Processed:
                return "Processed";
            case TypeMOJStatus.HasError:
                return "Has error";
            case TypeMOJStatus.Rejected:
                return "Rejected";
            case TypeMOJStatus.Cancelled:
                return "Cancelled";
            case TypeMOJStatus.Expired:
                return "Expired";

            default:
                return "-";
        }
    };

    const getBadgeColorByStatus = (status: TypeMOJStatus): string => {
        switch (status) {
            case TypeMOJStatus.Draft:
                return "orange";
            case TypeMOJStatus.WaitingForPayment:
                return "#1eb7ff";
            case TypeMOJStatus.Paid:
                return "green";
            case TypeMOJStatus.Processing:
                return "#1eb7ff";
            case TypeMOJStatus.Processed:
                return "green";
            case TypeMOJStatus.HasError:
                return "#c53030";
            case TypeMOJStatus.Rejected:
                return "#c53030";
            case TypeMOJStatus.Cancelled:
                return "#c53030";
            case TypeMOJStatus.Expired:
                return "#c53030";

            default:
                return "";
        }
    };

    const getActions = (moj: IMOJData): JSX.Element => {
        const editLink = (
            <Link to={`/moj/${moj.id}`}>
                <MdEdit size={20} />
            </Link>
        );

        const detailLink = (
            <Link to={`/moj-detail/${moj.id}`}>
                <FaEye size={20} />
            </Link>
        );

        const paymentLink = (
            <a href={moj.payPalPaymentLink}>
                <FaMoneyCheckAlt size={20} />
            </a>
        );

        let formattedActions: JSX.Element[] = [];

        switch (moj.mojStatus) {
            case TypeMOJStatus.Draft:
                formattedActions = [editLink];
                break;
            case TypeMOJStatus.WaitingForPayment:
                formattedActions = [paymentLink, detailLink];
                break;
            case TypeMOJStatus.Paid ||
                TypeMOJStatus.Processing ||
                TypeMOJStatus.Processed ||
                TypeMOJStatus.HasError ||
                TypeMOJStatus.Rejected:
                formattedActions = [detailLink];
                break;
            default:
                break;
        }

        return <ActionsCell>{formattedActions}</ActionsCell>;
    };

    const tableColumns = [
        {
            name: "First name",
            selector: "firstName",
        },
        {
            name: "Email",
            selector: "email",
            hide: 400,
        },
        {
            name: "Created at",
            selector: "creationDate",
            cell: (row: IMOJData) => (
                <span>{formatDate(row.creationDate)}</span>
            ),
            hide: 900,
            sortable: true,
        },
        {
            name: "Expiry date",
            selector: "expiryDateLink",
            cell: (row: IMOJData) => (
                <span>{formatDate(row.expiryDateLink)}</span>
            ),
            hide: 900,
            sortable: true,
        },
        {
            name: "Status",
            selector: "mojStatus",
            cell: (row: IMOJData) => (
                <Badge bgColor={getBadgeColorByStatus(row.mojStatus)}>
                    {getStatusDescription(row.mojStatus)}
                </Badge>
            ),
        },
        {
            name: "",
            cell: (row: IMOJData) => getActions(row),
        },
    ];

    return (
        <Container>
            <Info>
                <span>
                    To get started with your criminal history check, you need to
                    click on the <MdEdit size={20} /> button below
                </span>
            </Info>

            <CandidateMOJGrid />
        </Container>
    );
};

export default CandidateDashboard;
