import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

// Common Types
import ActionTypes from "../types/userActions";
import UrlsAddress from "../types/urlsAddress";

// Pages
import Forbidden from "../pages/Forbidden";
import PageNotFound from "../pages/PageNotFound";

import SignIn from "../pages/Account/SignIn";
import ForgotPassword from "../pages/Account/ForgotPassword";
import ResetPassword from "../pages/Account/ResetPassword";
import SignUpCustomer from "../pages/Account/SignUpCustomer";
import SignUpCompany from "../pages/Account/SignUpCompany";
import SignUpCandidate from "../pages/Account/SignUpCandidate";
import SignUpConsultant from "../pages/Account/SignUpConsultant";

import Dashboard from "../pages/Dashboard";

import LandingPage from "../pages/LandingPage";
import Profile from "../pages/Profile";
import MOJ from "../pages/MOJ";
import MOJDetail from "../pages/MOJDetail";
import InviteCandidates from "../pages/InviteCandidates";
import InviteConsultants from "../pages/InviteConsultants";
import PaymentFailed from "../pages/Payments/Failed";
import PaymentSuccess from "../pages/Payments/Success";
import BuyCredits from "../pages/BuyCredits";
import ConsultantPermissions from "../pages/ConsultantPermissions";
import Consultants from "../pages/Consultants";
import ConsultantMOJs from "../pages/ConsultantMOJs";
import ConsultantMOJDetail from "../pages/ConsultantMOJDetail";
import InternalMOJRequests from "../pages/InternalMOJRequests";
import Branches from "../pages/Branches";
import ManageCredits from "../pages/Branches/ManageCredits";
import BranchDetail from "../pages/Branches/BranchDetail";
import InternalStaff from "../pages/InternalStaff";
import ManageInternalStaff from "../pages/ManageInternalStaff";
import InternalFinancialDashboard from "../pages/InternalFinancialDashboard";
import InternalStaffPermissions from "../pages/InternalStaffPermissions";
import InternalMOJCost from "../pages/InternalMOJCost";
import AddStaff from "../pages/AddStaff";

const Routes: React.FC = () => (
    <Switch>
        <Route exact path={UrlsAddress.BASE} component={LandingPage} />
        {/* Account */}
        <Route path={UrlsAddress.SIGN_IN} component={SignIn} />
        <Route path={UrlsAddress.SIGN_UP_CUSTOMER} component={SignUpCustomer} />
        <Route path={UrlsAddress.SIGN_UP_COMPANY} component={SignUpCompany} />
        <Route
            path={UrlsAddress.SIGN_UP_CANDIDATE}
            component={SignUpCandidate}
        />
        <Route
            path={UrlsAddress.SIGN_UP_CONSULTANT}
            component={SignUpConsultant}
        />
        <Route path={UrlsAddress.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={UrlsAddress.RESET_PASSWORD} component={ResetPassword} />
        {/* DASHBOARD */}
        <Route path={UrlsAddress.DASHBOARD} component={Dashboard} isPrivate />
        {/* MOJ */}
        <Route path={UrlsAddress.MOJ} component={MOJ} isPrivate />
        <Route path={UrlsAddress.MOJ_DETAIL} component={MOJDetail} isPrivate />
        {/* PROFILE */}
        <Route path={UrlsAddress.PROFILE} component={Profile} isPrivate />
        {/* PAYMENTS */}
        <Route
            path={UrlsAddress.PAYMENT_FAILED}
            component={PaymentFailed}
            isPrivate
        />
        <Route
            path={UrlsAddress.PAYMENT_SUCCESS}
            component={PaymentSuccess}
            isPrivate
        />
        {/* COMPANIES */}
        <Route
            path={UrlsAddress.BUY_CREDITS}
            component={BuyCredits}
            permission={ActionTypes.COMPANY_ADMIN_BUY_CREDITS}
            isPrivate
        />
        {/* CONSULTANTS */}
        <Route
            path={UrlsAddress.STAFF}
            component={Consultants}
            permission={ActionTypes.COMPANY_ADMIN_MANAGE_CONSULTANTS}
            isPrivate
        />
        <Route
            path={UrlsAddress.CONSULTANT_PERMISSIONS}
            component={ConsultantPermissions}
            permission={ActionTypes.COMPANY_ADMIN_MANAGE_CONSULTANTS}
            isPrivate
        />
        <Route
            path={UrlsAddress.CONSULTANT_MOJS}
            component={ConsultantMOJs}
            permission={ActionTypes.CONSULTANT_LIST_ALL_MOJS_CONSULTANT}
            isPrivate
        />
        <Route
            path={UrlsAddress.INVITE_CANDIDATES}
            component={InviteCandidates}
            permission={ActionTypes.CONSULTANT_CREATE_CANDIDATE_MOJ}
            isPrivate
        />
        <Route
            path={UrlsAddress.INVITE_STAFF}
            component={InviteConsultants}
            permission={ActionTypes.COMPANY_ADMIN_INVITE_CONSULTANT}
            isPrivate
        />
        <Route
            path={UrlsAddress.CONSULTANT_MOJ_DETAIL}
            component={ConsultantMOJDetail}
            isPrivate
        />
        {/* INTERNAL CONSULTANTS */}
        <Route
            path={UrlsAddress.INTERNAL_MOJ_REQUESTS}
            component={InternalMOJRequests}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_MANAGE_BRANCHES}
            component={Branches}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_MANAGE_CREDITS_BRANCH}
            component={ManageCredits}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_BRANCH_DETAIL}
            component={BranchDetail}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_STAFF}
            component={InternalStaff}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_MANAGE_STAFF}
            component={ManageInternalStaff}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_FINANCIAL_DASHBOARD}
            component={InternalFinancialDashboard}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_MANAGE_STAFF_PERMISSIONS}
            component={InternalStaffPermissions}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_MANAGE_MOJ_COST}
            component={InternalMOJCost}
            isPrivate
        />
        <Route
            path={UrlsAddress.INTERNAL_ADD_STAFF}
            component={AddStaff}
            isPrivate
        />
        {/* ACCESS FORBIDDEN */}
        <Route
            path={UrlsAddress.ACCESS_FORBIDDEN}
            component={Forbidden}
            isPrivate
        />

        {/* NOT FOUND PAGE */}
        <Route component={PageNotFound} />
    </Switch>
);

export default Routes;
