import styled, { keyframes } from "styled-components";
import { shade } from "polished";

import signInBackgroundImg from "../../../assets/sign-up-background-company.png";

export const Container = styled.div`
    height: 100vh;

    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    /*  */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    /* max-width: 700px; */
    max-width: 768px;
`;

const appearFromRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromRight} 1s;

    img {
        width: 45%;
        height: auto;
    }

    form {
        margin: 60px 0 20px 0;
        width: 340px;
        text-align: center;
        padding: 0 20px;

        h1 {
            margin-bottom: 24px;
        }

        a {
            color: #7c9220;
            display: block;
            margin-top: 24px;
            text-decoration: none;
            transition: color 0.2s;

            &:hover {
                color: ${shade(0.2, "#7c9220")};
            }
        }
    }

    /*
        ">" => Only same level
        ">" means you're getting only "a" tag directly from content
    */
    > a {
        color: #8da725;
        display: block;
        margin-top: 24px;
        text-decoration: none;
        transition: color 0.2s;

        display: flex;
        align-items: center;

        svg {
            margin-right: 16px;
        }

        &:hover {
            color: ${shade(0.2, "#8da725")};
        }
    }
`;

export const Background = styled.div`
    /*  */
    flex: 1;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(${signInBackgroundImg}) no-repeat center;
    background-size: cover;
`;

export const NewContent = styled.div`
    display: grid;
    grid-template-rows: auto auto 1fr auto;

    animation: ${appearFromRight} 1s;
`;

export const NewAnimationContainer = styled.div`
    height: 100vh;
    width: 100%;
    max-width: 768px;
    padding: 20px;

    overflow: scroll;
    overflow-x: hidden;

    animation: ${appearFromRight} 1s;
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 45%;
        height: auto;
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;

    h1 {
    }

    h3 {
        /* margin-bottom: 24px; */
        margin: 10px 0 25px;
        font-size: 1rem;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 0 0 10px;

    /* padding: 0 20px; */

    form {
        max-width: 600px;
        text-align: center;
        width: 100%;
    }
`;

export const Links = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
        margin: 10px;
        color: #8da725;
        display: block;
        text-decoration: none;
        transition: color 0.2s;

        display: flex;
        align-items: center;

        svg {
            margin-right: 10px;
        }

        &:hover {
            color: ${shade(0.2, "#8da725")};
        }
    }
`;

export const GroupDataCard = styled.div`
    background: #fff;

    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: column;

    margin: 20px 0;
    padding: 15px;
    border-radius: 10px;

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const GroupDataCardTitle = styled.h3`
    /* color: #b2d235; */
    margin: 15px 0;
`;

export const GroupDataCardContent = styled.div`
    width: 100%;
`;
