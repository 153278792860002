import styled from "styled-components";

export const Container = styled.div`
    background: #b2d235;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

export const Banner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    min-height: 170px;
    flex-wrap: wrap;

    img {
        margin: 30px;
    }

    .support-local {
        margin: 15px;

        h3 {
            text-align: center;
            font-size: 2.5rem;
        }
    }
`;
