import styled from "styled-components";

export const Container = styled.div`
    /* background: #fff; */

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    background: #fff;
    margin-top: 20px;
    border-radius: 10px;
    padding: 25px;

    form {
        /* margin: 0 0 20px; */
        max-width: 600px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;

        h1 {
            margin-bottom: 24px;
            font-size: 20px;
            text-align: left;
        }
    }
`;

export const Title = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 15px;
    background: #fff;
    border-radius: 10px;

    h3 {
        font-size: 2rem;
        text-align: center;
    }
`;
