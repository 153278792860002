import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa";

interface ITargetProps {
    target: number;
}

const ScrollTopArrow = ({ target }: ITargetProps): JSX.Element => {
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = (): void => {
        if (!showScroll && window.pageYOffset > target) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= target) {
            setShowScroll(false);
        }
    };

    const scrollTop = (): void => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("scroll", checkScrollTop);

    return (
        <button
            type="button"
            onClick={scrollTop}
            style={{
                display: showScroll ? "flex" : "none",
                height: "40px",
                width: "40px",
                position: "fixed",
                bottom: "20px",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
                cursor: "pointer",
                background: "rgb(178, 210, 53)",
                right: "0px",
                marginRight: "20px",
                fontSize: "40px",
                border: "none",
                borderRadius: "8px",
                padding: "0.5rem",
                boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            }}
        >
            <FaArrowUp
                className="scrollTop"
                style={{
                    height: "32px",
                    width: "32px",
                    color: "#333",
                }}
            />
        </button>
    );
};

export default ScrollTopArrow;
