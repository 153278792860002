import React, { useEffect, useState } from "react";
import { useTransition } from "react-spring";

import { IToastMessage } from "../../hooks/toast";

import Toast from "./Toast";

import { Container } from "./styles";

interface IToastContainerProps {
    messages: IToastMessage[];
}

const ToastContainer: React.FC<IToastContainerProps> = ({ messages }) => {
    const [hasMessages, setHasMessages] = useState(false);

    const messagesWithTransictions = useTransition(
        // Which object will have transictions effects?
        messages,
        // What is the identifier of each object?
        message => message.id,
        // Object containing animations
        {
            // initial position
            from: { right: "-120%", opacity: 0 },
            // object position when entering on screen
            enter: { right: "0%", opacity: 1 },
            // what is the animation when leave the screen
            leave: {
                right: "-120%",
                opacity: 0,
            },
        },
    );

    const updateHasMessages = (quantity: number): void => {
        if (quantity > 0) {
            setHasMessages(true);
        } else {
            setHasMessages(false);
        }
    };

    useEffect(() => {
        updateHasMessages(messagesWithTransictions.length);

        return () => {
            updateHasMessages(messagesWithTransictions.length);
        };
    }, [messagesWithTransictions]);

    return (
        <Container expand={hasMessages}>
            {messagesWithTransictions.map(({ item, key, props }) => (
                // style={props} => our style to animate Toasts
                <Toast key={key} style={props} message={item} />
            ))}
        </Container>
    );
};

export default ToastContainer;
