import React from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { useHistory } from "react-router-dom";

// Icons
import { FaChevronLeft } from "react-icons/fa";

// Common Types
import { IStaffData } from "../../../types/internalConsultantsTypes";

import {
    Container,
    LinkContainer,
    LinkRow,
    Title,
    GridTitle,
    TableContent,
} from "./styles";

interface IStaffGridProps {
    staff: IStaffData[];
    columns: IDataTableColumn<IStaffData>[];
    title?: string;
}

const StaffGrid: React.FC<IStaffGridProps> = ({ staff, columns, title }) => {
    const history = useHistory();

    return (
        <Container>
            {/* <LinkContainer>
                <LinkRow>
                    <FaChevronLeft />
                    <button type="button" onClick={() => history.goBack()}>
                        Go back
                    </button>
                </LinkRow>
            </LinkContainer> */}
            <Title>Staff</Title>
            {title && <GridTitle>{title}</GridTitle>}
            <TableContent>
                <DataTable
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    highlightOnHover
                    columns={columns}
                    data={staff}
                    pagination
                    responsive
                    noHeader
                />
            </TableContent>
        </Container>
    );
};

export default StaffGrid;
