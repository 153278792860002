import styled from "styled-components";

export const Container = styled.div`
    /* background: #fff; */

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const InvitationCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
`;

export const InvitationTitle = styled.h2`
    margin-bottom: 16px;
`;

export const InvitationDescription = styled.h4`
    margin-bottom: 16px;
`;

export const FormContent = styled.div`
    max-width: 600px;
    width: 100%;
`;
