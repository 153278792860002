import styled, { css } from "styled-components";

interface IProductProps {
    type: "bronze" | "silver" | "gold";
}

const productBackgroundVariation = {
    bronze: css`
        background-image: var(--background-bronze);
    `,

    silver: css`
        background-image: var(--background-silver);
    `,

    gold: css`
        background-image: var(--background-gold);
    `,
};

const productBoxBorderVariation = {
    bronze: css`
        border: 2px #cd4432 solid;
    `,

    silver: css`
        border: 2px #9a9a9a solid;
    `,

    gold: css`
        border: 2px #ffae00 solid;
    `,
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const ProductTypeContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
`;

export const ProductTypeContentTitle = styled.h2`
    margin-bottom: 16px;
    font-size: 32px;
    text-align: center;
`;

export const ProductTypeContentDescription = styled.h4`
    margin-bottom: 16px;
    text-align: center;
`;

export const FormContent = styled.div`
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const SendEmailButtonContent = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }

    span {
        text-transform: uppercase;
    }
`;

export const ProductsContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
`;

export const ProductsContainerTitle = styled.span`
    margin-bottom: 16px;
    font-size: 28px;
    text-align: center;
    max-width: 600px;
`;

export const ProductsPanel = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

export const ProductBox = styled.div<IProductProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    border-radius: 10px;
    margin: 20px 5px;

    ${props => productBoxBorderVariation[props.type]}

    @media screen and (min-width: 1050px) {
        width: 260px;
        margin: 20px auto;
    }

    @media screen and (min-width: 1200px) {
        width: 280px;
        margin: 20px auto;
    }
`;

export const ProductBoxHeader = styled.div<IProductProps>`
    ${props => productBackgroundVariation[props.type]}

    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-radius: 5px 5px 0 0;
    min-height: 76px;

    color: #333;
`;

export const ProductBoxHeaderTitle = styled.div<IProductProps>`
    display: flex;

    span {
        font-weight: 500;
        font-size: 2rem;
        text-align: center;
    }
`;

export const ProductBoxBody = styled.div<IProductProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    background: #fff;
`;

export const ProductBoxBodyProcessingDays = styled.div`
    display: flex;
    align-items: baseline;
    margin: 8px 0;

    span {
        font-size: 40px;
        font-weight: 700;
    }

    small {
        font-size: 16px;
        margin-left: 8px;
    }
`;

export const ProductBoxBodyMOJCredits = styled.div`
    display: flex;
    align-items: baseline;
    margin: 8px 0;

    span {
        font-size: 40px;
        font-weight: 700;
    }

    small {
        font-size: 16px;
        margin-left: 8px;
    }
`;

export const ProductBoxBodySpecifications = styled.ul<IProductProps>`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;

export const ProductBoxBodySpecificationItem = styled.div<IProductProps>`
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin: 5px 0;

    text-align: left;

    svg {
        color: #b2d235;
        width: 12px;
        height: 12px;
    }

    span {
        color: #333;
        font-weight: 400;
        line-height: 1rem;
        margin-left: 10px;

        font-size: 1rem;

        @media screen and (min-width: 1100px) {
            font-size: 1rem;
        }
    }
`;

export const ProductBoxBodyPrice = styled.div<IProductProps>`
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 8px 0;

    span.signal {
        font-size: 1.8rem;
        font-weight: 400;
        margin-right: 5px;
    }

    span {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.5;
        margin-right: 5px;
    }

    > small {
    }
`;

export const ProductBoxBodyWarning = styled.span`
    width: 100%;
    font-size: 11px;
    font-style: italic;
    margin: 10px 0;
`;

export const ProductBoxFooter = styled.div<IProductProps>`
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 0 20px;
    background: #fff;

    button {
        display: flex;
        justify-content: center;
        width: 100%;
        font-weight: 500;
        text-transform: capitalize;
        border-radius: 5px;
        padding: 0.75rem 1.75rem;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        border-color: transparent;
        border: none;
        letter-spacing: 1px;
        text-transform: uppercase;

        font-size: 1rem;

        @media screen and (min-width: 1100px) {
            font-size: 1rem;
        }

        ${props => productBackgroundVariation[props.type]}
        color: #333;

        transition: all 0.5s;
        background-size: 200% auto;

        &:hover {
            color: #333;
            background-position: right center;
        }

        cursor: pointer;
        box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.2);
    }
`;
