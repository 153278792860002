import React, { useCallback, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Icons
import { FiMail, FiLogIn } from "react-icons/fi";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../services/api";

// Hooks
// import { useToast } from "../../../hooks/toast";

import logoImg from "../../../assets/boss-logo.png";

// Common Components
import InputWithLabel from "../../../components/InputWithLabel";
import ButtonLoader from "../../../components/ButtonLoader";
import getValidationErrors from "../../../utils/getValidationErrors";

// Common Types
import UrlsAddress from "../../../types/urlsAddress";

// Styles
import { Container, AnimationContainer, Content, Background } from "./styles";

interface IForgotPasswordFormData {
    email: string;
}

const ForgotPassword: React.FC = () => {
    const [processingRequest, setProcessingRequest] = useState(false);
    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();

    const handleSubmit = useCallback(async (data: IForgotPasswordFormData) => {
        try {
            setProcessingRequest(true);
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                email: Yup.string()
                    .required("E-mail address is required")
                    .email("E-mail address invalid"),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            await api.post("/account/forgotpassword", {
                email: data.email,
            });

            // addToast({
            //     type: "success",
            //     title: "Recovery password e-mail sent",
            //     description:
            //         "We have sent an email to recover your password. Check your inbox",
            // });
            toast.success(
                "We have sent an email to recover your password. Check your inbox",
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },
            );
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }

            // addToast({
            //     type: "error",
            //     title: "Error when recovering password",
            //     description:
            //         "There was an error recovering your password. Please, try again!",
            // });
            toast.error(
                "There was an error recovering your password. Please, try again!",
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },
            );
        } finally {
            setProcessingRequest(false);
        }
    }, []);

    return (
        <>
            <Container>
                {/*  */}
                <Content>
                    <AnimationContainer>
                        <img src={logoImg} alt="MOJ" />

                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <h1>Recover my password</h1>

                            <InputWithLabel
                                name="email"
                                icon={FiMail}
                                label="E-mail"
                                placeholder="E-mail *"
                            />
                            <ButtonLoader
                                processingRequest={processingRequest}
                                type="submit"
                            >
                                Send me a recovery link
                            </ButtonLoader>
                        </Form>

                        {/* <Link to="/sign-in"> */}
                        <Link to={UrlsAddress.SIGN_IN}>
                            <FiLogIn />
                            Go back to login
                        </Link>
                    </AnimationContainer>
                </Content>
                <Background />
            </Container>
        </>
    );
};

export default ForgotPassword;
