import styled from "styled-components";

export const Container = styled.button`
    background: var(--color-green);
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #312e38;
    width: 100%;
    font-weight: 700;
    margin-top: 16px;
    transition: background-color 0.2s;
    transition: color 0.2s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
        background: var(--color-green-hover);
    }
`;
