import styled from "styled-components";

export const TypeOfService = styled.div`
    margin-bottom: 16px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 8px;
    }
`;

export const RowField = styled.div`
    font-weight: 500;
    font-size: 18px;
`;

export const RowValue = styled.div`
    font-weight: 400;
    margin-left: 8px;
`;

export const CopyRequired = styled.div`
    margin-bottom: 16px;
`;

export const SignatureDisplay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    span {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 500;
    }

    img {
        max-width: 350px;
        width: 100%;
        height: auto;
        background: #ced4da;
        border: 2px #000 solid;
    }
`;
