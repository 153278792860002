import React, {
    useCallback,
    useRef,
    useState,
    useEffect,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { parse, isValid } from "date-fns";

// Icons
import { FiMail, FiLock, FiUser } from "react-icons/fi";
import { FaMapMarkerAlt, FaBirthdayCake } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API Service
import api from "../../../services/api";

// Hooks
// import { useToast } from "../../../hooks/toast";
import { useAuth } from "../../../hooks/auth";
import { useLoader } from "../../../hooks/loader";

// Common Components
import InputWithLabel from "../../../components/InputWithLabel";
import InputNumberWithLabel from "../../../components/InputNumberWithLabel";
import InputPasswordWithLabel from "../../../components/InputPasswordWithLabel";
import InputMaskWithLabel from "../../../components/InputMaskWithLabel";
import Button from "../../../components/Button/index";
import Checkbox from "../../../components/Checkbox";
import getValidationErrors from "../../../utils/getValidationErrors";

// Logo
import logoImg from "../../../assets/boss-logo.png";

// Modals
import TermsConditionsModal from "../../TermsConditionsModal";
import PrivacyPolicyModal from "../../PrivacyPolicyModal";

// Common Types
import { IMOJCustomError } from "../../../types/globalTypes";
import UrlsAddress from "../../../types/urlsAddress";

// Utils
import { convertStringToDate } from "../../../utils/dateUtilities";

import {
    Container,
    Background,
    NewContent,
    NewAnimationContainer,
    Logo,
    Title,
    FormContainer,
    GroupDataCard,
    GroupDataCardTitle,
    GroupDataCardContent,
} from "./styles";

interface ISignUpCandidateFormData {
    email: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    contactNumber: string;
    password: string;
    confirmPassword: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    idCountry: string;
    companyName: string;
    termsOfService: boolean;
}

const SignUpCandidate: React.FC = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [newZealandId, setNewZealandId] = useState("");

    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();
    const { signIn } = useAuth();
    const { showLoader, hideLoader } = useLoader();
    const history = useHistory();
    const location = useLocation();

    const getQueryParams = useCallback(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    const query = getQueryParams();

    const token = query.get("token");
    const email = query.get("email");

    const fetchData = useCallback(async () => {
        const { data } = await api.get("country/GetNewZealand");
        let idNZ = "00000000-0000-0000-0000-000000000000";

        if (data && data.success) {
            idNZ = data.data.id as string;
        }

        setNewZealandId(idNZ);
    }, []);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handleShowTermsClick = (event: any) => {
    //     event.stopPropagation();
    //     event.preventDefault();

    //     setShowTerms(true);
    // };
    const handleShowTermsClick = useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();

        setShowTerms(true);
    }, []);

    // const handleShowPrivacyClick = (event: any) => {
    //     event.stopPropagation();
    //     event.preventDefault();

    //     setShowPrivacy(true);
    // };
    const handleShowPrivacyClick = useCallback((event: any) => {
        event.stopPropagation();
        event.preventDefault();

        setShowPrivacy(true);
    }, []);

    // const getNewZealandId = async (): Promise<string> => {
    //     const { data } = await api.get("country/GetNewZealand");

    //     if (data && data.success) {
    //         return data.data.id as string;
    //     }

    //     return "00000000-0000-0000-0000-000000000000";
    // };
    const getNewZealandId = useMemo(() => {
        return newZealandId;
    }, [newZealandId]);

    const yupCustomDateValidator = (data: string): boolean => {
        if (data) {
            const parsedDate = parse(data, "dd/MM/yyyy", new Date());

            if (isValid(parsedDate)) {
                return true;
            }
        }

        return false;
    };

    const handleSubmit = useCallback(
        async (data: ISignUpCandidateFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail address is required")
                        .email("E-mail address invalid"),
                    firstName: Yup.string().required("First Name is required"),
                    birthDate: Yup.string().test(
                        "Date of Birth",
                        "Date is invalid",
                        value => yupCustomDateValidator(value),
                    ),
                    contactNumber: Yup.string().required(
                        "Phone number is required",
                    ),
                    password: Yup.string().min(
                        8,
                        "Password has to be longer than 8 characters",
                    ),
                    confirmPassword: Yup.string().when("password", {
                        is: val => val && val.length > 0,
                        then: Yup.string()
                            .oneOf(
                                [Yup.ref("password")],
                                "Password and password confirmation do not match",
                            )
                            .required(),
                    }),
                    streetAddress: Yup.string().required(
                        "PO Box/Street is required",
                    ),
                    suburb: Yup.string().required("Suburb is required"),
                    city: Yup.string().required("City/Town is required"),
                    state: Yup.string().required(
                        "Region/State/Province is required",
                    ),
                    postcode: Yup.string().required("Postcode is required"),
                    idCountry: Yup.string().required("Country is required"),

                    termsOfService: Yup.boolean()
                        .required("The terms and conditions must be accepted")
                        .oneOf(
                            [true],
                            "The terms and conditions must be accepted",
                        ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Creating account...");

                const { birthDate } = data;

                if (!token || !email) {
                    throw new Error();
                }

                await api.post("candidate/register", {
                    ...data,
                    confirmEmail: data.email,
                    birthDate: convertStringToDate(birthDate),
                    idCountry: getNewZealandId,
                    token,
                });

                // addToast({
                //     type: "success",
                //     title: "Candidate registered!",
                //     description: "You can now sign in!",
                // });

                toast.success("User registered!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                await signIn({
                    email: data.email,
                    password: data.password,
                });

                // history.push("/dashboard");
                history.push(UrlsAddress.DASHBOARD);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        // errors.map(error => {
                        //     addToast({
                        //         type: "error",
                        //         title: "Error when creating user",
                        //         description: error,
                        //     });
                        // });

                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            // addToast({
                            //     type: "error",
                            //     title: "Error when creating user",
                            //     description: element,
                            // });
                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    // addToast({
                    //     type: "error",
                    //     title: "Error when creating user",
                    //     description:
                    //         "There was an error signing up, check the required data!",
                    // });
                    toast.error(
                        "There was an error signing up, check the required data!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                hideLoader();
            }
        },
        [
            hideLoader,
            showLoader,
            history,
            email,
            token,
            getNewZealandId,
            signIn,
        ],
    );

    return (
        <>
            <>
                <Container>
                    <Background />
                    <NewAnimationContainer>
                        <NewContent>
                            <Logo>
                                <img src={logoImg} alt="MOJ" />
                            </Logo>
                            <Title>
                                <h1>Sign Up</h1>
                                <h3>Candidate</h3>
                            </Title>
                            <FormContainer>
                                <Form ref={formRef} onSubmit={handleSubmit}>
                                    <GroupDataCard>
                                        <GroupDataCardTitle>
                                            Account details
                                        </GroupDataCardTitle>
                                        <GroupDataCardContent>
                                            <InputWithLabel
                                                name="email"
                                                icon={FiMail}
                                                label="E-mail"
                                                placeholder="E-mail *"
                                                readOnly
                                                defaultValue={email || ""}
                                            />
                                            <InputWithLabel
                                                name="firstName"
                                                icon={FiUser}
                                                label="First Name"
                                                placeholder="First Name *"
                                            />
                                            <InputWithLabel
                                                name="lastName"
                                                icon={FiUser}
                                                label="Last Name"
                                                placeholder="Last Name"
                                            />
                                            <InputMaskWithLabel
                                                name="birthDate"
                                                icon={FaBirthdayCake}
                                                label="Date of Birth"
                                                placeholder={`e.g. 31/12/${new Date().getFullYear()}`}
                                                mask="99/99/9999"
                                            />
                                            <InputNumberWithLabel
                                                name="contactNumber"
                                                icon={FiMail}
                                                label="Contact Number"
                                                placeholder="Contact Number *"
                                            />
                                            <InputPasswordWithLabel
                                                name="password"
                                                icon={FiLock}
                                                label="Password"
                                                placeholder="Password *"
                                            />
                                            <InputPasswordWithLabel
                                                name="confirmPassword"
                                                icon={FiLock}
                                                label="Confirm Password"
                                                placeholder="Confirm your Password *"
                                            />
                                        </GroupDataCardContent>
                                    </GroupDataCard>
                                    <GroupDataCard>
                                        <GroupDataCardTitle>
                                            Address
                                        </GroupDataCardTitle>
                                        <GroupDataCardContent>
                                            <InputWithLabel
                                                name="streetAddress"
                                                icon={FaMapMarkerAlt}
                                                label="Street Address"
                                                placeholder="PO Box/Street Address *"
                                            />
                                            <InputWithLabel
                                                name="suburb"
                                                icon={FaMapMarkerAlt}
                                                label="Suburb"
                                                placeholder="Suburb *"
                                            />
                                            <InputWithLabel
                                                name="city"
                                                icon={FaMapMarkerAlt}
                                                label="City/Town"
                                                placeholder="City/Town *"
                                            />
                                            <InputWithLabel
                                                name="state"
                                                icon={FaMapMarkerAlt}
                                                label="Region"
                                                placeholder="Region *"
                                            />
                                            <InputMaskWithLabel
                                                name="postcode"
                                                icon={FaMapMarkerAlt}
                                                label="Postcode"
                                                placeholder="Postcode *"
                                                mask="9999"
                                            />
                                            <InputWithLabel
                                                name="idCountry"
                                                label="Country"
                                                icon={FaMapMarkerAlt}
                                                value="New Zealand"
                                                readOnly
                                            />
                                        </GroupDataCardContent>
                                    </GroupDataCard>

                                    <GroupDataCard>
                                        <GroupDataCardTitle>
                                            Terms and Conditions
                                        </GroupDataCardTitle>
                                        <GroupDataCardContent>
                                            <Checkbox
                                                name="termsOfService"
                                                noBackground
                                            >
                                                I've read, understand and agree
                                                to the{" "}
                                                <label
                                                    onClick={
                                                        handleShowTermsClick
                                                    }
                                                    aria-hidden="true"
                                                >
                                                    Terms and Conditions
                                                </label>{" "}
                                                &{" "}
                                                <label
                                                    onClick={
                                                        handleShowPrivacyClick
                                                    }
                                                    aria-hidden="true"
                                                >
                                                    Privacy Policy
                                                </label>
                                            </Checkbox>
                                        </GroupDataCardContent>
                                    </GroupDataCard>

                                    <Button type="submit">Sign Up</Button>
                                </Form>
                            </FormContainer>
                        </NewContent>
                    </NewAnimationContainer>
                </Container>
                <TermsConditionsModal
                    onClose={setShowTerms}
                    show={showTerms}
                    size="xl"
                />

                <PrivacyPolicyModal
                    onClose={setShowPrivacy}
                    show={showPrivacy}
                    size="xl"
                />
            </>
        </>
    );
};

export default SignUpCandidate;
