import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useHistory, useRouteMatch } from "react-router-dom";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useLoader } from "../../hooks/loader";
import { useAuth } from "../../hooks/auth";

// MasterPage
import MainNew from "../MainNew";

// Common Components
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";

// Common Types
import { IMOJCustomError } from "../../types/globalTypes";
import UrlsAddress from "../../types/urlsAddress";

import {
    Container,
    InvitationCard,
    InvitationTitle,
    InvitationDescription,
    FormContent,
    PermissionGroupBox,
    PermissionGroupBoxTitle,
    PermissionGroupBoxInputs,
} from "./styles";

interface IGroupPermissionsDTO {
    groupName: string;
    permissions: IPermissionDTO[];
}

interface IPermissionDTO {
    name: string;
    description: string;
    granted: boolean;
}

interface IPermissions {
    // Internal Staff
    readInternalDashboard: boolean;
    readMOJOverview: boolean;
    readMOJCharts: boolean;
    readMOJApplications: boolean;
    addCreditsToBranch: boolean;
    manageBranches: boolean;
    manageInternalConsultants: boolean;
    readFinancialDashboard: boolean;
    manageMOJCost: boolean;
    // CompanyAdmin Group
    addConsultant: boolean;
    buyCredits: boolean;
    inviteConsultant: boolean;
    manageConsultants: boolean;
    readCompanyDashboard: boolean;
    // Consultant Group
    readConsultantDetails: boolean;
    createCandidateMOJ: boolean;
    listAllMOJsConsultant: boolean;
    readCompanyCredits: boolean;
    updateConsultantDetails: boolean;
}

interface IInternalStaffParams {
    idStaff: string;
}

interface IUserPermissionsDTO {
    idUser: string;
    permissionsGroups: IGroupPermissionsDTO[];
    userName: string;
    userEmail: string;
}

const InternalStaffPermissions: React.FC = () => {
    const [permissions, setPermissions] = useState<IGroupPermissionsDTO[]>([]);
    const [initialData, setInitialData] = useState<IPermissions>(
        {} as IPermissions,
    );
    const [staffName, setStaffName] = useState("");

    const formRef = useRef<FormHandles>(null);

    const { user } = useAuth();
    const { showLoader, hideLoader } = useLoader();
    const history = useHistory();

    const { params } = useRouteMatch<IInternalStaffParams>();

    const fetchData = useCallback(async () => {
        if (user.userType !== 6 && user.userType !== 7) {
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            const response = await api.get(
                `internalConsultant/manage-staff-permissions/${params.idStaff}`,
                {
                    params: {
                        idStaff: params.idStaff,
                    },
                },
            );

            if (response.status === 200) {
                const { data }: { data: IUserPermissionsDTO } = response.data;

                setStaffName(data.userName);

                setPermissions(data.permissionsGroups);
            }

            hideLoader();
        }
    }, [showLoader, hideLoader, history, user.userType, params.idStaff]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getFormData = useCallback(() => {
        // Internal Staff
        let readInternalDashboard = false;
        let readMOJOverview = false;
        let readMOJCharts = false;
        let readMOJApplications = false;
        let addCreditsToBranch = false;
        let manageBranches = false;
        let manageInternalConsultants = false;
        let readFinancialDashboard = false;
        let manageMOJCost = false;
        // CompanyAdmin Group
        let addConsultant = false;
        let inviteConsultant = false;
        let manageConsultants = false;
        let readCompanyDashboard = false;
        // // Staff Group
        let readConsultantDetails = false;
        let createCandidateMOJ = false;
        let listAllMOJsConsultant = false;
        let readCompanyCredits = false;
        let updateConsultantDetails = false;

        if (formRef && formRef.current) {
            const readInternalDashboardGranted: boolean = formRef.current.getFieldValue(
                "readInternalDashboard",
            );

            const readMOJOverviewGranted: boolean = formRef.current.getFieldValue(
                "readMOJOverview",
            );

            const readMOJChartsGranted: boolean = formRef.current.getFieldValue(
                "readMOJCharts",
            );

            const readMOJApplicationsGranted: boolean = formRef.current.getFieldValue(
                "readMOJApplications",
            );

            const addCreditsToBranchGranted: boolean = formRef.current.getFieldValue(
                "addCreditsToBranch",
            );

            const manageBranchesGranted: boolean = formRef.current.getFieldValue(
                "manageBranches",
            );

            const manageInternalConsultantsGranted: boolean = formRef.current.getFieldValue(
                "manageInternalConsultants",
            );

            const readFinancialDashboardGranted: boolean = formRef.current.getFieldValue(
                "readFinancialDashboard",
            );

            const manageMOJCostGranted: boolean = formRef.current.getFieldValue(
                "manageMOJCost",
            );

            const addConsultantGranted: boolean = formRef.current.getFieldValue(
                "addConsultant",
            );

            const inviteConsultantGranted: boolean = formRef.current.getFieldValue(
                "inviteConsultant",
            );

            const manageConsultantsGranted: boolean = formRef.current.getFieldValue(
                "manageConsultants",
            );

            const readCompanyDashboardGranted: boolean = formRef.current.getFieldValue(
                "readCompanyDashboard",
            );
            // // // Consultant Group
            const readConsultantDetailsGranted: boolean = formRef.current.getFieldValue(
                "readConsultantDetails",
            );

            const createCandidateMOJGranted: boolean = formRef.current.getFieldValue(
                "createCandidateMOJ",
            );

            const listAllMOJsConsultantGranted: boolean = formRef.current.getFieldValue(
                "listAllMOJsConsultant",
            );

            const readCompanyCreditsGranted: boolean = formRef.current.getFieldValue(
                "readCompanyCredits",
            );

            const updateConsultantDetailsGranted: boolean = formRef.current.getFieldValue(
                "updateConsultantDetails",
            );

            readInternalDashboard = readInternalDashboardGranted;
            readMOJOverview = readMOJOverviewGranted;
            readMOJCharts = readMOJChartsGranted;
            readMOJApplications = readMOJApplicationsGranted;
            addCreditsToBranch = addCreditsToBranchGranted;
            manageBranches = manageBranchesGranted;
            manageInternalConsultants = manageInternalConsultantsGranted;
            readFinancialDashboard = readFinancialDashboardGranted;
            manageMOJCost = manageMOJCostGranted;

            addConsultant = addConsultantGranted;
            inviteConsultant = inviteConsultantGranted;
            manageConsultants = manageConsultantsGranted;
            readCompanyDashboard = readCompanyDashboardGranted;
            readConsultantDetails = readConsultantDetailsGranted;
            createCandidateMOJ = createCandidateMOJGranted;
            listAllMOJsConsultant = listAllMOJsConsultantGranted;
            readCompanyCredits = readCompanyCreditsGranted;
            updateConsultantDetails = updateConsultantDetailsGranted;
        }

        return {
            IdUser: params.idStaff,
            permissionsGroups: [
                {
                    groupName: "InternalConsultant",
                    permissions: [
                        {
                            name: "readInternalDashboard",
                            granted: readInternalDashboard,
                        },
                        {
                            name: "readMOJOverview",
                            granted: readMOJOverview,
                        },
                        {
                            name: "readMOJCharts",
                            granted: readMOJCharts,
                        },
                        {
                            name: "readMOJApplications",
                            granted: readMOJApplications,
                        },
                        {
                            name: "addCreditsToBranch",
                            granted: addCreditsToBranch,
                        },
                        {
                            name: "manageBranches",
                            granted: manageBranches,
                        },
                        {
                            name: "manageInternalConsultants",
                            granted: manageInternalConsultants,
                        },
                        {
                            name: "readFinancialDashboard",
                            granted: readFinancialDashboard,
                        },
                        {
                            name: "manageMOJCost",
                            granted: manageMOJCost,
                        },
                    ],
                },
                {
                    groupName: "CompanyAdmin",
                    permissions: [
                        {
                            name: "addConsultant",
                            granted: addConsultant,
                        },
                        {
                            name: "inviteConsultant",
                            granted: inviteConsultant,
                        },
                        {
                            name: "manageConsultants",
                            granted: manageConsultants,
                        },
                        {
                            name: "readCompanyDashboard",
                            granted: readCompanyDashboard,
                        },
                    ],
                },
                {
                    groupName: "Consultant",
                    permissions: [
                        {
                            name: "readConsultantDetails",
                            granted: readConsultantDetails,
                        },
                        {
                            name: "createCandidateMOJ",
                            granted: createCandidateMOJ,
                        },
                        {
                            name: "listAllMOJsConsultant",
                            granted: listAllMOJsConsultant,
                        },
                        {
                            name: "readCompanyCredits",
                            granted: readCompanyCredits,
                        },
                        {
                            name: "updateConsultantDetails",
                            granted: updateConsultantDetails,
                        },
                    ],
                },
            ],
        };
    }, [params.idStaff]);

    const handleSubmit = useCallback(async () => {
        try {
            formRef.current?.setErrors({});

            showLoader("Saving changes...");

            const formData = getFormData();

            await api.post(
                `internalConsultant/manage-staff-permissions/${params.idStaff}`,
                formData,
            );

            toast.success("Permissions updated!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            if (err.response.data) {
                const { errors, success }: IMOJCustomError = err.response.data;

                if (!success && errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const element = errors[index];

                        toast.error(element, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            } else {
                toast.error(
                    "There was an error saving changes. PLease, try again!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            }
        } finally {
            hideLoader();
        }
    }, [showLoader, hideLoader, getFormData, params.idStaff]);

    const toLowerCase = (value: string): string => {
        return value.charAt(0).toLowerCase() + value.slice(1);
    };

    const getPermissions = useMemo(() => {
        // Internal Staff
        let readInternalDashboard = false;
        let readMOJOverview = false;
        let readMOJCharts = false;
        let readMOJApplications = false;
        let addCreditsToBranch = false;
        let manageBranches = false;
        let manageInternalConsultants = false;
        let readFinancialDashboard = false;
        let manageMOJCost = false;
        // CompanyAdmin Group
        let addConsultant = false;
        let buyCredits = false;
        let inviteConsultant = false;
        let manageConsultants = false;
        let readCompanyDashboard = false;
        // // Consultant Group
        let readConsultantDetails = false;
        let createCandidateMOJ = false;
        let listAllMOJsConsultant = false;
        let readCompanyCredits = false;
        let updateConsultantDetails = false;

        if (permissions && permissions.length > 0) {
            for (
                let indexPermissionGroup = 0;
                indexPermissionGroup < permissions.length;
                indexPermissionGroup++
            ) {
                const permissionGroup = permissions[indexPermissionGroup];

                for (
                    let indexPermission = 0;
                    indexPermission < permissionGroup.permissions.length;
                    indexPermission++
                ) {
                    const permission =
                        permissionGroup.permissions[indexPermission];

                    permission.name = toLowerCase(permission.name);

                    if (permission.name === "readInternalDashboard") {
                        readInternalDashboard = permission.granted;
                    } else if (permission.name === "readMOJOverview") {
                        readMOJOverview = permission.granted;
                    } else if (permission.name === "readMOJCharts") {
                        readMOJCharts = permission.granted;
                    } else if (permission.name === "readMOJApplications") {
                        readMOJApplications = permission.granted;
                    } else if (permission.name === "addCreditsToBranch") {
                        addCreditsToBranch = permission.granted;
                    } else if (permission.name === "manageBranches") {
                        manageBranches = permission.granted;
                    } else if (
                        permission.name === "manageInternalConsultants"
                    ) {
                        manageInternalConsultants = permission.granted;
                    } else if (permission.name === "readFinancialDashboard") {
                        readFinancialDashboard = permission.granted;
                    } else if (permission.name === "manageMOJCost") {
                        manageMOJCost = permission.granted;
                    } else if (permission.name === "addConsultant") {
                        addConsultant = permission.granted;
                    } else if (permission.name === "buyCredits") {
                        buyCredits = permission.granted;
                    } else if (permission.name === "inviteConsultant") {
                        inviteConsultant = permission.granted;
                    } else if (permission.name === "manageConsultants") {
                        manageConsultants = permission.granted;
                    } else if (permission.name === "readCompanyDashboard") {
                        readCompanyDashboard = permission.granted;
                    } else if (permission.name === "readConsultantDetails") {
                        readConsultantDetails = permission.granted;
                    } else if (permission.name === "createCandidateMOJ") {
                        createCandidateMOJ = permission.granted;
                    } else if (permission.name === "listAllMOJsConsultant") {
                        listAllMOJsConsultant = permission.granted;
                    } else if (permission.name === "readCompanyCredits") {
                        readCompanyCredits = permission.granted;
                    } else if (permission.name === "updateConsultantDetails") {
                        updateConsultantDetails = permission.granted;
                    }
                }
            }
        }

        setInitialData({
            readInternalDashboard,
            readMOJOverview,
            readMOJCharts,
            readMOJApplications,
            addCreditsToBranch,
            manageBranches,
            manageInternalConsultants,
            readFinancialDashboard,
            manageMOJCost,
            addConsultant,
            buyCredits,
            inviteConsultant,
            manageConsultants,
            readCompanyDashboard,
            readConsultantDetails,
            createCandidateMOJ,
            listAllMOJsConsultant,
            readCompanyCredits,
            updateConsultantDetails,
        });

        return permissions;
    }, [permissions]);

    return (
        <MainNew>
            <Container>
                <InvitationCard>
                    <InvitationTitle>Staff Permissions</InvitationTitle>
                    <InvitationDescription>{staffName}</InvitationDescription>
                    {initialData && (
                        <FormContent>
                            <Form
                                ref={formRef}
                                onSubmit={handleSubmit}
                                initialData={{ ...initialData }}
                            >
                                {getPermissions &&
                                    getPermissions.map(permissionGroupItem => {
                                        return (
                                            <PermissionGroupBox
                                                key={
                                                    permissionGroupItem.groupName
                                                }
                                            >
                                                <PermissionGroupBoxTitle>
                                                    <h3>
                                                        {`${permissionGroupItem.groupName} Group`}
                                                    </h3>
                                                </PermissionGroupBoxTitle>
                                                <PermissionGroupBoxInputs>
                                                    {permissionGroupItem.permissions.map(
                                                        permissionItem => (
                                                            <Checkbox
                                                                key={
                                                                    permissionItem.name
                                                                }
                                                                name={
                                                                    permissionItem.name
                                                                }
                                                                noBackground
                                                                noPadding
                                                            >
                                                                {
                                                                    permissionItem.description
                                                                }
                                                            </Checkbox>
                                                        ),
                                                    )}
                                                </PermissionGroupBoxInputs>
                                            </PermissionGroupBox>
                                        );
                                    })}
                                <Button type="submit">Save</Button>
                            </Form>
                        </FormContent>
                    )}
                </InvitationCard>
            </Container>
        </MainNew>
    );
};

export default InternalStaffPermissions;
