import React from "react";
// import { Link } from "react-router-dom";
// import { FaAddressBook } from "react-icons/fa";
import { FcLike } from "react-icons/fc";

import Header from "./Header";
import Sidebar from "./Sidebar";

import {
    Container,
    AppMain,
    AppContent,
    AppContentInner,
    AppWrapper,
    AppFooter,
    AppFooterWrapper,
    AppFooterCompany,
    AppFooterQuote,
} from "./styles";

const Main: React.FC = ({ children }) => {
    return (
        <Container>
            <Header />
            <AppMain>
                <Sidebar />
                <AppContent>
                    <AppContentInner tinyPadding>
                        <AppWrapper>{children}</AppWrapper>
                    </AppContentInner>
                    <AppFooter>
                        <AppFooterWrapper>
                            <AppFooterCompany>
                                <strong>BOSS Technologies</strong>
                            </AppFooterCompany>
                            <AppFooterQuote>
                                © {new Date().getFullYear()}. Made with{" "}
                                <FcLike size={18} /> for a better web
                            </AppFooterQuote>
                        </AppFooterWrapper>
                    </AppFooter>
                </AppContent>
            </AppMain>
        </Container>
    );
};

export default Main;
