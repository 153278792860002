import styled from "styled-components";

export const Container = styled.div`
    /* background: #eaf6bc; */
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
`;

export const DetailBoxTitle = styled.div`
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px #333 solid;
`;

export const DetailBoxTitleText = styled.span`
    color: #333;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
`;

export const Content = styled.div``;
