import React, {
    forwardRef,
    useCallback,
    useState,
    useImperativeHandle,
} from "react";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../services/api";

// Hooks
// import { useToast } from "../../../hooks/toast";
import { useLoader } from "../../../hooks/loader";

import {
    Container,
    ContentBox,
    Title,
    Row,
    CellContent,
    EmailContent,
    TextLabel,
    TextValue,
    AddressBox,
    AddressBoxTitle,
    AddressRow,
    AddressCell,
    ActionsRows,
    CloseButton,
    DeleteButton,
} from "./styles";

export interface IDeleteConsultantInterface {
    id: string;
    name: string;
    contact: string;
    email: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    totalInvitations: number;
    totalMOJsCreated: number;
    hasAnyPendingMOJ: boolean;
    totalPendingMOJs: number;
}

interface IConsultantInterface {
    consultant: IDeleteConsultantInterface;
    close(): void;
    delete(): Promise<void>;
}

export interface IModalHandles {
    openModal: () => void;
}

const ModalDeleteConsultant: React.RefForwardingComponent<
    IModalHandles,
    IDeleteConsultantInterface
> = (
    {
        id,
        name,
        contact,
        email,
        streetAddress,
        suburb,
        city,
        state,
        postcode,
        totalInvitations,
        totalMOJsCreated,
        hasAnyPendingMOJ,
        totalPendingMOJs,
    },
    ref,
) => {
    const [visible, setVisible] = useState(false);

    // const { addToast } = useToast();
    const { showLoader, hideLoader } = useLoader();

    const openModal = useCallback(() => {
        setVisible(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
        };
    });

    const handleCloseModal = useCallback(() => {
        setVisible(false);
    }, []);

    const handleDeleteConsultant = useCallback(async () => {
        try {
            showLoader("Processing...");

            await api.post(`company/delete-consultant/${id}`, {
                id,
                IdCountry: "00000000-0000-0000-0000-000000000000",
                contactNumber: contact,
                email,
            });

            // addToast({
            //     type: "success",
            //     title: "Consultant deleted!",
            //     description: "Consultant has been deleted successfully!",
            // });
            toast.success("Consultant has been deleted successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            window.location.reload();
        } catch (err) {
            // addToast({
            //     type: "error",
            //     title: "Error when deleting consultant",
            //     description:
            //         "There was an error deleting consultant. Please, try again!",
            // });

            hideLoader();

            toast.error(
                "There was an error deleting consultant. Please, try again!",
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },
            );
        }
    }, [id, showLoader, hideLoader, contact, email]);

    if (!visible) {
        return null;
    }

    return (
        <Container>
            <ContentBox>
                <Title>
                    <span>Consultant Details</span>
                </Title>
                <Row>
                    <CellContent>
                        <TextLabel>Name:</TextLabel>
                        <TextValue>{name}</TextValue>
                    </CellContent>
                    <CellContent>
                        <TextLabel>Contact:</TextLabel>
                        <TextValue>{contact}</TextValue>
                    </CellContent>
                </Row>
                <Row>
                    <EmailContent>
                        <TextLabel>Email:</TextLabel>
                        <TextValue>{email}</TextValue>
                    </EmailContent>
                </Row>
                <AddressBox>
                    <AddressBoxTitle>
                        <span>Address</span>
                    </AddressBoxTitle>
                    <AddressRow>
                        <AddressCell>
                            <TextLabel>Street:</TextLabel>
                            <TextValue>{streetAddress}</TextValue>
                        </AddressCell>
                    </AddressRow>
                    <AddressRow>
                        <AddressCell>
                            <TextLabel>Suburb:</TextLabel>
                            <TextValue>{suburb}</TextValue>
                        </AddressCell>
                    </AddressRow>
                    <AddressRow>
                        <AddressCell>
                            <TextLabel>City:</TextLabel>
                            <TextValue>{city}</TextValue>
                        </AddressCell>
                    </AddressRow>
                    <AddressRow>
                        <AddressCell>
                            <TextLabel>Region:</TextLabel>
                            <TextValue>{state}</TextValue>
                        </AddressCell>
                    </AddressRow>
                    <AddressRow>
                        <AddressCell>
                            <TextLabel>Postcode:</TextLabel>
                            <TextValue>{postcode}</TextValue>
                        </AddressCell>
                    </AddressRow>
                </AddressBox>
                <Row>
                    <CellContent>
                        <TextLabel>Total invitations sent:</TextLabel>
                        <TextValue>{totalInvitations}</TextValue>
                    </CellContent>
                    <CellContent>
                        <TextLabel>Total MOJs created:</TextLabel>
                        <TextValue>{totalMOJsCreated}</TextValue>
                    </CellContent>
                </Row>
                <Row>
                    <CellContent>
                        <TextLabel>Has any pending MOJ:</TextLabel>
                        <TextValue>{hasAnyPendingMOJ ? "Yes" : "No"}</TextValue>
                    </CellContent>
                    <CellContent>
                        <TextLabel>All pending MOJs:</TextLabel>
                        <TextValue>{totalPendingMOJs}</TextValue>
                    </CellContent>
                </Row>
                <ActionsRows>
                    <CloseButton onClick={handleCloseModal}>Close</CloseButton>
                    <DeleteButton onClick={handleDeleteConsultant}>
                        Delete
                    </DeleteButton>
                </ActionsRows>
            </ContentBox>
        </Container>
    );
};

export default forwardRef(ModalDeleteConsultant);
