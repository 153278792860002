import React, { createContext, useCallback, useState, useContext } from "react";
import jwt_decode from "jwt-decode";
import api from "../services/api";

interface IUser {
    id: string;
    email: string;
    firstName: string;
    middleName?: string;
    lastName?: string;
    showInstructions: boolean;
    userType: number;
    claims: IUserClaims[];
    role: string;
}

export enum UserType {
    sysAdmin = 1,
    customer = 2,
    consultant = 4,
    candidate = 5,
    internalCompanyAdmin = 6,
}

interface IAuthState {
    accessToken: string;
    user: IUser;
}

interface ISignInCredentials {
    email: string;
    password: string;
}

interface IAuthContextData {
    user: IUser;
    signIn(credentials: ISignInCredentials): Promise<void>;
    signOut(): void;
    updateUser(user: IUser): void;
    isUserAllowed(action: string): boolean;
}

interface IUserClaims {
    value: string;
    type: string;
}

interface ITokenDTO {
    aud: string;
    email: string;
    exp: number;
    iat: number;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
    const [data, setData] = useState<IAuthState>(() => {
        const accessToken = localStorage.getItem("@BossMOJ:token");
        const user = localStorage.getItem("@BossMOJ:user");

        if (accessToken) {
            const { exp }: ITokenDTO = jwt_decode(accessToken);

            if (exp < Date.now() / 1000) {
                // Removing User Data from LocalStorage if token is expired
                localStorage.removeItem("@BossMOJ:token");
                localStorage.removeItem("@BossMOJ:user");

                return {} as IAuthState;
            }
        }

        if (accessToken && user) {
            // Will set automatically the token when you sign in the app

            api.defaults.headers.authorization = `Bearer ${accessToken}`;

            return { accessToken, user: JSON.parse(user) };
        }

        return {} as IAuthState;
    });

    const getUserRole = useCallback((user: any) => {
        if (user) {
            const userClaims = (user as IUser).claims;

            if (userClaims) {
                const role = userClaims.find(claim => claim.type === "role");

                if (role) {
                    return role.value;
                }
            }
        }

        return "";
    }, []);

    const signIn = useCallback(
        async ({ email, password }) => {
            const response = await api.post("account/login", {
                email,
                password,
            });

            const { accessToken, user } = response.data.data;

            const userRole = getUserRole(user);
            user.role = userRole;

            localStorage.setItem("@BossMOJ:token", accessToken);
            localStorage.setItem("@BossMOJ:user", JSON.stringify(user));

            // Clean MOJ Draft
            localStorage.removeItem("@BossMOJ:draftMOJ");

            // Will set automatically the token when you sign in the app
            api.defaults.headers.authorization = `Bearer ${accessToken}`;

            setData({ accessToken, user });
        },
        [getUserRole],
    );

    const signOut = useCallback(() => {
        localStorage.removeItem("@BossMOJ:token");
        localStorage.removeItem("@BossMOJ:user");
        localStorage.removeItem("@BossMOJ:draftMOJ");

        setData({} as IAuthState);
    }, []);

    const updateUser = useCallback(
        (user: IUser) => {
            localStorage.setItem("@BossMOJ:user", JSON.stringify(user));

            setData({
                accessToken: data.accessToken,
                user,
            });
        },
        [setData, data.accessToken],
    );

    const isUserAllowed = useCallback(
        (action: string): boolean => {
            if (data && data.user) {
                const rulesClaims = data.user.claims.find(
                    claim => claim.type.toLowerCase() === "rules",
                );

                if (rulesClaims) {
                    return rulesClaims.value
                        .toLowerCase()
                        .includes(action.toLowerCase());
                }
            }

            return false;
        },
        [data],
    );

    return (
        <AuthContext.Provider
            value={{
                user: data.user,
                signIn,
                signOut,
                updateUser,
                isUserAllowed,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): IAuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
}

export { AuthProvider, useAuth };
