import React, {
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useLoader } from "../../hooks/loader";
// import { useToast } from "../../hooks/toast";

// MasterPage
import MainNew from "../MainNew";

// Common Components
import InputWithLabel from "../../components/InputWithLabel";
import RadioInput from "../../components/RadioInput";

// Common Types
import { IRadioOption, IMOJCustomError } from "../../types/globalTypes";

import {
    Container,
    ProductTypeContent,
    ProductTypeContentTitle,
    ProductTypeContentDescription,
    FormContent,
    ProductsContainer,
    ProductsPanel,
    ProductsContainerTitle,
    ProductBox,
    ProductBoxHeader,
    ProductBoxHeaderTitle,
    ProductBoxBody,
    ProductBoxBodyMOJCredits,
    ProductBoxBodyProcessingDays,
    ProductBoxBodyPrice,
    ProductBoxFooter,
} from "./styles";
import { ensureObjectExists } from "../../utils/arrayUtilities";

interface IProduct {
    id: string;
    name: string;
    processingDays: number;
    mojCreditsQuantity: number;
    mojTypeService: number;
    pricePlusGST: number;
}

const BuyCredits: React.FC = () => {
    const [products, setProducts] = useState<IProduct[]>([]);
    const [productsToShow, setProductsToShow] = useState<IProduct[]>([]);
    const [selectedService, setSelectedService] = useState("");

    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();
    const { showLoader, hideLoader } = useLoader();

    const radioOptions: IRadioOption[] = [
        { id: "1", value: "1", label: "Gold" },
        { id: "2", value: "2", label: "Silver" },
        { id: "3", value: "3", label: "Bronze" },
    ];

    const fetchData = useCallback(async () => {
        showLoader("Processing...");

        await api.get("home/GetMOJsProductsCompany").then(response => {
            const { data } = response;

            if (data && data.success) {
                if (data && data.success && data.data) {
                    const productsResponse: IProduct[] = data.data;

                    setProducts(productsResponse);
                }
            }
        });

        hideLoader();
    }, [showLoader, hideLoader]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMOJProducts = useMemo(() => {
        return products;
    }, [products]);

    const getProductsToShow = useMemo(() => {
        return productsToShow;
    }, [productsToShow]);

    const getProductType = useCallback(
        (idProduct: string): "bronze" | "silver" | "gold" => {
            const existingProduct = ensureObjectExists(
                products.find(productItem => productItem.id === idProduct),
            );

            if (existingProduct.mojTypeService === 1) {
                return "gold";
            }

            if (existingProduct.mojTypeService === 2) {
                return "silver";
            }

            return "bronze";
        },
        [products],
    );

    const handleProductChange = useCallback(() => {
        if (formRef && formRef.current) {
            setProductsToShow([]);

            const productTypeRadioValue: string = formRef.current.getFieldValue(
                "productType",
            );

            if (productTypeRadioValue) {
                if (productTypeRadioValue === "1") {
                    setSelectedService("Gold");
                } else if (productTypeRadioValue === "2") {
                    setSelectedService("Silver");
                } else if (productTypeRadioValue === "3") {
                    setSelectedService("Bronze");
                }

                const filteredProducts = getMOJProducts
                    .filter(prodItem => {
                        return (
                            prodItem.mojTypeService ===
                            Number(productTypeRadioValue)
                        );
                    })
                    .sort((a, b) => (a.pricePlusGST > b.pricePlusGST ? 1 : -1));

                setProductsToShow(filteredProducts);
            }
        }
    }, [getMOJProducts]);

    const handleBuyCredits = useCallback(
        async (idProduct: string) => {
            let purchaseNumber = "";

            if (formRef && formRef.current) {
                const purchaseNumberValue: string = formRef.current.getFieldValue(
                    "purchaseNumber",
                );

                if (purchaseNumberValue) {
                    purchaseNumber = purchaseNumberValue;
                }
            }

            const objData = {
                idProduct,
                purchaseNumber,
            };

            showLoader("Processing your order...");

            await api
                .post("company/buycredits", objData)
                .then(response => {
                    if (response.data && response.data.success) {
                        window.location.href = response.data.data;
                    }
                })
                .catch(error => {
                    hideLoader();

                    const { success }: IMOJCustomError = error.response.data;

                    if (!success) {
                        toast.error(
                            "There was an error processing your purchase. Please, try again!",
                            {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            },
                        );
                    }
                });
        },
        [showLoader, hideLoader],
    );

    const handleSubmit = useCallback(() => {
        // mirror form
    }, []);

    return (
        <MainNew>
            <Container>
                <ProductTypeContent>
                    <ProductTypeContentTitle>
                        Buy criminal check credits
                    </ProductTypeContentTitle>
                    <ProductTypeContentDescription>
                        Choose below the type of service you want to buy credits
                    </ProductTypeContentDescription>
                    <FormContent>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <RadioInput
                                name="productType"
                                options={radioOptions}
                                noBackground
                                onChange={handleProductChange}
                                inline
                            />
                            <InputWithLabel
                                name="purchaseNumber"
                                label="Purchase Number"
                                placeholder="Purchase order number (optional)"
                            />
                        </Form>
                    </FormContent>
                </ProductTypeContent>

                {getProductsToShow && (
                    <ProductsContainer>
                        <ProductsContainerTitle>
                            Purchase options for {selectedService} service
                        </ProductsContainerTitle>
                        <ProductsPanel>
                            {getProductsToShow.map(product => {
                                const productType = getProductType(product.id);

                                return (
                                    <ProductBox
                                        key={product.id}
                                        type={productType}
                                    >
                                        <ProductBoxHeader type={productType}>
                                            <ProductBoxHeaderTitle
                                                type={productType}
                                            >
                                                <span>{product.name}</span>
                                            </ProductBoxHeaderTitle>
                                        </ProductBoxHeader>
                                        <ProductBoxBody type={productType}>
                                            <ProductBoxBodyProcessingDays>
                                                <span>
                                                    {product.processingDays}
                                                </span>
                                                <small>
                                                    days processing time
                                                </small>
                                            </ProductBoxBodyProcessingDays>
                                            <ProductBoxBodyMOJCredits>
                                                <span>
                                                    {product.mojCreditsQuantity}
                                                </span>
                                                <small>
                                                    {`${
                                                        product.mojCreditsQuantity >
                                                        1
                                                            ? "checks"
                                                            : "check"
                                                    }`}
                                                </small>
                                            </ProductBoxBodyMOJCredits>
                                            <ProductBoxBodyPrice
                                                type={productType}
                                            >
                                                <span className="signal">
                                                    $
                                                </span>
                                                <span>
                                                    {product.pricePlusGST}
                                                </span>
                                                <small> incl. GST</small>
                                            </ProductBoxBodyPrice>
                                        </ProductBoxBody>
                                        <ProductBoxFooter type={productType}>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleBuyCredits(product.id)
                                                }
                                            >
                                                Purchase now
                                            </button>
                                        </ProductBoxFooter>
                                    </ProductBox>
                                );
                            })}
                        </ProductsPanel>
                    </ProductsContainer>
                )}
            </Container>
        </MainNew>
    );
};

export default BuyCredits;
