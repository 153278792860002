import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    background: #fff;
    max-width: 1200px;

    margin: 30px 0;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    div.jss423 {
        padding: 40px 10px;

        div.jss422 {
            width: 100%;
            max-width: 960px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 15px;
            padding-right: 15px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                color: #b2d235;
                margin: 30px 0;
                display: flex;
                font-weight: 400;
                font-size: 3.75rem;
                line-height: 3.75rem;
            }
        }
    }

    .form-content {
        form {
            margin-top: 20px;
        }

        p {
            color: #333;
            font-size: 14px;
            margin: 0 0 20px;
        }

        button {
            margin-bottom: 30px;
        }
    }
`;
