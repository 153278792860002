import React, {
    InputHTMLAttributes,
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from "react";
import { useField } from "@unform/core";

import { IconBaseProps } from "react-icons";
import { FiAlertCircle } from "react-icons/fi";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";

import { Container, IconArrowContainer, Error } from "./styles";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    inline?: boolean;
    label: string;
    data: IDataOptions[];
    noOptionLabel: string;
}

interface IDataOptions {
    value: string;
    label: string;
}

const InputWithLabel: React.FC<IInputProps> = ({
    name,
    inline,
    icon: Icon,
    label,
    data,
    noOptionLabel,
    ...rest
}) => {
    const selectRef = useRef(null);
    const optionsContainerRef = useRef<HTMLDivElement>(null);
    const selectedRef = useRef<HTMLDivElement>(null);

    const [selectedItem, setSelectedItem] = useState("");
    const [selectedItemText, setSelectedItemText] = useState(noOptionLabel);
    const [isFilled, setIsFilled] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const { fieldName, defaultValue, error, registerField } = useField(name);

    const { readOnly } = rest;

    useEffect(() => {
        if (defaultValue) {
            setSelectedItem(defaultValue);

            const optionItem = data.find(item => item.value === defaultValue);

            if (optionItem && selectedRef && selectedRef.current) {
                // selectedRef.current.innerHTML = optionItem.label;
                setSelectedItemText(optionItem.label);
                setIsFilled(true);
            }
        }
    }, [defaultValue, data]);

    useEffect(() => {
        registerField({
            name: fieldName,
            // Within "current" is our "input"
            ref: selectRef.current,
            // When needs to get input value, we access by "path"
            // path: "value",
            getValue: () => {
                return selectedItem;
            },
        });
    }, [fieldName, registerField, selectedItem]);

    const selected = document.querySelector(".selected");

    const optionsList = document.querySelectorAll(".option");

    useEffect(() => {
        optionsList.forEach(o => {
            o.addEventListener("click", () => {
                if (selected) {
                    const labelEl = o.querySelector("label");

                    const inputEl = o.querySelector("input");

                    if (inputEl) {
                        setSelectedItem(inputEl.id);
                        setIsActive(false);
                        setIsFilled(true);
                    }

                    if (labelEl) {
                        // selected.innerHTML = labelEl.innerHTML;
                        setSelectedItemText(labelEl.innerHTML);
                        optionsContainerRef.current?.classList.remove("active");
                    }
                }
            });
        });
    }, [optionsList, selected, isActive]);

    const toggleActive = useCallback(() => {
        if (optionsContainerRef && optionsContainerRef.current) {
            optionsContainerRef.current.classList.toggle("active");
        }

        setIsActive(!isActive);
    }, [isActive]);

    const getOptionsData = useMemo(() => {
        // data={getBranches}

        return data;
    }, [data]);

    return (
        <Container
            isErrored={!!error}
            isFilled={isFilled}
            isReadOnly={readOnly || false}
            inline={inline}
        >
            <label className="input-label">{label}</label>
            {Icon && <Icon size={20} />}
            <div className="input-select">
                <div className="select-box">
                    <div
                        ref={optionsContainerRef}
                        className="options-container"
                    >
                        {getOptionsData.map(item => (
                            <div key={item.value} className="option">
                                <input
                                    id={item.value}
                                    name={item.value}
                                    type="radio"
                                    className="radio"
                                />
                                <label htmlFor={item.value}>{item.label}</label>
                            </div>
                        ))}
                    </div>
                    <div
                        ref={selectedRef}
                        className="selected"
                        onClick={toggleActive}
                    >
                        {/* <ArrowIcon /> */}
                        <IconArrowContainer>
                            {isActive ? (
                                <RiArrowUpSFill size={16} />
                            ) : (
                                <RiArrowDownSFill size={16} />
                            )}
                        </IconArrowContainer>
                        {selectedItemText}
                    </div>
                </div>
            </div>
            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default InputWithLabel;
