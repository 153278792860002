import React, { useState, useCallback, useRef } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { FiMail } from "react-icons/fi";
import * as Yup from "yup";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useLoader } from "../../hooks/loader";
// import { useToast } from "../../hooks/toast";

// MasterPage
import MainNew from "../MainNew";

// Common Components
import InputWithLabel from "../../components/InputWithLabel";
import ButtonLoader from "../../components/ButtonLoader";
import getValidationErrors from "../../utils/getValidationErrors";

// Common Types
import { IMOJCustomError } from "../../types/globalTypes";

import {
    Container,
    InvitationCard,
    InvitationTitle,
    InvitationDescription,
    FormContent,
} from "./styles";

interface IInviteConsultantFormData {
    email: string;
}

const InviteConsultants: React.FC = () => {
    const [processingRequest, setProcessingRequest] = useState(false);

    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();
    const { showLoader, hideLoader } = useLoader();

    const yupEmailMatchValidation = (confirmEmail: string): boolean => {
        if (formRef && formRef.current) {
            const emailValue: string = formRef.current.getFieldValue("email");
            return emailValue.toLowerCase() === confirmEmail.toLowerCase();
        }

        return false;
    };

    const handleSubmit = useCallback(
        async (data: IInviteConsultantFormData, { reset }) => {
            try {
                setProcessingRequest(true);

                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail address is required")
                        .email("E-mail address invalid"),
                    // confirmEmail: Yup.string().when("email", {
                    //     is: val => val && val.length > 0,
                    //     then: Yup.string()
                    //         .oneOf(
                    //             [Yup.ref("email")],
                    //             "E-mail and e-mail confirmation do not match",
                    //         )
                    //         .required(),
                    // }),
                    confirmEmail: Yup.string()
                        .email()
                        .test(
                            "emailMatch",
                            "E-mail and e-mail confirmation do not match",
                            value => yupEmailMatchValidation(value),
                        ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Sending email...");

                await api.post("Company/inviteConsultant", data);

                // addToast({
                //     type: "success",
                //     title: "Consultant invited!",
                //     description: `We sent an email to ${data.email}!`,
                // });
                toast.success(`We sent an email to ${data.email}!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // clean field e-mail
                reset();
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        // errors.map(error => {
                        //     return addToast({
                        //         type: "error",
                        //         title: "Error when authenticating",
                        //         description: error,
                        //     });
                        // });

                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    // addToast({
                    //     type: "error",
                    //     title: "Error when authenticating",
                    //     description:
                    //         "There was an error signing in, check your credentials!",
                    // });
                    toast.error(
                        "There was an error signing in, check your credentials!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                setProcessingRequest(false);
                hideLoader();
            }
        },
        [showLoader, hideLoader],
    );

    return (
        <MainNew>
            <Container>
                <InvitationCard>
                    <InvitationTitle>Invite Staff</InvitationTitle>
                    <InvitationDescription>
                        Send an invite to another user to give them access to
                        this account
                    </InvitationDescription>
                    <FormContent>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <InputWithLabel
                                name="email"
                                icon={FiMail}
                                label="E-mail"
                                placeholder="E-mail *"
                                autoFocus
                            />
                            <InputWithLabel
                                name="confirmEmail"
                                icon={FiMail}
                                label="Confirm E-mail"
                                placeholder="Confirm your E-mail *"
                            />
                            <ButtonLoader
                                type="submit"
                                processingRequest={processingRequest}
                            >
                                Send invite
                            </ButtonLoader>
                        </Form>
                    </FormContent>
                </InvitationCard>
            </Container>
        </MainNew>
    );
};

export default InviteConsultants;
