import React from "react";

import bossLogo from "../../../assets/boss-logo.png";

import CompanyCredits from "./CompanyCredits";
import UserMenu from "./UserMenu";

// Commo Types
import UrlsAddress from "../../../types/urlsAddress";

import {
    Container,
    HeaderLogo,
    HeaderLogoWrapper,
    HeaderToolbar,
} from "./styles";

const Header: React.FC = () => {
    return (
        <Container>
            <HeaderLogo>
                <HeaderLogoWrapper>
                    {/* <a href="/dashboard"> */}
                    <a href={UrlsAddress.DASHBOARD}>
                        <button type="button">
                            <span className="label">
                                <img src={bossLogo} alt="BOSS" />
                            </span>
                        </button>
                    </a>
                </HeaderLogoWrapper>
            </HeaderLogo>
            <HeaderToolbar>
                <CompanyCredits />
                <UserMenu />
            </HeaderToolbar>
        </Container>
    );
};

export default Header;
