import styled from "styled-components";

interface IBadgeProps {
    bgColor?: string;
    color?: string;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    /* align-items: center; */
    width: 100%;
`;

export const LinkRow = styled.div`
    display: flex;
    width: 100%;
    /* padding: 8px; */
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem;

    @media only screen and (min-width: 400px) {
    }

    svg {
        margin-right: 8px;
    }

    a {
        color: #333;
        /* text-transform: uppercase; */
        font-weight: 400;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    gap: 16px;
`;

export const SearchBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    border-radius: 0.5rem;

    background: #fff;
`;

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    > div {
        flex: 1;
        margin: 0;
    }
`;

export const SearchButton = styled.button`
    width: 100%;
    border: none;
    background: var(--color-green);
    color: #333;
    font-weight: 500;
    padding: 0.5rem;
    border-radius: 0.5rem;
    /* font-size: 0.75rem; */
    margin-top: 0.5rem;
    text-transform: uppercase;

    &:hover {
        font-weight: 500;
        background: var(--color-green-hover);
    }
`;

export const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const InfoBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.2rem;
`;

export const InfoBoxLabel = styled.label`
    font-weight: 700;
    line-height: 1.2rem;
    margin: 0 0.5rem;
`;

export const Badge = styled.span<IBadgeProps>`
    letter-spacing: 0.3px;

    color: ${props => props.color};

    background-color: ${props =>
        props.bgColor ? props.bgColor : "transparent"};

    display: inline-block;
    width: 100%;
    padding: 4px 8px;
    font-size: 85%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const ActionsCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a,
    button {
        color: #333;
        background: transparent;
        padding: 5px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.7;
        }
    }
`;

export const GridActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        border: none;
        font-weight: 600;
        color: #333;
        background: #b2d235;
        padding: 0.5rem;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        margin-top: 0.5rem;
    }
`;
