// Icons
import { IconBaseProps } from "react-icons";
import { BiBuildings } from "react-icons/bi";
import { BsCardChecklist } from "react-icons/bs";
import { FaUsers, FaCreditCard, FaRegMoneyBillAlt } from "react-icons/fa";
import { MdDashboard, MdGroupAdd } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { RiMailAddLine } from "react-icons/ri";

// Users Actions
import ActionTypes from "./userActions";

// URLs
import UrlsAddress from "./urlsAddress";

export interface IMenuLinks {
    position: number;
    route: string;
    text: string;
    hasChildren?: boolean;
    icon?: React.ComponentType<IconBaseProps>;
    action: string;
}

export const BossAdminLinks: IMenuLinks[] = [
    // READ_SYSTEM_DASHBOARD = "readSystemDashboard",
    {
        position: 1,
        // route: "/dashboard",
        route: UrlsAddress.DASHBOARD,
        text: "Dashboard",
        hasChildren: false,
        icon: MdDashboard,
        action: ActionTypes.ADMIN_READ_SYSTEM_DASHBOARD,
    },
    // ADMIN_GET_ALL_PRODUCTS = "getAllProducts",
    // ADMIN_CREATE_PAYPAL_WEBPROFILE_FROM_PRODUCT = "createPayPalWebProfileFromProduct",
    // ADMIN_CREATE_PAYPAL_WEBPROFILES_FOR_ALL_PRODUCTS = "createPayPalWebProfilesForAllProducts",
    // ADMIN_DELETE_PAYPAL_WEBPROFILES_FOR_ALL_PRODUCTS = "deletePayPalWebProfilesForAllProducts",
    // ADMIN_DELETE_PAYPAL_WEBPROFILE_FROM_PRODUCT = "deletePayPalWebProfileFromProduct",
    // ADMIN_CREATE_PRODUCT = "createProduct",
    // ADMIN_MANAGE_ROLES = "manageRoles",
    // ADMIN_MANAGE_USERS = "manageUsers",
    // ADMIN_LIST_ORDERS = "listOrders",
    // ADMIN_MANAGE_COMPANIES = "manageCompanies",
];

export const CandidateLinks: IMenuLinks[] = [
    {
        position: 1,
        route: UrlsAddress.DASHBOARD,
        text: "Dashboard",
        hasChildren: false,
        icon: MdDashboard,
        action: ActionTypes.CANDIDATE_LIST_ALL_CANDIDATE_MOJS,
    },
    {
        position: 2,
        route: UrlsAddress.PROFILE,
        text: "Profile",
        hasChildren: false,
        icon: ImProfile,
        action: ActionTypes.CANDIDATE_UPDATE_CANDIDATE_DETAILS,
    },
];

export const ConsultantLinks: IMenuLinks[] = [
    {
        position: 1,
        route: UrlsAddress.DASHBOARD,
        text: "Dashboard",
        hasChildren: false,
        icon: MdDashboard,
        action: ActionTypes.CONSULTANT_LIST_ALL_MOJS_CONSULTANT,
    },
    {
        position: 2,
        route: UrlsAddress.PROFILE,
        text: "Profile",
        hasChildren: false,
        icon: ImProfile,
        action: ActionTypes.CONSULTANT_READ_CONSULTANT_DETAILS,
    },
    {
        position: 3,
        route: UrlsAddress.BUY_CREDITS,
        text: "Buy Credits",
        hasChildren: false,
        icon: FaCreditCard,
        action: ActionTypes.COMPANY_ADMIN_BUY_CREDITS,
    },
    {
        position: 4,
        route: UrlsAddress.STAFF,
        text: "Staff",
        hasChildren: false,
        icon: FaUsers,
        action: ActionTypes.COMPANY_ADMIN_MANAGE_CONSULTANTS,
    },
    {
        position: 5,
        route: UrlsAddress.INVITE_STAFF,
        text: "Invite Staff",
        hasChildren: false,
        icon: MdGroupAdd,
        action: ActionTypes.COMPANY_ADMIN_INVITE_CONSULTANT,
    },
    {
        position: 6,
        route: UrlsAddress.INVITE_CANDIDATES,
        text: "Send MOJ Request",
        hasChildren: false,
        icon: RiMailAddLine,
        action: ActionTypes.CONSULTANT_CREATE_CANDIDATE_MOJ,
    },
];

export const CustomerLinks: IMenuLinks[] = [
    {
        position: 1,
        route: UrlsAddress.DASHBOARD,
        text: "Dashboard",
        hasChildren: false,
        icon: MdDashboard,
        action: ActionTypes.CUSTOMER_CREATE_CUSTOMER_MOJ,
    },
    {
        position: 2,
        route: UrlsAddress.PROFILE,
        text: "Profile",
        hasChildren: false,
        icon: ImProfile,
        action: ActionTypes.CUSTOMER_UPDATE_CUSTOMER_DETAILS,
    },
];

export const InternalConsultantLinks: IMenuLinks[] = [
    // INTERNAL_CONSULTANT_READ_MOJ_OVERVIEW = "readMOJOverview",
    // INTERNAL_CONSULTANT_READ_MOJ_CHARTS = "readMOJCharts",
    // INTERNAL_CONSULTANT_READ_MOJ_APPLICATIONS = "readMOJApplications",
    {
        position: 1,
        route: UrlsAddress.DASHBOARD,
        text: "Dashboard",
        hasChildren: false,
        icon: MdDashboard,
        action: ActionTypes.INTERNAL_CONSULTANT_READ_DASHBOARD,
    },
    {
        position: 2,
        route: UrlsAddress.PROFILE,
        text: "Profile",
        hasChildren: false,
        icon: ImProfile,
        action: ActionTypes.CONSULTANT_READ_CONSULTANT_DETAILS,
    },
    // INTERNAL_CONSULTANT_ADD_CREDITS_BRANCH = "addCreditsToBranch",
    // {
    //     position: 3,
    //     route: UrlsAddress.INTERNAL_ADD_CREDITS_BRANCH,
    //     text: "Add credits to branch",
    //     hasChildren: false,
    //     icon: BsCardChecklist,
    //     action: ActionTypes.INTERNAL_CONSULTANT_ADD_CREDITS_BRANCH,
    // },
    {
        position: 3,
        route: UrlsAddress.INTERNAL_MANAGE_BRANCHES,
        text: "Branches",
        hasChildren: false,
        icon: BiBuildings,
        action: ActionTypes.INTERNAL_CONSULTANT_MANAGE_BRANCHES,
    },
    // INTERNAL_CONSULTANT_MANAGE_INTERNAL_CONSULTANTS = "manageInternalConsultants",
    {
        position: 4,
        route: UrlsAddress.INTERNAL_STAFF.replace("/:branch?", "/all"),
        text: "Staff",
        hasChildren: false,
        icon: FaUsers,
        action: ActionTypes.INTERNAL_CONSULTANT_MANAGE_INTERNAL_CONSULTANTS,
    },
    {
        position: 5,
        route: UrlsAddress.INVITE_CANDIDATES,
        text: "Send MOJ Request",
        hasChildren: false,
        icon: RiMailAddLine,
        action: ActionTypes.CONSULTANT_CREATE_CANDIDATE_MOJ,
    },
    // INTERNAL_CONSULTANT_MANAGE_BRANCHES = "manageBranches",
    // {
    //     position: 6,
    //     route: UrlsAddress.INTERNAL_MANAGE_BRANCHES,
    //     text: "Branches",
    //     hasChildren: false,
    //     icon: BiBuildings,
    //     action: ActionTypes.INTERNAL_CONSULTANT_MANAGE_BRANCHES,
    // },
    // INTERNAL_CONSULTANT_READ_FINANCIAL_DASHBOARD = "readFinancialDashboard",
    {
        position: 6,
        route: UrlsAddress.INTERNAL_FINANCIAL_DASHBOARD,
        text: "Financial Dashboard",
        hasChildren: false,
        icon: FaRegMoneyBillAlt,
        action: ActionTypes.INTERNAL_CONSULTANT_READ_FINANCIAL_DASHBOARD,
    },
    {
        position: 7,
        route: UrlsAddress.INTERNAL_MANAGE_MOJ_COST,
        text: "MOJ Costs",
        hasChildren: false,
        icon: FaRegMoneyBillAlt,
        action: ActionTypes.INTERNAL_CONSULTANT_MANAGE_MOJ_COSTS,
    },
    //
    {
        position: 8,
        route: UrlsAddress.INTERNAL_ADD_STAFF,
        text: "Add Staff",
        hasChildren: false,
        icon: MdGroupAdd,
        action: ActionTypes.INTERNAL_CONSULTANT_MANAGE_INTERNAL_CONSULTANTS,
    },
];
