import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    background: #fff;
    border-radius: 10px;
    padding: 25px;

    form {
        max-width: 900px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
`;

export const TypeOfReportOptions = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
        font-weight: 500;
        margin-right: 8px;
    }
`;

export const AuthorisationToReleaseMOJBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;

    span {
        font-weight: 500;
    }

    & > div {
        margin: 0;
    }
`;

export const SignatureBox = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-weight: 500;
    }
`;

export const SignatureTitle = styled.div`
    /* display: flex;
    align-self: flex-start; */
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 2px solid #4db8ff;
    margin: 10px 0;
    border-radius: 10px;

    svg {
        margin-right: 8px;

        @media only screen and (min-width: 400px) {
            margin-right: 16px;
        }

        min-width: 25px;
        width: 30px;
        height: 30px;
        color: #4db8ff;
    }

    span {
        text-align: start;
    }
`;

export const Warning = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 2px solid #c53030;
    margin: 10px 0;
    border-radius: 10px;

    svg {
        margin-right: 16px;
        min-width: 25px;
        width: 30px;
        height: 30px;
        color: #c53030;
    }

    span {
        text-align: start;
        color: #c53030;
    }
`;

export const SignatureInputs = styled.div``;

export const SignatureBoxDrawingInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    canvas {
        cursor: crosshair;
        border: 2px #000 solid;

        @media only screen and (min-width: 400px) {
            width: 350px;
        }
    }
`;

export const SingatureActions = styled.div`
    margin: 8px 0;
    display: flex;
`;

export const SignatureActionClear = styled.button`
    display: flex;
    align-items: center;
    background: #c53030;
    border: none;
    padding: 8px;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;

    svg {
        color: #fff;
        margin-right: 8px;
    }

    &:hover {
        background: ${shade(0.2, "#c53030")};
    }
`;

export const SignatureActionSave = styled.button`
    display: flex;
    align-items: center;
    background: #b2d235;
    border: none;
    padding: 8px;
    color: #333;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;
    margin-left: 16px;

    svg {
        color: #333;
        margin-right: 8px;
    }

    &:hover {
        background: ${shade(0.2, "#b2d235")};
    }
`;

export const SignatureBoxDisplay = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    span {
        font-size: 24px;
        font-weight: 500;
    }

    img {
        max-width: 350px;
        height: auto;
        background: #ced4da;
        border: 2px #000 solid;
    }
`;
