import styled from "styled-components";
import { shade } from "polished";

interface IInputFileProps {
    invalid: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    background: #fff;
    border-radius: 10px;
    padding: 25px;

    form {
        max-width: 900px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
`;

export const Warning = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 2px solid #c53030;
    margin: 10px 0;
    border-radius: 10px;

    svg {
        margin-right: 16px;
        min-width: 25px;
        width: 30px;
        height: 30px;
        color: #c53030;
    }

    span {
        text-align: start;
    }
`;

export const OptionsLabel = styled.div`
    display: flex;
    justify-content: start;

    margin: 8px 0;

    span {
        font-size: 24px;
        font-weight: 500;
    }
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 2px solid #4db8ff;
    margin: 10px 0;
    border-radius: 10px;

    svg {
        margin-right: 8px;

        @media only screen and (min-width: 400px) {
            margin-right: 16px;
        }

        min-width: 25px;
        width: 30px;
        height: 30px;
        color: #4db8ff;
    }

    span {
        text-align: start;
    }
`;

export const LinkDownloadIdentificationForm = styled.a`
    align-items: center;
    padding: 16px;
    background: #b2d235;
    border-radius: 8px;
    cursor: pointer;
    color: #333;
    font-weight: 500;
    margin: 8px 0px;
`;

export const InputFileBox = styled.div<IInputFileProps>`
    input {
        display: none;
    }

    .js .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .inputfile + label {
        max-width: 80%;
        font-size: 1.25rem;
        /* 20px */
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        padding: 0.625rem 1.25rem;
        /* 10px 20px */
    }

    .no-js .inputfile + label {
        display: none;
    }

    .inputfile:focus + label,
    .inputfile.has-focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }

    .inputfile + label * {
        /* pointer-events: none; */
        /* in case of FastClick lib use */
    }

    .inputfile + label svg {
        width: 1em;
        height: 1em;
        vertical-align: middle;
        fill: currentColor;
        margin-top: -0.25em;
        margin-right: 0.25em;
    }

    /* INPUT CUSTOMISATION */
    .inputfile-4 + label {
        /* color: #333; */
        color: ${props => (props.invalid ? "#c53030" : "#333")};
    }

    .inputfile-4:focus + label,
    .inputfile-4.has-focus + label,
    .inputfile-4 + label:hover {

        /* ${props => (props.invalid ? "" : "")} */
        color: ${props =>
            props.invalid ? shade(0.2, "#c53030") : shade(0.2, "#b2d235")}

        /* color: ${shade(0.2, "#b2d235")}; */
    }

    .inputfile-4 + label figure {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        /* background-color: #b2d235; */
        background-color: ${props => (props.invalid ? "#c53030" : "#b2d235")};
        display: block;
        padding: 20px;
        margin: 0 auto 10px;
    }

    .inputfile-4:focus + label figure,
    .inputfile-4.has-focus + label figure,
    .inputfile-4 + label:hover figure {
        /* background-color: ${shade(0.2, "#b2d235")}; */
        background-color: ${props =>
            props.invalid ? shade(0.2, "#c53030") : shade(0.2, "#b2d235")}
    }

    .inputfile-4 + label svg {
        width: 100%;
        height: 100%;
        fill: #333;
    }

    .warningLimitSize {
        font-size: 14px;
        color: var(--color-red-danger);
    }
`;

export const InputFileDisplay = styled.div`
    img {
        width: 100%;
        height: auto;
    }

    iframe {
        width: 100%;
        height: 600px;
    }
`;
