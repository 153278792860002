import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

// API Service
import api from "../../../services/api";

// Common Types
import { TypeMOJStatus, IGridMOJData } from "../../../types/mojTypes";
import UrlsAddress from "../../../types/urlsAddress";

import {
    Card,
    CardBody,
    CardSeeMore,
    CardSubtitle,
    CardTitle,
    Container,
    Content,
    TotalBox,
    DraftBox,
    ExpiredBox,
    HasErrorBox,
    ProcessedBox,
    ProcessingBox,
    RejectedBox,
    Title,
} from "./styles";

interface IMOJDashboard {
    totalMOJs: number;
    totalDraftMOJs: number;
    totalProcessingMOJs: number;
    totalProcessedMOJs: number;
    totalExpiredMOJs: number;
    totalHasErrorMOJs: number;
    totalRejectedMOJs: number;
}

const ConsultantMOJOverview: React.FC = () => {
    const [mojsData, setMojsData] = useState<IGridMOJData[]>([]);

    const history = useHistory();

    const fetchData = useCallback(async () => {
        const { data } = await api.get("/company/getAllMOJsConsultant");

        if (data && data.success && data.data) {
            const mojs: IGridMOJData[] = data.data;

            setMojsData(mojs);
        }
    }, []);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (status: TypeMOJStatus): void => {
        history.push(`${UrlsAddress.CONSULTANT_MOJS}/${status}`);
    };

    const getTotalByMOJStatus = useCallback(
        (status: TypeMOJStatus): number => {
            let total = 0;

            if (status === TypeMOJStatus.Total) return mojsData.length;

            const mojsByStatus = mojsData.filter(
                mojItem => mojItem.mojStatus === status,
            );

            if (mojsByStatus) {
                total = mojsByStatus.length;
            }

            return total;
        },
        [mojsData],
    );

    const getMOJDashboard = useMemo(() => {
        const totalMOJs = getTotalByMOJStatus(TypeMOJStatus.Total);
        const totalDraftMOJs = getTotalByMOJStatus(TypeMOJStatus.Draft);
        const totalProcessingMOJs = getTotalByMOJStatus(
            TypeMOJStatus.Processing,
        );
        const totalProcessedMOJs = getTotalByMOJStatus(TypeMOJStatus.Processed);
        const totalExpiredMOJs = getTotalByMOJStatus(TypeMOJStatus.Expired);
        const totalHasErrorMOJs = getTotalByMOJStatus(TypeMOJStatus.HasError);
        const totalRejectedMOJs = getTotalByMOJStatus(TypeMOJStatus.Rejected);

        const mojDashboard: IMOJDashboard = {
            totalMOJs,
            totalDraftMOJs,
            totalProcessingMOJs,
            totalProcessedMOJs,
            totalExpiredMOJs,
            totalHasErrorMOJs,
            totalRejectedMOJs,
        };

        return mojDashboard;
    }, [getTotalByMOJStatus]);

    return (
        <Container>
            <Content>
                <Title>
                    <h3>Consultant MOJs Overview</h3>
                </Title>
                <TotalBox
                    onClick={() =>
                        getMOJDashboard.totalMOJs > 0 &&
                        handleClick(TypeMOJStatus.Total)
                    }
                >
                    <Card
                        backgroundColorMain="#030202"
                        backgroundColorLight="#333"
                        fontColor="#b2d235"
                        clickable={getMOJDashboard.totalMOJs > 0}
                    >
                        <CardBody>
                            <CardTitle>{getMOJDashboard.totalMOJs}</CardTitle>
                            <CardSubtitle>Total</CardSubtitle>
                            <CardSeeMore>See more</CardSeeMore>
                        </CardBody>
                    </Card>
                </TotalBox>
                <DraftBox
                    onClick={() =>
                        getMOJDashboard.totalDraftMOJs > 0 &&
                        handleClick(TypeMOJStatus.Draft)
                    }
                >
                    <Card
                        backgroundColorMain="#FFC600"
                        backgroundColorLight="#FFFF37"
                        fontColor="#333"
                        clickable={getMOJDashboard.totalDraftMOJs > 0}
                    >
                        <CardBody>
                            <CardTitle>
                                {getMOJDashboard.totalDraftMOJs}
                            </CardTitle>
                            <CardSubtitle>Draft</CardSubtitle>
                            <CardSeeMore>See more</CardSeeMore>
                        </CardBody>
                    </Card>
                </DraftBox>
                <ProcessingBox
                    onClick={() =>
                        getMOJDashboard.totalProcessingMOJs > 0 &&
                        handleClick(TypeMOJStatus.Processing)
                    }
                >
                    <Card
                        backgroundColorMain="#3C8CE7"
                        backgroundColorLight="#70bef5"
                        fontColor="#333"
                        clickable={getMOJDashboard.totalProcessingMOJs > 0}
                    >
                        <CardBody>
                            <CardTitle>
                                {getMOJDashboard.totalProcessingMOJs}
                            </CardTitle>
                            <CardSubtitle>Processing</CardSubtitle>
                            <CardSeeMore>See more</CardSeeMore>
                        </CardBody>
                    </Card>
                </ProcessingBox>
                <ProcessedBox
                    onClick={() =>
                        getMOJDashboard.totalProcessedMOJs > 0 &&
                        handleClick(TypeMOJStatus.Processed)
                    }
                >
                    <Card
                        backgroundColorMain="#49C628"
                        backgroundColorLight="#70F570"
                        fontColor="#333"
                        clickable={getMOJDashboard.totalProcessedMOJs > 0}
                    >
                        <CardBody>
                            <CardTitle>
                                {getMOJDashboard.totalProcessedMOJs}
                            </CardTitle>
                            <CardSubtitle>Processed</CardSubtitle>
                            <CardSeeMore>See more</CardSeeMore>
                        </CardBody>
                    </Card>
                </ProcessedBox>
                <ExpiredBox
                    onClick={() =>
                        getMOJDashboard.totalExpiredMOJs > 0 &&
                        handleClick(TypeMOJStatus.Expired)
                    }
                >
                    <Card
                        backgroundColorMain="rgba(197, 48, 48, 0.9)"
                        backgroundColorLight="#990000"
                        fontColor="#fff"
                        clickable={getMOJDashboard.totalExpiredMOJs > 0}
                    >
                        <CardBody>
                            <CardTitle>
                                {getMOJDashboard.totalExpiredMOJs}
                            </CardTitle>
                            <CardSubtitle>Expired</CardSubtitle>
                            <CardSeeMore>See more</CardSeeMore>
                        </CardBody>
                    </Card>
                </ExpiredBox>
                <HasErrorBox
                    onClick={() =>
                        getMOJDashboard.totalHasErrorMOJs > 0 &&
                        handleClick(TypeMOJStatus.HasError)
                    }
                >
                    <Card
                        backgroundColorMain="rgba(197, 48, 48, 0.9)"
                        backgroundColorLight="#990000"
                        fontColor="#fff"
                        clickable={getMOJDashboard.totalHasErrorMOJs > 0}
                    >
                        <CardBody>
                            <CardTitle>
                                {getMOJDashboard.totalHasErrorMOJs}
                            </CardTitle>
                            <CardSubtitle>Has error</CardSubtitle>
                            <CardSeeMore>See more</CardSeeMore>
                        </CardBody>
                    </Card>
                </HasErrorBox>
                <RejectedBox
                    onClick={() =>
                        getMOJDashboard.totalRejectedMOJs > 0 &&
                        handleClick(TypeMOJStatus.Rejected)
                    }
                >
                    <Card
                        backgroundColorMain="rgba(197, 48, 48, 0.9)"
                        backgroundColorLight="#990000"
                        fontColor="#fff"
                        clickable={getMOJDashboard.totalRejectedMOJs > 0}
                    >
                        <CardBody>
                            <CardTitle>
                                {getMOJDashboard.totalRejectedMOJs}
                            </CardTitle>
                            <CardSubtitle>Rejected</CardSubtitle>
                            <CardSeeMore>See more</CardSeeMore>
                        </CardBody>
                    </Card>
                </RejectedBox>
            </Content>
        </Container>
    );
};

export default ConsultantMOJOverview;
