import styled from "styled-components";

interface ISectionProps {
    backgroundColor?: string;
    backgroundImg?: string;
    hero: boolean;
    titleColor?: string;
    titleBackgroundColor?: string;
}

export const Section = styled.section<ISectionProps>`
    background: ${({ backgroundImg }) =>
        backgroundImg
            ? `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${backgroundImg}) no-repeat;`
            : ""};
    background-size: ${({ backgroundImg }) => (backgroundImg ? "cover" : "")};
    background-position: ${({ backgroundImg }) => (backgroundImg ? "50%" : "")};
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    color: #f3f3f3;
    background-color: ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : "#f3f3f3"};

    div.title-container {
        width: 100%;
        background: ${({ titleBackgroundColor }) =>
            titleBackgroundColor ? titleBackgroundColor : "#333"};
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div.title-content {
        max-width: 1200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div.container {
        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1.section-Title {
        color: ${({ titleColor }) => (titleColor ? titleColor : "#b2d235")};
        margin: 30px 0;
        display: flex;
        font-weight: 400;
        font-size: 3.75rem;
        line-height: 3.75rem;
    }

    div.content {
        max-width: 1200px;
        width: 100%;
        min-height: calc(100vh - 50px);

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: ${({ hero }) => (hero ? "70px" : "0")};

        padding: 25px;

        h1 {
            padding: 20px 0;
            font-size: 3.75rem;
            line-height: 3.75rem;
            font-weight: 300;
        }

        h3 {
            padding: 20px 0;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 300;
        }

        h4 {
            padding: 20px 0;
        }
    }
`;
