import React, {
    InputHTMLAttributes,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";

import { FiAlertCircle } from "react-icons/fi";

import { useField } from "@unform/core";

import { Container, Error, LabelContainer } from "./styles";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    small?: boolean;
    noBackground?: boolean;
    noPadding?: boolean;
}

const Checkbox: React.FC<IInputProps> = ({
    name,
    small,
    noBackground,
    noPadding,
    children,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [checkedBox, setCheckedBox] = useState(false);

    const { fieldName, defaultValue, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            // Within "current" is our "input"
            ref: inputRef.current,
            // When needs to get input value, we access by "path"
            path: "checked",
        });

        if (defaultValue) {
            setCheckedBox(true);
        }
    }, [fieldName, registerField, defaultValue]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCheckedBox(event.target.checked);
        },
        [],
    );

    return (
        <Container
            isErrored={!!error}
            small={small}
            noBackground={noBackground}
            noPadding={noPadding}
        >
            <LabelContainer small={small}>
                {children}
                <input
                    defaultValue={defaultValue}
                    ref={inputRef}
                    type="checkbox"
                    checked={checkedBox}
                    onChange={handleChange}
                    {...rest}
                />
                <span className="checkmark" />
            </LabelContainer>
            {error && (
                <Error className="erro-box" title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default Checkbox;
