import React, { useCallback, useLayoutEffect } from "react";

import { MdClose } from "react-icons/md";

import {
    Presentation,
    Container,
    ModalElement,
    ModalTitle,
    ModalContent,
    ModalActions,
} from "./styles";

interface IModalProps {
    show: boolean;
    size: "sm" | "md" | "lg" | "xl";
    title: string;
    onClose: any;
}

const Modal: React.FC<IModalProps> = ({
    show,
    size,
    title,
    onClose,
    children,
}) => {
    const handleModalClose = useCallback(() => {
        onClose(false);
    }, [onClose]);

    useLayoutEffect(() => {
        if (show) {
            document.documentElement.style.overflowY = "hidden";
        }

        return () => {
            document.documentElement.style.overflowY = "auto";
        };
    }, [show, onClose]);

    return (
        <Presentation show={show} size={size}>
            <Container>
                <ModalElement show={show} size={size}>
                    <ModalTitle>
                        <h2>{title}</h2>
                        <MdClose size={30} onClick={handleModalClose} />
                    </ModalTitle>
                    <ModalContent>{children}</ModalContent>
                    <ModalActions>
                        <button type="button" onClick={handleModalClose}>
                            Close
                        </button>
                    </ModalActions>
                </ModalElement>
            </Container>
        </Presentation>
    );
};

export default Modal;
