import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import DataTable from "react-data-table-component";
import { useHistory, Link } from "react-router-dom";

// Icons
import { FaUserCog, FaRegTrashAlt } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../services/api";

// Hooks
import { useAuth } from "../../../hooks/auth";
import { useLoader } from "../../../hooks/loader";
// import { useToast } from "../../../hooks/toast";
import { IMOJ } from "../../../hooks/moj";

// Common Types
import { ICandidateInvitation } from "../../../types/companyTypes";
import ActionTypes from "../../../types/userActions";
import UrlsAddress from "../../../types/urlsAddress";

// Common Components
import ModalDeleteConsultant, {
    IDeleteConsultantInterface,
    IModalHandles,
} from "../ModalDeleteConsultant";
import Can from "../../Can";

// Styles
import { Container, Content, Title, TableContent, ActionsCell } from "./styles";

export interface IConsultantGridData {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    contactNumber: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    hasPendingMOJs: boolean;
    hasPendingInvitations: boolean;
    totalPendingDraftMOJs: number;
    totalPendingHasErrorMOJs: number;
    totalPendingInvitations: number;
    totalPendingProcessingMOJs: number;
    mojs: IMOJ[];
    candidatesInvitations: ICandidateInvitation[];
}

const ConsultantsGrid: React.FC = () => {
    const [consultantsGridData, setConsultantsGridData] = useState<
        IConsultantGridData[]
    >([]);
    const [processingConsultantsGrid, setProcessingConsultantsGrid] = useState(
        false,
    );

    const [consultantToDelete, setConsultantToDelete] = useState<
        IDeleteConsultantInterface
    >({} as IDeleteConsultantInterface);

    const { isUserAllowed } = useAuth();
    const history = useHistory();
    // const { addToast } = useToast();

    const { showLoader, hideLoader } = useLoader();

    const modalDeleteConsultantRef = useRef<IModalHandles>(null);

    const fetchData = useCallback(async () => {
        if (!isUserAllowed(ActionTypes.COMPANY_ADMIN_MANAGE_CONSULTANTS)) {
            // history.push("/access-forbidden");
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");
            setProcessingConsultantsGrid(true);

            await api.get("company/getAllConsultants").then(response => {
                const { data } = response;
                if (data && data.success) {
                    if (data && data.success && data.data) {
                        setConsultantsGridData(data.data);
                    }
                }
            });

            setProcessingConsultantsGrid(false);
            hideLoader();
        }
    }, [showLoader, hideLoader, history, isUserAllowed]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenModalDeleteConsultant = useCallback(() => {
        modalDeleteConsultantRef.current?.openModal();
    }, []);

    const handleDeleteConsultant = useCallback(
        async (id: string) => {
            try {
                showLoader("Getting consultant data...");

                const apiResponse = await api.get(
                    `company/delete-consultant/${id}`,
                );

                if (apiResponse.status === 200) {
                    const { data } = apiResponse;

                    if (data.success && data.data) {
                        const consultantResp: IConsultantGridData = data.data;

                        const totalPendingMOJs = 0;

                        const consultant: IDeleteConsultantInterface = {
                            id,
                            name: `${consultantResp.firstName} ${consultantResp.lastName}`,
                            contact: consultantResp.contactNumber,
                            email: consultantResp.email,
                            streetAddress: consultantResp.streetAddress,
                            suburb: consultantResp.suburb,
                            city: consultantResp.city,
                            state: consultantResp.state,
                            postcode: consultantResp.postcode,
                            totalInvitations: consultantResp.candidatesInvitations
                                ? consultantResp.candidatesInvitations.length
                                : 0,
                            totalMOJsCreated: consultantResp.mojs
                                ? consultantResp.mojs.length
                                : 0,
                            hasAnyPendingMOJ: consultantResp.hasPendingMOJs,
                            totalPendingMOJs,
                        };

                        setConsultantToDelete(consultant);

                        handleOpenModalDeleteConsultant();
                    }
                }
            } catch (err) {
                // addToast({
                //     type: "error",
                //     title: "Error when getting consultant data",
                //     description:
                //         "There was an error recovering consultant data. Please, try again!",
                // });
                toast.error(
                    "There was an error recovering consultant data. Please, try again!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            } finally {
                hideLoader();
            }
        },
        [handleOpenModalDeleteConsultant, showLoader, hideLoader],
    );

    const getActions = (consultant: IConsultantGridData): JSX.Element => {
        const editLink = (
            <Link to={`/consultant-permissions/${consultant.id}`}>
                <FaUserCog size={20} />
            </Link>
        );

        const deleteButton = (
            <button
                type="button"
                onClick={() => handleDeleteConsultant(consultant.id)}
            >
                <FaRegTrashAlt size={20} style={{ color: "#c53030" }} />
            </button>
        );

        let formattedActions: JSX.Element[] = [];

        formattedActions = [editLink, deleteButton];

        return <ActionsCell>{formattedActions}</ActionsCell>;
    };

    const tableColumns = [
        {
            name: "First name",
            selector: "firstName",
            sortable: true,
        },
        {
            name: "Last name",
            selector: "lastName",
        },
        {
            name: "Email",
            selector: "email",
            hide: 400,
            sortable: true,
        },
        {
            name: "",
            cell: (row: IConsultantGridData) => getActions(row),
        },
    ];

    const getConsultantsData = useMemo(() => {
        return consultantsGridData;
    }, [consultantsGridData]);

    return (
        <Container>
            <Can
                action={ActionTypes.COMPANY_ADMIN_MANAGE_CONSULTANTS}
                yes={() => (
                    <>
                        <Content>
                            <Title>
                                <h3>Staff</h3>
                            </Title>
                            <TableContent>
                                <DataTable
                                    noHeader
                                    highlightOnHover
                                    pagination
                                    responsive
                                    columns={tableColumns}
                                    data={getConsultantsData}
                                    progressPending={processingConsultantsGrid}
                                />
                            </TableContent>
                        </Content>
                    </>
                )}
            />
            <ModalDeleteConsultant
                {...consultantToDelete}
                ref={modalDeleteConsultantRef}
            />
        </Container>
    );
};

export default ConsultantsGrid;
