import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useLocation } from "react-router-dom";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useLoader } from "../../hooks/loader";
// import { useToast } from "../../hooks/toast";

// MasterPage
import MainNew from "../MainNew";

// Common Components
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";

// Common Types
import { IMOJCustomError } from "../../types/globalTypes";
import UrlsAddress from "../../types/urlsAddress";

import {
    Container,
    InvitationCard,
    InvitationTitle,
    InvitationDescription,
    FormContent,
    PermissionGroupBox,
    PermissionGroupBoxTitle,
    PermissionGroupBoxInputs,
} from "./styles";

interface IGroupPermissionsDTO {
    groupName: string;
    permissions: IPermissionDTO[];
}

interface IPermissionDTO {
    name: string;
    description: string;
    granted: boolean;
}

interface IPermissions {
    // CompanyAdmin Group
    addConsultant: boolean;
    buyCredits: boolean;
    inviteConsultant: boolean;
    manageConsultants: boolean;
    readCompanyDashboard: boolean;
    // Consultant Group
    readConsultantDetails: boolean;
    createCandidateMOJ: boolean;
    listAllMOJsConsultant: boolean;
    readCompanyCredits: boolean;
    updateConsultantDetails: boolean;
}

interface IUserPermissionsDTO {
    idUser: string;
    permissionsGroups: IGroupPermissionsDTO[];
    userName: string;
    userEmail: string;
}

const ConsultantPermissions: React.FC = () => {
    const [permissions, setPermissions] = useState<IGroupPermissionsDTO[]>([]);
    const [initialData, setInitialData] = useState<IPermissions>(
        {} as IPermissions,
    );

    const [consultantName, setConsultantName] = useState("");

    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();
    const { showLoader, hideLoader } = useLoader();

    const location = useLocation();

    const idConsultant = location.pathname.replace(
        // "/consultant-permissions/",
        `${UrlsAddress.CONSULTANT_PERMISSIONS}/`,
        "",
    );

    const fetchData = useCallback(async () => {
        showLoader("Processing...");

        await api
            .get(`company/manage-permissions/${idConsultant}`)
            .then(response => {
                if (response.status === 200) {
                    const {
                        data,
                    }: { data: IUserPermissionsDTO } = response.data;

                    setConsultantName(data.userName);

                    setPermissions(data.permissionsGroups);
                }
            });

        hideLoader();
    }, [showLoader, hideLoader, idConsultant]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFormData = useCallback(() => {
        // CompanyAdmin Group
        let addConsultant = false;
        let buyCredits = false;
        let inviteConsultant = false;
        let manageConsultants = false;
        let readCompanyDashboard = false;
        // // Consultant Group
        let readConsultantDetails = false;
        let createCandidateMOJ = false;
        let listAllMOJsConsultant = false;
        let readCompanyCredits = false;
        let updateConsultantDetails = false;

        if (formRef && formRef.current) {
            const addConsultantGranted: boolean = formRef.current.getFieldValue(
                "addConsultant",
            );

            const buyCreditsGranted: boolean = formRef.current.getFieldValue(
                "buyCredits",
            );

            const inviteConsultantGranted: boolean = formRef.current.getFieldValue(
                "inviteConsultant",
            );

            const manageConsultantsGranted: boolean = formRef.current.getFieldValue(
                "manageConsultants",
            );

            const readCompanyDashboardGranted: boolean = formRef.current.getFieldValue(
                "readCompanyDashboard",
            );
            // // // Consultant Group
            const readConsultantDetailsGranted: boolean = formRef.current.getFieldValue(
                "readConsultantDetails",
            );

            const createCandidateMOJGranted: boolean = formRef.current.getFieldValue(
                "createCandidateMOJ",
            );

            const listAllMOJsConsultantGranted: boolean = formRef.current.getFieldValue(
                "listAllMOJsConsultant",
            );

            const readCompanyCreditsGranted: boolean = formRef.current.getFieldValue(
                "readCompanyCredits",
            );

            const updateConsultantDetailsGranted: boolean = formRef.current.getFieldValue(
                "updateConsultantDetails",
            );

            addConsultant = addConsultantGranted;
            buyCredits = buyCreditsGranted;
            inviteConsultant = inviteConsultantGranted;
            manageConsultants = manageConsultantsGranted;
            readCompanyDashboard = readCompanyDashboardGranted;
            readConsultantDetails = readConsultantDetailsGranted;
            createCandidateMOJ = createCandidateMOJGranted;
            listAllMOJsConsultant = listAllMOJsConsultantGranted;
            readCompanyCredits = readCompanyCreditsGranted;
            updateConsultantDetails = updateConsultantDetailsGranted;
        }

        return {
            IdUser: idConsultant,
            permissionsGroups: [
                {
                    groupName: "CompanyAdmin",
                    permissions: [
                        {
                            name: "addConsultant",
                            granted: addConsultant,
                        },
                        {
                            name: "buyCredits",
                            granted: buyCredits,
                        },
                        {
                            name: "inviteConsultant",
                            granted: inviteConsultant,
                        },
                        {
                            name: "manageConsultants",
                            granted: manageConsultants,
                        },
                        {
                            name: "readCompanyDashboard",
                            granted: readCompanyDashboard,
                        },
                    ],
                },
                {
                    groupName: "Consultant",
                    permissions: [
                        {
                            name: "readConsultantDetails",
                            granted: readConsultantDetails,
                        },
                        {
                            name: "createCandidateMOJ",
                            granted: createCandidateMOJ,
                        },
                        {
                            name: "listAllMOJsConsultant",
                            granted: listAllMOJsConsultant,
                        },
                        {
                            name: "readCompanyCredits",
                            granted: readCompanyCredits,
                        },
                        {
                            name: "updateConsultantDetails",
                            granted: updateConsultantDetails,
                        },
                    ],
                },
            ],
        };
    }, [idConsultant]);

    // const getFormaData = () => {

    // };

    const handleSubmit = useCallback(async () => {
        try {
            formRef.current?.setErrors({});

            showLoader("Saving changes...");

            const formData = getFormData();

            await api.post(
                `company/manage-permissions/${idConsultant}`,
                formData,
            );

            // addToast({
            //     type: "success",
            //     title: "Permissions updated!",
            //     description: "Permissions updated!",
            // });
            toast.success("Permissions updated!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            if (err.response.data) {
                const { errors, success }: IMOJCustomError = err.response.data;

                if (!success && errors) {
                    // errors.map(error => {
                    //     return addToast({
                    //         type: "error",
                    //         title: "Error when changing permissions",
                    //         description: error,
                    //     });
                    // });

                    for (let index = 0; index < errors.length; index++) {
                        const element = errors[index];

                        toast.error(element, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            } else {
                // addToast({
                //     type: "error",
                //     title: "Error when saving",
                //     description:
                //         "There was an error saving changes. PLease, try again!",
                // });
                toast.error(
                    "There was an error saving changes. PLease, try again!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            }
        } finally {
            hideLoader();
        }
    }, [showLoader, hideLoader, getFormData, idConsultant]);

    const toLowerCase = (value: string): string => {
        return value.charAt(0).toLowerCase() + value.slice(1);
    };

    const getPermissions = useMemo(() => {
        // CompanyAdmin Group
        let addConsultant = false;
        let buyCredits = false;
        let inviteConsultant = false;
        let manageConsultants = false;
        let readCompanyDashboard = false;
        // // Consultant Group
        let readConsultantDetails = false;
        let createCandidateMOJ = false;
        let listAllMOJsConsultant = false;
        let readCompanyCredits = false;
        let updateConsultantDetails = false;

        if (permissions && permissions.length > 0) {
            for (
                let indexPermissionGroup = 0;
                indexPermissionGroup < permissions.length;
                indexPermissionGroup++
            ) {
                const permissionGroup = permissions[indexPermissionGroup];

                for (
                    let indexPermission = 0;
                    indexPermission < permissionGroup.permissions.length;
                    indexPermission++
                ) {
                    const permission =
                        permissionGroup.permissions[indexPermission];

                    permission.name = toLowerCase(permission.name);

                    if (permission.name === "addConsultant") {
                        addConsultant = permission.granted;
                    } else if (permission.name === "buyCredits") {
                        buyCredits = permission.granted;
                    } else if (permission.name === "inviteConsultant") {
                        inviteConsultant = permission.granted;
                    } else if (permission.name === "manageConsultants") {
                        manageConsultants = permission.granted;
                    } else if (permission.name === "readCompanyDashboard") {
                        readCompanyDashboard = permission.granted;
                    } else if (permission.name === "readConsultantDetails") {
                        readConsultantDetails = permission.granted;
                    } else if (permission.name === "createCandidateMOJ") {
                        createCandidateMOJ = permission.granted;
                    } else if (permission.name === "listAllMOJsConsultant") {
                        listAllMOJsConsultant = permission.granted;
                    } else if (permission.name === "readCompanyCredits") {
                        readCompanyCredits = permission.granted;
                    } else if (permission.name === "updateConsultantDetails") {
                        updateConsultantDetails = permission.granted;
                    }
                }
            }
        }

        setInitialData({
            addConsultant,
            buyCredits,
            inviteConsultant,
            manageConsultants,
            readCompanyDashboard,
            readConsultantDetails,
            createCandidateMOJ,
            listAllMOJsConsultant,
            readCompanyCredits,
            updateConsultantDetails,
        });

        return permissions;
    }, [permissions]);

    return (
        <MainNew>
            <Container>
                <InvitationCard>
                    <InvitationTitle>Consultant Permissions</InvitationTitle>
                    <InvitationDescription>
                        {consultantName}
                    </InvitationDescription>
                    {initialData && (
                        <FormContent>
                            <Form
                                ref={formRef}
                                onSubmit={handleSubmit}
                                initialData={{ ...initialData }}
                            >
                                {getPermissions &&
                                    getPermissions.map(permissionGroupItem => {
                                        return (
                                            <PermissionGroupBox
                                                key={
                                                    permissionGroupItem.groupName
                                                }
                                            >
                                                <PermissionGroupBoxTitle>
                                                    <h3>
                                                        {`${permissionGroupItem.groupName} Group`}
                                                    </h3>
                                                </PermissionGroupBoxTitle>
                                                <PermissionGroupBoxInputs>
                                                    {permissionGroupItem.permissions.map(
                                                        permissionItem => (
                                                            <Checkbox
                                                                key={
                                                                    permissionItem.name
                                                                }
                                                                name={
                                                                    permissionItem.name
                                                                }
                                                                noBackground
                                                                noPadding
                                                            >
                                                                {
                                                                    permissionItem.description
                                                                }
                                                            </Checkbox>
                                                        ),
                                                    )}
                                                </PermissionGroupBoxInputs>
                                            </PermissionGroupBox>
                                        );
                                    })}
                                <Button type="submit">Save</Button>
                            </Form>
                        </FormContent>
                    )}
                </InvitationCard>
            </Container>
        </MainNew>
    );
};

export default ConsultantPermissions;
