import styled from "styled-components";

interface IMOJWidgetProps {
    backgroundColorMain: string;
    backgroundColorLight?: string;
}

interface IMOJWIdgetQuantityProps {
    fontColor?: string;
}

interface IMOJWIdgetLabelProps {
    fontColor?: string;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #fff;
`;

export const MOJWidgets = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 8px;
`;

export const MOJWidget = styled.div<IMOJWidgetProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 16px);
    margin: 8px;
    padding: 8px 16px;

    border-radius: 8px;

    /* background: #fff; */
    ${({ backgroundColorMain, backgroundColorLight }) => {
        if (backgroundColorLight) {
            return `
          background: ${backgroundColorLight};
          background: -webkit-radial-gradient(top left, ${backgroundColorLight}, ${backgroundColorMain});
          background: -moz-radial-gradient(top left, ${backgroundColorLight}, ${backgroundColorMain});
          background: radial-gradient(to bottom right, ${backgroundColorLight}, ${backgroundColorMain});
        `;
        }

        return `
          background: ${backgroundColorMain};
        `;
    }}

    @media (min-width: 768px) {
        flex: 1 1 250px;
        padding: 16px;
    }
`;

export const MOJWidgetQuantity = styled.label<IMOJWIdgetQuantityProps>`
    font-size: 2em;
    font-weight: bold;

    color: ${({ fontColor }) => fontColor || "#333"};

    @media (min-width: 768px) {
        font-size: 3em;
    }
`;

export const MOJWidgetLabel = styled.div<IMOJWIdgetLabelProps>`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    font-size: 1.2rem;
    text-align: center;

    color: ${({ fontColor }) => fontColor || "#333"};

    @media (min-width: 768px) {
        font-size: 1.5rem;
    }
`;
