import React from "react";

// Hooks
import ScrollTopArrow from "../../hooks/ScrollTopArrow";

// SECTIONS
import Header from "./Sections/Header";
import Hero from "./Sections/Hero";
import AboutUs from "./Sections/AboutUs";
import Pricing from "./Sections/Pricing";
import ContactUs from "./Sections/ContactUs";
import Footer from "./Sections/Footer";

// Support Local Banner
import SupportLocal from "./SupportLocal";

const LandingPage: React.FC = () => {
    return (
        <>
            <Header />
            <Hero />
            <AboutUs />
            <SupportLocal />
            <Pricing />
            <ContactUs />
            <ScrollTopArrow target={500} />
            <Footer />
        </>
    );
};

export default LandingPage;
