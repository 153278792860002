import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// Hooks
import { useCompanyCredits } from "../../../../hooks/companyCredits";
import UseOutsideClick from "../../../../hooks/UseOutsideClick";

// Common Components
import Can from "../../../../components/Can";

// Common Types
import ActionTypes from "../../../../types/userActions";
import UrlsAddress from "../../../../types/urlsAddress";

import {
    Container,
    DropDownContainer,
    DropDownButton,
    LongDescription,
    ShortDescription,
    IconDropDownExpanded,
    IconDropDown,
    MOJCreditsDropDownContent,
    MOJCreditsBox,
    MOJCreditsBadges,
    BadgeBox,
    GoldBadgeColor,
    BadgeTitle,
    SilverBadgeColor,
    BronzeBadgeColor,
    Divider,
    MOJCreditsBoxBuyCredits,
} from "./styles";

const CompanyCredits: React.FC = () => {
    const [showMOJCreditsMenu, setShowMOJCreditsMenu] = useState(false);
    const refMOJCreditsMenu = useRef<HTMLDivElement>(null);

    const { credits, refreshCredits } = useCompanyCredits();

    useEffect(() => {
        // fetchData();

        refreshCredits();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleMOJCreditsMenu = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            setShowMOJCreditsMenu(!showMOJCreditsMenu);
        },
        [showMOJCreditsMenu],
    );

    UseOutsideClick(refMOJCreditsMenu, () => {
        if (showMOJCreditsMenu) {
            setShowMOJCreditsMenu(false);
        }
    });

    return (
        <Container>
            <Can
                action={ActionTypes.CONSULTANT_READ_COMPANY_CREDITS}
                yes={() => (
                    <DropDownContainer className="DropDownContainer">
                        <DropDownButton
                            className="DropDownButton"
                            type="button"
                            onClick={toggleMOJCreditsMenu}
                        >
                            <LongDescription>
                                MOJ CREDITS: {credits.total}
                            </LongDescription>
                            <ShortDescription>{credits.total}</ShortDescription>
                            {showMOJCreditsMenu ? (
                                <IconDropDownExpanded />
                            ) : (
                                <IconDropDown />
                            )}
                        </DropDownButton>
                        {showMOJCreditsMenu && (
                            <MOJCreditsDropDownContent
                                ref={refMOJCreditsMenu}
                                className="DropDownContent"
                            >
                                <MOJCreditsBox>
                                    <MOJCreditsBadges>
                                        <BadgeBox>
                                            <GoldBadgeColor>
                                                {/* {getMOJCompanyCredits.gold} */}
                                                {credits.gold}
                                            </GoldBadgeColor>
                                            <BadgeTitle>Gold</BadgeTitle>
                                        </BadgeBox>
                                        <BadgeBox>
                                            <SilverBadgeColor>
                                                {/* {
                                                    getMOJCompanyCredits.silver
                                                } */}
                                                {credits.silver}
                                            </SilverBadgeColor>
                                            <BadgeTitle>Silver</BadgeTitle>
                                        </BadgeBox>
                                        <BadgeBox>
                                            <BronzeBadgeColor>
                                                {/* {
                                                    getMOJCompanyCredits.bronze
                                                } */}
                                                {credits.bronze}
                                            </BronzeBadgeColor>
                                            <BadgeTitle>Bronze</BadgeTitle>
                                        </BadgeBox>
                                    </MOJCreditsBadges>
                                    <Can
                                        action={
                                            ActionTypes.COMPANY_ADMIN_BUY_CREDITS
                                        }
                                        yes={() => (
                                            <>
                                                <Divider />
                                                <MOJCreditsBoxBuyCredits>
                                                    <Link
                                                        to={
                                                            UrlsAddress.BUY_CREDITS
                                                        }
                                                    >
                                                        Buy more
                                                    </Link>
                                                </MOJCreditsBoxBuyCredits>
                                            </>
                                        )}
                                    />
                                </MOJCreditsBox>
                            </MOJCreditsDropDownContent>
                        )}
                    </DropDownContainer>
                )}
            />
        </Container>
    );
};

export default CompanyCredits;
