import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
// Common Components
import ConsultantMOJOverview from "../ConsultantMOJOverview";
import ConsultantsGrid from "../ConsultantsGrid";

// Common Types
import UrlsAddress from "../../../types/urlsAddress";

import {
    Container,
    CardsInvitations,
    CardInvite,
    CardInviteTitle,
    CardInviteDescription,
    CardInviteButton,
} from "./styles";

const CompanyAdminDashboard: React.FC = () => {
    const history = useHistory();

    const handleInviteCandidates = useCallback(() => {
        // history.push("/invite-candidates");
        history.push(UrlsAddress.INVITE_CANDIDATES);
    }, [history]);

    const handleInviteConsultants = useCallback(() => {
        // history.push("/invite-staff");
        history.push(UrlsAddress.INVITE_STAFF);
    }, [history]);

    return (
        <Container>
            <CardsInvitations>
                <CardInvite>
                    <CardInviteTitle>
                        <span>Candidates</span>
                    </CardInviteTitle>
                    <CardInviteDescription>
                        <span>
                            Invite candidates to fill out the criminal check
                            form!
                        </span>
                    </CardInviteDescription>
                    <CardInviteButton onClick={handleInviteCandidates}>
                        Send Email
                    </CardInviteButton>
                </CardInvite>
                <CardInvite>
                    <CardInviteTitle>
                        <span>Staff</span>
                    </CardInviteTitle>
                    <CardInviteDescription>
                        <span>Invite additional users from your company!</span>
                    </CardInviteDescription>
                    <CardInviteButton onClick={handleInviteConsultants}>
                        Send Email
                    </CardInviteButton>
                </CardInvite>
            </CardsInvitations>

            <ConsultantMOJOverview />

            <ConsultantsGrid />
        </Container>
    );
};

export default CompanyAdminDashboard;
