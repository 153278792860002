import React from "react";

// Components
import MainNew from "../MainNew";
import ConsultantsGrid from "../../components/Consultant/ConsultantsGrid";

// Styles
import { Container } from "./styles";

const Consultants: React.FC = () => {
    return (
        <MainNew>
            <Container>
                <ConsultantsGrid />
            </Container>
        </MainNew>
    );
};

export default Consultants;
