import React from "react";
import { Link } from "react-router-dom";

// Icons
import { FaCheck } from "react-icons/fa";

import Section from "../../Section";

// Common Types
import UrlsAddress from "../../../../types/urlsAddress";

import {
    Container,
    PriceBox,
    PriceBoxtHeader,
    ProductTitle,
    ProductTitleBreak,
    ProductPriceContainer,
    ProductPrice,
    ProductGst,
    PriceBoxDescription,
    ProductBusinessDays,
    ProductDescription,
    PriceBoxFooter,
} from "./styles";

interface IProducts {
    title: string;
    type: "bronze" | "silver" | "gold" | "company";
    category: "product" | "company";
    price?: number;
    listClassType: "price-list" | "company-descriptions";
    businessDays?: number;
    productsDescriptions: string[];
    buttonDescription: string;
    promote: boolean;
    route: string;
}

const products: IProducts[] = [
    {
        title: "Bronze",
        type: "bronze",
        category: "product",
        price: 20,
        listClassType: "price-list",
        businessDays: 16,
        productsDescriptions: ["business days*"],
        buttonDescription: "Purchase now",
        promote: false,
        // route: "/sign-in",
        route: UrlsAddress.SIGN_IN,
    },
    {
        title: "Silver",
        type: "silver",
        category: "product",
        price: 25,
        listClassType: "price-list",
        businessDays: 11,
        productsDescriptions: ["business days*"],
        buttonDescription: "Purchase now",
        promote: false,
        // route: "/sign-in",
        route: UrlsAddress.SIGN_IN,
    },
    {
        title: "Gold",
        type: "gold",
        category: "product",
        price: 35,
        listClassType: "price-list",
        businessDays: 4,
        productsDescriptions: ["business days*"],
        buttonDescription: "Purchase now",
        promote: true,
        // route: "/sign-in",
        route: UrlsAddress.SIGN_IN,
    },
    {
        title: "Enterprise account",
        type: "company",
        category: "company",
        listClassType: "company-descriptions",
        productsDescriptions: [
            "Buy credits in bulk",
            "Setup multiple accounts",
            "Send links for candidates to complete background checks remotely",
        ],
        buttonDescription: "Get Started",
        promote: false,
        // route: "/sign-up-company",
        route: UrlsAddress.SIGN_UP_COMPANY,
    },
];

const Pricing: React.FC = () => {
    return (
        <Section
            isHero={false}
            id="pricing"
            title="Our Prices"
            backgroundColor="#fff"
        >
            <Container>
                {products.map((product, index) => (
                    <PriceBox
                        key={index}
                        type={product.type}
                        promote={product.promote}
                    >
                        <PriceBoxtHeader
                            type={product.type}
                            promote={product.promote}
                        >
                            <ProductTitle
                                type={product.type}
                                promote={product.promote}
                            >
                                <span>{product.title}</span>
                            </ProductTitle>
                            {product.category === "product" && (
                                <ProductTitleBreak />
                            )}
                            {product.category === "product" && (
                                <ProductPriceContainer>
                                    <ProductPrice
                                        type={product.type}
                                        promote={product.promote}
                                    >
                                        <small>$</small>
                                        <span>{product.price}</span>
                                    </ProductPrice>
                                    <ProductGst>
                                        <span>NZD + GST</span>
                                    </ProductGst>
                                </ProductPriceContainer>
                            )}
                        </PriceBoxtHeader>
                        <PriceBoxDescription>
                            {product.category === "product" &&
                                product.businessDays && (
                                    <ProductBusinessDays
                                        type={product.type}
                                        promote={product.promote}
                                    >
                                        <span>{product.businessDays}</span>
                                    </ProductBusinessDays>
                                )}
                            <ProductDescription
                                type={product.type}
                                promote={product.promote}
                            >
                                {product.productsDescriptions.map(
                                    (productDescription, indexDescription) => (
                                        <li key={indexDescription}>
                                            {product.category === "company" && (
                                                <FaCheck size={18} />
                                            )}
                                            <span>{productDescription}</span>
                                        </li>
                                    ),
                                )}
                            </ProductDescription>
                        </PriceBoxDescription>
                        <PriceBoxFooter
                            type={product.type}
                            promote={product.promote}
                        >
                            <Link to={product.route}>
                                {product.buttonDescription}
                            </Link>
                        </PriceBoxFooter>
                    </PriceBox>
                ))}
            </Container>
        </Section>
    );
};

export default Pricing;
