import React from "react";

import { Container, Spinner } from "./styles";

interface ILoaderProps {
    show: boolean;
    description?: string;
}

const Loader: React.FC<ILoaderProps> = ({ show, description }) => {
    const desc = description || "Processing...";

    return (
        <Container showSpinner={show}>
            <Spinner />
            <h2>{desc}</h2>
        </Container>
    );
};

export default Loader;
