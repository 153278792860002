import styled from "styled-components";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 8px;
    }
`;

export const RowField = styled.div`
    font-weight: 500;
    font-size: 18px;
`;

export const RowValue = styled.div`
    font-weight: 400;
    margin-left: 8px;
`;

export const PreviousNamesList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`;

// export const PreviousNamesListTitle = styled.span`
//     font-weight: 500;
//     color: #333;
//     margin-bottom: 8px;
// `;

export const PreviousNameListBoxes = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-self: center;
`;

export const PreviousNameBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;
    /* border: 2px #b2d235 solid; */
    border-radius: 8px;

    min-width: 290px;
    flex-shrink: 1;

    > span {
        background: #e0f787;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`;

export const PreviousNameBoxTitle = styled.span`
    display: flex;
    width: 100%;
    font-weight: 500;
    text-decoration: underline;
    justify-content: center;
    padding: 8px;
    background: #b2d235;
`;

export const PreviousNameBoxLabels = styled.div`
    padding: 8px;

    background: #cce95a;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const PreviousNameBoxLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    & + & {
        margin-top: 8px;
    }
`;

export const PreviousNameValue = styled.span`
    font-weight: 400;
`;
