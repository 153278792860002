import React from "react";

// MasterPage
import Main from "../Main";
import MainNew from "../MainNew";

// Hooks
import { useAuth } from "../../hooks/auth";

// Common Components
import CustomerDashboard from "../../components/Customer/CustomerDashboard";
import ConsultantDashboard from "../../components/Consultant/ConsultantDashboard";
import CandidateDashboard from "../../components/Candidate/CandidateDashboard";
import CompanyAdminDashboard from "../../components/Consultant/CompanyAdminDashboard";

import InternalConsultantDashboard from "../../components/InternalConsultant/ConsultantDashboard";

import { Container } from "./styles";

const Dashboard: React.FC = () => {
    const { user } = useAuth();

    const getUserDashboard = (): JSX.Element | null => {
        switch (user.userType) {
            case 1:
                return <h1>dash admin</h1>;
            case 2:
                return <CustomerDashboard />;
            case 3:
                return <CompanyAdminDashboard />;
            case 4:
                return <ConsultantDashboard />;
            case 5:
                return <CandidateDashboard />;
            case 6:
                return <InternalConsultantDashboard />;
            case 7:
                return <InternalConsultantDashboard />;
            default:
                return null;
        }
    };

    return (
        <MainNew>
            {/* <Container>{getUserDashboard()}</Container> */}
            {getUserDashboard()}
        </MainNew>
    );
};

export default Dashboard;
