import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { parse, isValid } from "date-fns";

// Icons
import { FaCalendar, FaRegBuilding } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useLoader } from "../../hooks/loader";

// Common Components
import MainNew from "../MainNew";

// Common Types
import { IMOJCustomError } from "../../types/globalTypes";

// Utils
import {
    convertDateToString,
    getFormattedDate,
} from "../../utils/dateUtilities";

import {
    Container,
    Wrapper,
    Title,
    FilterBox,
    InputsContainer,
    SearchButton,
    BranchTitle,
    FinancialWidgets,
    Info,
    ItemWidget,
    ItemWidgetTitle,
    ItemWidgetAmount,
    ItemWidgetQuantity,
    FinancialGlobalOverview,
    FinancialGlobalOverviewHeader,
    HeaderBranch,
    HeaderAmount,
    HeaderQuantity,
    FinancialGlobalOverviewRow,
    BranchColumn,
    AmountColumn,
    QuantityColumn,
} from "./styles";
import UrlsAddress from "../../types/urlsAddress";
import InputMaskWithLabel from "../../components/InputMaskWithLabel";
import SelectWithLabel from "../../components/SelectWithLabel";
import getValidationErrors from "../../utils/getValidationErrors";
import {
    IBranchData,
    IBranchesSelectOptions,
} from "../../types/internalConsultantsTypes";

interface IFinancialDashboardOverview {
    gold: IItemDashboard;
    silver: IItemDashboard;
    bronze: IItemDashboard;
    total: IItemDashboard;
    auckland: IItemDashboard;
    christchurch: IItemDashboard;
    hamilton: IItemDashboard;
    tauranga: IItemDashboard;
    wellington: IItemDashboard;
    headOffice: IItemDashboard;
    branch: string;
}

interface IItemDashboard {
    amount: number;
    quantity: number;
}

interface IFilterFormData {
    idBranch?: string;
    dateFrom: Date;
    dateTo: Date;
}

const InternalFinancialReport: React.FC = () => {
    const [
        showFinancialGlobalOverview,
        setShowFinancialGlobalOverview,
    ] = useState(false);
    const [branchesData, setBranchesData] = useState<IBranchData[]>([]);
    const [financialOverviewData, setFinancialOverviewData] = useState<
        IFinancialDashboardOverview
    >({
        gold: { amount: 0, quantity: 0 },
        silver: { amount: 0, quantity: 0 },
        bronze: { amount: 0, quantity: 0 },
        total: { amount: 0, quantity: 0 },
        auckland: { amount: 0, quantity: 0 },
        christchurch: { amount: 0, quantity: 0 },
        hamilton: { amount: 0, quantity: 0 },
        tauranga: { amount: 0, quantity: 0 },
        wellington: { amount: 0, quantity: 0 },
        headOffice: { amount: 0, quantity: 0 },
        branch: "",
    });

    const [dateFrom, setDateFrom] = useState(() => {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();

        return getFormattedDate(new Date(year, month, 1), "dd/MM/yyyy");
    });

    const [dateTo, setDateTo] = useState(() => {
        const lastDayOfMonth = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0,
        );

        return getFormattedDate(lastDayOfMonth, "dd/MM/yyyy");
    });

    const formRef = useRef<FormHandles>(null);

    const { showLoader, hideLoader } = useLoader();

    const fetchData = useCallback(async () => {
        showLoader("Processing...");

        const month = new Date().getMonth();
        const year = new Date().getFullYear();

        const splittedDateFrom = convertDateToString(
            new Date(year, month, 1),
        ).split("/");
        const splittedDateTo = convertDateToString(
            new Date(year, month + 1, 0),
        ).split("/");

        try {
            await api
                .get("internalConsultant/get-internal-branches")
                .then(response => {
                    const { data } = response;

                    if (data && data.success && data.data) {
                        const branches: IBranchData[] = data.data;

                        setBranchesData(branches);
                    }
                });

            const response = await api.get(
                "internalConsultant/get-financial-dashboard",
                {
                    params: {
                        dateFrom: `${splittedDateFrom[1]}/${splittedDateFrom[0]}/${splittedDateFrom[2]}`,
                        dateTo: `${splittedDateTo[1]}/${splittedDateTo[0]}/${splittedDateTo[2]}`,
                    },
                },
            );

            if (response.status === 200) {
                const { data } = response.data;

                setFinancialOverviewData(data);
                setShowFinancialGlobalOverview(true);
            }
        } catch (err) {
            if (err.response.data) {
                const { errors, success }: IMOJCustomError = err.response.data;

                if (!success && errors) {
                    for (let index = 0; index < errors.length; index++) {
                        const element = errors[index];

                        toast.error(element, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            } else {
                toast.error(
                    "There was an error signing in, check your credentials!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            }
        } finally {
            hideLoader();
        }
    }, [showLoader, hideLoader]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getFinancialOverviewData = useMemo(() => {
        return financialOverviewData;
    }, [financialOverviewData]);

    const yupCustomDateValidator = (data: string): boolean => {
        if (data) {
            const parsedDate = parse(data, "dd/MM/yyyy", new Date());

            if (isValid(parsedDate)) {
                return true;
            }
        }

        return false;
    };

    const yupCustomCompareDateValidator = (dateToValue: string): boolean => {
        if (formRef && formRef.current) {
            const dateFromValue: string = formRef.current.getFieldValue(
                "dateFrom",
            );

            const parsedDateFrom = parse(
                dateFromValue,
                "dd/MM/yyyy",
                new Date(),
            );

            const parsedDateTo = parse(dateToValue, "dd/MM/yyyy", new Date());

            return parsedDateTo > parsedDateFrom;
        }

        return false;
    };

    const handleFilter = useCallback(
        async (formData: IFilterFormData) => {
            showLoader("Processing...");

            const splittedDateFrom = formData.dateFrom.toString().split("/");
            const splittedDateTo = formData.dateTo.toString().split("/");

            const response = await api.get(
                "internalConsultant/get-financial-dashboard",
                {
                    params: {
                        idBranch: formData.idBranch,
                        dateFrom: `${splittedDateFrom[1]}/${splittedDateFrom[0]}/${splittedDateFrom[2]}`,
                        dateTo: `${splittedDateTo[1]}/${splittedDateTo[0]}/${splittedDateTo[2]}`,
                    },
                },
            );

            const { data } = response.data;

            if (response.data.success && data) {
                setFinancialOverviewData(data);

                const { idBranch } = formData;

                if (
                    idBranch &&
                    idBranch !== "00000000-0000-0000-0000-000000000000"
                ) {
                    setShowFinancialGlobalOverview(false);
                } else {
                    setShowFinancialGlobalOverview(true);
                }
            }

            hideLoader();
        },
        [showLoader, hideLoader],
    );

    const yupCustomBranchValidator = (data: string): boolean => {
        if (data) return true;

        return false;
    };

    const handleSubmitFilter = useCallback(
        async (data: IFilterFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    dateFrom: Yup.string()
                        .test("Date from", "Date is invalid", value =>
                            yupCustomDateValidator(value),
                        )
                        .required("Date From is required"),
                    dateTo: Yup.string().when("dateFrom", {
                        is: val => val && val.length > 0,
                        then: Yup.string().test(
                            "Date from",
                            "Date To must be greater than Date From",
                            value => yupCustomCompareDateValidator(value),
                        ),
                    }),
                    idBranch: Yup.string().test(
                        "Branch",
                        "Branch is required",
                        value => yupCustomBranchValidator(value),
                    ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                await handleFilter(data);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error("There was an error filtering your requests!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        },
        [handleFilter],
    );

    const getDateFrom = useMemo(() => {
        return dateFrom;
    }, [dateFrom]);

    const getDateTo = useMemo(() => {
        return dateTo;
    }, [dateTo]);

    const getBranches = useMemo(() => {
        const allBranches: IBranchesSelectOptions[] = [];

        if (branchesData.length > 0) {
            allBranches.push({
                label: "Global",
                value: "00000000-0000-0000-0000-000000000000",
            });

            for (let index = 0; index < branchesData.length; index++) {
                const branch = branchesData[index];

                const option: IBranchesSelectOptions = {
                    label: branch.name,
                    value: branch.id,
                };

                allBranches.push(option);
            }
        }

        return allBranches;
    }, [branchesData]);

    return (
        <MainNew>
            <Container>
                <Wrapper>
                    <Title>Financial Dashboard</Title>
                    <FilterBox>
                        <Form
                            ref={formRef}
                            onSubmit={handleSubmitFilter}
                            initialData={{
                                dateFrom: getDateFrom,
                                dateTo: getDateTo,
                            }}
                        >
                            <InputsContainer>
                                <InputMaskWithLabel
                                    name="dateFrom"
                                    icon={FaCalendar}
                                    label="Date From"
                                    placeholder={`e.g. 31/12/${new Date().getFullYear()}`}
                                    mask="99/99/9999"
                                />
                                <InputMaskWithLabel
                                    name="dateTo"
                                    icon={FaCalendar}
                                    label="Date To"
                                    placeholder={`e.g. 31/12/${new Date().getFullYear()}`}
                                    mask="99/99/9999"
                                />

                                <SelectWithLabel
                                    name="idBranch"
                                    icon={FaRegBuilding}
                                    label="Branch"
                                    data={getBranches}
                                    noOptionLabel="Branch"
                                />
                            </InputsContainer>
                            <SearchButton type="submit">Search</SearchButton>
                        </Form>
                    </FilterBox>
                    <BranchTitle>
                        <h2>{getFinancialOverviewData.branch}</h2>
                    </BranchTitle>

                    <FinancialWidgets>
                        <Info>
                            All data are considering the date the request is
                            delivered to the Ministry of Justice
                        </Info>
                        <ItemWidget type="gold">
                            <ItemWidgetTitle>Gold</ItemWidgetTitle>
                            <ItemWidgetAmount>
                                <small>$</small>
                                <span>
                                    {getFinancialOverviewData.gold.amount.toFixed(
                                        2,
                                    )}
                                </span>
                            </ItemWidgetAmount>
                            <ItemWidgetQuantity>
                                <small>Req.</small>
                                {getFinancialOverviewData.gold.quantity}
                            </ItemWidgetQuantity>
                        </ItemWidget>
                        <ItemWidget type="silver">
                            <ItemWidgetTitle>Silver</ItemWidgetTitle>
                            <ItemWidgetAmount>
                                <small>$</small>
                                <span>
                                    {getFinancialOverviewData.silver.amount.toFixed(
                                        2,
                                    )}
                                </span>
                            </ItemWidgetAmount>
                            <ItemWidgetQuantity>
                                <small>Req.</small>
                                {getFinancialOverviewData.silver.quantity}
                            </ItemWidgetQuantity>
                        </ItemWidget>
                        <ItemWidget type="bronze">
                            <ItemWidgetTitle>Bronze</ItemWidgetTitle>
                            <ItemWidgetAmount>
                                <small>$</small>
                                <span>
                                    {getFinancialOverviewData.bronze.amount.toFixed(
                                        2,
                                    )}
                                </span>
                            </ItemWidgetAmount>
                            <ItemWidgetQuantity>
                                <small>Req.</small>
                                {getFinancialOverviewData.bronze.quantity}
                            </ItemWidgetQuantity>
                        </ItemWidget>
                        <ItemWidget type="total">
                            <ItemWidgetTitle>Total</ItemWidgetTitle>
                            <ItemWidgetAmount>
                                <small>$</small>
                                <span>
                                    {getFinancialOverviewData.total.amount.toFixed(
                                        2,
                                    )}
                                </span>
                            </ItemWidgetAmount>
                            <ItemWidgetQuantity>
                                <small>Req.</small>
                                {getFinancialOverviewData.total.quantity}
                            </ItemWidgetQuantity>
                        </ItemWidget>
                    </FinancialWidgets>
                    {showFinancialGlobalOverview && (
                        <FinancialGlobalOverview>
                            <FinancialGlobalOverviewHeader>
                                <HeaderBranch>Branch</HeaderBranch>
                                <HeaderAmount>$</HeaderAmount>
                                <HeaderQuantity>Requests</HeaderQuantity>
                            </FinancialGlobalOverviewHeader>
                            <FinancialGlobalOverviewRow>
                                <BranchColumn>Auckland</BranchColumn>
                                <AmountColumn>
                                    {getFinancialOverviewData.auckland.amount.toFixed(
                                        2,
                                    )}
                                </AmountColumn>
                                <QuantityColumn>
                                    {getFinancialOverviewData.auckland.quantity}
                                </QuantityColumn>
                            </FinancialGlobalOverviewRow>
                            <FinancialGlobalOverviewRow>
                                <BranchColumn>Christchurch</BranchColumn>
                                <AmountColumn>
                                    {getFinancialOverviewData.christchurch.amount.toFixed(
                                        2,
                                    )}
                                </AmountColumn>
                                <QuantityColumn>
                                    {
                                        getFinancialOverviewData.christchurch
                                            .quantity
                                    }
                                </QuantityColumn>
                            </FinancialGlobalOverviewRow>
                            <FinancialGlobalOverviewRow>
                                <BranchColumn>Hamilton</BranchColumn>
                                <AmountColumn>
                                    {getFinancialOverviewData.hamilton.amount.toFixed(
                                        2,
                                    )}
                                </AmountColumn>
                                <QuantityColumn>
                                    {getFinancialOverviewData.hamilton.quantity}
                                </QuantityColumn>
                            </FinancialGlobalOverviewRow>
                            <FinancialGlobalOverviewRow>
                                <BranchColumn>Tauranga</BranchColumn>
                                <AmountColumn>
                                    {getFinancialOverviewData.tauranga.amount.toFixed(
                                        2,
                                    )}
                                </AmountColumn>
                                <QuantityColumn>
                                    {getFinancialOverviewData.tauranga.quantity}
                                </QuantityColumn>
                            </FinancialGlobalOverviewRow>
                            <FinancialGlobalOverviewRow>
                                <BranchColumn>Wellington</BranchColumn>
                                <AmountColumn>
                                    {getFinancialOverviewData.wellington.amount.toFixed(
                                        2,
                                    )}
                                </AmountColumn>
                                <QuantityColumn>
                                    {
                                        getFinancialOverviewData.wellington
                                            .quantity
                                    }
                                </QuantityColumn>
                            </FinancialGlobalOverviewRow>
                            <FinancialGlobalOverviewRow>
                                <BranchColumn>Head-Office</BranchColumn>
                                <AmountColumn>
                                    {getFinancialOverviewData.headOffice.amount.toFixed(
                                        2,
                                    )}
                                </AmountColumn>
                                <QuantityColumn>
                                    {
                                        getFinancialOverviewData.headOffice
                                            .quantity
                                    }
                                </QuantityColumn>
                            </FinancialGlobalOverviewRow>
                        </FinancialGlobalOverview>
                    )}
                </Wrapper>
            </Container>
        </MainNew>
    );
};

export default InternalFinancialReport;
