import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface IContainerProps {
    isFilled: boolean;
    isErrored: boolean;
    isReadOnly?: boolean;
    inline?: boolean;
}

export const Container = styled.div<IContainerProps>`
    background: var(--color-green-1);

    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;

    border: 2px solid var(--color-green-1);
    color: #5d700f;

    display: flex;
    align-items: center;
    position: relative;

    ${props =>
        props.inline &&
        css`
            border-radius: 5px;
        `}

    ${props =>
        props.isReadOnly &&
        css`
            cursor: not-allowed;
            background: #e9ecef;
            border: 2px solid #ced4da;
        `}

    & + div {
        margin-top: 1rem;

        ${props =>
            props.inline &&
            css`
                margin-top: 0;
                margin-right: 10px;
            `}
    }

    ${props =>
        props.isFilled &&
        css`
            color: #5d700f;
            border-color: #5d700f;
        `}

    ${props =>
        props.isErrored &&
        css`
            border-color: #c53030;
        `}

    input {
        flex: 1;
        background: transparent;
        border: 0;
        color: #475412;

        ${props =>
            props.isReadOnly &&
            css`
                cursor: not-allowed;
            `}

        ${props =>
            props.isFilled &&
            css`
                border-radius: 0.2rem;
                font-weight: 500;
            `}
    }

    svg {
        margin-right: 1rem;
        color: var(--color-green-4);

        align-self: baseline;
        margin-top: 0.5rem;
    }

    .input-label {
        position: absolute;
        transition: all 0.3s;
        color: #333;
        font-weight: normal;
        font-weight: 500;

        display: block;
        color: ${props => (props.isErrored ? "#fff" : "#333")};
        left: 5px;
        top: -10px;
        background: ${props =>
            props.isErrored ? "var(--color-red-danger)" : "var(--color-green)"};
        padding: 0 5px;
        border-radius: 0.2rem;
    }

    .input-select {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .select-box {
        display: flex;
        /* width: 400px; */
        width: 100%;
        flex-direction: column;
    }

    .select-box .options-container {
        background: #b2d235;
        color: #333;
        max-height: 0;
        width: 100%;
        opacity: 0;
        transition: all 0.4s;
        border-radius: 0.5rem;
        overflow: hidden;

        /* HANDLING USING REACT INSTEAD OF ORDER  */
        order: 1;
    }

    .selected {
        background: var(--color-green);
        border-radius: 0.5rem;
        /* margin-bottom: 0.5rem; */
        color: #333;
        font-weight: 500;
        position: relative;

        order: 0;
    }

    .select-box .options-container.active {
        max-height: 150px;
        opacity: 1;
        overflow-y: scroll;
        margin-top: 0.5rem;
    }

    .select-box .options-container::-webkit-scrollbar {
        width: 0.8rem;
        background: var(--color-green-4);
        border-radius: 0 0.5rem 0.5rem 0;
    }

    .select-box .options-container::-webkit-scrollbar-thumb {
        /* background: var(--color-green-1); */
        background: #333;
        border-radius: 0 0.5rem 0.5rem 0;
    }

    .select-box .option,
    .selected {
        padding: 0.5rem 2rem 0.5rem 1rem;
        cursor: pointer;
    }

    .select-box .option:hover {
        background: var(--color-green-2);
    }

    .select-box label {
        width: 100%;
        cursor: pointer;
    }

    .select-box .option .radio {
        display: none;
    }
`;

export const IconArrowContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;

    transition: all 0.4s;

    svg {
        margin: initial;
        align-self: initial;
        color: #333 !important;
        height: 2rem !important;
        width: 3rem !important;
    }
`;

// Stylising another Custom Component => Tooltip/index.tsx
// Check Tooltip Component.
// It is receiving a "className" props, and setting its className={className}
export const Error = styled(Tooltip)`
    align-self: baseline;

    height: 20px;
    margin-left: 16px;

    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
