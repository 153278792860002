import React from "react";

import MOJOverview from "../MOJOverview";
import MOJCharts from "../MOJCharts";
import MOJApplicationsGrid from "../MOJApplicationsGrid";

import Can from "../../Can";

import { Container, Wrapper } from "./styles";
import ActionTypes from "../../../types/userActions";

const ConsultantDashboard: React.FC = () => {
    // const { user } = useAuth();

    // if (user.userType !== 2 && user.userType !== 5) {
    //     // history.push("/access-forbidden");
    //     history.push(UrlsAddress.ACCESS_FORBIDDEN);
    // } else {
    //     // setLoading(true);
    //     showLoader("Processing...");

    //     await getMOJData(idMOJ);

    //     hideLoader();
    // }

    return (
        <Container>
            <Wrapper>
                <Can
                    action={ActionTypes.INTERNAL_CONSULTANT_READ_MOJ_OVERVIEW}
                    yes={() => <MOJOverview />}
                />
                <Can
                    action={ActionTypes.INTERNAL_CONSULTANT_READ_MOJ_CHARTS}
                    yes={() => <MOJCharts />}
                />
                <Can
                    action={
                        ActionTypes.INTERNAL_CONSULTANT_READ_MOJ_APPLICATIONS
                    }
                    yes={() => <MOJApplicationsGrid />}
                />
            </Wrapper>
        </Container>
    );
};

export default ConsultantDashboard;
