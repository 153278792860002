import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useLocation, useHistory, Link } from "react-router-dom";
import * as Yup from "yup";

// Icons
import { FaChevronLeft } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../services/api";

// Hooks
import { useAuth } from "../../../hooks/auth";
import { useLoader } from "../../../hooks/loader";

// Common Components
import MainNew from "../../MainNew";
import Button from "../../../components/Button";
import InputNumberWithLabel from "../../../components/InputNumberWithLabel";

// Utils
import getValidationErrors from "../../../utils/getValidationErrors";

// Types
import UrlsAddress from "../../../types/urlsAddress";
import { IMOJCustomError } from "../../../types/globalTypes";
import { IBranchData } from "../../../types/internalConsultantsTypes";

import {
    Container,
    LinkContainer,
    LinkRow,
    TitleContainer,
    Title,
    BranchInfo,
    BranchName,
    MOJGoldCredits,
    GoldIcon,
    MOJSilverCredits,
    SilverIcon,
    MOJBronzeCredits,
    BronzeIcon,
    FormContent,
} from "./styles";

interface IManageMOJCreditsFormData {
    mojGoldCredits: number;
    mojSilverCredits: number;
    mojBronzeCredits: number;
}

const ManageCredits: React.FC = () => {
    const [branch, setBranch] = useState({} as IBranchData);

    const formRef = useRef<FormHandles>(null);

    const { user } = useAuth();
    const { showLoader, hideLoader } = useLoader();
    const location = useLocation();
    const history = useHistory();

    const idBranch = location.pathname.replace(
        `${UrlsAddress.INTERNAL_MANAGE_CREDITS_BRANCH}/`,
        "",
    );

    const fetchData = useCallback(async () => {
        if (user.userType !== 6 && user.userType !== 7) {
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            const branchResponse = await api.get(
                `internalConsultant/get-internal-branch/${idBranch}`,
                {
                    params: {
                        idBranch,
                    },
                },
            );

            if (branchResponse.status === 200) {
                const { data } = branchResponse.data;

                setBranch(data);
            }

            hideLoader();
        }
    }, [showLoader, hideLoader, history, idBranch, user.userType]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getBranchData = useMemo(() => {
        return branch;
    }, [branch]);

    const handleSubmit = useCallback(
        async (data: IManageMOJCreditsFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    mojGoldCredits: Yup.number()
                        .typeError("must be a number")
                        .required("Gold credits is required")
                        .min(0, "Min acceptable: 0"),
                    mojSilverCredits: Yup.number()
                        .typeError("must be a number")
                        .required("Silver credits is required")
                        .min(0, "Min acceptable: 0"),
                    mojBronzeCredits: Yup.number()
                        .typeError("must be a number")
                        .required("Bronze credits is required")
                        .min(0, "Min acceptable: 0"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Updating credits...");

                const formData = {
                    idBranch,
                    mojGoldCredits: Number(data.mojGoldCredits),
                    mojSilverCredits: Number(data.mojSilverCredits),
                    mojBronzeCredits: Number(data.mojBronzeCredits),
                };

                await api.put(
                    `internalConsultant/manage-branch-moj-credits/${idBranch}`,
                    formData,
                );

                toast.success("MOJ credits successfully updated!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // clean field e-mail
                history.push(UrlsAddress.INTERNAL_MANAGE_BRANCHES);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error(
                        "There was an error updating credits. Please, try again!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                hideLoader();
            }
        },
        [showLoader, hideLoader, history, idBranch],
    );

    return (
        <MainNew>
            <Container>
                <LinkContainer>
                    <LinkRow>
                        <FaChevronLeft />
                        <Link to={UrlsAddress.INTERNAL_MANAGE_BRANCHES}>
                            Go back to branches
                        </Link>
                    </LinkRow>
                </LinkContainer>
                <TitleContainer>
                    <Title>Manage Branch MOJ Credits</Title>
                </TitleContainer>
                <BranchInfo>
                    <BranchName>{getBranchData.name}</BranchName>
                    <MOJGoldCredits>
                        Gold credits: {getBranchData.mojGoldCreditsQuantity}
                    </MOJGoldCredits>
                    <MOJSilverCredits>
                        Silver credits: {getBranchData.mojSilverCreditsQuantity}
                    </MOJSilverCredits>
                    <MOJBronzeCredits>
                        Bronze credits: {getBranchData.mojBronzeCreditsQuantity}
                    </MOJBronzeCredits>
                </BranchInfo>
                <FormContent>
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={{
                            mojGoldCredits:
                                getBranchData.mojGoldCreditsQuantity,
                            mojSilverCredits:
                                getBranchData.mojSilverCreditsQuantity,
                            mojBronzeCredits:
                                getBranchData.mojBronzeCreditsQuantity,
                        }}
                    >
                        <InputNumberWithLabel
                            name="mojGoldCredits"
                            icon={GoldIcon}
                            label="Gold credits"
                            placeholder="Min: 0"
                        />
                        <InputNumberWithLabel
                            name="mojSilverCredits"
                            icon={SilverIcon}
                            label="Silver credits"
                            placeholder="Min: 0"
                        />
                        <InputNumberWithLabel
                            name="mojBronzeCredits"
                            icon={BronzeIcon}
                            label="Bronze credits"
                            placeholder="Min: 0"
                        />
                        <Button type="submit">SAVE</Button>
                    </Form>
                </FormContent>
            </Container>
        </MainNew>
    );
};

export default ManageCredits;
