import React from "react";

import { AuthProvider } from "./auth";
import { MOJProvider } from "./moj";
import { ToastProvider } from "./toast";
import { LoaderProvider } from "./loader";
import { CompanyCreditsProvider } from "./companyCredits";

const AppProvider: React.FC = ({ children }) => (
    <AuthProvider>
        <LoaderProvider>
            <ToastProvider>
                <CompanyCreditsProvider>
                    <MOJProvider>{children}</MOJProvider>
                </CompanyCreditsProvider>
            </ToastProvider>
        </LoaderProvider>
    </AuthProvider>
);

export default AppProvider;
