import React, {
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
} from "react";
import { Link } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { FiMail } from "react-icons/fi";
import * as Yup from "yup";

import { FaPlus, FaTimesCircle } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../services/api";

// Hooks
import { useLoader } from "../../hooks/loader";
// import { useToast } from "../../hooks/toast";
import { useCompanyCredits } from "../../hooks/companyCredits";

// MasterPage
import MainNew from "../MainNew";

// Common Components
import InputWithLabel from "../../components/InputWithLabel";
import Select from "../../components/Select";
import RadioInput from "../../components/RadioInput";
import getValidationErrors from "../../utils/getValidationErrors";

// Utils
import { ensureObjectExists } from "../../utils/arrayUtilities";

// Common Types
import { IMOJCustomError, IRadioOption } from "../../types/globalTypes";
import UrlsAddress from "../../types/urlsAddress";

import {
    Container,
    InvitationCard,
    InvitationTitle,
    InvitationDescription,
    FormContent,
    EmailError,
    AddEmailButton,
    AddEmailButtonContent,
    ListEmailsContainer,
    ListEmailsContainerTitle,
    EmailAddedRow,
    EmailInfoContainer,
    EmailAndProductLine,
    EmailText,
    ProductText,
    ProductBadge,
    MOJReportTypeLine,
    DeleteButton,
    SendLinkContainer,
    SendEmailButton,
    SendEmailButtonContent,
    ResultContainer,
    ResultContainerTitle,
    ResultErrorRow,
    LinkContainer,
} from "./styles";

interface IProduct {
    id: string;
    name: string;
    processingDays: number;
}

interface IInviteCandidateFormData {
    email: string;
    idProduct: string;
    mojReportType: number;
}

interface ICandidateEmail {
    email: string;
    idProduct: string;
    type: "bronze" | "silver" | "gold";
    mojReportType: number;
}

interface IInvitationCandidatesData {
    candidatesEmails: ICandidateEmail[];
    purchaseNumber: string;
}

interface ISelectOptions {
    value: string;
    label: string;
}

interface IResultCreateCandidatesMOJ {
    data: {
        failEmails: string[];
        fullyCreated: boolean;
        partiallyCreated: boolean;
        noneCreated: boolean;
        messages: IResultCreateCandidatesMOJMessage[];
        hasError: boolean;
        successMOJs: IResultCreateCandidatesMOJSuccessMOJ[];
    };
    success: boolean;
}

interface IResultCreateCandidatesMOJSuccessMOJ {
    email: string;
}

interface IResultCreateCandidatesMOJMessage {
    email: string;
    message: string;
}

const InviteCandidates: React.FC = () => {
    const [processingProductsRequest, setProcessingProductsRequest] = useState(
        false,
    );
    const [emailHasError, setEmailHasError] = useState(false);

    const [products, setProducts] = useState<IProduct[]>([]);
    const [productsOptions, setProductsOptions] = useState<ISelectOptions[]>(
        [],
    );
    const [emailsList, setEmailsList] = useState<ICandidateEmail[]>([]);

    const formAddEmailRef = useRef<FormHandles>(null);
    const formSendEmailsListRef = useRef<FormHandles>(null);

    // const [successMessages, setSuccessMessages] = useState<string[]>([]);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const radioOptions: IRadioOption[] = [
        { id: "1", value: "1", label: "All convictions" },
        { id: "2", value: "2", label: "Traffic convictions only" },
    ];

    // const { addToast } = useToast();
    const { showLoader, hideLoader } = useLoader();
    const { refreshCredits } = useCompanyCredits();

    const fetchData = useCallback(async () => {
        showLoader("Processing...");
        setProcessingProductsRequest(true);

        await api.get("home/getMOJsProductsCustomer").then(response => {
            const { data } = response;
            if (data && data.success) {
                if (data && data.success && data.data) {
                    let productsResponse: IProduct[] = data.data;

                    const productsList: ISelectOptions[] = [];

                    if (productsResponse) {
                        setProducts(productsResponse);

                        // productsResponse
                        //     .sort((a, b) =>
                        //         a.processingDays > b.processingDays ? 1 : -1,
                        //     )
                        //     .map(prod => {
                        //         productsList.push({
                        //             label: `${prod.name} - ${prod.processingDays} days`,
                        //             value: prod.id,
                        //         });
                        //     });

                        productsResponse = productsResponse.sort((a, b) =>
                            a.processingDays > b.processingDays ? 1 : -1,
                        );

                        for (
                            let index = 0;
                            index < productsResponse.length;
                            index++
                        ) {
                            const element = productsResponse[index];

                            productsList.push({
                                label: `${element.name} - ${element.processingDays} days`,
                                value: element.id,
                            });
                        }
                    }

                    setProductsOptions(productsList);
                }
            }
        });

        hideLoader();
        setProcessingProductsRequest(false);
    }, [showLoader, hideLoader]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMOJProducts = useMemo(() => {
        return productsOptions;
    }, [productsOptions]);

    const getEmailsList = useMemo(() => {
        return emailsList;
    }, [emailsList]);

    const handleRemoveEmail = (emailToRemove: string): void => {
        const remainingEmails = getEmailsList.filter(emailItem => {
            return emailItem.email !== emailToRemove;
        });

        setEmailsList(remainingEmails);
    };

    const getProductName = (idProduct: string): string => {
        const existingProduct = ensureObjectExists(
            products.find(productItem => productItem.id === idProduct),
        );

        return existingProduct.name;
    };

    const getProductType = useCallback(
        (idProduct: string): "bronze" | "silver" | "gold" => {
            const existingProduct = ensureObjectExists(
                products.find(productItem => productItem.id === idProduct),
            );

            if (existingProduct.name.toLowerCase() === "gold") {
                return "gold";
            }

            if (existingProduct.name.toLowerCase() === "silver") {
                return "silver";
            }

            return "bronze";
        },
        [products],
    );

    const validateExistingEmailAddress = useCallback(
        (emailAddress: string) => {
            setEmailHasError(false);

            const existingEmail = getEmailsList.find(
                emailItem => emailItem.email === emailAddress,
            );

            if (existingEmail) {
                setEmailHasError(true);
                return false;
            }

            return true;
        },
        [getEmailsList],
    );

    const fillResultPanel = useCallback(
        ({ data }: IResultCreateCandidatesMOJ) => {
            const listErrors: string[] = [];
            // const listSuccesses: string[] = [];

            setErrorMessages([]);
            // setSuccessMessages([]);

            const failEmails: string[] = data.failEmails.map(
                failEmailItem => failEmailItem,
            );

            const successEmails: string[] = data.successMOJs.map(
                successMOJ => successMOJ.email,
            );

            const hasErrors = failEmails.length > 0;
            const hasSuccess = successEmails.length > 0;

            for (let index = 0; index < data.messages.length; index++) {
                const element = data.messages[index];

                if (failEmails.includes(element.email)) {
                    listErrors.push(element.message);
                }

                // if (successEmails.includes(element.email)) {
                //     listSuccesses.push(`${element.email} - ${element.message}`);
                // }
            }

            if (failEmails.length > 0) {
                setErrorMessages(listErrors);
            }

            // if (successEmails.length > 0) {
            //     setSuccessMessages(listSuccesses);
            // }

            if (hasErrors && !hasSuccess) {
                toast.info("Check the results on the Results Panel below!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            if (hasSuccess && !hasErrors) {
                toast.success("All emails were sent successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [],
    );

    const handleAddEmail = useCallback(
        async (data: IInviteCandidateFormData) => {
            try {
                formAddEmailRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail address is required")
                        .email("E-mail address invalid"),
                    idProduct: Yup.string().required("Type is required"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                if (!validateExistingEmailAddress(data.email)) return;

                const newCandidateEmail: ICandidateEmail = {
                    email: data.email,
                    idProduct: data.idProduct,
                    type: getProductType(data.idProduct),
                    mojReportType: Number(data.mojReportType),
                };

                setEmailsList(oldState => [...oldState, newCandidateEmail]);

                if (formAddEmailRef && formAddEmailRef.current) {
                    formAddEmailRef.current.setFieldValue("email", "");
                    formAddEmailRef.current.setFieldValue("mojReportType", "1");
                }
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formAddEmailRef.current?.setErrors(errors);
                }
            }
        },
        [validateExistingEmailAddress, getProductType],
    );

    const handleSendEmails = useCallback(
        async (data: IInvitationCandidatesData) => {
            try {
                showLoader("Sending email(s)...");

                formSendEmailsListRef.current?.setErrors({});

                const formData: IInvitationCandidatesData = {
                    candidatesEmails: getEmailsList,
                    purchaseNumber: data.purchaseNumber,
                };

                const responseSendEmails = await api.post(
                    "Company/createCandidateMOJ",
                    formData,
                );

                const resultCreateCandidates: IResultCreateCandidatesMOJ =
                    responseSendEmails.data;

                setEmailsList([]);
                refreshCredits();

                fillResultPanel(resultCreateCandidates);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formAddEmailRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    if (!success && errors) {
                        const listErrors: string[] = [];

                        // errors.map(error => {
                        //     listErrors.push(error);
                        // });

                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            listErrors.push(element);
                        }

                        setErrorMessages(listErrors);
                    }
                } else {
                    // addToast({
                    //     type: "error",
                    //     title: "Error when sending emails",
                    //     description:
                    //         "There was an error sending emails. Please, try again!",
                    // });
                    toast.error(
                        "There was an error sending emails. Please, try again!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            } finally {
                hideLoader();
            }
        },
        [
            getEmailsList,
            showLoader,
            hideLoader,
            fillResultPanel,
            refreshCredits,
        ],
    );

    return (
        <MainNew>
            <Container>
                <InvitationCard>
                    <InvitationTitle>Send criminal check links</InvitationTitle>
                    <InvitationDescription>
                        Enter the e-mail address of the person you wish to
                        complete a criminal check form
                    </InvitationDescription>
                    <FormContent>
                        <Form
                            ref={formAddEmailRef}
                            onSubmit={handleAddEmail}
                            initialData={{
                                mojReportType: "1",
                            }}
                        >
                            <InputWithLabel
                                name="email"
                                icon={FiMail}
                                label="E-mail"
                                placeholder="E-mail *"
                                autoFocus
                            />
                            {emailHasError && (
                                <EmailError>
                                    Email address already added
                                </EmailError>
                            )}
                            <Select
                                name="idProduct"
                                placeholder="Type of MOJ *"
                                isLoading={processingProductsRequest}
                                data={getMOJProducts}
                                noOptionsMessage={() => "No type found!"}
                            />
                            <RadioInput
                                name="mojReportType"
                                options={radioOptions}
                                inline
                            />
                            <AddEmailButton type="submit">
                                <AddEmailButtonContent>
                                    <FaPlus />
                                    <span>Add email</span>
                                </AddEmailButtonContent>
                            </AddEmailButton>
                        </Form>
                    </FormContent>
                </InvitationCard>

                {getEmailsList.length > 0 && (
                    <ListEmailsContainer>
                        <ListEmailsContainerTitle>
                            Emails added
                        </ListEmailsContainerTitle>
                        {getEmailsList.map(emailItem => (
                            <EmailAddedRow
                                key={emailItem.email}
                                className="emailAddedRow"
                            >
                                <EmailInfoContainer>
                                    <EmailAndProductLine>
                                        <EmailText>{emailItem.email}</EmailText>
                                        <ProductText>
                                            <ProductBadge type={emailItem.type}>
                                                {getProductName(
                                                    emailItem.idProduct,
                                                )}
                                            </ProductBadge>
                                        </ProductText>
                                    </EmailAndProductLine>
                                    <MOJReportTypeLine>
                                        {emailItem.mojReportType === 1 ? (
                                            <span>All convictions</span>
                                        ) : (
                                            <span>
                                                Traffic convictions only
                                            </span>
                                        )}
                                    </MOJReportTypeLine>
                                </EmailInfoContainer>
                                <DeleteButton>
                                    <FaTimesCircle
                                        onClick={() =>
                                            handleRemoveEmail(emailItem.email)
                                        }
                                    />
                                </DeleteButton>
                            </EmailAddedRow>
                        ))}
                        <FormContent>
                            <Form
                                ref={formSendEmailsListRef}
                                onSubmit={handleSendEmails}
                            >
                                <InputWithLabel
                                    name="purchaseNumber"
                                    icon={FiMail}
                                    label="Purchase Number"
                                    placeholder="Purchase order number (optional)"
                                    autoFocus
                                />
                                <SendLinkContainer>
                                    <SendEmailButton type="submit">
                                        <SendEmailButtonContent>
                                            <RiMailSendLine />
                                            <span>Send link(s)</span>
                                        </SendEmailButtonContent>
                                    </SendEmailButton>
                                </SendLinkContainer>
                            </Form>
                        </FormContent>
                    </ListEmailsContainer>
                )}

                {errorMessages.length > 0 ? (
                    <ResultContainer>
                        <ResultContainerTitle>
                            Check the results for your request
                        </ResultContainerTitle>
                        {errorMessages &&
                            errorMessages.map(errorItem => (
                                <ResultErrorRow key={errorItem}>
                                    <span>{errorItem}</span>
                                </ResultErrorRow>
                            ))}
                        <LinkContainer>
                            {/* <Link to="/dashboard">Go to dashboard</Link> */}
                            <Link to={UrlsAddress.DASHBOARD}>
                                Go to dashboard
                            </Link>
                        </LinkContainer>
                    </ResultContainer>
                ) : null}
            </Container>
        </MainNew>
    );
};

export default InviteCandidates;
