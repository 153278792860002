import styled, { css } from "styled-components";
import { shade } from "polished";

interface IProductProps {
    type: "bronze" | "silver" | "gold";
}

const productBackgroundVariation = {
    bronze: css`
        /* background-image: linear-gradient(
            to right,
            #cd4432 0%,
            #e59e58 51%,
            #cd4432 100%
        ); */
        background-image: var(--background-bronze);
    `,

    silver: css`
        /* background-image: linear-gradient(
            to right,
            #9a9a9a 0%,
            #c0c0c0 51%,
            #9a9a9a 100%
        ); */
        background-image: var(--background-silver);
    `,

    gold: css`
        /* background-image: linear-gradient(
            to right,
            #ffae00 0%,
            #ffd700 51%,
            #ffae00 100%
        ); */
        background-image: var(--background-gold);
    `,
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const InvitationCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
`;

export const InvitationTitle = styled.h2`
    margin-bottom: 16px;
    font-size: 32px;
    text-align: center;
`;

export const InvitationDescription = styled.h4`
    margin-bottom: 16px;
    text-align: center;
`;

export const FormContent = styled.div`
    max-width: 600px;
    width: 100%;
`;

export const EmailError = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #c53030;
    border-radius: 10px;

    svg {
        margin-right: 10px;
    }
`;

export const AddEmailButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    background: #b2d235;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #312e38;
    width: 100%;
    font-weight: 700;
    margin-top: 16px;
    transition: background-color 0.2s;
    transition: color 0.2s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
        background: ${shade(0.2, "#b2d235")};
    }
`;

export const AddEmailButtonContent = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 16px;
    }

    span {
        text-transform: uppercase;
    }
`;

export const ListEmailsContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
`;

export const ListEmailsContainerTitle = styled.h2`
    margin-bottom: 16px;
`;

export const EmailAddedRow = styled.div`
    display: flex;
    align-items: center;
    /* margin: 8px 0 24px; */
    padding: 16px 0;
    width: 100%;
    max-width: 600px;

    svg {
        min-width: 30px;
        width: 20px;
        height: 20px;
        margin-left: 16px;
        color: #c53030;
        cursor: pointer;
    }

    & + div.emailAddedRow {
        border-top: 1px #b2d235 solid;
    }
`;

export const EmailInfoContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const EmailAndProductLine = styled.div`
    display: flex;
    align-items: center;
`;

export const MOJReportTypeLine = styled.div`
    margin-top: 8px;

    span {
        font-size: 14px;
    }
`;

export const EmailText = styled.span`
    font-weight: 500;
`;

export const ProductText = styled.div`
    flex: 1;
    margin-left: 16px;
`;

export const ProductBadge = styled.span<IProductProps>`
    letter-spacing: 0.3px;

    color: #333;

    ${props => productBackgroundVariation[props.type]}

    display: inline-block;
    padding: 8px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const DeleteButton = styled.div``;

export const SendLinkContainer = styled.div``;

export const SendEmailButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    background: #b2d235;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #312e38;
    width: 100%;
    font-weight: 700;
    margin-top: 16px;
    transition: background-color 0.2s;
    transition: color 0.2s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
        background: ${shade(0.2, "#b2d235")};
    }
`;

export const SendEmailButtonContent = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }

    span {
        text-transform: uppercase;
    }
`;

export const ResultContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
`;

export const ResultContainerTitle = styled.span`
    margin-bottom: 16px;
    font-size: 28px;
    text-align: center;
    max-width: 600px;
`;

export const ResultErrorRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 8px;
    margin: 8px 0;
    border-radius: 8px;

    width: 100%;
    max-width: 600px;

    background: #fddede;
    color: #c53030;

    span {
        margin-left: 8px;
        text-align: center;
    }
`;

export const LinkContainer = styled.div`
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: start;

    svg {
        margin-right: 8px;
    }

    a {
        color: #333;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: underline;
    }
`;
