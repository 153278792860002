import React, {
    useRef,
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useEffect,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

// Icons
import { FiMail, FiUser } from "react-icons/fi";
import {
    FaInfoCircle,
    FaPhoneAlt,
    FaIdCard,
    FaPlus,
    FaTimesCircle,
} from "react-icons/fa";

// types
import { IMOJPreviousName } from "../../../types/mojTypes";

// Hooks
import { useMOJ, IStepMainDetailFormData } from "../../../hooks/moj";

// Common Components
import InputWithLabel from "../../../components/InputWithLabel";
import InputNumberWithLabel from "../../../components/InputNumberWithLabel";
import InputInlineWithLabel from "../../../components/InputInlineWithLabel";
import Checkbox from "../../../components/Checkbox";

import getValidationErrors from "../../../utils/getValidationErrors";

// Styles
import {
    Container,
    Content,
    Warning,
    AddPreviousNames,
    PreviousNameList,
    PreviousNameItem,
    PreviousNameInputs,
    PreviousNameRemoveButton,
} from "./styles";

export interface IMainDetailsMethods {
    isValid(): boolean;
    validate(): void;
    returnMainDetailsData(): IStepMainDetailFormData;
}

const StepMailDetail: React.RefForwardingComponent<
    IMainDetailsMethods,
    IStepMainDetailFormData
> = (
    {
        email,
        firstName,
        middleName,
        lastName,
        contactNumber,
        mojPreviousNames,
        nzDriverLicenceNumber,
        noLastName,
    },
    ref,
) => {
    const formRef = useRef<FormHandles>(null);

    const [mainDetailsData, setMainDetailsData] = useState<
        IStepMainDetailFormData | undefined
    >();
    const [isFormValid, setIsFormValid] = useState(false);
    const [previousNamesList, setPreviousNamesList] = useState<
        IMOJPreviousName[]
    >([]);
    const [canAddPreviousName, setCanAddPreviousName] = useState(true);

    const { updateMainDetail } = useMOJ();

    useEffect(() => {
        const mainDetail: IStepMainDetailFormData = {
            email,
            firstName,
            middleName,
            lastName,
            contactNumber,
            mojPreviousNames,
            nzDriverLicenceNumber,
            noLastName,
        };

        setMainDetailsData(mainDetail);

        if (mojPreviousNames) {
            const listPreviousNames: IMOJPreviousName[] = [];

            for (let index = 0; index < mojPreviousNames.length; index++) {
                const element = mojPreviousNames[index];

                const newPreviousName: IMOJPreviousName = {
                    firstName: element.firstName,
                    middleName: element.middleName,
                    lastName: element.lastName,
                    order: element.order,
                };

                listPreviousNames.push(newPreviousName);
            }

            // mojPreviousNames.map(previousName => {
            //     const newPreviousName: IMOJPreviousName = {
            //         firstName: previousName.firstName,
            //         middleName: previousName.middleName,
            //         lastName: previousName.lastName,
            //         order: previousName.order,
            //     };
            //     listPreviousNames.push(newPreviousName);
            // });

            setPreviousNamesList(listPreviousNames);
        }
    }, [
        email,
        firstName,
        middleName,
        lastName,
        contactNumber,
        mojPreviousNames,
        nzDriverLicenceNumber,
        noLastName,
    ]);

    function isValid(): boolean {
        return isFormValid;
    }

    function validate(): void {
        formRef.current?.submitForm();
    }

    function returnMainDetailsData(): IStepMainDetailFormData {
        return mainDetailsData!;
    }

    useImperativeHandle(ref, () => ({
        isValid,
        validate,
        returnMainDetailsData,
    }));

    const handleAddNewPreviousName = (
        e: React.MouseEvent<HTMLElement>,
    ): void => {
        e.preventDefault();

        if (!canAddPreviousName) return;
        let order = 1;

        if (previousNamesList) {
            order = previousNamesList.length + 1;
        }

        const newPreviousName: IMOJPreviousName = {
            firstName: "",
            middleName: "",
            lastName: "",
            order,
        };

        setPreviousNamesList([...previousNamesList, newPreviousName]);

        if (previousNamesList.length >= 4) {
            setCanAddPreviousName(false);
        }
    };

    const handleRemoveNewPreviousName = (removeIndex: number): void => {
        if (previousNamesList) {
            const newPreviousNamesList = previousNamesList.filter(
                (previousName, index) => {
                    return index !== removeIndex;
                },
            );

            setPreviousNamesList(newPreviousNamesList);
        }

        if (previousNamesList.length <= 5) {
            setCanAddPreviousName(true);
        }
    };

    const yupLastNameValidator = (lastNameValue: string): boolean => {
        if (formRef && formRef.current) {
            const noLastNameValue = formRef.current.getFieldValue("noLastName");

            if (!noLastNameValue && !lastNameValue) return false;

            return true;
        }

        return false;
    };

    const handleValidation = useCallback(
        async (data: IStepMainDetailFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail is required")
                        .email("E-mail is invalid"),
                    firstName: Yup.string().required("First Name is required"),
                    lastName: Yup.string().test(
                        "Last Name",
                        "Last Name is required",
                        value => yupLastNameValidator(value),
                    ),
                    contactNumber: Yup.string().required(
                        "Contact number is required",
                    ),
                    mojPreviousNames: Yup.array(
                        Yup.object({
                            firstName: Yup.string().required(
                                "First name is required",
                            ),
                        }),
                    ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                await updateMainDetail(data);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    setIsFormValid(false);
                }
            }
        },
        [updateMainDetail],
    );

    return (
        <Container>
            <Content>
                <Form
                    ref={formRef}
                    onSubmit={handleValidation}
                    initialData={mainDetailsData}
                >
                    <InputWithLabel
                        name="email"
                        label="E-mail"
                        icon={FiMail}
                        readOnly
                    />
                    <Warning>
                        <FaInfoCircle />
                        <span>
                            Please make sure the name you enter here matches
                            with your identification document you upload in step
                            4
                        </span>
                    </Warning>
                    <InputWithLabel
                        name="firstName"
                        icon={FiUser}
                        label="First Name"
                        placeholder="First Name *"
                        autoFocus
                    />
                    <InputWithLabel
                        name="middleName"
                        icon={FiUser}
                        label="Middle Name"
                        placeholder="Middle Name"
                    />
                    <InputWithLabel
                        name="lastName"
                        icon={FiUser}
                        label="Last Name"
                        placeholder="Last Name *"
                    />
                    <Checkbox
                        name="noLastName"
                        small
                        noBackground
                        style={{ marginBottom: "10px" }}
                    >
                        No Last Name
                    </Checkbox>
                    <Warning>
                        <FaInfoCircle />
                        <span>
                            Please make sure to add any Previous names - Maiden
                            names, other names you are known as, or have used.
                            Click on the button below to add previous names:
                        </span>
                    </Warning>
                    <PreviousNameList>
                        {previousNamesList &&
                            previousNamesList.map((previousName, index) => (
                                <PreviousNameItem key={previousName.order}>
                                    <PreviousNameInputs>
                                        <InputInlineWithLabel
                                            name={`mojPreviousNames[${index}].firstName`}
                                            label="First Name"
                                            placeholder="First Name *"
                                        />
                                        <InputInlineWithLabel
                                            name={`mojPreviousNames[${index}].middleName`}
                                            label="Middle Name"
                                            placeholder="Middle Name"
                                        />
                                        <InputInlineWithLabel
                                            name={`mojPreviousNames[${index}].lastName`}
                                            label="Last Name"
                                            placeholder="Last Name"
                                        />
                                    </PreviousNameInputs>
                                    <PreviousNameRemoveButton>
                                        <FaTimesCircle
                                            onClick={() =>
                                                handleRemoveNewPreviousName(
                                                    index,
                                                )
                                            }
                                        />
                                    </PreviousNameRemoveButton>
                                </PreviousNameItem>
                            ))}
                    </PreviousNameList>
                    <AddPreviousNames
                        enabled={canAddPreviousName}
                        onClick={handleAddNewPreviousName}
                    >
                        <FaPlus />
                        <span>Add previous name</span>
                    </AddPreviousNames>
                    <InputNumberWithLabel
                        name="contactNumber"
                        icon={FaPhoneAlt}
                        label="Contact Number"
                        placeholder="Contact Number *"
                    />
                    <InputWithLabel
                        name="nzDriverLicenceNumber"
                        icon={FaIdCard}
                        label="NZ Driver Licence"
                        placeholder="NZ Driver Licence"
                    />
                </Form>
            </Content>
        </Container>
    );
};

export default forwardRef(StepMailDetail);
