import React, { useState, useEffect, useRef } from "react";

// Icons
import { FcLike } from "react-icons/fc";

// Hooks
import UseOutsideClick from "../../hooks/UseOutsideClick";

// Common Components
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";
import Sidebar from "./Sidebar";

import {
    Container,
    FixedHeader,
    SidebarContainer,
    MainContainer,
    MainContent,
    FixedFooter,
} from "./styles";

interface IMainProps {
    center?: boolean;
}

const Main: React.FC<IMainProps> = ({ center, children, ...rest }) => {
    const [showUserSettings, setShowUserSettings] = useState(false);
    const [contractAside, setContractAside] = useState(false);
    const [showButtonContractAside, setShowButtonContractAside] = useState(
        true,
    );
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const refUserSettings = useRef<HTMLDivElement>(null);

    const handleScreeResize = (): void => {
        setScreenWidth(window.innerWidth);

        if (screenWidth < 900) {
            setShowButtonContractAside(false);
            setContractAside(true);
        } else {
            setShowButtonContractAside(true);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleScreeResize);

        if (screenWidth < 900) {
            setShowButtonContractAside(false);
        }

        return () => {
            window.removeEventListener("resize", handleScreeResize);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    UseOutsideClick(refUserSettings, () => {
        if (showUserSettings) {
            setShowUserSettings(false);
        }
    });

    return (
        <Container contractAside={contractAside}>
            <FixedHeader
                showUserSettings={showUserSettings}
                showButtonContractAside={showButtonContractAside}
            >
                <MobileHeader />
                <DesktopHeader />
            </FixedHeader>
            <SidebarContainer>
                <Sidebar />
            </SidebarContainer>
            <MainContainer>
                <MainContent center={center}>{children}</MainContent>
            </MainContainer>
            <FixedFooter>
                <div className="footer-container">
                    <strong>BOSS Technologies</strong>
                    <span>
                        © {new Date().getFullYear()}. Made with{" "}
                        <FcLike size={18} /> for a better web
                    </span>
                </div>
            </FixedFooter>
        </Container>
    );
};

export default Main;
