import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

// CSS for React Toastify
import "react-toastify/dist/ReactToastify.css";
// Toast
import { ToastContainer } from "react-toastify";

import GlobalStyle from "./styles/global";

import Routes from "./routes";

import AppProvider from "./hooks";

const App: React.FC = () => (
    <Router>
        <AppProvider>
            <Routes />
        </AppProvider>
        <GlobalStyle />
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </Router>
);

export default App;
