import styled from "styled-components";

interface IFixedHeaderProps {
    showUserSettings: boolean;
    showButtonContractAside: boolean;
}

interface IContainerProps {
    contractAside: boolean;
}

interface IAsideProps {
    contractMenu: boolean;
}

interface IMainContentProps {
    center?: boolean;
}

export const Container = styled.div<IContainerProps>`
    display: grid;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr 45px;
    grid-template-areas:
        "header"
        "main"
        "footer";

    @media only screen and (min-width: 768px) {
        grid-template-columns: 130px 1fr;
        grid-template-rows: 60px 1fr 45px;
        grid-template-areas:
            "sidebar    header  header"
            "sidebar    main    main"
            "sidebar    footer  footer";
    }
`;

export const FixedHeader = styled.nav<IFixedHeaderProps>`
    background: #fff;
    display: grid;
    border-bottom: 1px solid #b2d235;
    grid-area: header;
`;

export const CreditsContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: red;
    border: none;

    svg {
        margin-left: 8px;
    }
`;

export const CreditsContent = styled.ul``;

export const SidebarContainer = styled.aside`
    background: #fff;
    display: none;
    grid-area: sidebar;
    z-index: 10;

    box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.2),
        0px 1px 6px 0px rgba(0, 0, 0, 0.14), 0px 0px 6px 0px rgba(0, 0, 0, 0.12);

    @media only screen and (min-width: 768px) {
        display: grid;
    }
`;

export const MainContainer = styled.div`
    display: grid;
    grid-area: main;
    overflow: hidden;
    overflow-y: auto;
`;

export const MainContent = styled.div<IMainContentProps>`
    margin: 0 auto;

    display: flex;
    align-items: ${({ center }) => (center ? "center" : "baseline")};
    justify-content: center;

    width: 100%;
    max-width: 1200px;

    @media only screen and (min-width: 450px) {
        padding: 25px;
    }
`;

export const FixedFooter = styled.footer`
    background: #fff;
    display: grid;
    grid-area: footer;
    border-top: 1px solid #b2d235;

    div.footer-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;

        strong {
            margin-right: 10px;
            text-align: center;
        }

        span {
            text-align: center;
        }
    }
`;
