import React from "react";

import { Section as SectionComponent } from "./styles";

interface ISectionProps {
    id: string;
    isHero: boolean;
    title?: string;
    backgroundImg?: string;
    backgroundColor?: string;
    titleColor?: string;
    titleBackgroundColor?: string;
}

const Section: React.FC<ISectionProps> = ({
    id,
    title,
    isHero,
    backgroundImg,
    backgroundColor,
    titleColor,
    titleBackgroundColor,
    children,
    ...rest
}) => {
    return (
        <SectionComponent
            hero={isHero}
            id={id}
            backgroundImg={backgroundImg}
            backgroundColor={backgroundColor}
            titleColor={titleColor}
            titleBackgroundColor={titleBackgroundColor}
            className="landing-section"
        >
            <div className="title-container">
                <div className="title-content">
                    {title && <h1 className="section-Title">{title}</h1>}
                </div>
            </div>
            <div className="container">
                <div className="content">{children}</div>
            </div>
        </SectionComponent>
    );
};

export default Section;
