import React from "react";

import Modal from "../../components/Modal";

import {
    Container,
    Paragraph,
    Subtitle,
    OrderedList,
    SquareList,
} from "./styles";

interface IModalProps {
    show: boolean;
    size: "sm" | "md" | "lg" | "xl";
    onClose: any;
}

const TermsConditionsModal: React.FC<IModalProps> = ({
    show,
    size,
    onClose,
}) => {
    return (
        <Modal
            onClose={onClose}
            show={show}
            size={size}
            title="Terms and Conditions"
        >
            <Container>
                <Paragraph>
                    These terms & conditions are a legal agreement between BOSS
                    Online and yourself. By requesting a Criminal Record Check,
                    you agree that you have read and understood these terms &
                    conditions.
                </Paragraph>
                <Subtitle>Release of information</Subtitle>
                <Paragraph>
                    BOSS Online can not release any information without you
                    filling out and signing the Criminal Check Application Form
                    and providing us with your valid ID.
                </Paragraph>

                <Subtitle>Applicant’s obligation</Subtitle>
                <Paragraph>
                    Before submitting a Criminal Check, the applicant must
                    review all information provided to make sure the information
                    provided is accurate to the best of their knowledge. If any
                    incorrect information is provided, it will result in a
                    returned uncompleted Criminal Check.
                </Paragraph>
                <Paragraph>
                    If the correct information has not been provided within
                    three months, the applicant acknowledges that another
                    application will be needed.
                </Paragraph>

                <Subtitle>Criminal record check</Subtitle>
                <Paragraph>
                    The applicant understands that there are three types of
                    checks BOSS Online can carry out:
                </Paragraph>
                <OrderedList>
                    <li>Gold Ministry of Justice Check – 4 working days</li>
                    <li>Silver Ministry of Justice Check – 11 working days</li>
                    <li>Bronze Ministry of Justice Check – 16 working days</li>
                </OrderedList>
                <Paragraph>
                    The applicant understands BOSS Online can not fasten the
                    process and if there is extra information required to carry
                    out the check this may result in longer period of times
                    before the Criminal Check result comes back.
                </Paragraph>

                <Subtitle>Refunds</Subtitle>
                <Paragraph>BOSS Online will not provide a refund if:</Paragraph>
                <SquareList>
                    <li>
                        The Applicant provides us with incorrect information on
                        the Criminal Check Application Form
                    </li>
                    <li>
                        The applicant uses incorrect spelling when filling out
                        the Criminal Check Application Form
                    </li>
                    <li>
                        The Applicant submits the same check more than one time
                        (by mistake or otherwise)
                    </li>
                    <li>Unauthorized Criminal Checks</li>
                    <li>
                        The applicant has second thoughts about submitting a
                        check once the application has been started
                    </li>
                    <li>
                        The Applicant selects the wrong type or reason for the
                        Criminal Check
                    </li>
                </SquareList>

                <Paragraph fontStyle="italic">
                    For any information relating to the privacy of your
                    information, please contact our team via email:{" "}
                    <a href="mailto:admin@bossonline.co.nz">
                        admin@bossonline.co.nz
                    </a>
                </Paragraph>
            </Container>
        </Modal>
    );
};

export default TermsConditionsModal;
