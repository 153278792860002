import React, { useCallback, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { FiLogIn, FiMail, FiLock } from "react-icons/fi";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";

// Toast
import { toast } from "react-toastify";

// Hooks
import { useAuth } from "../../../hooks/auth";
// import { useToast } from "../../../hooks/toast";

// Common Components
import InputWithLabel from "../../../components/InputWithLabel";
import InputPasswordWithLabel from "../../../components/InputPasswordWithLabel";
import Button from "../../../components/Button/index";
import getValidationErrors from "../../../utils/getValidationErrors";
import Loader from "../../../components/Loader";

// Logo
import logoImg from "../../../assets/boss-logo.png";

// Common Types
import { IMOJCustomError } from "../../../types/globalTypes";
import UrlsAddress from "../../../types/urlsAddress";

// Styles
import { Container, Content, AnimationContainer, Background } from "./styles";

interface ISignInFormData {
    email: string;
    password: string;
}

const SignIn: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [loadingDescription, setLoadingDescription] = useState("");

    const formRef = useRef<FormHandles>(null);

    const { signIn } = useAuth();
    // const { addToast } = useToast();
    const history = useHistory();

    const showLoader = (message: string): void => {
        setLoading(true);
        setLoadingDescription(message);
    };

    const hideLoader = (): void => {
        setLoading(false);
        setLoadingDescription("");
    };

    const handleSubmit = useCallback(
        async (data: ISignInFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail address is required")
                        .email("E-mail address invalid"),
                    password: Yup.string().required("Password is required"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Signing In...");

                await signIn({
                    email: data.email,
                    password: data.password,
                });

                // history.push("/dashboard");
                history.push(UrlsAddress.DASHBOARD);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    hideLoader();

                    if (!success && errors) {
                        // errors.map(error => {
                        //     return addToast({
                        //         type: "error",
                        //         title: "Error when authenticating",
                        //         description: error,
                        //     });
                        // });

                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    // addToast({
                    //     type: "error",
                    //     title: "Error when authenticating",
                    //     description:
                    //         "There was an error signing in, check your credentials!",
                    // });

                    toast.error(
                        "There was an error signing in, check your credentials!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            }
        },
        [signIn, history],
    );

    return (
        <>
            <Container>
                {/*  */}
                <Content>
                    <AnimationContainer>
                        <img src={logoImg} alt="MOJ" />

                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <h1>Log In</h1>

                            <InputWithLabel
                                name="email"
                                icon={FiMail}
                                placeholder="E-mail *"
                                label="E-mail"
                                autoFocus
                            />
                            <InputPasswordWithLabel
                                name="password"
                                icon={FiLock}
                                label="Password"
                                placeholder="Password"
                            />
                            <Button type="submit">Login</Button>
                            {/* <Link to="/forgot-password"> */}
                            <Link to={UrlsAddress.FORGOT_PASSWORD}>
                                Forgot my password
                            </Link>
                        </Form>

                        {/* <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        /> */}

                        {/* <Link to="/sign-up-customer"> */}
                        <Link to={UrlsAddress.SIGN_UP_CUSTOMER}>
                            <FiLogIn />
                            Sign Up (Customer)
                        </Link>

                        {/* <Link to="/sign-up-company"> */}
                        <Link to={UrlsAddress.SIGN_UP_COMPANY}>
                            <FiLogIn />
                            Sign Up (Company)
                        </Link>
                    </AnimationContainer>
                </Content>
                <Background />
            </Container>
            <Loader show={loading} description={loadingDescription} />
        </>
    );
};

export default SignIn;
