import React, { useCallback, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";

// Icons
import { FiLogIn, FiMail, FiLock, FiUser } from "react-icons/fi";

// Toast
import { toast } from "react-toastify";

// API Service
import api from "../../../services/api";

// Hooks
// import { useToast } from "../../../hooks/toast";
import { useAuth } from "../../../hooks/auth";

// Common Components
import InputWithLabel from "../../../components/InputWithLabel";
import InputPasswordWithLabel from "../../../components/InputPasswordWithLabel";
import Button from "../../../components/Button/index";
import Checkbox from "../../../components/Checkbox";
import getValidationErrors from "../../../utils/getValidationErrors";
import Loader from "../../../components/Loader";

// Logo
import logoImg from "../../../assets/boss-logo.png";

// Modals
import TermsConditionsModal from "../../TermsConditionsModal";
import PrivacyPolicyModal from "../../PrivacyPolicyModal";

// Types
import { IMOJCustomError } from "../../../types/globalTypes";
import UrlsAddress from "../../../types/urlsAddress";

// Styles
import {
    Container,
    Background,
    AnimationContainer,
    Content,
    Logo,
    Title,
    FormContainer,
    Links,
} from "./styles";

interface ISignUpCustomerFormData {
    firstName: string;
    middleName?: string;
    lastName?: string;
    email: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
    termsOfService: boolean;
}

const SignUpCustomer: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [loadingDescription, setLoadingDescription] = useState("");
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);

    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();
    const { signIn } = useAuth();
    const history = useHistory();

    const showLoader = (message: string): void => {
        setLoading(true);
        setLoadingDescription(message);
    };

    const hideLoader = (): void => {
        setLoading(false);
        setLoadingDescription("");
    };

    const handleShowTermsClick = (event: any): void => {
        event.stopPropagation();
        event.preventDefault();

        setShowTerms(true);
    };

    const handleShowPrivacyClick = (event: any): void => {
        event.stopPropagation();
        event.preventDefault();

        setShowPrivacy(true);
    };

    const yupEmailMatchValidation = (confirmEmail: string): boolean => {
        if (formRef && formRef.current) {
            const emailValue: string = formRef.current.getFieldValue("email");
            return emailValue.toLowerCase() === confirmEmail.toLowerCase();
        }

        return false;
    };

    const handleSubmit = useCallback(
        async (data: ISignUpCustomerFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    firstName: Yup.string().required("First Name is required"),
                    email: Yup.string()
                        .required("E-mail address is required")
                        .email("E-mail address invalid"),

                    confirmEmail: Yup.string()
                        .email()
                        .test(
                            "emailMatch",
                            "E-mail and e-mail confirmation do not match",
                            value => yupEmailMatchValidation(value),
                        ),
                    password: Yup.string().min(
                        8,
                        "Password has to be longer than 8 characters",
                    ),
                    confirmPassword: Yup.string().when("password", {
                        is: val => val && val.length > 0,
                        then: Yup.string()
                            .oneOf(
                                [Yup.ref("password")],
                                "Password and password confirmation do not match",
                            )
                            .required(),
                    }),
                    termsOfService: Yup.boolean()
                        .required("The terms and conditions must be accepted")
                        .oneOf(
                            [true],
                            "The terms and conditions must be accepted",
                        ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader("Registering...");

                await api.post("/customer/register", data);

                toast.success("User registered!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                await signIn({
                    email: data.email,
                    password: data.password,
                });

                // history.push("/dashboard");
                history.push(UrlsAddress.DASHBOARD);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                if (err.response.data) {
                    const {
                        errors,
                        success,
                    }: IMOJCustomError = err.response.data;

                    hideLoader();

                    if (!success && errors) {
                        // errors.map(error => {
                        //     addToast({
                        //         type: "error",
                        //         title: "Error when creating user",
                        //         description: error,
                        //     });
                        // });

                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            // addToast({
                            //     type: "error",
                            //     title: "Error when creating user",
                            //     description: element,
                            // });
                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    // addToast({
                    //     type: "error",
                    //     title: "Error when creating user",
                    //     description:
                    //         "There was an error signing up, check the required data!",
                    // });
                    toast.error(
                        "There was an error signing up, check the required data!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        },
                    );
                }
            }
        },
        [history, signIn],
    );

    return (
        <>
            <Container>
                <Background />
                <AnimationContainer>
                    <Content>
                        <Logo>
                            <img src={logoImg} alt="MOJ" />
                        </Logo>
                        <Title>
                            <h1>Sign Up</h1>
                            <h3>Customer</h3>
                        </Title>
                        <FormContainer>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <InputWithLabel
                                    name="firstName"
                                    icon={FiUser}
                                    label="First Name"
                                    placeholder="First Name *"
                                />
                                <InputWithLabel
                                    name="middleName"
                                    icon={FiUser}
                                    label="Middle Name"
                                    placeholder="Middle Name"
                                />
                                <InputWithLabel
                                    name="lastName"
                                    icon={FiUser}
                                    label="Last Name"
                                    placeholder="Last Name"
                                />
                                <InputWithLabel
                                    name="email"
                                    icon={FiMail}
                                    label="E-mail"
                                    placeholder="E-mail *"
                                />
                                <InputWithLabel
                                    name="confirmEmail"
                                    icon={FiMail}
                                    label="Confirm E-mail"
                                    placeholder="Confirm your E-mail *"
                                />
                                <InputPasswordWithLabel
                                    name="password"
                                    icon={FiLock}
                                    label="Password"
                                    placeholder="Password *"
                                />
                                <InputPasswordWithLabel
                                    name="confirmPassword"
                                    icon={FiLock}
                                    label="Confirm Password"
                                    placeholder="Confirm your Password *"
                                />

                                <Checkbox name="termsOfService" noBackground>
                                    I've read, understand and agree to the{" "}
                                    <label
                                        onClick={handleShowTermsClick}
                                        aria-hidden="true"
                                    >
                                        Terms and Conditions
                                    </label>{" "}
                                    &{" "}
                                    <label
                                        onClick={handleShowPrivacyClick}
                                        aria-hidden="true"
                                    >
                                        Privacy Policy
                                    </label>
                                </Checkbox>

                                <Button type="submit">Sign Up</Button>
                            </Form>
                        </FormContainer>
                        <Links>
                            {/* <Link to="/sign-in"> */}
                            <Link to={UrlsAddress.SIGN_IN}>
                                <FiLogIn />
                                Login
                            </Link>

                            {/* <Link to="/sign-up-company"> */}
                            <Link to={UrlsAddress.SIGN_UP_COMPANY}>
                                <FiLogIn />
                                Sign Up (Company)
                            </Link>
                        </Links>
                    </Content>
                </AnimationContainer>
            </Container>
            <TermsConditionsModal
                onClose={setShowTerms}
                show={showTerms}
                size="xl"
            />

            <PrivacyPolicyModal
                onClose={setShowPrivacy}
                show={showPrivacy}
                size="xl"
            />
            <Loader show={loading} description={loadingDescription} />
        </>
    );
};

export default SignUpCustomer;
