import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    background: #fff;
    border-radius: 10px;
    padding: 25px;
`;

export const Warning = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 2px solid #c53030;
    margin: 10px 0;
    border-radius: 10px;

    svg {
        margin-right: 16px;
        min-width: 25px;
        width: 30px;
        height: 30px;
        color: #c53030;
    }

    span {
        text-align: start;
        color: #333;
        font-weight: 500;
        font-size: 20px;
    }
`;

export const MainDetails = styled.div`
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    /* flex: 1; */

    border: 2px #333 solid;
`;

export const BirthDetails = styled.div`
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 16px;

    border: 2px #333 solid;
`;

export const AddressDetails = styled.div`
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;

    border: 2px #333 solid;
`;

export const ProofOfIdentityDetails = styled.div`
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 16px;

    border: 2px #333 solid;
`;

export const SignatureDetails = styled.div`
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 16px;

    border: 2px #333 solid;
`;

export const StepTitle = styled.div`
    /* background-color: #eaf6bc; */
    /* padding: 8px; */
    /* border-radius: 8px; */
    margin-bottom: 8px;
    width: 100%;
    /* background: red; */
    display: flex;
    justify-content: center;
    border-bottom: 2px #333 solid;
`;

export const StepTitleText = styled.span`
    color: #333;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
`;

export const StepLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 8px;
    }
`;

export const StepLineField = styled.span`
    font-weight: 500;
    font-size: 18px;
`;

export const StepLineValue = styled.span`
    font-weight: 400;
    margin-left: 8px;
`;

export const PreviousNamesList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`;

export const PreviousNamesListTitle = styled.span`
    font-weight: 500;
    color: #333;
    margin-bottom: 8px;
`;

export const PreviousNameListBoxes = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-self: center;
`;

export const PreviousNameBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;
    /* border: 2px #b2d235 solid; */
    border-radius: 8px;

    min-width: 290px;
    flex-shrink: 1;

    > span {
        background: #e0f787;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`;

export const PreviousNameBoxTitle = styled.span`
    display: flex;
    width: 100%;
    font-weight: 500;
    text-decoration: underline;
    justify-content: center;
    padding: 8px;
    background: #b2d235;
`;

export const PreviousNameBoxLabels = styled.div`
    padding: 8px;

    background: #cce95a;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const PreviousNameBoxLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    & + & {
        margin-top: 8px;
    }
`;

export const PreviousNameValue = styled.span`
    font-weight: 400;
    /* margin-left: 8px; */
`;

export const AddressListBoxes = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-self: center;
`;

export const AddressBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;
    /* border: 2px #b2d235 solid; */
    border-radius: 8px;

    min-width: 290px;
    flex-shrink: 1;

    > span {
        background: #e0f787;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`;

export const AddressBoxTitle = styled.span`
    display: flex;
    width: 100%;
    font-weight: 500;
    text-decoration: underline;
    justify-content: center;
    padding: 8px;
    background: #b2d235;
`;

export const AddressBoxLabels = styled.div`
    padding: 8px;

    background: #cce95a;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const AddressBoxLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 8px;
    }
`;

export const AddressBoxField = styled.span`
    font-weight: 500;
    /* font-size: 18px; */
`;

export const AddressBoxValue = styled.span`
    font-weight: 400;
    margin-left: 8px;
`;

export const ProofOfIdentityDisplay = styled.div`
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }

    iframe {
        width: 100%;
        height: 600px;
    }
`;

export const TypeOfService = styled.div`
    margin-bottom: 16px;
`;

export const CopyRequired = styled.div`
    margin-bottom: 16px;
`;

export const SignatureDisplay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    span {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 500;
    }

    img {
        max-width: 350px;
        height: auto;
        background: #ced4da;
        border: 2px #000 solid;
    }
`;
