import styled from "styled-components";
import { RiStarFill } from "react-icons/ri";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem 2rem;

    background: #fff;
    border-radius: 0.5rem;
`;

export const LinkContainer = styled.div`
    display: flex;
    align-self: flex-start;
    margin-bottom: 1.5rem;
    width: 100%;
`;

export const LinkRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    svg {
        margin-right: 8px;
    }

    a {
        color: #333;
        font-weight: 400;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 1rem;

    background: #f2f1f3;
    border-radius: 0.5rem;
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    text-align: center;
`;

export const BranchInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    gap: 0.5rem;
    width: 100%;
`;

export const BranchName = styled.h2`
    margin: 1rem 0;
`;

export const MOJGoldCredits = styled.div`
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    background: var(--background-gold);
`;

export const GoldIcon = styled(RiStarFill)`
    color: var(--color-gold-dark) !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
`;

export const MOJSilverCredits = styled.div`
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    background: var(--background-silver);
`;

export const SilverIcon = styled(RiStarFill)`
    color: var(--color-silver-dark) !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
`;

export const MOJBronzeCredits = styled.div`
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    background: var(--background-bronze);
`;

export const BronzeIcon = styled(RiStarFill)`
    color: var(--color-bronze-dark) !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
`;

export const FormContent = styled.div`
    max-width: 600px;
    width: 100%;
    margin-top: 1rem;
`;
