import React, { useEffect, useCallback, useRef, useMemo } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";

// Icons
import {
    FaArrowLeft,
    FaArrowRight,
    FaUserEdit,
    FaBirthdayCake,
    FaMapMarkerAlt,
    FaPassport,
    FaSignature,
    FaBars,
    FaCheck,
    FaSpinner,
    FaChevronLeft,
} from "react-icons/fa";

// Hooks
import { useAuth } from "../../hooks/auth";
import { useMOJ } from "../../hooks/moj";
import { useLoader } from "../../hooks/loader";

// MasterPage
import Main from "../Main";
import MainNew from "../MainNew";

// Steps
import StepMainDetail, { IMainDetailsMethods } from "./StepMainDetail";
import StepBirthDetail, { IBirthDetailsMethods } from "./StepBirthDetail";
import StepAddress, { IAddressMethods } from "./StepAddress";
import StepProofOfIdentity, {
    IProofOfIdentityMethods,
} from "./StepProofOfIdentity";
import StepSignature, { ISignatureMethods } from "./StepSignature";
import StepSummary from "./StepSummary";

// Common Types
import UrlsAddress from "../../types/urlsAddress";

import {
    Container,
    LinkContainer,
    LinkRow,
    WizardTitle,
    MOJTitle,
    MOJSubtitle,
    MOJDetails,
    MOJCode,
    MOJExpiryDate,
    WizardBody,
    WizardBodyForm,
    WizardBodyFormHeader,
    WizardBodyFormHeaderNavigation,
    WizardBodyFormHeaderNavButton,
    WizardBodyFormHeaderStep,
    WizardBodyFormContent,
    WizardActions,
    ActionsContainer,
    Previous,
    Next,
    Submit,
} from "./styles";

interface IMOJ {
    id: string;
    mojFormLink: string;
    code: string;
    expiryDateLink: string;
    // Main Details
    email: string;
    firstName: string;
    middleName?: string;
    lastName?: string;
    contactNumber: string;
    nzDriverLicenceNumber?: string;
    mojPreviousNames?: IMOJPreviousName[];
    // Birth Details
    birthDate?: string;
    birthCity?: string;
    idBirthCountry?: string;
    gender?: number;
    // Address
    mojAddresses?: IMOJAddress[];
    isResidentialAddressEqualToPostalAddress: boolean;
    // Identity of Proof
    mojApplicantIdentificationType?: number;
    identityFormLink?: string;
    hasIncludedProofOfIdentity: boolean;
    hasAgreedWithIDTerms: boolean;
    // Signature
    copyRequiredViaEmail: boolean;
    copyRequiredViaPost: boolean;
    copyNotRequired: boolean;
    signatureLink?: string;
    hasAcceptedTermsConditions: boolean;
}

interface IMOJAddress {
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    idCountry: string;
    order: number;
    addressType: MOJAddressType;
}

enum MOJAddressType {
    PostalAddress = 1,
    CurrentResidential = 2,
    Other1 = 3,
    Other2 = 4,
    Other3 = 5,
}

interface IMOJPreviousName {
    firstName: string;
    middleName?: string;
    lastName?: string;
    order: number;
}

interface IFormStep {
    idPrevStep?: number;
    idCurrentStep: number;
    idNextStep?: number;
    showSubmit: boolean;
}

const MOJ: React.FC = () => {
    // Context
    const {
        getMOJData,
        moj,
        goBack,
        goToStep,
        showNextButton,
        showPreviousButton,
        showSubmitButton,
        processingRequest,
        submitMOJ,
    } = useMOJ();
    const { showLoader, hideLoader } = useLoader();

    // Steps Components Refs
    const mainDetailsRef = useRef<IMainDetailsMethods>(null);
    const birthDetailsRef = useRef<IBirthDetailsMethods>(null);
    const addressRef = useRef<IAddressMethods>(null);
    const proofOfIdentityRef = useRef<IProofOfIdentityMethods>(null);
    const signatureRef = useRef<ISignatureMethods>(null);

    const { user } = useAuth();
    const history = useHistory();
    const location = useLocation();

    // const idMOJ = location.pathname.replace("/moj/", "");
    const idMOJ = location.pathname.replace(`${UrlsAddress.MOJ}/`, "");

    const fetchData = useCallback(async () => {
        // 2 = customer
        // 5 = candidate
        if (user.userType !== 2 && user.userType !== 5) {
            // history.push("/access-forbidden");
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            // setLoading(true);
            showLoader("Processing...");

            await getMOJData(idMOJ);

            hideLoader();
        }
    }, [showLoader, hideLoader, getMOJData, history, idMOJ, user.userType]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStepValidation = useCallback((): void => {
        switch (moj && moj.currentStep.id) {
            case 1:
                mainDetailsRef.current?.validate();
                break;
            case 2:
                birthDetailsRef.current?.validate();
                break;
            case 3:
                addressRef.current?.validate();
                break;
            case 4:
                proofOfIdentityRef.current?.validate();
                break;
            case 5:
                signatureRef.current?.validate();
                break;
            default:
        }
    }, [moj]);

    const getMOJStep = useCallback(() => {
        switch (moj && moj.currentStep.id) {
            case 1:
                return (
                    <StepMainDetail
                        email={moj ? moj.email : ""}
                        firstName={moj ? moj.firstName : ""}
                        middleName={moj ? moj.middleName : ""}
                        lastName={moj ? moj.lastName : ""}
                        contactNumber={moj ? moj.contactNumber : ""}
                        mojPreviousNames={
                            moj ? moj.mojPreviousNames : undefined
                        }
                        nzDriverLicenceNumber={
                            moj ? moj.nzDriverLicenceNumber : ""
                        }
                        noLastName={moj ? moj.noLastName : false}
                        ref={mainDetailsRef}
                    />
                );
            case 2:
                return <StepBirthDetail ref={birthDetailsRef} />;
            case 3:
                return <StepAddress ref={addressRef} />;
            case 4:
                return (
                    <StepProofOfIdentity
                        mojApplicantIdentificationType={
                            moj && moj.mojApplicantIdentificationType
                        }
                        identityFormLink={moj && moj.identityFormLink}
                        hasIncludedProofOfIdentity={
                            moj ? moj.hasIncludedProofOfIdentity : false
                        }
                        hasAgreedWithIDTerms={
                            moj ? moj.hasAgreedWithIDTerms : false
                        }
                        ref={proofOfIdentityRef}
                    />
                );
            case 5:
                return (
                    <StepSignature
                        copyRequiredViaEmail={
                            moj ? moj.copyRequiredViaEmail : false
                        }
                        copyRequiredViaPost={
                            moj ? moj.copyRequiredViaPost : false
                        }
                        copyNotRequired={moj ? moj.copyNotRequired : false}
                        signatureLink={moj && moj.signatureLink}
                        hasAcceptedTermsConditions={
                            moj ? moj.hasAcceptedTermsConditions : false
                        }
                        mojReportType={moj && moj.mojReportType}
                        ref={signatureRef}
                    />
                );
            case 6:
                return <StepSummary />;
            default:
                return null;
        }
    }, [moj]);

    const goNextStep = useCallback(async () => {
        handleStepValidation();
    }, [handleStepValidation]);

    const goPrevStep = useCallback(() => {
        goBack();
    }, [goBack]);

    const handleSubmitMOJ = useCallback(async () => {
        await submitMOJ();
    }, [submitMOJ]);

    const nextButtonVisibleMemo = useMemo(() => {
        return showNextButton();
    }, [showNextButton]);

    const prevButtonVisibleMemo = useMemo(() => {
        return showPreviousButton();
    }, [showPreviousButton]);

    const submitButtonVisibleMemo = useMemo(() => {
        return showSubmitButton();
    }, [showSubmitButton]);

    return (
        <MainNew>
            <Container>
                <LinkContainer>
                    <LinkRow>
                        <FaChevronLeft />
                        {/* <Link to="/dashboard">Go back to dashboard</Link> */}
                        <Link to={UrlsAddress.DASHBOARD}>
                            Go back to dashboard
                        </Link>
                    </LinkRow>
                </LinkContainer>
                <WizardTitle>
                    <MOJTitle>
                        <MOJSubtitle id="labelMOJSubtitle">
                            Criminal check form
                        </MOJSubtitle>
                        <MOJDetails>
                            <MOJCode>
                                <small>Code: </small>
                                {moj && moj.code}
                            </MOJCode>
                            <MOJExpiryDate>
                                <small>Expiry at: </small>
                                {moj && moj.formattedExpiryDate}
                            </MOJExpiryDate>
                        </MOJDetails>
                    </MOJTitle>
                </WizardTitle>
                <WizardBody>
                    <WizardBodyForm>
                        <WizardBodyFormHeader>
                            <WizardBodyFormHeaderNavigation>
                                <WizardBodyFormHeaderNavButton
                                    isCompleted={moj && moj.currentStep.id > 1}
                                    isCurrentStep={
                                        moj && moj.currentStep.id === 1
                                    }
                                    onClick={() => goToStep(1)}
                                    title="Main Details"
                                >
                                    <FaUserEdit />
                                    <span>Main Details</span>
                                </WizardBodyFormHeaderNavButton>
                                <WizardBodyFormHeaderNavButton
                                    isCompleted={moj && moj.currentStep.id > 2}
                                    isCurrentStep={
                                        moj && moj.currentStep.id === 2
                                    }
                                    onClick={() => goToStep(2)}
                                    title="Birth Details"
                                >
                                    <FaBirthdayCake />
                                    <span>Birth Details</span>
                                </WizardBodyFormHeaderNavButton>
                                <WizardBodyFormHeaderNavButton
                                    isCompleted={moj && moj.currentStep.id > 3}
                                    isCurrentStep={
                                        moj && moj.currentStep.id === 3
                                    }
                                    onClick={() => goToStep(3)}
                                    title="Address"
                                >
                                    <FaMapMarkerAlt />
                                    <span>Address</span>
                                </WizardBodyFormHeaderNavButton>
                                <WizardBodyFormHeaderNavButton
                                    isCompleted={moj && moj.currentStep.id > 4}
                                    isCurrentStep={
                                        moj && moj.currentStep.id === 4
                                    }
                                    onClick={() => goToStep(4)}
                                    title="Proof of Identity"
                                >
                                    <FaPassport />
                                    <span>Proof of Identity</span>
                                </WizardBodyFormHeaderNavButton>
                                <WizardBodyFormHeaderNavButton
                                    isCompleted={moj && moj.currentStep.id > 5}
                                    isCurrentStep={
                                        moj && moj.currentStep.id === 5
                                    }
                                    onClick={() => goToStep(5)}
                                    title="Signature"
                                >
                                    <FaSignature />
                                    <span>Signature</span>
                                </WizardBodyFormHeaderNavButton>
                                <WizardBodyFormHeaderNavButton
                                    isCompleted={moj && moj.currentStep.id > 6}
                                    isCurrentStep={
                                        moj && moj.currentStep.id === 6
                                    }
                                    title="Summary"
                                >
                                    <FaBars />
                                    <span>Summary</span>
                                </WizardBodyFormHeaderNavButton>
                            </WizardBodyFormHeaderNavigation>
                            <WizardBodyFormHeaderStep>
                                <h1>{moj && moj.currentStep.title}</h1>
                            </WizardBodyFormHeaderStep>
                        </WizardBodyFormHeader>
                        <WizardBodyFormContent>
                            {getMOJStep()}
                        </WizardBodyFormContent>
                    </WizardBodyForm>
                </WizardBody>
                <WizardActions>
                    <ActionsContainer>
                        <Previous
                            enabled={prevButtonVisibleMemo}
                            onClick={goPrevStep}
                        >
                            <FaArrowLeft />
                            <button type="button">Prev</button>
                        </Previous>
                        <Next
                            enabled={nextButtonVisibleMemo}
                            onClick={goNextStep}
                            loading={processingRequest}
                        >
                            {processingRequest && (
                                <FaSpinner
                                    className="next-icon-loading"
                                    size={20}
                                />
                            )}
                            <button disabled={processingRequest} type="button">
                                Next
                            </button>
                            <FaArrowRight />
                        </Next>
                        <Submit
                            enabled={submitButtonVisibleMemo}
                            onClick={handleSubmitMOJ}
                        >
                            <button type="button">Submit</button>
                            <FaCheck />
                        </Submit>
                    </ActionsContainer>
                </WizardActions>
            </Container>
        </MainNew>
    );
};

export default MOJ;
