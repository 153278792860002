import styled from "styled-components";

export const Container = styled.div`
    position: relative;

    span {
        width: 160px;
        background: #b2d235;
        padding: 8px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        opacity: 0;
        transition: opacity 0.4s;
        visibility: hidden;

        position: absolute;
        bottom: calc(100% + 12px);

        z-index: 15;

        /*
            Hack to set element in the middle of the element "error Icon"
        */
        left: 50%;
        transform: translateX(-50%);

        color: #312e38;

        &::before {
            content: "";
            border-style: solid;
            border-color: #b2d235 transparent;
            border-width: 6px 6px 0 6px;
            top: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:hover span {
        opacity: 1;
        visibility: visible;
    }
`;
