import styled, { keyframes } from "styled-components";
import { shade } from "polished";

import signInBackgroundImg from "../../../assets/sign-in-background-2.png";

export const Container = styled.div`
    height: 100vh;

    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    /*  */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    /* max-width: 700px; */
    max-width: 768px;
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

    img {
        width: 45%;
        height: auto;
    }

    form {
        margin: 60px 0 20px 0;
        width: 340px;
        text-align: center;
        padding: 0 20px;

        h1 {
            margin-bottom: 24px;
        }

        a {
            color: #7c9220;
            display: block;
            margin-top: 24px;
            text-decoration: none;
            transition: color 0.2s;

            &:hover {
                color: ${shade(0.2, "#7c9220")};
            }
        }
    }

    /*
        ">" => Only same level
        ">" means you're getting only "a" tag directly from content
    */
    > a {
        color: #8da725;
        display: block;
        margin-top: 24px;
        text-decoration: none;
        transition: color 0.2s;

        display: flex;
        align-items: center;

        svg {
            margin-right: 16px;
        }

        &:hover {
            color: ${shade(0.2, "#8da725")};
        }
    }
`;

export const Background = styled.div`
    /*  */
    flex: 1;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(${signInBackgroundImg}) no-repeat center;
    background-size: cover;
`;
