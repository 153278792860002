import styled, { keyframes } from "styled-components";
import { shade } from "polished";

import signInBackgroundImg from "../../../assets/sign-up-background-hello.png";

const appearFromRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const Container = styled.div`
    height: 100vh;
    min-height: 560px;

    display: flex;
    align-items: stretch;
`;

export const Content = styled.div`
    display: grid;
    grid-template-rows: auto auto 1fr auto;

    animation: ${appearFromRight} 1s;
`;

export const AnimationContainer = styled.div`
    height: 100vh;
    width: 100%;
    max-width: 768px;
    padding: 20px 0;

    overflow: scroll;
    overflow-x: hidden;

    animation: ${appearFromRight} 1s;
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 45%;
        height: auto;
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;

    h1 {
    }

    h3 {
        margin: 10px 0 25px;
        font-size: 1rem;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 20px 0 20px;

    form {
        @media only screen and (max-width: 600px) {
            width: 90%;
        }

        max-width: 600px;
        text-align: center;
        width: 100%;
    }
`;

export const Links = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
        margin: 10px;
        color: #8da725;
        display: block;
        text-decoration: none;
        transition: color 0.2s;

        display: flex;
        align-items: center;

        svg {
            margin-right: 10px;
        }

        &:hover {
            color: ${shade(0.2, "#8da725")};
        }
    }
`;

export const Background = styled.div`
    flex: 1;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(${signInBackgroundImg}) no-repeat center;
    background-size: cover;
`;
