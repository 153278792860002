import React, { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../../services/api";

import {
    Container,
    MOJWidgets,
    MOJWidget,
    MOJWidgetQuantity,
    MOJWidgetLabel,
} from "./styles";

interface IMOJOVerview {
    draftQuantity: number;
    processingQuantity: number;
    processedQuantity: number;
    expiredQuantity: number;
    incompleteQuantity: number;
}

const MOJOverview: React.FC = () => {
    const [mojOverviewData, setMOJOverviewData] = useState<IMOJOVerview>({
        draftQuantity: 0,
        processingQuantity: 0,
        processedQuantity: 0,
        expiredQuantity: 0,
        incompleteQuantity: 0,
    });

    const fetchData = useCallback(async () => {
        const response = await api.get("internalConsultant/mojOverview");

        if (response.status === 200) {
            const { data } = response.data;

            setMOJOverviewData(data);
        }
    }, []);

    useEffect(() => {
        fetchData();

        // // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    const getMOJOVerviewData = useMemo(() => {
        return mojOverviewData;
    }, [mojOverviewData]);

    return (
        <Container>
            <MOJWidgets>
                <MOJWidget
                    backgroundColorMain="#FFC600"
                    backgroundColorLight="#FFFF37"
                >
                    <MOJWidgetQuantity fontColor="#333">
                        {getMOJOVerviewData.draftQuantity}
                    </MOJWidgetQuantity>
                    <MOJWidgetLabel fontColor="#333">
                        Cons. Created Request
                    </MOJWidgetLabel>
                </MOJWidget>
                <MOJWidget
                    backgroundColorMain="#3C8CE7"
                    backgroundColorLight="#70bef5"
                >
                    <MOJWidgetQuantity>
                        {getMOJOVerviewData.processingQuantity}
                    </MOJWidgetQuantity>
                    <MOJWidgetLabel>Cand. Processed</MOJWidgetLabel>
                </MOJWidget>
                <MOJWidget
                    backgroundColorMain="#49C628"
                    backgroundColorLight="#70F570"
                >
                    <MOJWidgetQuantity>
                        {getMOJOVerviewData.processedQuantity}
                    </MOJWidgetQuantity>
                    <MOJWidgetLabel>MOJ Returned</MOJWidgetLabel>
                </MOJWidget>
                <MOJWidget
                    backgroundColorMain="rgba(197, 48, 48, 0.9)"
                    backgroundColorLight="#990000"
                >
                    <MOJWidgetQuantity fontColor="#fff">
                        {getMOJOVerviewData.expiredQuantity}
                    </MOJWidgetQuantity>
                    <MOJWidgetLabel fontColor="#fff">
                        Request Expired
                    </MOJWidgetLabel>
                </MOJWidget>
                <MOJWidget
                    backgroundColorMain="rgba(197, 48, 48, 0.9)"
                    backgroundColorLight="#990000"
                >
                    <MOJWidgetQuantity fontColor="#fff">
                        {getMOJOVerviewData.incompleteQuantity}
                    </MOJWidgetQuantity>
                    <MOJWidgetLabel fontColor="#fff">
                        Incomplete (returned)
                    </MOJWidgetLabel>
                </MOJWidget>
            </MOJWidgets>
        </Container>
    );
};

export default MOJOverview;
