import styled from "styled-components";

export const Container = styled.div`
    /* background: #fff; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const MessageDisplay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    > svg {
        width: 64px;
        height: 64px;
        color: #b2d235;
    }
`;

export const MessageTitle = styled.span`
    font-weight: 700;
    font-size: 24px;
    margin: 16px 0;
`;

export const MessageContent = styled.div`
    text-align: center;
    margin-bottom: 16px;
`;

export const MessageLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;

    svg {
        width: 24px;
        height: 24px;
        margin: 0 8px;
    }

    span {
        text-align: center;
    }
`;

export const LinkContainer = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 8px;
    }

    a {
        color: #333;
    }
`;
