import styled from "styled-components";

export const Container = styled.aside`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: start;

    height: 100vh;
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;

    padding: 5px;
`;

export const Logo = styled.img`
    width: 90%;
    height: auto;
`;

export const LinksContainer = styled.ul`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    overflow-y: auto;
`;

export const LinkItem = styled.li`
    transition: background 0.2s;

    &:hover {
        background: var(--color-green);
    }

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 90px;
        color: #333;
        text-decoration: none;
        padding: 0 4px;

        > svg {
            margin-bottom: 10px;
        }
    }
`;

export const LinkText = styled.span`
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
`;
