import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useMemo,
} from "react";
import { Link } from "react-router-dom";

// API
// import api from "../../../services/api";

// Can Component
import Can from "../../../components/Can";

// Hooks
import { useAuth } from "../../../hooks/auth";
import { useCompanyCredits } from "../../../hooks/companyCredits";
import UseOutsideClick from "../../../hooks/UseOutsideClick";

// Common Types
import ActionTypes from "../../../types/userActions";
// import { IMOJCompanyCredits } from "../../../types/companyTypes";
import {
    IMenuLinks,
    BossAdminLinks,
    CustomerLinks,
    ConsultantLinks,
    CandidateLinks,
} from "../../../types/menuLinks";

// Styles
import {
    Container,
    Wrapper,
    DropDownContainer,
    MenuDropDownContainer,
    DropDownButton,
    LongDescription,
    ShortDescription,
    MOJCreditsDropDownContent,
    MenuDropDownContent,
    IconCog,
    IconDropDown,
    IconDropDownExpanded,
    MenuUserInfo,
    MenuUserInfoName,
    MenuUserInfoEmail,
    MenuDropDownLinks,
    MenuDropDownLinksRow,
    Divider,
    BronzeBadgeColor,
    SilverBadgeColor,
    GoldBadgeColor,
    IconSignOut,
    MOJCreditsBox,
    MOJCreditsBoxBuyCredits,
    MOJCreditsBadges,
    BadgeBox,
    BadgeTitle,
    SignOutBox,
} from "./styles";

const MobileHeader: React.FC = () => {
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showMOJCreditsMenu, setShowMOJCreditsMenu] = useState(false);
    const refUserMenu = useRef<HTMLDivElement>(null);
    const refMOJCreditsMenu = useRef<HTMLDivElement>(null);

    const [menuLinks, setMenuLinks] = useState<IMenuLinks[]>([]);

    // const [mojCompanyCredits, setMOJCompanyCredits] = useState<
    //     IMOJCompanyCredits
    // >({
    //     gold: 0,
    //     silver: 0,
    //     bronze: 0,
    //     total: 0,
    // } as IMOJCompanyCredits);

    const { user, signOut } = useAuth();
    const { credits, refreshCredits } = useCompanyCredits();

    // const fetchData = useCallback(async () => {
    //     if (isUserAllowed(ActionTypes.CONSULTANT_READ_COMPANY_CREDITS)) {
    //         await api.get("Company/getCompanyCredits").then(response => {
    //             const { data } = response;
    //             if (data && data.success && data.data) {
    //                 const mojCredits: IMOJCompanyCredits = data.data;

    //                 const mojCreditsTotal =
    //                     mojCredits.gold + mojCredits.silver + mojCredits.bronze;

    //                 setMOJCompanyCredits({
    //                     ...mojCredits,
    //                     total: mojCreditsTotal,
    //                 });
    //             }
    //         });
    //     }
    // }, [isUserAllowed]);

    const defineMenuLinks = useCallback(() => {
        switch (user.userType) {
            case 1:
                setMenuLinks(BossAdminLinks);
                break;
            case 2:
                setMenuLinks(CustomerLinks);
                break;
            case 3:
                setMenuLinks(ConsultantLinks);
                break;
            case 4:
                setMenuLinks(ConsultantLinks);
                break;
            case 5:
                setMenuLinks(CandidateLinks);
                break;
            case 6:
                setMenuLinks(ConsultantLinks);
                break;
            case 7:
                setMenuLinks(ConsultantLinks);
                break;

            default:
                break;
        }
    }, [user.userType]);

    useEffect(() => {
        // fetchData();

        defineMenuLinks();

        refreshCredits();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFullName = (): string => {
        let fullName = user.firstName;

        if (user.middleName) {
            fullName += ` ${user.middleName}`;
        }

        if (user.lastName) {
            fullName += ` ${user.lastName}`;
        }

        return fullName;
    };

    const toggleUserMenu = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            setShowUserMenu(!showUserMenu);
        },
        [showUserMenu],
    );

    const toggleMOJCreditsMenu = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            setShowMOJCreditsMenu(!showMOJCreditsMenu);
        },
        [showMOJCreditsMenu],
    );

    UseOutsideClick(refUserMenu, () => {
        if (showUserMenu) {
            setShowUserMenu(false);
        }
    });

    UseOutsideClick(refMOJCreditsMenu, () => {
        if (showMOJCreditsMenu) {
            setShowMOJCreditsMenu(false);
        }
    });

    const getMenuLinks = useMemo(() => {
        return menuLinks;
    }, [menuLinks]);

    // const getMOJCompanyCredits = useMemo(() => {
    //     return mojCompanyCredits;
    // }, [mojCompanyCredits]);

    return (
        <Container className="mobile-container">
            <Wrapper className="mobile-wrapper">
                <Can
                    action={ActionTypes.CONSULTANT_READ_COMPANY_CREDITS}
                    yes={() => (
                        <DropDownContainer className="DropDownContainer">
                            <DropDownButton
                                className="DropDownButton"
                                type="button"
                                onClick={toggleMOJCreditsMenu}
                            >
                                <LongDescription>
                                    MOJ CREDITS: {credits.total}
                                </LongDescription>
                                <ShortDescription>
                                    {credits.total}
                                </ShortDescription>
                                {showMOJCreditsMenu ? (
                                    <IconDropDownExpanded />
                                ) : (
                                    <IconDropDown />
                                )}
                            </DropDownButton>
                            {showMOJCreditsMenu && (
                                <MOJCreditsDropDownContent
                                    ref={refMOJCreditsMenu}
                                    className="DropDownContent"
                                >
                                    <MOJCreditsBox>
                                        <MOJCreditsBadges>
                                            <BadgeBox>
                                                <GoldBadgeColor>
                                                    {credits.gold}
                                                </GoldBadgeColor>
                                                <BadgeTitle>Gold</BadgeTitle>
                                            </BadgeBox>
                                            <BadgeBox>
                                                <SilverBadgeColor>
                                                    {credits.silver}
                                                </SilverBadgeColor>
                                                <BadgeTitle>Silver</BadgeTitle>
                                            </BadgeBox>
                                            <BadgeBox>
                                                <BronzeBadgeColor>
                                                    {credits.bronze}
                                                </BronzeBadgeColor>
                                                <BadgeTitle>Bronze</BadgeTitle>
                                            </BadgeBox>
                                        </MOJCreditsBadges>
                                        <Can
                                            action={
                                                ActionTypes.COMPANY_ADMIN_BUY_CREDITS
                                            }
                                            yes={() => (
                                                <>
                                                    <Divider />
                                                    <MOJCreditsBoxBuyCredits>
                                                        <Link to="buy-credits">
                                                            Buy more
                                                        </Link>
                                                    </MOJCreditsBoxBuyCredits>
                                                </>
                                            )}
                                        />
                                    </MOJCreditsBox>
                                </MOJCreditsDropDownContent>
                            )}
                        </DropDownContainer>
                    )}
                />
                <MenuDropDownContainer className="DropDownContainer">
                    <DropDownButton
                        className="DropDownButton"
                        type="button"
                        onClick={toggleUserMenu}
                    >
                        <LongDescription>{getFullName()}</LongDescription>
                        <ShortDescription>{user.firstName}</ShortDescription>
                        <IconCog />
                    </DropDownButton>
                    {showUserMenu && (
                        <MenuDropDownContent
                            ref={refUserMenu}
                            className="DropDownContent"
                        >
                            <MenuUserInfo>
                                <MenuUserInfoName>
                                    {getFullName()}
                                </MenuUserInfoName>
                                <MenuUserInfoEmail>
                                    {user.email}
                                </MenuUserInfoEmail>
                            </MenuUserInfo>
                            <Divider />
                            <MenuDropDownLinks>
                                {getMenuLinks &&
                                    getMenuLinks.map((linkItem, index) => (
                                        <Can
                                            key={index}
                                            action={linkItem.action}
                                            yes={() => (
                                                <MenuDropDownLinksRow
                                                    key={linkItem.position}
                                                >
                                                    <Link to={linkItem.route}>
                                                        {linkItem.text}
                                                    </Link>
                                                </MenuDropDownLinksRow>
                                            )}
                                        />
                                    ))}
                            </MenuDropDownLinks>
                            <Divider />
                            <SignOutBox>
                                <button type="button" onClick={signOut}>
                                    <IconSignOut />
                                    <span>Sign Out</span>
                                </button>
                            </SignOutBox>
                        </MenuDropDownContent>
                    )}
                </MenuDropDownContainer>
            </Wrapper>
        </Container>
    );
};

export default MobileHeader;
