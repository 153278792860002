import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

// Common Components
import ConsultantMOJOverview from "../ConsultantMOJOverview";
import Can from "../../Can";

// Common Types
import ActionTypes from "../../../types/userActions";
import UrlsAddress from "../../../types/urlsAddress";

import {
    Container,
    CardsInvitations,
    CardInvite,
    CardInviteTitle,
    CardInviteDescription,
    CardInviteButton,
} from "./styles";

const ConsultantDashboard: React.FC = () => {
    const history = useHistory();

    const handleInviteCandidates = useCallback(() => {
        // history.push("/invite-candidates");
        history.push(UrlsAddress.INVITE_CANDIDATES);
    }, [history]);

    const handleInviteConsultants = useCallback(() => {
        // history.push("/invite-staff");
        history.push(UrlsAddress.INVITE_STAFF);
    }, [history]);

    return (
        <Container>
            <CardsInvitations>
                <Can
                    action={ActionTypes.CONSULTANT_CREATE_CANDIDATE_MOJ}
                    yes={() => (
                        <CardInvite>
                            <CardInviteTitle>
                                <span>Candidates</span>
                            </CardInviteTitle>
                            <CardInviteDescription>
                                <span>
                                    Invite candidates to fill out the criminal
                                    check form!
                                </span>
                            </CardInviteDescription>
                            <CardInviteButton onClick={handleInviteCandidates}>
                                Send Email
                            </CardInviteButton>
                        </CardInvite>
                    )}
                />
                <Can
                    action={ActionTypes.COMPANY_ADMIN_INVITE_CONSULTANT}
                    yes={() => (
                        <CardInvite>
                            <CardInviteTitle>
                                <span>Staff</span>
                            </CardInviteTitle>
                            <CardInviteDescription>
                                <span>
                                    Invite additional users from your company!
                                </span>
                            </CardInviteDescription>
                            <CardInviteButton onClick={handleInviteConsultants}>
                                Send Email
                            </CardInviteButton>
                        </CardInvite>
                    )}
                />
            </CardsInvitations>

            <Can
                action={ActionTypes.CONSULTANT_LIST_ALL_MOJS_CONSULTANT}
                yes={() => <ConsultantMOJOverview />}
            />
        </Container>
    );
};

export default ConsultantDashboard;
