import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Link } from "react-router-dom";

// Hooks
import { useAuth } from "../../../../hooks/auth";
import UseOutsideClick from "../../../../hooks/UseOutsideClick";

// Common Components
import Can from "../../../../components/Can";

// Common Types
import {
    BossAdminLinks,
    CandidateLinks,
    ConsultantLinks,
    CustomerLinks,
    InternalConsultantLinks,
    IMenuLinks,
} from "../../../../types/menuLinks";

import {
    Container,
    DropDownButton,
    LongDescription,
    ShortDescription,
    IconCog,
    MenuDropDownContent,
    MenuUserInfo,
    MenuUserInfoName,
    MenuUserInfoEmail,
    Divider,
    MenuDropDownLinks,
    MenuDropDownLinksRow,
    SignOutBox,
    IconSignOut,
} from "./styles";

const UserMenu: React.FC = () => {
    const [showUserMenu, setShowUserMenu] = useState(false);
    const refUserMenu = useRef<HTMLDivElement>(null);

    const [menuLinks, setMenuLinks] = useState<IMenuLinks[]>([]);

    const { user, signOut } = useAuth();

    const defineMenuLinks = useCallback(() => {
        switch (user.userType) {
            case 1:
                setMenuLinks(BossAdminLinks);
                break;
            case 2:
                setMenuLinks(CustomerLinks);
                break;
            case 3:
                setMenuLinks(ConsultantLinks);
                break;
            case 4:
                setMenuLinks(ConsultantLinks);
                break;
            case 5:
                setMenuLinks(CandidateLinks);
                break;
            case 6:
                setMenuLinks(InternalConsultantLinks);
                break;
            case 7:
                setMenuLinks(InternalConsultantLinks);
                break;

            default:
                break;
        }
    }, [user.userType]);

    useEffect(() => {
        defineMenuLinks();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFullName = (): string => {
        let fullName = user.firstName;

        // if (user.middleName) {
        //     fullName += ` ${user.middleName}`;
        // }

        if (user.lastName) {
            fullName += ` ${user.lastName}`;
        }

        return fullName;
    };

    const toggleUserMenu = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            setShowUserMenu(!showUserMenu);
        },
        [showUserMenu],
    );

    UseOutsideClick(refUserMenu, () => {
        if (showUserMenu) {
            setShowUserMenu(false);
        }
    });

    const getMenuLinks = useMemo(() => {
        return menuLinks;
    }, [menuLinks]);

    return (
        <Container>
            <DropDownButton
                className="DropDownButton"
                type="button"
                onClick={toggleUserMenu}
            >
                <LongDescription>{getFullName()}</LongDescription>
                <ShortDescription>{user.firstName}</ShortDescription>
                <IconCog />
            </DropDownButton>
            {showUserMenu && (
                <MenuDropDownContent
                    ref={refUserMenu}
                    className="DropDownContent"
                >
                    <MenuUserInfo>
                        <MenuUserInfoName>{getFullName()}</MenuUserInfoName>
                        <MenuUserInfoEmail>{user.email}</MenuUserInfoEmail>
                    </MenuUserInfo>
                    <Divider />
                    <MenuDropDownLinks>
                        {getMenuLinks &&
                            getMenuLinks.map((linkItem, index) => (
                                <Can
                                    key={index}
                                    action={linkItem.action}
                                    yes={() => (
                                        <MenuDropDownLinksRow
                                            key={linkItem.position}
                                        >
                                            <Link to={linkItem.route}>
                                                {linkItem.text}
                                            </Link>
                                        </MenuDropDownLinksRow>
                                    )}
                                />
                            ))}
                    </MenuDropDownLinks>
                    <Divider />
                    <SignOutBox>
                        <button type="button" onClick={signOut}>
                            <IconSignOut />
                            <span>Sign Out</span>
                        </button>
                    </SignOutBox>
                </MenuDropDownContent>
            )}
        </Container>
    );
};

export default UserMenu;
