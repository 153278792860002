import React, { useMemo } from "react";
import { format } from "date-fns";

import DetailBox from "../DetailBox";

import { IMOJPreviousName } from "../../../types/mojTypes";

import {
    Row,
    RowField,
    RowValue,
    PreviousNamesList,
    PreviousNameListBoxes,
    PreviousNameBox,
    PreviousNameBoxTitle,
    PreviousNameBoxLabels,
    PreviousNameBoxLine,
    PreviousNameValue,
} from "./styles";

interface IPersonalDetails {
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    noLastName: boolean;
    contactNumber: string;
    nzDriverLicenceNumber: string;
    previousNames: IMOJPreviousName[];
    birthDate: Date;
    city: string;
    countryName: string;
    gender: number;
}

const PersonalDetails: React.FC<IPersonalDetails> = ({
    email,
    firstName,
    middleName,
    lastName,
    noLastName,
    contactNumber,
    nzDriverLicenceNumber,
    previousNames,
    birthDate,
    city,
    countryName,
    gender,
}) => {
    const getPreviousNames = useMemo(() => {
        if (previousNames) {
            const previousNamesOrdered = previousNames.sort((a, b) =>
                a.order > b.order ? 1 : -1,
            );

            const listPreviousNames: IMOJPreviousName[] = [];

            // previousNamesOrdered.map((previousNameItem, index) => {
            //     const existingPreviousName: IMOJPreviousName = {
            //         ...previousNameItem,
            //         order: index + 1,
            //     };

            //     listPreviousNames.push(existingPreviousName);
            // });

            for (let index = 0; index < previousNamesOrdered.length; index++) {
                const element = previousNamesOrdered[index];

                const existingPreviousName: IMOJPreviousName = {
                    ...element,
                    order: index + 1,
                };

                listPreviousNames.push(existingPreviousName);
            }

            return listPreviousNames;
        }

        return [] as IMOJPreviousName[];
    }, [previousNames]);

    const getDateOfBirth = useMemo(() => {
        if (birthDate) {
            const parsedDate = Date.parse(birthDate.toString());

            return format(parsedDate, "dd/MM/yyyy");
        }

        return "";
    }, [birthDate]);

    const getPlaceOfBirth = useMemo(() => {
        return `${city} - ${countryName}`;
    }, [city, countryName]);

    const getGender = useMemo(() => {
        if (gender === 1) return "Male";

        if (gender === 2) return "Female";

        return "";
    }, [gender]);

    return (
        <DetailBox title="Personal Details">
            <Row>
                <RowField>Email</RowField>
                <RowValue>{email}</RowValue>
            </Row>
            <Row>
                <RowField>First Name</RowField>
                <RowValue>{firstName}</RowValue>
            </Row>
            {middleName && (
                <Row>
                    <RowField>Middle Name</RowField>
                    <RowValue>{middleName}</RowValue>
                </Row>
            )}
            {noLastName ? (
                <Row>
                    <RowField>No Last Name</RowField>
                </Row>
            ) : (
                <Row>
                    <RowField>Last Name</RowField>
                    <RowValue>{lastName}</RowValue>
                </Row>
            )}
            <Row>
                <RowField>Contact Number</RowField>
                <RowValue>{contactNumber}</RowValue>
            </Row>
            <Row>
                <RowField>NZ Driver Licence</RowField>
                <RowValue>{nzDriverLicenceNumber}</RowValue>
            </Row>
            <Row>
                <RowField>Date of Birth</RowField>
                <RowValue>{getDateOfBirth}</RowValue>
            </Row>
            <Row>
                <RowField>Place of Birth</RowField>
                <RowValue>{getPlaceOfBirth}</RowValue>
            </Row>
            <Row>
                <RowField>Gender</RowField>
                <RowValue>{getGender}</RowValue>
            </Row>
            {getPreviousNames && getPreviousNames.length > 0 && (
                <PreviousNamesList>
                    <PreviousNameListBoxes>
                        {getPreviousNames.map((prevName, index) => (
                            <PreviousNameBox key={prevName.order}>
                                <PreviousNameBoxTitle>
                                    {`Previous Name ${index + 1}`}
                                </PreviousNameBoxTitle>
                                <PreviousNameBoxLabels>
                                    <PreviousNameBoxLine>
                                        <PreviousNameValue>
                                            {prevName.firstName}
                                        </PreviousNameValue>
                                        <PreviousNameValue>
                                            {prevName.middleName}
                                        </PreviousNameValue>
                                        <PreviousNameValue>
                                            {prevName.lastName}
                                        </PreviousNameValue>
                                    </PreviousNameBoxLine>
                                </PreviousNameBoxLabels>
                            </PreviousNameBox>
                        ))}
                    </PreviousNameListBoxes>
                </PreviousNamesList>
            )}
        </DetailBox>
    );
};

export default PersonalDetails;
