import styled, { css } from "styled-components";

interface IWidgetProps {
    type: "bronze" | "silver" | "gold" | "total";
}

const widgetBackgroundVariation = {
    bronze: css`
        background-image: var(--background-bronze);
    `,

    silver: css`
        background-image: var(--background-silver);
    `,

    gold: css`
        background-image: var(--background-gold);
    `,
    total: css`
        background-image: var(--background-company);
    `,
};

const widgetSizeVariation = {
    bronze: css`
        flex: 1 1;
    `,

    silver: css`
        flex: 1 1;
    `,

    gold: css`
        flex: 1 1;
    `,
    total: css`
        flex: 1 1 100%;
    `,
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    /* align-items: center; */
    width: 100%;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    gap: 16px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;

    padding: 1rem;

    font-size: 2rem;
    font-weight: 700;

    background: #fff;
    color: #333;
    border-radius: 0.5rem;
    text-transform: uppercase;
`;

export const FilterBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    border-radius: 0.5rem;

    background: #fff;
`;

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    > div {
        flex: 1;
        margin: 0;
    }
`;

export const SearchButton = styled.button`
    width: 100%;
    border: none;
    background: var(--color-green);
    color: #333;
    font-weight: 500;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    text-transform: uppercase;

    &:hover {
        font-weight: 500;
        background: var(--color-green-hover);
    }
`;

export const BranchTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 1;
    font-size: 1.5rem;
`;

export const FinancialWidgets = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 8px;
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0 1rem;
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--color-red-danger);
`;

export const ItemWidget = styled.div<IWidgetProps>`
    ${props => widgetBackgroundVariation[props.type]};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 1rem);
    margin: 0.5rem;
    padding: 1rem;

    border-radius: 0.5rem;

    box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.3);

    gap: 0.5rem;

    @media (min-width: 768px) {
        /* flex: 1 1 250px; */
        ${props => widgetSizeVariation[props.type]};
        padding: 1rem;
    }
`;

export const ItemWidgetTitle = styled.div`
    font-weight: bold;
    font-size: 2.2rem;
`;

export const ItemWidgetAmount = styled.div`
    font-size: 3rem;
    line-height: 1;
    font-weight: 300;
    /* margin: 0 5px; */

    small {
        font-size: 2rem;
        margin-right: 0.5rem;
    }
`;

export const ItemWidgetQuantity = styled.div`
    font-size: 2rem;
    line-height: 1;
    font-weight: 400;

    small {
        margin-right: 0.5rem;
    }
`;

export const FinancialGlobalOverview = styled.div`
    display: table;
    width: 100%;
    max-width: 600px;
`;

export const FinancialGlobalOverviewHeader = styled.div`
    display: table-header-group;
    background: #b2d235;
`;

export const HeaderBranch = styled.div`
    display: table-cell;
    padding: 4px 8px;
    border-top: 2px solid #333;
    border-left: 2px solid #333;
    border-bottom: 1px solid #333;
    color: #333;
    font-weight: bold;
`;

export const HeaderAmount = styled.div`
    display: table-cell;
    padding: 4px 8px;
    border-top: 2px solid #333;
    border-left: 2px solid #333;
    border-right: 2px solid #333;
    border-bottom: 1px solid #333;
    color: #333;
    font-weight: bold;

    text-align: center;
`;

export const HeaderQuantity = styled.div`
    display: table-cell;
    padding: 4px 8px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
    border-bottom: 1px solid #333;
    color: #333;
    font-weight: bold;
`;

export const FinancialGlobalOverviewRow = styled.div`
    display: table-row;
`;

export const BranchColumn = styled.div`
    display: table-cell;
    padding: 4px 8px;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    border-bottom: 1px solid #333;
`;

export const AmountColumn = styled.div`
    display: table-cell;
    padding: 4px 8px;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    border-left: 1px solid #333;
    border-right: 1px solid #333;

    text-align: center;
`;

export const QuantityColumn = styled.div`
    display: table-cell;
    padding: 4px 8px;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    border-right: 1px solid #333;
`;
