import React, { ButtonHTMLAttributes } from "react";

import { FaSpinner } from "react-icons/fa";

import { Container } from "./styles";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    processingRequest?: boolean;
}

const ButtonLoader: React.FC<IButtonProps> = ({
    processingRequest,
    children,
    ...rest
}) => {
    return (
        <Container
            type="button"
            processingRequest={processingRequest}
            {...rest}
        >
            {processingRequest && <FaSpinner size={20} />}
            {processingRequest ? "Processing..." : children}
        </Container>
    );
};

export default ButtonLoader;
