import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shade } from "polished";
import { Bar, Pie } from "react-chartjs-2";

// API
import api from "../../../services/api";

import {
    Container,
    Wrapper,
    MOJHistoryContent,
    Title,
    Summary,
    SummaryContent,
    Description,
    Quantity,
    MOJHistoryChartContainer,
    TotalBranchContent,
    SummaryBranch,
    SummaryBranchDescription,
    SummaryBranchQuantity,
    BranchChartContainer,
} from "./styles";

interface IMOJHistoryDTO {
    total: number;
    average: number;
    lastMonth: number;
    currentYear: number;
    jan: number;
    feb: number;
    mar: number;
    apr: number;
    may: number;
    jun: number;
    jul: number;
    aug: number;
    sep: number;
    oct: number;
    nov: number;
    dec: number;
}

interface ITotalBranchDTO {
    total: number;
    branches: IBranchDTO[];
}

interface IBranchDTO {
    name: string;
    quantity: number;
    label: string;
    tooltip: string;
}

const MOJCharts: React.FC = () => {
    const [mojHistoryData, setMOJHistoryData] = useState<IMOJHistoryDTO>({
        total: 0,
        average: 0,
        lastMonth: 0,
        currentYear: new Date().getFullYear(),
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
    });

    const [mojTotalBranchData, setMOJTotalBranchData] = useState<
        ITotalBranchDTO
    >({
        total: 0,
        branches: [
            {
                name: "Auckland",
                quantity: 0,
                label: "Auckland 0",
                tooltip: "Auckland 0",
            },
            {
                name: "Christchurch",
                quantity: 0,
                label: "Christchurch 0",
                tooltip: "Christchurch 0",
            },
            {
                name: "Hamilton",
                quantity: 0,
                label: "Hamilton 0",
                tooltip: "Hamilton 0",
            },
            {
                name: "Tauranga",
                quantity: 0,
                label: "Tauranga 0",
                tooltip: "Tauranga 0",
            },
            {
                name: "Wellington",
                quantity: 0,
                label: "Wellington 0",
                tooltip: "Wellington 0",
            },
        ],
    });

    const fetchData = useCallback(async () => {
        await api.get("internalConsultant/mojHistory").then(response => {
            if (response.status === 200) {
                const { data } = response.data;

                setMOJHistoryData(data);
            }
        });

        await api.get("internalConsultant/totalByBranch").then(response => {
            if (response.status === 200) {
                const { data } = response.data;

                setMOJTotalBranchData(data);
            }
        });
    }, []);

    useEffect(() => {
        fetchData();

        // // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    const getMOJHistoryData = useMemo(() => {
        return mojHistoryData;
    }, [mojHistoryData]);

    const getMOJHistoryChartData = useMemo(() => {
        const data = {
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            datasets: [
                {
                    label: getMOJHistoryData.currentYear.toString(),
                    backgroundColor: "rgba(178,210,53,0.6)",
                    borderColor: "rgba(178,210,53,1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(146,177,24, 0.8)",
                    hoverBorderColor: "rgba(146,177,24,1)",
                    data: [
                        getMOJHistoryData.jan,
                        getMOJHistoryData.feb,
                        getMOJHistoryData.mar,
                        getMOJHistoryData.apr,
                        getMOJHistoryData.may,
                        getMOJHistoryData.jun,
                        getMOJHistoryData.jul,
                        getMOJHistoryData.aug,
                        getMOJHistoryData.sep,
                        getMOJHistoryData.oct,
                        getMOJHistoryData.nov,
                        getMOJHistoryData.dec,
                    ],
                    categoryPercentage: 1,
                    barPercentage: 0.5,
                },
            ],
        };

        return data;
    }, [getMOJHistoryData]);

    const getMOJTotalBranchData = useMemo(() => {
        return mojTotalBranchData;
    }, [mojTotalBranchData]);

    const branchChartLabels = useMemo(() => {
        const branchesLabels: string[] = [];

        for (
            let index = 0;
            index < getMOJTotalBranchData.branches.length;
            index++
        ) {
            const branchEl = getMOJTotalBranchData.branches[index];

            branchesLabels.push(branchEl.label);
        }

        return branchesLabels;
    }, [getMOJTotalBranchData]);

    const getTotalBranchByName = useCallback(
        (branchName: string): number => {
            const branch = getMOJTotalBranchData.branches.find(
                item => item.name.toLowerCase() === branchName.toLowerCase(),
            );

            if (branch) {
                return branch.quantity;
            }

            return 0;
        },
        [getMOJTotalBranchData.branches],
    );

    const getMOJTotalBranchChartData = useMemo(() => {
        const data = {
            labels: branchChartLabels,
            datasets: [
                {
                    data: [
                        getTotalBranchByName("Auckland"),
                        getTotalBranchByName("Christchurch"),
                        getTotalBranchByName("Hamilton"),
                        getTotalBranchByName("Tauranga"),
                        getTotalBranchByName("Wellington"),
                    ],
                    backgroundColor: [
                        "#b2d235",
                        "#36A2EB",
                        "#FFCE56",
                        "#dc5b57",
                        "#4c5d8a",
                    ],
                    hoverBackgroundColor: [
                        shade(0.15, "#b2d235"),
                        shade(0.15, "#36A2EB"),
                        shade(0.15, "#FFCE56"),
                        shade(0.15, "#dc5b57"),
                        shade(0.15, "#4c5d8a"),
                    ],
                },
            ],
        };

        return data;
    }, [branchChartLabels, getTotalBranchByName]);

    const getToolTipByBranch = useCallback(
        (index: number): string => {
            const splittedText = branchChartLabels[index].split(" ");

            return `${splittedText[0]}: ${splittedText[1]}`;
        },
        [branchChartLabels],
    );

    return (
        <Container>
            <Wrapper>
                <MOJHistoryContent>
                    <Title>MOJ History</Title>
                    <Summary>
                        <SummaryContent>
                            <Description>Total</Description>
                            <Quantity>{getMOJHistoryData.total}</Quantity>
                        </SummaryContent>
                        <SummaryContent>
                            <Description>Avg.</Description>
                            <Quantity>{getMOJHistoryData.average}</Quantity>
                        </SummaryContent>
                        <SummaryContent>
                            <Description>Last Month</Description>
                            <Quantity>{getMOJHistoryData.lastMonth}</Quantity>
                        </SummaryContent>
                    </Summary>
                    <MOJHistoryChartContainer>
                        <Bar
                            redraw
                            data={getMOJHistoryChartData}
                            options={{
                                maintainAspectRatio: false,
                                responsive: true,
                            }}
                        />
                    </MOJHistoryChartContainer>
                </MOJHistoryContent>
            </Wrapper>
            <Wrapper>
                <TotalBranchContent>
                    <Title>Branch</Title>
                    <SummaryBranch>
                        <SummaryBranchDescription>
                            Current Month Total
                        </SummaryBranchDescription>
                        <SummaryBranchQuantity>
                            {getMOJTotalBranchData.total}
                        </SummaryBranchQuantity>
                    </SummaryBranch>
                    <BranchChartContainer>
                        <Pie
                            redraw
                            data={getMOJTotalBranchChartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                tooltips: {
                                    mode: "index",
                                    callbacks: {
                                        label: item => {
                                            if (item) {
                                                const { index } = item;

                                                if (
                                                    index !== undefined &&
                                                    index !== null
                                                ) {
                                                    return getToolTipByBranch(
                                                        index,
                                                    );
                                                }
                                            }

                                            return "";
                                        },
                                    },
                                },
                                legend: {
                                    position: "right",
                                },
                            }}
                        />
                    </BranchChartContainer>
                </TotalBranchContent>
            </Wrapper>
        </Container>
    );
};

export default MOJCharts;
