import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    background: #fff;

    border-radius: 10px;
    padding: 20px;
    margin: 20px auto;

    @media screen and (min-width: 900px) {
        width: 410px;
    }

    @media screen and (min-width: 1200px) {
        width: 410px;
    }
`;

export const BoxIcon = styled.div`
    background: rgba(0, 0, 0, 0.05);

    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease;

    svg {
        path,
        circle {
            color: #666;
            stroke: #666;
        }
    }

    &:hover {
        background: #b2d235;

        svg {
            path,
            circle {
                color: #fff;
                stroke: #fff;
            }
        }
    }
`;

export const BoxTitle = styled.div`
    border-bottom: 2px solid #b2d235;
    width: 100%;

    h4 {
        color: #414141;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: normal;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
    }
`;

export const BoxDescription = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;

    p {
        margin-top: 30px;
        color: #666;
        text-align: center;
        font-size: 1.2rem;
        line-height: 2;
    }
`;

export const Paragraph = styled.p`
    color: #333;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2rem;
    margin: 20px 0;
`;
