import React from "react";

// MasterPage
import MainNew from "../MainNew";

// Hooks
import { useAuth } from "../../hooks/auth";

import { Container } from "./styles";
import CustomerProfile from "../../components/Customer/CustomerProfile";
import ConsultantProfile from "../../components/Consultant/ConsultantProfile";
import CandidateProfile from "../../components/Candidate/CandidateProfile";

const Profile: React.FC = () => {
    const { user } = useAuth();

    const getUserProfile = (): JSX.Element | null => {
        switch (user.userType) {
            case 1:
                return <h1>God Admin Profile</h1>;
            case 2:
                return <CustomerProfile />;
            case 3:
                return <ConsultantProfile />;
            case 4:
                return <ConsultantProfile />;
            case 5:
                return <CandidateProfile />;
            case 6:
                // internal admin
                return <ConsultantProfile />;
            case 7:
                // internal consultant
                return <ConsultantProfile />;
            default:
                return null;
        }
    };

    return (
        <MainNew>
            <Container>{getUserProfile()}</Container>
        </MainNew>
    );
};

export default Profile;
