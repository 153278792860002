import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem 2rem;

    background: #fff;
    border-radius: 0.5rem;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 1rem;

    background: #f2f1f3;
    border-radius: 0.5rem;
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    text-align: center;
`;

export const FormContent = styled.div`
    max-width: 600px;
    width: 100%;
    margin-top: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 20px 0 20px;

    form {
        @media only screen and (max-width: 600px) {
            width: 90%;
        }

        max-width: 600px;
        text-align: center;
        width: 100%;
    }
`;
