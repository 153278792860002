import styled from "styled-components";

import { MdAttachMoney } from "react-icons/md";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem 2rem;

    background: #fff;
    border-radius: 0.5rem;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 1rem;

    background: #f2f1f3;
    border-radius: 0.5rem;
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    text-align: center;
`;

export const MOJsInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    gap: 0.5rem;
    width: 100%;
`;

export const MOJGoldCosts = styled.div`
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    background: var(--background-gold);

    font-weight: 500;

    small {
        margin-left: 0.5rem;
    }
`;

export const MOJSilverCosts = styled.div`
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    background: var(--background-silver);

    font-weight: 500;

    small {
        margin-left: 0.5rem;
    }
`;

export const MOJBronzeCosts = styled.div`
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    background: var(--background-bronze);

    font-weight: 500;

    small {
        margin-left: 0.5rem;
    }
`;

export const MoneyIcon = styled(MdAttachMoney)`
    color: #333 !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
`;

export const FormContent = styled.div`
    max-width: 600px;
    width: 100%;
    margin-top: 1rem;
`;
