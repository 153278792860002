import React, { useCallback, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";

// Icons
import { FiLock } from "react-icons/fi";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../services/api";

// Hooks
// import { useToast } from "../../../hooks/toast";

import logoImg from "../../../assets/boss-logo.png";

// Common Components
import InputPasswordWithLabel from "../../../components/InputPasswordWithLabel";
import ButtonLoader from "../../../components/ButtonLoader";
import getValidationErrors from "../../../utils/getValidationErrors";

// Styles
import { Container, AnimationContainer, Content, Background } from "./styles";
import UrlsAddress from "../../../types/urlsAddress";

interface IResetPasswordFormData {
    password: string;
    password_confirmation: string;
}

const ResetPassword: React.FC = () => {
    const [processingRequest, setProcessingRequest] = useState(false);
    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();
    const history = useHistory();
    const location = useLocation();

    const getQueryParams = useCallback(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    const handleSubmit = useCallback(
        async (data: IResetPasswordFormData) => {
            try {
                setProcessingRequest(true);
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    password: Yup.string().required("Password is required"),
                    password_confirmation: Yup.string().when("password", {
                        is: val => val && val.length > 0,
                        then: Yup.string()
                            .oneOf(
                                [Yup.ref("password")],
                                "Passwords didn't match",
                            )
                            .required(),
                    }),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                const { password, password_confirmation } = data;

                const query = getQueryParams();

                const token = query.get("token");
                const email = query.get("email");

                if (!token || !email) {
                    throw new Error();
                }

                await api.post("/account/resetPassword", {
                    password,
                    confirmPassword: password_confirmation,
                    email,
                    token,
                });

                // addToast({
                //     type: "success",
                //     title: "Password successfully reset",
                //     description:
                //         "We've reset your password. Please, sign in using your new password.",
                // });
                toast.success(
                    "We've reset your password. Please, sign in using your new password.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );

                // history.push("/sign-in");
                history.push(UrlsAddress.SIGN_IN);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                // addToast({
                //     type: "error",
                //     title: "Error when recovering password",
                //     description:
                //         "There was an error reseting your password. Please, try again!",
                // });
                toast.error(
                    "There was an error reseting your password. Please, try again!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            } finally {
                setProcessingRequest(false);
            }
        },
        [getQueryParams, history],
    );

    return (
        <>
            <Container>
                {/*  */}
                <Content>
                    <AnimationContainer>
                        <img src={logoImg} alt="MOJ" />

                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <h1>Reset my password</h1>

                            <InputPasswordWithLabel
                                name="password"
                                icon={FiLock}
                                label="New password"
                                placeholder="New password"
                            />
                            <InputPasswordWithLabel
                                name="password_confirmation"
                                icon={FiLock}
                                label="Confirm Password"
                                placeholder="New password confirmation"
                            />
                            <ButtonLoader
                                processingRequest={processingRequest}
                                type="submit"
                            >
                                Change password
                            </ButtonLoader>
                        </Form>
                    </AnimationContainer>
                </Content>
                <Background />
            </Container>
        </>
    );
};

export default ResetPassword;
