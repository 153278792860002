import React, {
    useCallback,
    useRef,
    useState,
    useEffect,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { parse, isValid } from "date-fns";

// Icons
import { FiMail, FiUser } from "react-icons/fi";
import { FaMapMarkerAlt, FaBirthdayCake, FaPhoneAlt } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// Service
import api from "../../../services/api";

// Hooks
import { useAuth } from "../../../hooks/auth";
// import { useToast } from "../../../hooks/toast";
import { useLoader } from "../../../hooks/loader";

// Common Components
import InputWithLabel from "../../InputWithLabel";
import InputMaskWithLabel from "../../InputMaskWithLabel";
import InputNumberWithLabel from "../../InputNumberWithLabel";
import ButtonLoader from "../../ButtonLoader";
import getValidationErrors from "../../../utils/getValidationErrors";

// Styles
import { Container, Content, Title } from "./styles";
import {
    convertDateToString,
    convertStringToDate,
} from "../../../utils/dateUtilities";
import { IMOJCustomError } from "../../../types/globalTypes";

// Interfaces
interface IProfileFormData {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    contactNumber: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    idCountry: string | null;
    countryName: string;
    // initialCountry: number;
}

const CustomerProfile: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [newZealandId, setNewZealandId] = useState("");

    const [customerData, setCustomerData] = useState<
        IProfileFormData | undefined
    >();

    const { showLoader, hideLoader } = useLoader();

    const { user, updateUser } = useAuth();

    const fetchData = useCallback(async () => {
        showLoader("Processing...");

        await api
            .get("customer")
            .then(response => {
                const { data } = response;

                if (data && data.success) {
                    const customer: IProfileFormData = data.data;

                    if (customer.birthDate) {
                        const parsedDate = new Date(customer.birthDate);

                        customer.birthDate = convertDateToString(parsedDate);
                    }

                    setCustomerData(customer);
                }
            })
            .catch(() => {
                toast.error("Error when loading Customer data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        const { data } = await api.get("country/GetNewZealand");
        let idNZ = "00000000-0000-0000-0000-000000000000";

        if (data && data.success) {
            idNZ = data.data.id as string;

            setNewZealandId(idNZ);
        }

        hideLoader();
    }, [showLoader, hideLoader]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const yupCustomDateValidator = (data: string): boolean => {
        if (data) {
            const parsedDate = parse(data, "dd/MM/yyyy", new Date());

            if (isValid(parsedDate)) {
                return true;
            }
        }

        return false;
    };

    const getNewZealandId = useMemo(() => {
        return newZealandId;
    }, [newZealandId]);

    const handleSubmit = useCallback(
        async (data: IProfileFormData) => {
            try {
                formRef.current?.setErrors({});
                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail is required")
                        .email("E-mail is invalid"),
                    firstName: Yup.string().required("First name is required"),
                    birthDate: Yup.string().test(
                        "Date of Birth",
                        "Date is invalid",
                        value => yupCustomDateValidator(value),
                    ),
                    contactNumber: Yup.string().required(
                        "Contact number is required",
                    ),
                    streetAddress: Yup.string().required(
                        "Street address is required",
                    ),
                    suburb: Yup.string().required("Suburb is required"),
                    city: Yup.string().required("City is required"),
                    state: Yup.string().required("State is required"),
                    postcode: Yup.string().required("Postcode is required"),
                    idCountry: Yup.string().required("Country is required"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                setProcessingRequest(true);

                const { birthDate } = data;

                const formData = {
                    ...data,
                    id: user.id,
                    idCountry: getNewZealandId,
                    birthDate: convertStringToDate(birthDate),
                };

                await api.put(`customer/update/${user.id}`, formData);

                // success message
                toast.success(
                    "Your profile information has been updated successfully!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );

                updateUser({
                    id: user.id,
                    email: user.email,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    lastName: data.lastName,
                    showInstructions: user.showInstructions,
                    userType: user.userType,
                    claims: user.claims,
                    role: user.role,
                });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                const { errors, success }: IMOJCustomError = err.response.data;

                if (!success) {
                    if (errors) {
                        for (let index = 0; index < errors.length; index++) {
                            const element = errors[index];

                            toast.error(element, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }

                    return;
                }

                toast.error(
                    "An error occurred when updating your profile. Please, try again!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            } finally {
                setProcessingRequest(false);
            }
        },
        [
            user.id,
            getNewZealandId,
            updateUser,
            user.claims,
            user.email,
            user.role,
            user.showInstructions,
            user.userType,
        ],
    );

    return (
        <Container>
            <Title>
                <h3>Profile</h3>
            </Title>

            <Content>
                {customerData ? (
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={customerData}
                    >
                        <InputWithLabel
                            name="email"
                            label="E-mail"
                            icon={FiMail}
                            readOnly
                        />
                        <InputWithLabel
                            name="firstName"
                            icon={FiUser}
                            label="First Name"
                            placeholder="First Name *"
                        />
                        <InputWithLabel
                            name="middleName"
                            icon={FiUser}
                            label="Middle Name"
                            placeholder="Middle Name"
                        />
                        <InputWithLabel
                            name="lastName"
                            icon={FiUser}
                            label="Last Name"
                            placeholder="Last Name"
                        />
                        <InputMaskWithLabel
                            name="birthDate"
                            icon={FaBirthdayCake}
                            label="Date of Birth"
                            placeholder={`e.g. 31/12/${new Date().getFullYear()}`}
                            mask="99/99/9999"
                        />
                        <InputNumberWithLabel
                            name="contactNumber"
                            icon={FaPhoneAlt}
                            label="Contact number"
                            placeholder="Contact number *"
                        />

                        <InputWithLabel
                            name="streetAddress"
                            icon={FaMapMarkerAlt}
                            label="Street Address"
                            placeholder="PO Box/Street Address *"
                        />
                        <InputWithLabel
                            name="suburb"
                            icon={FaMapMarkerAlt}
                            label="Suburb"
                            placeholder="Suburb *"
                        />
                        <InputWithLabel
                            name="city"
                            icon={FaMapMarkerAlt}
                            label="City/Town"
                            placeholder="City/Town *"
                        />
                        <InputWithLabel
                            name="state"
                            icon={FaMapMarkerAlt}
                            label="Region"
                            placeholder="Region *"
                        />
                        <InputMaskWithLabel
                            name="postcode"
                            icon={FaMapMarkerAlt}
                            label="Postcode"
                            placeholder="Postcode *"
                            mask="9999"
                        />
                        <InputWithLabel
                            name="idCountry"
                            label="Country"
                            icon={FaMapMarkerAlt}
                            value="New Zealand"
                            readOnly
                        />

                        <ButtonLoader
                            processingRequest={processingRequest}
                            type="submit"
                        >
                            Save changes
                        </ButtonLoader>
                    </Form>
                ) : (
                    <h1>Loading...</h1>
                )}
            </Content>
        </Container>
    );
};

export default CustomerProfile;
