import { parse, format } from "date-fns";

export function convertStringToDate(dateValue: string): Date {
    const parsedDate = parse(dateValue, "dd/MM/yyyy", new Date());

    const day = parsedDate.getDate();
    const month = parsedDate.getMonth();
    const year = parsedDate.getFullYear();

    return new Date(year, month, day, 23, 59, 59);
}

export function convertDateToString(dateValue: Date): string {
    const day = `0${dateValue.getDate()}`.slice(-2);

    const month = `0${dateValue.getMonth() + 1}`.slice(-2);

    const year = dateValue.getFullYear();

    return `${day}/${month}/${year}`;
}

export function getDateTimestamp(data: string): number {
    return Date.parse(data);
}

export function getFormattedDate(
    data: Date | number,
    dateFormat: string,
): string {
    return format(data, dateFormat);
}
