import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface IContainerProps {
    isErrored: boolean;
    isReadOnly?: boolean;
    noBackground?: boolean;
}

interface IOptionsProps {
    inline?: boolean;
}

interface ILabelProps {
    noBackground?: boolean;
    small?: boolean;
    inline?: boolean;
}

export const Container = styled.div<IContainerProps>`
    /* background: #f7faea; */
    /* background: #eaf6bc; */

    background: ${props => (props.noBackground ? "transparent" : "#eaf6bc")};

    border-radius: 10px;
    padding: 16px;
    /* width: 100%; */
    align-self: flex-start;

    /* border: 2px solid #f7faea; */
    /* border: 2px solid #eaf6bc; */

    border: ${props =>
        props.noBackground ? "2px solid transparent" : "2px solid #eaf6bc"};

    /* color: #7c9220; */
    color: #5d700f;

    display: flex;

    ${props =>
        props.isReadOnly &&
        css`
            cursor: not-allowed;
            background: #e9ecef;
            border: 2px solid #ced4da;
        `}

    /* & + div {
        margin-top: 10px;
    } */

    ${props =>
        props.isErrored &&
        css`
            border-color: #c53030;
        `}

    input {
        flex: 1;
        background: transparent;
        border: 0;
        color: #475412;

        ${props =>
            props.isReadOnly &&
            css`
                cursor: not-allowed;
            `}

        &::placeholder {
            color: #475412;
        }
    }

    svg {
        margin-right: 16px;
        color: #7c9220;
    }
`;

export const Options = styled.div<IOptionsProps>`

/* flex-direction: ${({ inline }) => (inline ? "row" : "column")};

    align-items: ${({ inline }) => (inline ? "center" : "flex-start")}; */

    /* ${props =>
        props.inline &&
        css`
            border-radius: 5px;
        `} */

/* ${props =>
    props.inline &&
    css`
        margin-top: 0;
        margin-right: 10px;
    `} */

    display: flex;

    flex-direction: ${({ inline }) => (inline ? "row" : "column")};
    align-items: ${({ inline }) => (inline ? "center" : "flex-start")};
`;

export const Label = styled.label<ILabelProps>`
    font-size: ${props => (props.small ? "12px" : "16px")};

    text-align: start;

    /* font-size: 18px; */
    /* background: #eaf6bc; */
    font-weight: 500;
    background: ${props => (props.noBackground ? "transparent" : "#eaf6bc")};
    color: #5d700f;
    cursor: pointer;

    & + & {
        margin-top: ${({ inline }) => (inline ? "none" : "8px")};
    }

    input {
        margin-right: 16px;
        cursor: pointer;
    }

    input[type="radio"]:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #e9ecef;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid #ced4da;
    }

    input[type="radio"]:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #b2d235;

        /* background-color: ${props =>
            props.noBackground ? "#b2d235" : "#5d700f"}; */

        content: "";
        display: inline-block;
        visibility: visible;
        /* border: 2px solid white; */
        border: 2px solid #5d700f;
    }

    & + & {
        margin-left: ${({ inline }) => (inline ? "16px" : "none")};
    }
`;

export const Error = styled(Tooltip)`
    height: 20px;
    margin-left: 16px;

    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
