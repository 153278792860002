import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";

// assets
import ImgLogo from "../../../assets/boss-logo.png";

// Hooks
import { useAuth } from "../../../hooks/auth";

// Can Component
import Can from "../../../components/Can";

// Common Types
import {
    IMenuLinks,
    BossAdminLinks,
    CustomerLinks,
    ConsultantLinks,
    CandidateLinks,
} from "../../../types/menuLinks";

import {
    Container,
    LogoContainer,
    Logo,
    LinksContainer,
    LinkItem,
    LinkText,
} from "./styles";

const Sidebar: React.FC = () => {
    const [menuLinks, setMenuLinks] = useState<IMenuLinks[]>([]);

    const { user } = useAuth();

    const defineMenuLinks = useCallback(() => {
        // sysAdmin = 1,
        // customer = 2,
        // consultant = 4,
        // candidate = 5,
        // internalCompanyAdmin = 6,
        // internalConsultant = 7,

        switch (user.userType) {
            case 1:
                setMenuLinks(BossAdminLinks);
                break;
            case 2:
                setMenuLinks(CustomerLinks);
                break;
            case 3:
                setMenuLinks(ConsultantLinks);
                break;
            case 4:
                setMenuLinks(ConsultantLinks);
                break;
            case 5:
                setMenuLinks(CandidateLinks);
                break;
            case 6:
                setMenuLinks(ConsultantLinks);
                break;
            case 7:
                setMenuLinks(ConsultantLinks);
                break;

            default:
                break;
        }
    }, [user.userType]);

    useEffect(() => {
        defineMenuLinks();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMenuLinks = useMemo(() => {
        return menuLinks;
    }, [menuLinks]);

    return (
        <Container>
            <LogoContainer>
                <Logo src={ImgLogo} alt="BOSS Technologies" />
            </LogoContainer>
            <LinksContainer>
                {getMenuLinks &&
                    getMenuLinks.map(linkItem => (
                        <Can
                            key={linkItem.position}
                            action={linkItem.action}
                            yes={() => (
                                <LinkItem key={linkItem.position}>
                                    <Link to={linkItem.route}>
                                        {linkItem.icon && (
                                            <linkItem.icon size={30} />
                                        )}
                                        <LinkText>{linkItem.text}</LinkText>
                                    </Link>
                                </LinkItem>
                            )}
                        />
                    ))}
            </LinksContainer>
        </Container>
    );
};

export default Sidebar;
