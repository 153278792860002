import styled from "styled-components";

import { FaCaretDown, FaCaretUp } from "react-icons/fa";

export const Container = styled.div``;

export const DropDownContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const DropDownButton = styled.button`
    background: var(--color-green);
    border: 0;
    outline: 0;
    margin: 0 8px;
    padding: 8px;
    border-radius: 8px;

    font-size: 0.875rem;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #333;
    cursor: pointer;

    box-shadow: -1px 3px 4px 2px rgba(0, 0, 0, 0.3);

    &:hover {
        background: var(--color-green-hover);
    }

    @media only screen and (min-width: 600px) {
        font-size: 1rem;
    }
`;

export const LongDescription = styled.div`
    display: none;

    @media (min-width: 500px) {
        display: block;
    }
`;

export const ShortDescription = styled.div`
    display: block;

    @media (min-width: 500px) {
        display: none;
    }
`;

export const IconDropDownExpanded = styled(FaCaretUp)`
    margin-left: 8px;
`;

export const IconDropDown = styled(FaCaretDown)`
    margin-left: 8px;
`;

export const MOJCreditsDropDownContent = styled.div`
    display: flex;
    background: var(--color-whitesmoke);
    border-radius: 8px;

    position: absolute;
    opacity: 1;
    transform: none;
    transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 62px;

    /* left: -40%; */
    left: -125px;

    @media only screen and (min-width: 375px) {
        /* left: -80px; */
        left: -150px;
    }

    @media only screen and (min-width: 425px) {
        /* left: -50px; */
        left: -140px;
    }

    @media only screen and (min-width: 500px) {
        /* left: -50px; */
        left: -60px;
    }

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const MOJCreditsBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const MOJCreditsBadges = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BadgeBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;
    margin: 0 8px;
`;

export const GoldBadgeColor = styled.div`
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: var(--background-gold);

    color: #333;
    font-weight: 700;
`;

export const BadgeTitle = styled.span`
    font-weight: 600;
`;

export const SilverBadgeColor = styled.div`
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: var(--background-silver);

    color: #333;
    font-weight: 700;
`;

export const BronzeBadgeColor = styled.div`
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: var(--background-bronze);

    color: #333;
    font-weight: 700;
`;

export const Divider = styled.div`
    background: var(--color-green);
    height: 2px;
    overflow: hidden;
    width: 100%;
`;

export const MOJCreditsBoxBuyCredits = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;

    a {
        background: var(--color-green);
        border: 0;
        outline: 0;
        margin: 0 8px;
        padding: 8px;
        border-radius: 8px;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #333;
        cursor: pointer;

        &:hover {
            background: var(--color-green-hover);
        }
    }
`;
