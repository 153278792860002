import React, {
    InputHTMLAttributes,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { IconBaseProps } from "react-icons";
import { FiAlertCircle } from "react-icons/fi";
import { useField } from "@unform/core";

import { Container, Error } from "./styles";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    label: string;
}

const InputInlineWithLabel: React.FC<IInputProps> = ({
    name,
    icon: Icon,
    label,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const { fieldName, defaultValue, error, registerField } = useField(name);

    const { readOnly } = rest;

    useEffect(() => {
        if (defaultValue) {
            setIsFilled(true);
        }

        if (inputRef.current?.value) {
            setIsFilled(true);
        }
    }, [defaultValue]);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);

        setIsFilled(!!inputRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            // Within "current" is our "input"
            ref: inputRef.current,
            // When needs to get input value, we access by "path"
            path: "value",
        });
    }, [fieldName, registerField]);

    return (
        <Container
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocused}
            isReadOnly={readOnly || false}
        >
            {Icon && <Icon size={20} />}
            <input
                id={name}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                ref={inputRef}
                {...rest}
            />
            {label && (
                <label htmlFor={name} className="input-label">
                    {label}
                </label>
            )}
            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default InputInlineWithLabel;
