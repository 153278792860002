import React, {
    createContext,
    useCallback,
    useState,
    useContext,
    useMemo,
    useEffect,
} from "react";

// API
import api from "../services/api";

// Hooks
import { useAuth } from "./auth";

// Common Types
import ActionTypes from "../types/userActions";

interface ICompanyCredits {
    gold: number;
    silver: number;
    bronze: number;
    total: number;
}

interface ICompanyCreditsContextData {
    credits: ICompanyCredits;
    refreshCredits(): void;
}

const CompanyCreditsContext = createContext<ICompanyCreditsContextData>(
    {} as ICompanyCreditsContextData,
);

const CompanyCreditsProvider: React.FC = ({ children }) => {
    const [data, setData] = useState<ICompanyCredits>({} as ICompanyCredits);

    const { isUserAllowed } = useAuth();

    const fetchData = useCallback(async () => {
        if (isUserAllowed(ActionTypes.CONSULTANT_READ_COMPANY_CREDITS)) {
            const response = await api.get("company/getCompanyCredits");

            if (response.data && response.data.success && response.data.data) {
                const { gold, silver, bronze } = response.data.data;

                const objMojCredits: ICompanyCredits = {
                    gold,
                    silver,
                    bronze,
                    total: gold + silver + bronze,
                };

                setData(objMojCredits);
            }
        }
    }, [isUserAllowed]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshCredits = useCallback((): void => {
        fetchData();
    }, [fetchData]);

    const getData = useMemo(() => {
        return data;
    }, [data]);

    return (
        <CompanyCreditsContext.Provider
            value={{
                credits: getData,
                refreshCredits,
            }}
        >
            {children}
        </CompanyCreditsContext.Provider>
    );
};

function useCompanyCredits(): ICompanyCreditsContextData {
    const context = useContext(CompanyCreditsContext);

    if (!context) {
        throw new Error(
            "useCompanyCredits must be used within an CompanyCreditsProvider",
        );
    }

    return context;
}

export { CompanyCreditsProvider, useCompanyCredits };
