export interface IMOJPreviousName {
    firstName: string;
    middleName?: string;
    lastName?: string;
    order: number;
}

export interface IMOJIdentifierReferenceFormDTO {
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    telephone: string;
    mobile: string;
    applicantFirstName: string;
    applicantMiddleName: string;
    applicantLastName: string;
    relationshipYears: number;
}

export interface IMOJAddress {
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    idCountry: string;
    order: number;
    addressType: MOJAddressType;
}

export interface IGridMOJData {
    id: string;
    email: string;
    firstName: string;
    lastName?: string;
    creationDate: Date;
    expiryDateLink: Date;
    code: string;
    mojStatus: number;
    mojFormLink: string;
    payPalPaymentLink?: string;
}

export interface IInternalMOJApplications {
    mojId: string;
    branch: string;
    date: string;
    applicant: string;
    internalReference: number;
    status: string;
    candidateId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
}

enum MOJAddressType {
    PostalAddress = 1,
    CurrentResidential = 2,
    Other1 = 3,
    Other2 = 4,
    Other3 = 5,
}

export enum TypeMOJStatus {
    Total = 0,
    Draft = 1,
    WaitingForPayment = 2,
    Paid = 3,
    Processing = 4,
    Processed = 5,
    HasError = 6,
    Rejected = 7,
    Cancelled = 8,
    Expired = 9,
}

export enum MOJReportType {
    AllConvictions = 1,
    TrafficConvictionsOnly = 2,
}
