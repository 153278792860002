import React from "react";

import { Link } from "react-router-dom";

import Section from "../../Section";

import heroImg from "../../../../assets/check.jpg";
import UrlsAddress from "../../../../types/urlsAddress";

const Hero: React.FC = () => {
    return (
        <Section isHero={true} id="home" backgroundImg={heroImg}>
            <div
                className="blablabla"
                style={{
                    display: "flex",
                    minHeight: "calc(100vh - 120px)",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flex: "1 1 0%",
                        flexFlow: "column wrap",
                        placeContent: "stretch space-around",
                        alignItems: "stratch",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                        }}
                    >
                        <h1>
                            Your New Zealand urgent criminal history checking
                            service
                        </h1>
                    </div>
                    <div
                        style={{
                            //
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                        }}
                    >
                        <h3>
                            Apply for a one-off check or order bulk checks at
                            once
                        </h3>
                    </div>
                    <div
                        style={{
                            //
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                        }}
                    >
                        <div
                            className="buttons-group"
                            style={{
                                //
                                display: "flex",
                                flex: "1 1 0%",
                                flexFlow: "row wrap",
                                placeContent: "center space-around",
                                alignItems: "center",
                                maxWidth: "750px",
                            }}
                        >
                            <div
                                className="button-group-item"
                                style={{
                                    //
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: "250px",
                                    padding: "10px 15px",
                                    margin: "10px",
                                }}
                            >
                                <Link
                                    // to="/sign-up-customer"
                                    to={UrlsAddress.SIGN_UP_CUSTOMER}
                                    style={{
                                        //
                                        background: "#b2d235",
                                        color: "#333",
                                        fontWeight: "bold",
                                        padding: "15px 20px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    GET ONE OFF CHECK
                                </Link>
                            </div>
                            <div
                                className="button-group-item"
                                style={{
                                    //
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: "250px",
                                    padding: "10px 15px",
                                    margin: "10px",
                                    color: "#333",
                                }}
                            >
                                <Link
                                    // to="/sign-up-company"
                                    to={UrlsAddress.SIGN_UP_COMPANY}
                                    style={{
                                        //
                                        background: "#b2d235",
                                        color: "#333",
                                        fontWeight: "bold",
                                        padding: "15px 20px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    SETUP A COMPANY ACCOUNT
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Hero;
