import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface IContainerProps {
    isErrored: boolean;
}

export const Container = styled.div<IContainerProps>`
    background: #eaf6bc;
    border-radius: 10px;
    padding: 8px 16px;
    width: 100%;

    border: 2px solid #eaf6bc;
    color: #7c9220;

    display: flex;
    align-items: center;

    & + div {
        margin-top: 10px;
    }

    ${props =>
        props.isErrored &&
        css`
            border-color: #c53030;
        `}

    div.selectList {
        flex: 1;
        background: transparent;
        border: 0;
        color: #475412;

        &::placeholder {
            color: #475412;
        }
    }

    svg {
        margin-right: 16px;
        color: #7c9220;
    }

    div.passwordEye {
        margin-left: 16px;

        svg {
            margin: 0;
            color: #7c9220;
            cursor: pointer;
        }
    }

    div.react-select__control {
        background: transparent;
        border: none;
        cursor: pointer;
    }

    div.react-select__menu-list {
        max-height: 200px;
    }
`;

export const Error = styled(Tooltip)`
    height: 20px;
    margin-left: 16px;

    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
