import React from "react";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { FcPaid } from "react-icons/fc";

// MasterPage
import Main from "../../Main";

// Common Types
import UrlsAddress from "../../../types/urlsAddress";

import {
    Container,
    MessageDisplay,
    MessageTitle,
    MessageContent,
    MessageLine,
    LinkContainer,
} from "./styles";

const PaymentSuccess: React.FC = () => {
    return (
        <Main center>
            <Container>
                <MessageDisplay>
                    <FcPaid />
                    <MessageTitle>Successfully Submitted!</MessageTitle>
                    <MessageContent>
                        <MessageLine>
                            Thanks for using our service as your urgent criminal
                            checking service provider
                        </MessageLine>
                    </MessageContent>
                    <LinkContainer>
                        <FaChevronLeft />
                        {/* <Link to="/dashboard">Go to dashboard</Link> */}
                        <Link to={UrlsAddress.DASHBOARD}>Go to dashboard</Link>
                    </LinkContainer>
                </MessageDisplay>
            </Container>
        </Main>
    );
};

export default PaymentSuccess;
