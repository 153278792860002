import styled, { css } from "styled-components";
import { shade } from "polished";

interface IButtonStepProps {
    enabled: boolean;
    loading?: boolean;
}

interface INavButtonProps {
    // isClickable: boolean;
    isCompleted: boolean;
    isCurrentStep: boolean;
}

export const Loader = styled.div`
    height: 100%;
    width: 100%;
    background: #000;
    color: #b2d235;
    font-weight: bold;
    font-size: 4rem;
`;

export const Container = styled.div`
    /* background: #fff; */

    /* display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%; */
    display: grid;
    height: 100%;
    width: 100%;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 70px 1fr 70px;
    grid-template-areas:
        "linkDashboard"
        "wizardTitle"
        "wizardMain"
        "wizardActions";
`;

export const LinkContainer = styled.div`
    /* display: flex; */
    display: grid;
    grid-area: linkDashboard;
    /* background: #b2d235; */
`;

export const LinkRow = styled.div`
    display: flex;
    /* padding: 8px; */
    align-items: center;
    justify-content: start;

    @media only screen and (min-width: 400px) {
    }

    svg {
        margin-right: 8px;
    }

    a {
        color: #333;
        /* text-transform: uppercase; */
        font-weight: 400;
    }
`;

export const WizardTitle = styled.div`
    /* background: red; */
    display: grid;
    /* border-bottom: 1px solid #b2d235; */
    grid-area: wizardTitle;
`;

export const MOJTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
`;

export const MOJSubtitle = styled.h1`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
    width: 100%;
    text-align: center;

    @media only screen and (min-width: 768px) {
        font-size: 2.2rem;
    }
`;

export const MOJDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    margin-top: 5px;
`;

export const MOJCode = styled.div`
    font-weight: bold;
    font-size: 0.9rem;

    @media only screen and (min-width: 768px) {
        font-size: 1rem;
    }

    small {
        font-weight: normal;
    }
`;

export const MOJExpiryDate = styled.div`
    font-weight: bold;
    font-size: 0.9rem;

    @media only screen and (min-width: 768px) {
        font-size: 1rem;
    }

    small {
        font-weight: normal;
    }
`;

export const WizardBody = styled.div`
    background: #fff;
    display: grid;
    border-bottom: 1px solid #b2d235;
    grid-area: wizardMain;
    padding: 10px 0;

    /* BORDER-TOP */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 2px;
    border-top-color: #b2d235;
    border-top-style: solid;

    /* overflow: scroll;
    overflow-x: hidden; */
`;

export const WizardBodyForm = styled.div``;

export const WizardBodyFormHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
`;

export const WizardBodyFormHeaderNavigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    flex-wrap: wrap;
`;

export const WizardBodyFormHeaderNavButton = styled.button<INavButtonProps>`
    margin: 8px;
    background: transparent;
    border: none;

    cursor: not-allowed;

    display: flex;
    align-items: center;

    ${props =>
        props.isCompleted &&
        css`
            cursor: pointer;
        `}

    svg {
        width: 32px;
        height: 32px;
        color: #b3b3b3;
        /* color: #c53030; */
        cursor: inherit;

        @media only screen and (min-width: 768px) {
            width: 40px;
            height: 40px;
        }

        @media only screen and (min-width: 992px) {
            width: 24px;
            height: 24px;
        }

        ${props =>
            props.isCurrentStep &&
            css`
                color: #c53030;
            `}

        ${props =>
            props.isCompleted &&
            css`
                color: #4da216;
            `}
    }

    span {
        display: none;

        @media only screen and (min-width: 992px) {
            display: flex;
        }

        margin-left: 8px;
        /* font-size: 14px; */
    }
`;

export const WizardBodyFormHeaderStep = styled.div`
    display: flex;
    justify-content: center;

    h1 {
        font-size: 1.5rem;
        text-align: center;
    }
`;

export const WizardBodyFormContent = styled.div``;

export const WizardActions = styled.div`
    background: #eaf6bc;
    display: grid;
    border-bottom: 1px solid #b2d235;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    grid-area: wizardActions;
`;

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const Previous = styled.div<IButtonStepProps>`
    display: ${props => (props.enabled ? "flex" : "none")};
    align-items: center;
    padding: 10px;
    background: #b2d235;
    border-radius: 5px;
    cursor: pointer;

    transition: color 0.2s;

    &:hover {
        background: ${shade(0.1, "#b2d235")};
    }

    ${props =>
        !props.enabled &&
        css`
            cursor: not-allowed;
            background: #ced4da;

            &:hover {
                background: #ced4da;
            }
        `}

    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    button {
        background: transparent;
        border: none;

        ${props =>
            !props.enabled &&
            css`
                cursor: not-allowed;
            `}
    }
`;

export const Next = styled.div<IButtonStepProps>`
    display: ${props => (props.enabled ? "flex" : "none")};
    align-items: center;
    padding: 10px;
    background: #b2d235;
    border-radius: 5px;
    cursor: pointer;

    transition: color 0.2s;

    &:hover {
        background: ${shade(0.1, "#b2d235")};
    }

    ${props =>
        !props.enabled &&
        css`
            cursor: not-allowed;
            background: #ced4da;

            &:hover {
                background: #ced4da;
            }
        `}

    svg {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }

    button {
        background: transparent;
        border: none;

        ${props =>
            !props.enabled &&
            css`
                cursor: not-allowed;
            `}
    }

    ${props =>
        props.loading &&
        css`
            cursor: not-allowed;
            background: #ced4da;

            &:hover {
                background: #ced4da;
            }

            button {
                cursor: not-allowed;
            }

            .next-icon-loading {
                margin-right: 15px;

                -webkit-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;

                @-webkit-keyframes spin {
                    0% {
                        -webkit-transform: rotate(0deg);
                    }
                    100% {
                        -webkit-transform: rotate(360deg);
                    }
                }

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        `}
`;

export const Submit = styled.div<IButtonStepProps>`
    display: ${props => (props.enabled ? "flex" : "none")};
    align-items: center;
    padding: 10px;
    background: #b2d235;
    border-radius: 5px;
    cursor: pointer;

    transition: color 0.2s;

    &:hover {
        background: ${shade(0.1, "#b2d235")};
    }

    ${props =>
        !props.enabled &&
        css`
            display: none;
            cursor: not-allowed;
            background: #ced4da;

            &:hover {
                background: #ced4da;
            }
        `}

    svg {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }

    button {
        background: transparent;
        border: none;

        ${props =>
            !props.enabled &&
            css`
                cursor: not-allowed;
            `}
    }
`;
