import styled from "styled-components";

interface IModalProps {
    show: boolean;
    size: "sm" | "md" | "lg" | "xl";
}

export const Presentation = styled.div<IModalProps>`
    position: fixed;
    z-index: 7000;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);

    display: ${({ show }) => (show ? "block" : "none")};

    /* ${({ show }) =>
        show &&
        `
        background-color: rgba(178, 210, 53, 0.7);
        html, body {
            overflow: hidden;
        }
    `} */
`;

export const Container = styled.div`
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    height: 100%;
    outline: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalElement = styled.div<IModalProps>`
    max-width: 1200px;
    display: flex;
    max-height: calc(100% - 70px);
    flex-direction: column;
    margin: 35px;
    position: relative;
    overflow-y: auto;
    box-shadow: 0px 11px 15px -7px (0, 0, 0, 0.2) 0px 24px 38px 3px (
            0,
            0,
            0,
            0.14
        ) 0px 9px 46px 8px (0, 0, 0, 0.12);

    border-radius: 4px;
    background-color: #fff;
`;

export const ModalTitle = styled.div`
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 24px;

    /* Aligning Close button */
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
        font-size: 2rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.0075em;
        color: #b2d235;
    }

    svg {
        color: #b2d235;
        cursor: pointer;
    }
`;

export const ModalContent = styled.div`
    padding: 16px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`;

export const ModalActions = styled.div`
    flex: 0 0 auto;
    display: flex;
    padding: 10px 24px;
    align-items: center;
    justify-content: flex-end;

    button {
        color: #b2d235;
        padding: 6px 8px;
        font-size: 1.2rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;

        border: 0;
        cursor: pointer;
        margin: 0;
        display: inline-flex;
        outline: 0;
        padding: 0;
        position: relative;
        align-items: center;
        user-select: none;
        border-radius: 0;
        vertical-align: middle;
        -moz-appearance: none;
        justify-content: center;
        text-decoration: none;
        background-color: transparent;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }
`;
