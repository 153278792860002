import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    /* align-items: center; */
    width: 100%;
    /* background: #fff; */
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    /* background: red; */

    gap: 16px;
`;
